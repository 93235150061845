import React from 'react';
import { Link } from 'react-router-dom';

import { CloseCircleOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';

import { getDecimals } from 'helpers';
import { useAppSelector } from 'store/hook';

interface Props {
  data: any;
  lat: number | string;
  lng: number | string;
  communityList: any;
}

const Marker = ({ data, communityList }: Props) => {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const [show, setShow] = React.useState<boolean>(false);
  const renderMarkerColor = () => {
    if (data?.apartmenttype === 'competitor') {
      return '#fa8c16';
    } else {
      return '#1677ff'; // blue
    }
  };

  if (show) {
    //
  }

  const getMarkerDetails = () => {
    // console.log(communityList, data);
    const found = communityList.filter((item: any) => item.Id == data.parent_id)[0];
    if (found) {
      //
      data.market_Avg_base_rent = found.market_Avg_base_rent;
      // data.market_Avg_google_rating = found.Avg_google_rating;
      // data.market_Avg_price_per_sq = found.Avg_price_per_sq;
      // data.market_Avg_sq_footage = found.Avg_sq_footage;
      // data.market_Sum_google_reviews = found.Sum_google_reviews;
      // data.parent_name = found.apartmentname;
    }
    setShow(true);
  };
  return (
    <motion.div style={{ position: 'relative', cursor: 'pointer' }}>
      <svg
        onClick={() => getMarkerDetails()}
        height="14px"
        width="14px"
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="-1.92 -1.92 35.80 35.80"
        xmlSpace="preserve"
        fill={renderMarkerColor()}
        stroke={renderMarkerColor()}
        strokeWidth="0.00031955"
      >
        <g id="SVGRepo_bgCarrier" strokeWidth="0">
          <rect x="-1.92" y="-1.92" width="35.80" height="35.80" rx="17.9" fill="#fff" strokeWidth="0"></rect>
        </g>
        <g
          id="SVGRepo_tracerCarrier"
          strokeLinecap="round"
          strokeLinejoin="round"
          stroke="#CCCCCC"
          strokeWidth="0.06391"
        ></g>
        <g id="SVGRepo_iconCarrier">
          {' '}
          <g>
            {' '}
            <path
              style={{ fill: renderMarkerColor() }}
              d="M27.25,4.655C20.996-1.571,10.88-1.546,4.656,4.706C-1.571,10.96-1.548,21.076,4.705,27.3 c6.256,6.226,16.374,6.203,22.597-0.051C33.526,20.995,33.505,10.878,27.25,4.655z"
            ></path>{' '}
            <path
              style={{ fill: renderMarkerColor() }}
              d="M13.288,23.896l-1.768,5.207c2.567,0.829,5.331,0.886,7.926,0.17l-0.665-5.416 C17.01,24.487,15.067,24.5,13.288,23.896z M8.12,13.122l-5.645-0.859c-0.741,2.666-0.666,5.514,0.225,8.143l5.491-1.375 C7.452,17.138,7.426,15.029,8.12,13.122z M28.763,11.333l-4.965,1.675c0.798,2.106,0.716,4.468-0.247,6.522l5.351,0.672 C29.827,17.319,29.78,14.193,28.763,11.333z M11.394,2.883l1.018,5.528c2.027-0.954,4.356-1.05,6.442-0.288l1.583-5.137 C17.523,1.94,14.328,1.906,11.394,2.883z"
            ></path>{' '}
            <circle style={{ fill: renderMarkerColor() }} cx="15.979" cy="15.977" r="6.117"></circle>{' '}
          </g>{' '}
        </g>
      </svg>
      <AnimatePresence>
        {show && (
          <motion.div className="marker__popup">
            {/* <Button
              type="ghost"
              danger
              size="small"
              icon={
                <CloseCircleOutlined style={{ fontSize: 16, color: "#fff" }} />
              }
              style={{
                position: "absolute",
                top: 5,
                right: 0,
              }}
              onClick={() => setShow(false)}
            /> */}
            <CloseCircleOutlined
              style={{
                fontSize: 18,
                color: '#fff',
                position: 'absolute',
                top: 5,
                right: 5,
              }}
              onClick={() => setShow(false)}
            />
            <Link
              to={
                data?.apartmenttype === 'competitor'
                  ? `/view-apartments?id=${data.parent_id}&c_id=${data?.id}`
                  : `/view-apartments?id=${data.id}`
              }
              // to={
              //   currentUser?.isStaff
              //     ? `/create-apartments/comparison?id=${data.id}`
              //     : `/view-apartments?id=${data.id}`
              // }
            >
              <Typography.Title level={5} style={{ color: '#fff' }}>
                {data?.community?.apartmentname}
              </Typography.Title>
            </Link>

            <div style={{ display: 'inline-block' }}>
              <span className="marker_rate_title">AVG. RATE</span>
              <div className="marker_price__text_box">
                {/* <small className="marker_doller_sign">$</small> */}
                <Typography.Title level={2} className="marker_price_value" style={{ color: 'inherit' }}>
                  <sup className="marker_doller_signn" style={{ fontSize: 14 }}>
                    $
                  </sup>
                  {parseInt(`${getDecimals(data?.Avg_base_rent).int}`).toLocaleString()}
                  <small className="marker_float_points">.{getDecimals(data?.Avg_base_rent).point}</small>
                </Typography.Title>
              </div>
              <span className="marker_rate_title">MARKET AVG.</span>
              <div className="marker_price__text_boxl">
                <Typography.Title
                  level={2}
                  className="marker_price_value"
                  style={{ color: '#389e0d', position: 'relative' }}
                >
                  <sup className="marker_doller_signn" style={{ color: '#389e0d', fontSize: 14 }}>
                    $
                  </sup>
                  {data?.apartmenttype === 'apartment' && (
                    <>
                      {parseInt(`${getDecimals(data?.market_Avg_base_rent).int}`).toLocaleString()}
                      <small className="marker_float_points">
                        .{parseInt(`${getDecimals(data?.market_Avg_base_rent).point}`)}
                      </small>
                    </>
                  )}
                </Typography.Title>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default Marker;
