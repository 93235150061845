import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, message, Select, Table, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import axios from 'axios';
// @ts-ignore
import { format } from 'date-fns';
import * as XLSX from 'xlsx';
// @ts-ignore
import * as XlsxPopulate from 'xlsx-populate/browser/xlsx-populate';

import { useAppSelector } from 'store/hook';

interface CalculationType {
  label: string;
  value: number;
  id: number;
  CompanyName: string;
  CompanyId: number;
  description: string;
  current_occupancy: number;
  goal_occupancy: number;
  months_to_goal: number;
  lead_volume: string;
  move_outs: string;
  created_at: string;
  update_at: string;
  created_by: string;
  updated_by: string;
  status: 0 | 1;
  baseline_delta: string;
  baseline_swot: string;
  sales_conversion: string;
  company: number;
  base_rent: number;
  sq_footage: number;
  price_per_sq: number;
  CommunityId: number;
  LivingTypeName: string;
  LivingTypeId: number;
  ApartmentTypeId: number;
  process_id: string;
  mkt_avg_base_rent: string;
  calc_proc_id: number;
  competitors_avg_swot_score: number;
  community_swot_score: number;
  needed_to_goal: number;
  inquiry_needed: number;
  first_adjustment: string;
  second_adjustment: string;
  final_recommended_rate: string;
}

interface Props {
  calculationsData: any;
  setCalculationsData: any;
  currentConfig?: any[];
  currentCalculator?: any;
  communityInfo: any;
  onSuccess?: () => void;
}

function GeneratedEditableTable({
  calculationsData,
  setCalculationsData,
  currentConfig = [],
  currentCalculator,
  communityInfo,
  onSuccess,
}: Props) {
  const [params] = useSearchParams();
  const communityId = params.get('id');
  const [isEditable, setIsEditable] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [livingOptions, setLivingOptions] = useState<any[]>([]);
  const [apartmentOptions, setApartmentOptions] = useState<any[]>([]);
  const [add_loading, setAddLoading] = useState<boolean>(false);
  const company_id = useAppSelector((state) => state.auth.currentUser?.company);
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const showMessage = (type: 'success' | 'error', mgs: string) => {
    messageApi.open({
      type: type,
      content: mgs,
    });
  };

  const handleEdit = () => {
    setIsEditable(true);
    showMessage('success', 'Editing is Enabled');
  };

  const exportToExcell = (tabledata: any) => {
    const customHeadings = tabledata.map((item: any) => ({
      'Living Type': item.LivingTypeName,
      'Apartment Type': apartmentOptions.filter((apart) => apart.value == item.apartment_type)[0].label,
      'Avg Market Rate': `$${item.mkt_avg_base_rent}`,
      'Swot Adjustment': (parseFloat(item?.first_adjustment) - parseFloat(item?.mkt_avg_base_rent)).toFixed(2),
      'Sales Conversion Adjustment': item.second_adjustment,
      'Final Price': `$${item.final_recommended_rate}`,
      Description: item.notes,
    }));

    const ws = XLSX.utils.json_to_sheet([]);

    const wb = {
      Sheets: { Calculator: ws },
      SheetNames: ['Calculator'],
      origin: 'A1',
    };
    XLSX.utils.sheet_add_json(
      wb.Sheets.Calculator,
      [
        {
          note: "Report Generated by Further's Pricing Assistant",
        },
      ],
      {
        header: ['note'],
        skipHeader: true,
        origin: 'A1',
      }
    );
    XLSX.utils.sheet_add_json(
      wb.Sheets.Calculator,
      [
        {
          logo: <img src="/pricing_assistant_logo.png" />,
          note: 'Calculator',
          space: '',
          at: `Report Created at: ${format(new Date(), 'MM-dd-yyyy:hh:mm:ss')}`,
        },
      ],
      {
        header: ['logo', 'note', 'space', 'at'],
        skipHeader: true,
        origin: 'A2',
      }
    );
    XLSX.utils.sheet_add_json(wb.Sheets.Calculator, customHeadings, {
      origin: 'A3',
    });
    const ws_details_sheet = 'Pricing Assistant Details';

    /* make worksheet */
    const ws_data = [
      ['Company Name', 'Further Technologies'],
      ['Product Name', 'Pricing Assistant'],
      ['File Name', 'Calculator.xlsx'],
      ['Product Name', 'Pricing Assistant'],
      ['Download By', `${currentUser?.name}`],
      ['Download Date', `${format(new Date(), 'MM-dd-yyyy')}`],
      ['Learn More about the Pricing Assistant', 'https://www.talkfurther.com/pricingassistant'],
    ];
    const ws_details = XLSX.utils.aoa_to_sheet(ws_data);

    /* Add the worksheet to the workbook */
    XLSX.utils.book_append_sheet(wb, ws_details, ws_details_sheet);
    const excelBuffer = XLSX.write(wb, {
      bookType: 'xlsx',
      type: 'array',
      Props: {
        Company: 'Further Technologies',
        Manager: 'Alex Smit',
        Author: `${currentUser?.name}`,
        LastAuthor: `${currentUser?.name}`,
        Title: 'Calculator From Pricing Assistant',
        Subject: `Calculator File of ${communityInfo?.comunity_name}`,
      },
    });
    const data = new Blob([excelBuffer], { type: 'xlsx' });
    const dataInfo = {
      atCell: 'D2:D2',
      titleRange: 'B2:C2',
      theadRange: 'A3:G3',
      tbodyRange: `A4:G${customHeadings.length + 3}`,
    };
    addStyle(data, dataInfo).then((url: any) => {
      //
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute("download", "Calculator.xlsx");
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  };

  const addStyle = (workbookBlob: any, dataInfo: any) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook: any) => {
      workbook.sheets().forEach((sheet: any, index: number) => {
        if (index == 0) {
          sheet.usedRange().style({
            fontFamily: 'AvenirLTPro-Medium',
            verticalAlignment: 'center',
          });
          sheet.column('A').width(35);
          sheet.column('B').width(33);
          sheet.column('C').width(33);
          sheet.column('D').width(36);
          sheet.column('E').width(58);
          sheet.column('F').width(23);
          sheet.column('G').width(35);
          sheet.row(1).style({
            horizontalAlignment: 'left',
            fontSize: 9,
          });
          sheet.range(dataInfo.titleRange).merged(true).style({
            fontSize: 36,
            horizontalAlignment: 'center',
            verticalAlignment: 'center',
            underline: true,
          });
          sheet.range(dataInfo.theadRange).style({
            bold: true,
            horizontalAlignment: 'left',
            fontSize: 24,
            fill: 'D9D9D9',
          });
          if (dataInfo.tbodyRange) {
            sheet.range(dataInfo.tbodyRange).style({
              horizontalAlignment: 'left',
              bold: true,
              fontSize: 12,
              wrapText: true,
            });
          }
          sheet.cell(2, 4).style({
            horizontalAlignment: 'center',
            verticalAlignment: 'bottom',
            fontSize: 9,
          });
        } else if (index == 1) {
          sheet.column('A').width(35);
          sheet.column('B').width(55);
          sheet.cell(7, 2).style({
            horizontalAlignment: 'left',
            fontColor: '0563c1',
            underline: true,
          });
        }
      });

      return workbook.outputAsync().then((workbookBlob: any) => URL.createObjectURL(workbookBlob));
    });
  };

  const handleDelete = (record: CalculationType) => {
    const idx = calculationsData.findIndex((el: any) => el?.id === record?.id);
    if (idx !== -1) {
      if (calculationsData[idx].op === 'added' || calculationsData[idx].op === undefined) {
        const temp = [...calculationsData];
        temp.splice(idx, 1);
        setCalculationsData(temp);
      } else {
        const finalPayload = {
          data: [
            {
              id: currentConfig[0]?.id,
              apartment_id: currentConfig[0].CommunityId,
              description: 'Test Parent',
              current_occupancy: currentConfig[0].current_occupancy,
              goal_occupancy: currentConfig[0].goal_occupancy,
              months_to_goal: currentConfig[0].months_to_goal,
              lead_volume: currentConfig[0].lead_volume,
              move_outs: currentConfig[0].move_outs,
              status: 1,
              baseline_delta: currentConfig[0].baseline_delta,
              baseline_swot: currentConfig[0].baseline_swot,
              competitors_avg_swot_score: currentConfig[0].competitors_avg_swot_score,
              community_swot_score: currentConfig[0].community_swot_score,
              process_id: currentConfig[0].process_id,
              inquiry_needed: currentConfig[0].inquiry_needed,
              needed_to_goal: currentConfig[0].needed_to_goal,
              Calculator: [
                {
                  id: calculationsData[idx]?.id,
                  description: calculationsData[idx]?.description,
                  apartment_type_id: calculationsData[idx].ApartmenTypeId,
                  living_type_id: calculationsData[idx].LivingTypeId,
                  mkt_avg_base_rent: calculationsData[idx].mkt_avg_base_rent,
                  first_adjustment: calculationsData[idx].first_adjustment,
                  second_adjustment: calculationsData[idx].second_adjustment,
                  final_recommended_rate: calculationsData[idx].final_recommended_rate,
                  calc_proc_id: calculationsData[idx].calc_proc_id,
                  op: 'deleted',
                  disabled: false,
                },
              ],
              sales_conversion: currentConfig[0].sales_conversion,
              op: 'edited',
              disabled: false,
            },
          ],
        };

        setAddLoading(true);
        axios
          .post('/communities/Save_CommunityCalculator/', finalPayload)
          .then((res) => {
            setAddLoading(false);
            setCalculationsData([]);
            showMessage('success', 'Deleted Successfully');
            axios
              .get(`/communities/Get_CommunityCalculator/${currentCalculator}/`)
              .then((res) => {
                setCalculationsData(res.data?.payload?.Calculator);
              })
              .catch((err) => {});
          })
          .catch((err) => {
            setAddLoading(false);
            showMessage("error", "Something went wrong !");
          });
        setIsEditable(false);
      }
    }
  };

  const handleGetRespectiveMarketRate = (
    index: number,
    LivingTypeId: number | string,
    ApartmentTypeId: number | string,
    operation?: string
  ) => {
    axios
      .get(`/communities/Get_CommunityCalculatorRate/${communityId}/${LivingTypeId}/${ApartmentTypeId}/`)
      .then((res) => {
        const base_rent = res.data?.payload['Avg_Values'][0]['Avg_base_rent'];
        const mkt_avg_base_rent = Number(parseInt(base_rent).toFixed(0));
        //
        const sales_conversion: any =
          parseFloat(currentConfig[0]['baseline_delta']) - parseFloat(currentConfig[0]['inquiry_needed']);

        let firstAdj = 0;
        if (currentConfig[0]['community_swot_score'] > currentConfig[0]['competitors_avg_swot_score']) {
          if (currentConfig[0]['community_swot_score'] < parseInt(currentConfig[0]['baseline_swot'])) {
            firstAdj =
              mkt_avg_base_rent +
              (((parseInt(currentConfig[0]['baseline_swot']) + currentConfig[0]['community_swot_score']) * 100) / 100) *
                mkt_avg_base_rent;
          } else {
            firstAdj =
              mkt_avg_base_rent -
              (((currentConfig[0]['community_swot_score'] - parseInt(currentConfig[0]['baseline_swot'])) * 100) / 100) *
                mkt_avg_base_rent;
          }
        } else {
          if (currentConfig[0]['competitors_avg_swot_score'] < parseInt(currentConfig[0]['baseline_swot'])) {
            firstAdj =
              mkt_avg_base_rent +
              (((parseInt(currentConfig[0]['baseline_swot']) - currentConfig[0]['competitors_avg_swot_score']) * 100) /
                100) *
                mkt_avg_base_rent;
          } else {
            firstAdj =
              mkt_avg_base_rent -
              (((currentConfig[0]['competitors_avg_swot_score'] - parseInt(currentConfig[0]['baseline_swot'])) * 100) /
                100) *
                mkt_avg_base_rent;
          }
        }
        const secondAdj = (firstAdj * sales_conversion) / 100;
        const finalAdj = firstAdj + secondAdj;

        const temp = [...calculationsData];
        //
        if (operation === "edit") {
          temp[index]["mkt_avg_base_rent"] = mkt_avg_base_rent;
          temp[index]["first_adjustment"] = firstAdj;
          temp[index]["second_adjustment"] = secondAdj;
          temp[index]["final_recommended_rate"] = finalAdj;
          temp[index]["calc_proc_id"] = currentConfig[0]?.id;
        } else {
          temp.push({
            id: -1,
            description: '',
            LivingTypeId: '1',
            ApartmentTypeId: '1',
            mkt_avg_base_rent: mkt_avg_base_rent,
            first_adjustment: firstAdj.toFixed(0),
            second_adjustment: secondAdj.toFixed(2),
            final_recommended_rate: finalAdj,
            calc_proc_id: currentConfig[0]?.id,
            company_id: company_id,
            op: 'added',
          });
        }
        setCalculationsData(temp);
      })
      .catch((err) => {});
  };
  const handleTableChange = (val: string | number | boolean, row: CalculationType, key: string) => {
    const temp = [...calculationsData];
    const index = temp.findIndex((el) => el?.id === row.id);
    if (index !== -1) {
      temp[index][key] = val;

      if (temp[index]['op'] === '') {
        temp[index]['op'] = 'edited';
      }
      setCalculationsData(temp);

      if (key === 'LivingTypeId' || key === 'ApartmentTypeId') {
        handleGetRespectiveMarketRate(
          index,
          calculationsData[index].LivingTypeId,
          calculationsData[index].ApartmentTypeId,
          'edit'
        );
      }
    }
  };

  const handleAddRow = () => {
    handleGetRespectiveMarketRate(calculationsData.length + 1, 1, 1);
  };

  const handleSave = () => {
    const childRecords = calculationsData?.map((obj: any) => ({
      id: -1,
      apartment_id: obj?.CommunityId,
      description: obj?.description,
      apartment_type_id: obj.ApartmentTypeId,
      living_type_id: obj.LivingTypeId,
      mkt_avg_base_rent: obj.mkt_avg_base_rent,
      first_adjustment: obj.first_adjustment,
      second_adjustment: obj.second_adjustment,
      final_recommended_rate: obj.final_recommended_rate,
      calc_proc_id: obj.calc_proc_id,
      base_rent: obj.base_rent,
      company_id: obj?.CompanyId,
      sq_footage: obj.sq_footage,
      price_per_sq: obj.price_per_sq,
      op: obj.op !== undefined ? obj.op : 'added',
      disabled: false,
    }));

    let finalPayload;
    if (calculationsData[0].CommunityId) {
      finalPayload = {
        data: [
          {
            id: -1,
            apartment_id: calculationsData[0].CommunityId,
            description: 'Test Parent',
            current_occupancy: calculationsData[0].current_occupancy,
            goal_occupancy: calculationsData[0].goal_occupancy,
            months_to_goal: calculationsData[0].months_to_goal,
            lead_volume: calculationsData[0].lead_volume,
            move_outs: calculationsData[0].move_outs,
            status: currentConfig[0]?.status,
            baseline_delta: calculationsData[0].baseline_delta,
            baseline_swot: calculationsData[0].baseline_swot,
            competitors_avg_swot_score: calculationsData[0].competitors_avg_swot_score,
            community_swot_score: calculationsData[0].community_swot_score,
            process_id: calculationsData[0].process_id,
            inquiry_needed: calculationsData[0].inquiry_needed,
            needed_to_goal: calculationsData[0].needed_to_goal,
            Calculator: childRecords,
            sales_conversion: calculationsData[0].sales_conversion,
            op: 'added',
            disabled: false,
          },
        ],
      };
    } else {
      finalPayload = {
        data: [
          {
            id: currentConfig[0]?.id,
            apartment_id: currentConfig[0]?.apartment,
            description: 'Test Parent',
            current_occupancy: currentConfig[0]?.current_occupancy,
            goal_occupancy: currentConfig[0]?.goal_occupancy,
            months_to_goal: currentConfig[0]?.months_to_goal,
            lead_volume: currentConfig[0]?.lead_volume,
            move_outs: currentConfig[0]?.move_outs,
            status: currentConfig[0]?.status,
            baseline_delta: currentConfig[0]?.baseline_delta,
            baseline_swot: currentConfig[0]?.baseline_swot,
            competitors_avg_swot_score: currentConfig[0]?.competitors_avg_swot_score,
            community_swot_score: currentConfig[0]?.community_swot_score,
            process_id: currentConfig[0]?.process_id,
            inquiry_needed: currentConfig[0]?.inquiry_needed,
            needed_to_goal: currentConfig[0]?.needed_to_goal,
            Calculator: childRecords,
            sales_conversion: currentConfig[0]?.sales_conversion,
            op: 'edited',
            disabled: false,
          },
        ],
      };
    }

    setAddLoading(true);
    axios
      .post('/communities/Save_CommunityCalculator/', finalPayload)
      .then((res) => {
        setAddLoading(false);
        if (onSuccess) {
          onSuccess();
        }
        if (calculationsData[0].CommunityId) setCalculationsData([]);
        showMessage('success', 'Saved Successfully');
      })
      .catch((err) => {
        setAddLoading(false);
        showMessage("error", "Something went wrong !");
      });
    setIsEditable(false);
  };

  useEffect(() => {
    axios
      .get(`/communities/Get_UniqueList/`)
      .then((res: any) => {
        const livingType = res.data?.payload['LivingTypes'];
        const apartmentType = res.data?.payload['ApartmentTypes'];

        const modifiedLivingTypes: any = [
          ...livingType.map((service: any) => ({
            label: service.type,
            value: Math.floor(service.id),
          })),
        ];

        const modifiedApartmentTypes: any = [
          ...apartmentType.map((floorPlan: any) => ({
            label: floorPlan.type,
            value: Math.floor(floorPlan.id),
          })),
        ];

        setLivingOptions(modifiedLivingTypes);
        setApartmentOptions(modifiedApartmentTypes);
      })
      .catch((err) => {});
  }, []);

  // table props

  //

  const columns: ColumnsType<CalculationType> = [
    {
      title: <h2 style={{ fontSize: 18 }}>Living Type</h2>,
      dataIndex: 'LivingTypeName',
      key: 'LivingTypeName',
      render: (value, record) => {
        return (
          <span style={{ fontSize: 16 }}>
            <Select
              bordered={false}
              value={value}
              style={{ width: '200px' }}
              options={livingOptions}
              onChange={(val) => handleTableChange(val, record, 'LivingTypeId')}
              placeholder="Select"
            />
          </span>
        );
      },
    },
    {
      title: <h2 style={{ fontSize: 18 }}>Apartment Type</h2>,
      dataIndex: 'ApartmentTypeName',
      key: 'ApartmentTypeName',
      render: (value, record) => {
        return (
          <span style={{ fontSize: 16 }}>
            <Select
              bordered={false}
              value={value}
              style={{ width: '200px' }}
              options={apartmentOptions}
              onChange={(val) => handleTableChange(val, record, 'ApartmentTypeId')}
              placeholder="Select"
            />
          </span>
        );
      },
    },
    {
      title: <h2 style={{ fontSize: 18 }}>Avg Market Rate</h2>,
      dataIndex: 'mkt_avg_base_rent',
      key: 'mkt_avg_base_rent',
      render: (value, record) => {
        return (
          <span style={{ fontSize: 16 }}>
            <Input
              type="number"
              value={value}
              required
              readOnly={false}
              onChange={(e) => handleTableChange(e.target.value, record, 'mkt_avg_base_rent')}
            />
          </span>
        );
      },
    },
    {
      title: <h2 style={{ fontSize: 18 }}>Swot Adjustment</h2>,
      dataIndex: 'first_adjustment',
      key: 'first_adjustment',
      render: (value, record) => {
        return (
          <span style={{ fontSize: 16 }}>
            {/* <Input
              type="string"
              value={value}
              required
              readOnly={false}
              onChange={(e) =>
                handleTableChange(e.target.value, record, "first_adjustment")
              }
            /> */}
            {(parseFloat(record?.first_adjustment) - parseFloat(record?.mkt_avg_base_rent)).toFixed(2)}
          </span>
        );
      },
    },
    {
      title: <h2 style={{ fontSize: 18 }}>Sales Conversion Adjustment</h2>,
      dataIndex: 'second_adjustment',
      key: 'second_adjustment',
      render: (value, record) => {
        return (
          <span style={{ fontSize: 16 }}>
            {value}
            {/* <Input
              type="string"
              value={value}
              required
              readOnly={false}
              onChange={(e) =>
                handleTableChange(e.target.value, record, "second_adjustment")
              }
            /> */}
          </span>
        );
      },
    },
    {
      title: <h2 style={{ fontSize: 22 }}>Final Price</h2>,
      dataIndex: 'final_recommended_rate',
      key: 'final_recommended_rate',
      render: (value, record) => {
        return (
          <div style={{ fontSize: 22 }}>
            <Input
              type="string"
              value={value}
              style={{
                fontWeight: 700,
                fontSize: 24,
                width: 150,
                background: 'transparent',
              }}
              required
              readOnly={false}
              onChange={(e) => handleTableChange(e.target.value, record, 'final_recommended_rate')}
            />
          </div>
        );
      },
    },
    {
      title: <h2 style={{ fontSize: 18 }}>Description</h2>,
      dataIndex: 'description',
      key: 'description',
      render: (value, record) => {
        return (
          <div style={{ fontSize: 20 }}>
            <Input
              type="text"
              value={value}
              onChange={(e) => handleTableChange(e.target.value, record, 'description')}
            />
          </div>
        );
      },
    },
    {
      title: <h2 style={{ fontSize: 18 }}>Action</h2>,
      key: 'operation',
      render: (_, record) => {
        return <Button type="link" danger icon={<DeleteOutlined />} onClick={() => handleDelete(record)} />;
      },
    },
  ];

  return (
    <div style={{ margin: '0 auto', padding: '0px 20px 100px 20px' }}>
      {contextHolder}
      <Tooltip title="Export to Excel Sheet" placement="topRight">
        <Button
          onClick={() => exportToExcell(calculationsData)}
          type="text"
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 96 96"
              fill="#FFF"
              strokeMiterlimit="10"
              strokeWidth="2"
              style={{ width: '100%', height: 25 }}
            >
              <path
                stroke="#979593"
                d="M67.1716,7H27c-1.1046,0-2,0.8954-2,2v78 c0,1.1046,0.8954,2,2,2h58c1.1046,0,2-0.8954,2-2V26.8284c0-0.5304-0.2107-1.0391-0.5858-1.4142L68.5858,7.5858 C68.2107,7.2107,67.702,7,67.1716,7z"
              />
              <path fill="none" stroke="#979593" d="M67,7v18c0,1.1046,0.8954,2,2,2h18" />
              <path
                fill="#C8C6C4"
                d="M51 61H41v-2h10c.5523 0 1 .4477 1 1l0 0C52 60.5523 51.5523 61 51 61zM51 55H41v-2h10c.5523 0 1 .4477 1 1l0 0C52 54.5523 51.5523 55 51 55zM51 49H41v-2h10c.5523 0 1 .4477 1 1l0 0C52 48.5523 51.5523 49 51 49zM51 43H41v-2h10c.5523 0 1 .4477 1 1l0 0C52 42.5523 51.5523 43 51 43zM51 67H41v-2h10c.5523 0 1 .4477 1 1l0 0C52 66.5523 51.5523 67 51 67zM79 61H69c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C80 60.5523 79.5523 61 79 61zM79 67H69c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C80 66.5523 79.5523 67 79 67zM79 55H69c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C80 54.5523 79.5523 55 79 55zM79 49H69c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C80 48.5523 79.5523 49 79 49zM79 43H69c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C80 42.5523 79.5523 43 79 43zM65 61H55c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C66 60.5523 65.5523 61 65 61zM65 67H55c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C66 66.5523 65.5523 67 65 67zM65 55H55c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C66 54.5523 65.5523 55 65 55zM65 49H55c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C66 48.5523 65.5523 49 65 49zM65 43H55c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C66 42.5523 65.5523 43 65 43z"
              />
              <path
                fill="#107C41"
                d="M12,74h32c2.2091,0,4-1.7909,4-4V38c0-2.2091-1.7909-4-4-4H12c-2.2091,0-4,1.7909-4,4v32 C8,72.2091,9.7909,74,12,74z"
              />
              <path d="M16.9492,66l7.8848-12.0337L17.6123,42h5.8115l3.9424,7.6486c0.3623,0.7252,0.6113,1.2668,0.7471,1.6236 h0.0508c0.2617-0.58,0.5332-1.1436,0.8164-1.69L33.1943,42h5.335l-7.4082,11.9L38.7168,66H33.041l-4.5537-8.4017 c-0.1924-0.3116-0.374-0.6858-0.5439-1.1215H27.876c-0.0791,0.2684-0.2549,0.631-0.5264,1.0878L22.6592,66H16.9492z" />
            </svg>
          }
          style={{ marginBottom: 10, marginLeft: 25 }}
        />
      </Tooltip>
      <Table
        columns={columns}
        dataSource={calculationsData}
        scroll={{ x: 1500 }}
        pagination={false}
        bordered={true}
        rowKey="ID"
      />

      <br />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Button
          icon={<PlusOutlined />}
          type="default"
          shape="round"
          style={{
            fontSize: 14,
            height: 'max-content',
            padding: '6px 10px 4px 10px',
            marginTop: '5px',
          }}
          onClick={handleAddRow}
        >
          Add
        </Button>
        <Button
          type="primary"
          htmlType="button"
          loading={add_loading}
          disabled={add_loading}
          onClick={handleSave}
          style={{ minWidth: 150 }}
        >
          Save
        </Button>
      </div>
    </div>
  );
}

export default GeneratedEditableTable;
