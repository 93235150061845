import React, { Dispatch, memo, SetStateAction, useEffect, useState } from 'react';
import { useReactToPrint } from 'react-to-print';

import {
  CaretDownOutlined,
  CaretUpOutlined,
  CloseCircleFilled,
  FullscreenOutlined,
  InfoCircleOutlined,
  LeftOutlined,
  LoadingOutlined,
  RedoOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { Column, ColumnConfig, Scatter } from '@ant-design/plots';
import {
  Button,
  Col,
  message,
  Rate,
  Row,
  Skeleton,
  Space,
  Spin,
  Table,
  Tabs,
  Tooltip,
  Typography,
  Watermark,

} from "antd";
import { ColumnsType } from "antd/es/table";
import axios from "config/axiosPrivate";
import { getDecimals } from "helpers";
import CommunityDetailsComparison from "./CommunityDetailsComparison";
import CompareAccommodation from "./CompareAccommodation";
import CompareAccommodationGraph from "../../pageComponents/Graphs/CompareAccomodation";
import CompareCare from "./CompareCare";
import CompareImage from "./CompareImages";
import { useAppDispatch, useAppSelector } from "store/hook";
import { handleSetOptionTypes } from "store/slices/globalSlice";
import { geocodeByAddress } from "react-places-autocomplete";
import { format } from "date-fns";
import GaugeGraph from "../../pageComponents/Graphs/Guage";
import SearchCommunityModal from "modals/SearchCommunityModal";
// import BirdEyeModal from "modals/BirdEyeModal";

function groupByLivingType(data: any[], key: string) {
  const groups: any = {};

  data.forEach(function (val) {
    const category = val[key];
    if (category) {
      if (category in groups) {
        groups[category].push(val);
      } else {
        groups[category] = new Array(val);
      }
    }
  });

  return groups;
}

function groupByApartmentId(data: any[]) {
  const groups: any = {};

  data.forEach(function (val) {
    const category = val.apartment_id;
    if (category) {
      if (category in groups) {
        groups[category].push(val);
      } else {
        groups[category] = new Array(val);
      }
    }
  });

  return groups;
}

interface DataType {
  healing_center: {
    living_type: string;
    amount: string;
    sq_ft: string;
    sq_fit_price: string;
  };
  senior_living: {
    living_type: string;
    amount: string;
    sq_ft: string;
    sq_fit_price: string;
  };
  solar_homes: {
    living_type: string;
    amount: string;
    sq_ft: string;
    sq_fit_price: string;
  };
  senior_homes: {
    living_type: string;
    amount: string;
    sq_ft: string;
    sq_fit_price: string;
  };
  best_living: {
    living_type: string;
    amount: string;
    sq_ft: string;
    sq_fit_price: string;
  };
}

const calculateAverage = (data: any[]) => {
  let avg = 0;
  if (data.length === 0) return avg;
  for (let i = 0; i <= data.length - 1; i++) {
    avg += data[i].base_rent;
  }
  return Math.round(avg / data.length);
};

const getlivingAvgDataset = (data: any[]) => {
  if (data.length === 0) return null;

  let avgBaseRent = 0;
  let avgSqFt = 0;
  let avgRatePerSqFt = 0;

  for (let i = 0; i <= data.length - 1; i++) {
    avgBaseRent += data[i].base_rent || 0;
    avgSqFt += data[i]?.sq_footage || 0;
    avgRatePerSqFt += data[i]?.price_per_sq || 0;
  }

  return {
    avgBaseRent: Math.round(avgBaseRent / data.length),
    avgSqFt: Math.round(avgSqFt / data.length),
    avgRatePerSqFt: Math.round(avgRatePerSqFt / data.length),
  };
};

//===================
interface Props {
  community_id: number;
  communityInfo: any;
  competitorInfo: any;
  loading?: boolean;
  toggleRefresh?: boolean;
  setToggleRefresh?: Dispatch<SetStateAction<boolean>>;
  openPdfModal?: boolean;
  setOpenPdfModal?: Dispatch<SetStateAction<boolean>>;
}

const ComparisonTab = ({
  community_id,
  communityInfo,
  competitorInfo,
  loading = false,
  openPdfModal = false,
  setOpenPdfModal,
  setToggleRefresh,
  toggleRefresh,
}: Props) => {
  const [tableColumns, setColumns] = React.useState<any[]>([]);
  const [tableData, setTableData] = React.useState<any[]>([]);
  const [selectedTab, setSelectedTab] = React.useState<string>('accommodation');
  const [avgData, setAvgData] = React.useState<any>(null);
  const [leftGraphData, setLeftGraphData] = React.useState<any[]>([]);
  const [scatterLoading, setScatterLoading] = React.useState<boolean>(false);
  const [ratingGraphLoading, setRatingGraphLoading] = React.useState<boolean>(true);
  const [qlScoreLoading, setQLScoreLoading] = React.useState<boolean>(false);
  const [leftGraphLoading, setLeftGraphLoading] = React.useState<boolean>(false);
  const [rightGraphLoading, setRightGraphLoading] = React.useState<boolean>(false);
  const [swotBarLoading, setSwotBarLoading] = React.useState<boolean>(false);
  const [categoryGraphLoading, setCategoryGraphLoading] = React.useState<boolean>(false);
  const [ratingLoading, setRatingLoading] = React.useState<boolean>(false);
  const [scatterData, setScatterData] = React.useState<any[]>([]);
  const [swotBarData, setSwotBarData] = React.useState<any[]>([]);
  const [swot4BarData, setSwot4BarData] = React.useState<any[]>([]);
  const [swot4FilteredBarData, setSwot4FilteredBarData] = React.useState<any[]>([]);
  const [ratingData, setRatingData] = React.useState<any[]>([]);
  const [qualitativeScoreData, setQualitativeScoreData] = React.useState<any[]>([]);
  const [feeIncentiveData, setFeeIncentiveData] = React.useState<any[]>([]);
  const [community_market, setcommunity_market] = useState<any>(null);
  const [swotCompetitor, setSwotCompetitor] = useState<string>("");
  const [openSearchCommunityModal, setOpenSearchCommunityModal] =
    useState<boolean>(false);
  // const [openBirdEyeModal, setOpenBirdEyeModal] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const global = useAppSelector((state) => state.global);
  const dispatch = useAppDispatch();
  const componentRef = React.useRef<any>();
  const file_name_with_date =
    communityInfo?.comunity_name + "-" + format(new Date(), "MM-dd-yyyy");
  //
  const ref = React.useRef<HTMLInputElement>(null);
  const makeFullscreen = () => {
    if (document.fullscreenElement == null) {
      const elem = ref.current;
      if (elem) {
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        }
      }
    } else {
      document.exitFullscreen();
    }
  };
  const MemoColumn = memo(({ config }: any) => {
    return <Column {...config} />;
  });

  MemoColumn.displayName = 'MemoColumn';
  const showMessage = (type: 'success' | 'error', mgs: string) => {
    messageApi.open({
      type: type,
      content: mgs,
    });
  };
  const handlePrint = useReactToPrint({
    pageStyle: `@media print {
      body {-webkit-print-color-adjust: exact;}
      tr{
        page-break-inside:avoid;page-break-after:auto;
      }
     
      .page-break{
        page-break-inside:avoid;page-break-after:auto;
      
      }
      @page {
        size: landscape;
        -webkit-print-color-adjust: exact;
       
      }
    }`,
    content: () => componentRef.current,
    documentTitle: file_name_with_date,
  });
  const handleSetLocation = (data: any) => {
    //
    communityInfo.google_map_location = data.place_id;
    communityInfo.address = data.formatted_address;
    communityInfo.latitude = data.lat;
    communityInfo.longitude = data.lng;
    communityInfo.comunity_name = data.name;
    communityInfo.phone_number = data.phone_number;
    communityInfo.state = data.state;
    communityInfo.website = data.website;
    communityInfo.zip_code = data.zip_code;
    communityInfo.city = data.city;

    updateRating();
    setOpenSearchCommunityModal(false);
  };
  // const SaveGooglePlaceID = (place_id: any) => {
  //   const payload = {
  //     id: community_id,
  //     google_map_location: place_id,
  //     op: "edited",
  //   };
  //   axios
  //     .post("/communities/Save_AddComm_CompetitorInfo/", {
  //       data: [payload],
  //     })
  //     .then((res) => {
  //
  //     })
  //     .catch((err) => {
  //
  //     });
  // };

  const updateRating = async () => {
    //
    let updates: any = [];
    let place_id: any = null;

    const service = new google.maps.places.PlacesService(document.createElement('div'));
    if (communityInfo.google_map_location) {
      place_id = communityInfo.google_map_location;
    } else {
      setOpenSearchCommunityModal(true);
      return;
    }
    setRatingLoading(true);
    try {
      service.getDetails(
        {
          placeId: place_id,
        },
        async function (place: any, status: any) {
          //
          if (status == "INVALID_REQUEST") {
            setOpenSearchCommunityModal(true);
            setRatingLoading(false);
            return;
          }
          updates.push({
            id: 1,
            apartment_id: communityInfo.id,
            old_google_ratings: communityInfo.google_rating,
            old_google_reviews: communityInfo.google_reviews,
            op: 'added',
            google_ratings: place.rating || 0,
            google_reviews: place.user_ratings_total || 0,
            place_id: communityInfo.google_map_location,
            formatted_address: communityInfo.address,
            lat: communityInfo.latitude,
            lng: communityInfo.longitude,
            name: communityInfo.comunity_name,
            city: communityInfo.city,
            phone_number: communityInfo.phone_number,
            state: communityInfo.state,
            website: communityInfo.website,
            zip_code: communityInfo.zip_code,
          });

          const placeReviews = place.reviews?.map((_r: any) => ({
            id: 1,
            apartment_id: communityInfo.id,
            google_review_id: '12345678',
            google_review_user: _r.author_name,
            google_review_date: new Date(_r.time * 1000),
            google_review_text: _r.text,
            google_review_url: _r.author_url,
            op: 'added',
          }));
          saveGoogleMatrics(updates, placeReviews || []);
        }
      );
    } catch (err) {
      setRatingLoading(false);
    }
  };
  const saveGoogleMatrics = (updates: any, reviews: any) => {
    axios
      .post(`communities/Save_GoogleMetrics/`, { data: updates })
      .then((res) => {
        saveGoogleReviews(reviews);
      })
      .catch((err) => {
        setRatingLoading(false);
        showMessage('error', 'Something went wrong');
      });
  };
  const saveGoogleReviews = (reviews: any) => {
    if (reviews.length < 1) {
      if (setToggleRefresh) {
        setToggleRefresh(!toggleRefresh);
      }
      getCommunityInfo();
      getQualitativeScore();
      showMessage('success', 'Saved successfully!');
      setRatingLoading(false);
      return;
    }
    axios
      .post(`communities/Save_GoogleReviews/`, { data: reviews })
      .then((res) => {
        //
        if (setToggleRefresh) {
          setToggleRefresh(!toggleRefresh);
        }
        getCommunityInfo();
        getQualitativeScore();
        showMessage('success', 'Saved successfully!');
        setRatingLoading(false);
      })
      .catch((err) => {
        setRatingLoading(false);
        showMessage('error', 'Something went wrong');
      });
  };

  const getFeeIncentives = () => {
    axios
      .get(`/communities/Get_CommunityFeesIncentives/${community_id}/`)
      .then((res) => {
        //
        let Community = res.data.payload.Community;
        if (Community.length > 0) {
          Community = [Community[0]];
        }
        const Competitors = res.data.payload.Competitors;
        const data = [...Community, ...Competitors];
        setFeeIncentiveData(data);
      });
  };

  const getQualitativeScore = () => {
    setQLScoreLoading(true);
    axios
      .get(`/communities/Get_QualitativeScores/${community_id}/`)
      .then((res) => {
        let highScore = 0;
        let highScoreIndex = 0;
        const data = res.data?.payload.map((item: any, idx: any) => {
          if (item.qualitativescores > highScore) {
            highScoreIndex = idx;
            highScore = item.qualitativescores;
          }
          return {
            ...item,
            qualitativescores: item.google_rating && item.google_rating > 0 ? item.qualitativescores : 0,
          };
        });
        data[highScoreIndex].highScore = true;
        //
        const compt_data = data.slice(1);
        const sorted_array = compt_data.sort((a: any, b: any) => {
          if (a.comunity_name < b.comunity_name) {
            return -1;
          }
          if (a.comunity_name > b.comunity_name) {
            return 1;
          }
          return 0;
        });
        //
        setQualitativeScoreData([data[0], ...sorted_array]);
        setQLScoreLoading(false);
      })
      .catch((err) => {
        setQLScoreLoading(false);
      });
  };

  const getCommunityInfo = () => {
    axios
      .get(`/communities/Get_CommunityInfo/${community_id}/`)
      .then((res) => {
        const data = res.data?.payload;

        const community_market = data.Avg_info;
        community_market.Avg_google_rating =
          data.Community_Info[0]?.GoogleRating;
        community_market.Sum_google_reviews =
          data.Community_Info[0]?.GoogleReviews;
        //
        //
        const managementCompany = data.Community_Info[0]?.MGMTCompanyName
          ? `-${data.Community_Info[0]?.MGMTCompanyName}`
          : '';
        setcommunity_market(community_market);
        dispatch(
          handleSetOptionTypes({
            ...global,
            selectedComunityCompanyName: data.Community_Info[0]?.CompanyName + managementCompany,
          })
        );
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (competitorInfo && communityInfo) {
      const array: any = [];
      Object.keys(competitorInfo).forEach((item: any) => {
        array.push(competitorInfo[item]);
      });
      let highScore = 0;
      let highScoreIndex = 0;
      array.map((item: any, idx: any) => {
        if (item.google_rating > highScore) {
          highScoreIndex = idx;
          highScore = item.google_rating;
        }
      });
      //
      if (communityInfo?.google_rating < array[highScoreIndex]?.google_rating) {
        array[highScoreIndex].highScore = true;
      } else {
        communityInfo.highScore = true;
      }

      const sorted_array = array.sort((a: any, b: any) => {
        if (a.comunity_name < b.comunity_name) {
          return -1;
        }
        if (a.comunity_name > b.comunity_name) {
          return 1;
        }
        return 0;
      });
      //
      setRatingData(sorted_array);
      setRatingGraphLoading(false);
    }
  }, [competitorInfo, communityInfo]);

  useEffect(() => {
    if (communityInfo && competitorInfo) {
      const community_column: ColumnsType<DataType> = [
        {
          title: (
            <div style={{ paddingLeft: '30px' }}>
              <Typography.Title style={{ margin: 0 }} level={4}>
                {communityInfo?.comunity_name}
                <span
                  style={{
                    background: '#4CD7D2',
                    color: '#fff',
                    fontSize: '12px',
                    padding: '5px 9px',
                    borderRadius: '10px',
                    marginLeft: 6,
                  }}
                >
                  ME
                </span>
              </Typography.Title>
              <Typography.Text style={{ fontSize: '18px' }}>
                $ {calculateAverage(communityInfo?.livingInfo)} fee
              </Typography.Text>
            </div>
          ),
          dataIndex: `apartment_${communityInfo?.id}`,
          key: `apartment_${communityInfo?.id}`,
          render: (value) => {
            //
            return (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  minWidth: '400px',
                }}
                className="healing__active"
              >
                <div>
                  <Typography.Title level={4} style={{ margin: 0, marginBottom: -10 }}>
                    {value?.Communitytypename}
                  </Typography.Title>
                  <Typography.Title
                    level={5}
                    style={{
                      margin: 0,
                      fontSize: 16,
                      color: '#989696',
                      marginTop: 8,
                    }}
                  >
                    {value?.Livingtypename}
                  </Typography.Title>
                </div>
                <div style={{ textAlign: 'right' }}>
                  <Typography.Title level={3} style={{ margin: '0px' }}>
                    <sup style={{ fontSize: '13px' }}>$</sup>
                    {value?.base_rent || 0}
                  </Typography.Title>
                  <Typography.Text style={{ color: '#989696' }}>
                    {value?.sq_footage || 0} sq. ft. ($
                    {value?.price_per_sq || 0})
                  </Typography.Text>
                </div>
              </div>
            );
          },
        },
      ];

      const competitor_columns: ColumnsType<DataType> = Object.keys(competitorInfo)?.map((key: any) => ({
        title: (
          <div>
            <Typography.Title style={{ margin: 0 }} level={4}>
              {competitorInfo[key]?.comunity_name}
            </Typography.Title>
            <Typography.Text
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '18px',
              }}
            >
              <span style={{ color: '#1ad392', marginRight: 2 }}>$ </span>{' '}
              {calculateAverage(competitorInfo[key]?.livingInfo)} fee
              <CaretUpOutlined style={{ color: '#1ad392', marginLeft: 5 }} />
            </Typography.Text>
          </div>
        ),
        dataIndex: `apartment_${competitorInfo[key]?.id}`,
        key: `apartment_${competitorInfo[key]?.id}`,
        render: (value) => {
          if (value) {
            return (
              <div>
                <Typography.Text style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ color: '#fba31d' }}>$</span>
                  {value?.base_rent || 0}
                  <CaretDownOutlined style={{ color: '#fba31d', marginLeft: 5 }} />
                </Typography.Text>
                <Typography.Title style={{ margin: 0 }} level={5}>
                  {value?.Communitytypename}, {value?.Livingtypename}
                </Typography.Title>
                <Typography.Text style={{ color: '#989696' }}>
                  {value?.sq_footage || 0} sq. ft. ($
                  {value?.price_per_sq || 0})
                </Typography.Text>
              </div>
            );
          } else {
            return null;
          }
        },
      }));
      const columns = community_column.concat(competitor_columns);
      setColumns(columns);

      // make table data
      const sortedLiving = communityInfo?.livingInfo?.sort((a: any, b: any) =>
        a.Livingtypeid < b.Livingtypeid ? -1 : 1
      );

      const livingAvgDataset = getlivingAvgDataset(sortedLiving);
      setAvgData(livingAvgDataset);

      // table data
      const tData = [...sortedLiving];
      Object.keys(competitorInfo).forEach((key: any, idx: number) => {
        tData.push(...competitorInfo[key]?.livingInfo);
      });
      const modified_data = groupByApartmentId(tData);
      let max_length = 0;
      Object.keys(modified_data).forEach((key) => {
        if (modified_data[key]?.length > max_length) {
          max_length = modified_data[key]?.length;
        }
      });

      const tableData: any[] = [];

      for (let i = 0; i < max_length; i++) {
        let obj = {};
        Object.keys(modified_data).forEach((key: any) => {
          obj = {
            ...obj,
            [`apartment_${key}`]: modified_data[key][i] || null,
          };
        });
        tableData.push(obj);
      }

      setTableData(tableData);
    }
  }, [communityInfo, competitorInfo]);

  useEffect(() => {
    if (!community_id || !communityInfo) {
      return;
    }
    setLeftGraphLoading(true);
    axios
      .get(`/communities/Get_HomeFeeLatestGraph/${community_id}/`)
      .then((res) => {
        const data: any[] = res.data?.payload?.Community_Fee_Avereges;
        if (data?.length > 0) {
          const community: any[] = data[0]?.Community || [];
          const competitors: any[] = data[1]?.Competitors || [];
          const sortedCompetitors = competitors.sort(
            (a, b) => b.LivingTypeId - a.LivingTypeId
          );
          //
          const list = [...community, ...sortedCompetitors];
          const grp = groupByLivingType(list, 'LivingTypeId');
          const graph_data: any[] = [];
          Object.keys(grp).forEach((key) => {
            const filtergrp = grp[key].filter((item: any, index: number) => {
              if (item.ApartmentTypeName == 'apartment') {
                const temp = item;
                grp[key][index] = grp[key][0];
                grp[key][0] = temp;
              }
              return item;
            });
            if (key == '1' && filtergrp.length == 0) {
              const extra = {
                ApartmentTypeName: 'apartment',
                CommunityFee: 0,
                CommunityName: communityInfo?.comunity_name,
                LivingTypeName: 'Independent Living',
              };
              grp[key] = [extra, ...grp[key]];
              graph_data.push(...grp[key]);
            } else {
              graph_data.push(...grp[key]);
            }
          });
          const cm_modify = graph_data?.map((cm) => ({
            type: cm?.LivingTypeName,
            value: cm?.CommunityFee,
            name: cm?.CommunityName,
            apartmentTypeName: cm?.ApartmentTypeName,
          }));
          setLeftGraphData(cm_modify);
          setLeftGraphLoading(false);
        }
      })
      .catch((err) => {
        setLeftGraphLoading(false);
      });
  }, [community_id, communityInfo]);

  const [rightGraphData, setRightGraphData] = useState<any[]>([]);

  useEffect(() => {
    if (!community_id || !communityInfo) {
      return;
    }
    setRightGraphLoading(true);
    axios
      .get(`/communities/Get_HomeAvgRateGraph/${community_id}/`)
      .then((res) => {
        const communitydata = res.data.payload?.Market_Rate_Avereges[0];
        const competitordata = res.data.payload?.Market_Rate_Avereges[1];

        const graph_list = [];

        if (communitydata?.Community && communitydata?.Community?.length > 0) {
          graph_list.push(...communitydata?.Community);
        }
        if (competitordata?.Competitors && competitordata?.Competitors?.length > 0) {
          graph_list.push(...competitordata?.Competitors);
        }
        //
        const grp = groupByLivingType(graph_list, "LivingTypesId");
        const graph_data: any[] = [];
        Object.keys(grp).forEach((key) => {
          //
          // const sorted_group = grp[key].sort((a: any, b: any) =>
          //   a.Avg_base_rent > b.Avg_base_rent ? -1 : 1
          // );
          // graph_data.push(...sorted_group);
          const filtergrp = grp[key].filter((item: any, index: number) => {
            if (item.ApartmentTypeName == 'apartment') {
              const temp = item;
              grp[key][index] = grp[key][0];
              grp[key][0] = temp;
            }
            return item;
          });
          if (key == '1' && filtergrp.length == 0) {
            const extra = {
              Avg_base_rent: null,
              CommunityName: communityInfo?.comunity_name,
              LivingTypeName: 'Independent Living',
            };
            grp[key] = [extra, ...grp[key]];
            graph_data.push(...grp[key]);
          } else {
            graph_data.push(...grp[key]);
          }
        });

        const modified_graph_list = graph_data.map((obj) => ({
          type: obj?.LivingTypeName,
          value: Math.floor(obj?.Avg_base_rent),
          name: obj?.CommunityName,
        }));

        // const temp = [extra, ...modified_graph_list];
        //
        setRightGraphData(modified_graph_list);
        setRightGraphLoading(false);
      })
      .catch((error) => {
        setRightGraphLoading(false);
      });
  }, [community_id, communityInfo]);

  useEffect(() => {
    getCommunityInfo();
    getFeeIncentives();
    getQualitativeScore();
  }, [community_id]);

  useEffect(
    () => () => {
      dispatch(
        handleSetOptionTypes({
          ...global,
          selectedComunityCompanyName: '',
        })
      );
    },
    []
  );

  const getScatterGraphData = (commpetitor_id: number) => {
    if (scatterLoading) {
      return;
    }
    setScatterLoading(true);
    //
    axios
      .get(`/communities/Get_DB_SWOT1_Rating_By_Category/${community_id}/${commpetitor_id || 0}`)
      .then((res) => {
        //
        const data = res.data?.payload;
        const sorteddata = data.sort((a: any, b: any) => {
          a['Score'] = a.score = parseFloat(a.score);
          b['Score'] = b.score = parseFloat(b.score);
          a['Category Score'] = a.category_score = parseFloat(a.category_score);
          b['Category Score'] = b.category_score = parseFloat(b.category_score);
          return a.score - b.score;
        });

        setScatterData(sorteddata);
        setScatterLoading(false);
        if (commpetitor_id == 0) {
          setSwotCompetitor('');
        }
      })
      .catch((err) => {
        setScatterLoading(false);
      });
  };
  const getSwot4GraphData = (commpetitor_id: number) => {
    if (categoryGraphLoading) {
      return;
    }
    setCategoryGraphLoading(true);
    axios
      .get(`/communities/Get_DB_SWOT4_Category_Details/${community_id}/${commpetitor_id || 0}`)
      .then((res) => {
        //
        const data = res.data?.payload;
        //
        const intData = data.map((item: any) => ({
          ...item,
          avg_sub_category_score: parseFloat(item.avg_sub_category_score),
          sub_category: item.sub_category.replace(/_/g, ' '),
          'Sub Category Score': parseFloat(item.avg_sub_category_score),
          'Category Name': item.category_name,
        }));
        setSwot4BarData(intData);
        setSwot4FilteredBarData(intData.filter((item: any) => item.category_id == 1));
        setCategoryGraphLoading(false);
      })
      .catch((err) => {
        setCategoryGraphLoading(false);
      });
  };

  const filteredSwot4Data = (category_id: number) => {
    const data = swot4BarData.filter((item) => item.category_id == category_id);
    setSwot4FilteredBarData(data);
  };

  useEffect(() => {
    getScatterGraphData(0);
    getSwot4GraphData(0);
  }, [community_id]);
  // useEffect(() => {
  //
  // }, [swotCompetitor]);

  useEffect(() => {
    setSwotBarLoading(true);
    axios
      .get(`/communities/Get_DB_SWOT2_Score_Comparison/${community_id}/`)
      .then((res) => {
        const data = res.data?.payload;
        const swots = data.map((item: any) => ({
          competitor_id: item.competitor_id,
          competitor_name: item.competitor_name,
          overall_score: parseFloat(item.overall_score),
        }));
        setSwotBarData(swots);
        setSwotBarLoading(false);
      })
      .catch((err) => {
        setSwotBarLoading(false);
      });
  }, [community_id]);

  // left graph
  const leftGraphConfig: ColumnConfig = {
    data: leftGraphData,
    xField: 'type',
    yField: 'value',
    seriesField: 'name',
    appendPadding: 20,
    isGroup: true,
    columnStyle: {
      radius: [0, 0, 0, 0],
    },

    label: {
      position: 'top',
      // 'top', 'middle', 'bottom'
      layout: [
        {
          type: 'interval-adjust-position',
        },
        {
          type: 'adjust-color',
        },
      ],
      formatter: function (val: any, opt: any) {
        return val?.value > 0 ? '$ ' + parseFloat(val?.value).toLocaleString() : '';
      },
    },

    // colorField: "name", // or seriesField in some cases
    // color: colorArray,

    color: ({ name }) => {
      if (name == communityInfo?.comunity_name) {
        return '#275aff';
      }
      return '#d6d6d6';
    },
    legend: {
      position: 'top',
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
      tickLine: {
        length: 0,
      },
    },
    yAxis: {
      label: {
        formatter(text) {
          return `$ ${parseInt(text).toLocaleString()}`;
        },
      },
    },
    tooltip: {
      formatter: (datum: any) => {
        return {
          name: datum.name,
          value: datum.value ? `$ ${parseInt(datum.value).toLocaleString()}` : '$ 0',
        };
      },
    },
  };

  const seriesColors: { [key: string]: string } = {};

  rightGraphData.forEach((item) => {
    const name = item.name;
    if (!seriesColors[name]) {
      if (name == communityInfo?.comunity_name) {
        seriesColors[name] = '#005cbb';
      } else {
        seriesColors[name] = '#5c5c5c';
      }
    }
  });
  const rightGraphConfig: any = {
    data: rightGraphData,
    isGroup: true,
    xField: 'type',
    yField: 'value',
    seriesField: 'name',
    appendPadding: 20,
    columnStyle: (item: any) => {
      return {
        radius: [0, 0, 0, 0],
        fill: seriesColors[item.livingTypeName],
      };
    },
    //colorField: "name", // or seriesField in some cases
    // color: colorArray,
    color: ({ name }: any) => {
      if (name == communityInfo?.comunity_name) {
        return '#275aff';
      }
      return '#d6d6d6';
    },
    label: {
      position: 'top',
      layout: [
        {
          type: 'interval-adjust-position',
        },
        {
          type: 'adjust-color',
        },
      ],
      formatter: function (val: any, opt: any) {
        return val?.value > 0 ? '$ ' + parseFloat(val?.value).toLocaleString() : '';
      },
    },

    legend: {
      position: 'top-right',
    },
    xAxis: {
      visible: true,
      label: {
        autoHide: true,
        autoRotate: false,
      },
      tickLine: {
        length: 0,
      },
      line: {
        style: {
          stroke: '#aaa',
          lineWidth: 1,
        },
      },
      categories: ['Independent Living', 'Assisted Living', 'Memory Care'], // Use the sorted categories
    },
    yAxis: {
      label: {
        formatter(text: any) {
          return `$ ${parseInt(text).toLocaleString()}`;
        },
      },
    },
    tooltip: {
      formatter: (datum: any) => {
        return {
          name: datum.name,
          value: datum.value ? `$ ${parseInt(datum.value).toLocaleString()}` : '$ 0',
        };
      },
    },
  };

  const scatterGraphConfig = {
    appendPadding: 20,
    data: scatterData,
    xField: 'score',
    yField: 'category_score',
    colorField: 'category_id',
    size: 5,
    shape: 'circle',
    pointStyle: {
      fillOpacity: 1,
    },
    yAxis: {
      line: {
        style: {
          stroke: ' #aaa',
        },
      },
      title: {
        text: 'Importance to Customer',
      },
    },
    xAxis: {
      min: 0,
      max: 6,
      tickCount: 8,
      title: {
        text: 'Avg.Market Competition Rating',
      },
      label: {
        autoHide: true,
        autoRotate: false,
      },
      grid: {
        line: {
          style: (text: any, index: any, total: any) => {
            //
            if (index == 2) {
              return {
                stroke: '#fa7550',
              };
            } else if (index == 3) {
              return {
                stroke: '#89919d',
              };
            } else if (index == 4) {
              return {
                stroke: '#22cfd1',
              };
            }
            return {
              stroke: '#eee',
            };
          },
        },
      },

      line: {
        style: {
          stroke: '#aaa',
        },
      },
      tickLine: {
        length: 0,
      },
    },
    tooltip: {
      fields: ['Score', 'Category Score'],
    },
    label: {
      formatter: function (val: any, opt: any) {
        return val?.category_name;
      },
      autoHide: true,
    },
    legend: false as const,
  };

  const swotBarConfig = {
    data: swotBarData,
    xField: 'competitor_name',
    yField: 'overall_score',
    seriesField: 'competitor_name',
    legend: {
      position: 'top',
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    yAxis: {
      min: 0,
      max: 5,
      title: {
        text: 'SWOT Score',
      },
      grid: {
        line: {
          style: (text: any, index: any, total: any) => {
            //
            if (index == 1) {
              return {
                stroke: '#ff0000',
              };
            } else if (index == 2) {
              return {
                stroke: '#fa7550',
              };
            } else if (index == 3) {
              return {
                stroke: '#89919d',
              };
            } else if (index == 4) {
              return {
                stroke: '#22cfd1',
              };
            } else if (index == 5) {
              return {
                stroke: '#008000',
              };
            }
            return {
              stroke: '#eee',
            };
          },
        },
      },
    },
    label: {
      position: 'top',
      layout: [
        {
          type: 'interval-adjust-position',
        },
        {
          type: 'adjust-color',
        },
      ],
      formatter: function (val: any, opt: any) {
        return val?.overall_score;
      },
    },
  };

  const swot4BarConfig = {
    data: swot4FilteredBarData,
    xField: 'sub_category',
    yField: 'avg_sub_category_score',
    legend: {
      position: 'top',
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: true,
      },
    },
    yAxis: {
      min: 0,
      max: 5,
    },
    label: {
      position: 'top',
      layout: [
        {
          type: 'interval-adjust-position',
        },
        {
          type: 'adjust-color',
        },
      ],
      formatter: function (val: any, opt: any) {
        return val?.avg_sub_category_score;
      },
    },
    tooltip: {
      fields: ['Category Name', 'Sub Category Score'],
    },
  };

  const FeeIncentivesColumns: ColumnsType<any> = [
    {
      title: 'Date',
      dataIndex: 'updated_at',
      key: 'updated_at',
      render: (_, row) => (
        <div style={{ height: '100%', width: '100%' }}>
          <span style={{ fontSize: '14px' }}>{row ? format(new Date(row?.updated_at), 'MM-dd-yyyy') : null}</span>
        </div>
      ),
    },
    {
      title: 'Communities',
      dataIndex: 'CommunityName',
      key: 'CommunityName',
    },
    {
      title: 'Living Type Name',
      dataIndex: 'LivingTypeName',
      key: 'LivingTypeName',
    },
    {
      title: 'Incentives',
      dataIndex: 'incentive',
      key: 'incentive',
      render: (_, row) => (
        <div style={{ height: '100%', width: '100%' }}>
          <span style={{ fontSize: '14px' }}>{row?.incentive}</span>
        </div>
      ),
    },
  ];

  if (loading) {
    return (
      <div style={{ padding: '20px' }}>
        <Space>
          <Skeleton.Button active={true} size="large" style={{ width: '200px', height: 100 }} />
          <Skeleton.Button active={true} size="large" style={{ width: '200px', height: 100 }} />
          <Skeleton.Button active={true} size="large" style={{ width: '200px', height: 100 }} />{' '}
          <Skeleton.Button active={true} size="large" style={{ width: '200px', height: 100 }} />
        </Space>
        <br />
        <br />
        <Skeleton active={true} style={{ width: '100%', height: '100px' }} />
        <Skeleton active={true} style={{ width: '100%', height: '100px' }} />
      </div>
    );
  }

  // @ts-ignore
  // @ts-ignore
  return (
    <div>
      {contextHolder}
      <SearchCommunityModal
        loading={false}
        open={openSearchCommunityModal}
        handleClose={() => setOpenSearchCommunityModal(false)}
        handleSetLocation={handleSetLocation}
        searchKeyword={communityInfo?.comunity_name}
      />

      <div
        style={{
          maxWidth: '1100px',
          padding: '0px 20px',
        }}
      >
        <Row className="rowgap-vbox" gutter={[16, 10]}>
          {/* 1 */}
          <Col xs={12} sm={12} md={8} lg={5} xl={5}>
            <div>
              <div>
                <p
                  className="c_rate_title"
                  style={{
                    fontSize: '14px',
                    color: '#000',
                    marginBottom: -5,
                    marginLeft: 15,
                    display: 'block',
                  }}
                >
                  AVG. RATE
                </p>
              </div>
              <div className="c_price__text_box" style={{ color: '#000' }}>
                <Typography.Paragraph
                  className="c_price_value"
                  style={{
                    color: 'inherit',
                    fontSize: '46px',
                    margin: 0,
                  }}
                >
                  <sup style={{ fontSize: '22px' }}>$</sup>
                  {parseInt(`${getDecimals(community_market?.Avg_Base_Rent).int}`).toLocaleString()}
                  <span style={{ fontSize: '16px' }}>.{getDecimals(community_market?.Avg_Base_Rent).point}</span>
                </Typography.Paragraph>
              </div>
              <div style={{ marginTop: -15, marginLeft: 14 }}>
                <span style={{ color: '#000', fontSize: 16 }}>Market avg. </span>
                <span
                  style={{
                    color: '#d46b08',
                    fontSize: '14px',
                  }}
                >
                  ${' '}
                  {parseFloat(community_market?.market_Avg_base_rent || 0).toLocaleString('en-US', {
                    maximumFractionDigits: 2,
                  })}
                </span>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={8} lg={5} xl={5}>
            <Tooltip
              title={() => (
                <span>
                  Rate: ${parseInt(`${getDecimals(community_market?.Avg_Base_Rent).int}`).toLocaleString()}.
                  {getDecimals(community_market?.Avg_Base_Rent).point} <br /> Care: $
                  {parseInt(`${getDecimals(community_market?.Avg_Base_Rent_Care).int}`).toLocaleString()}.
                  {getDecimals(community_market?.Avg_Base_Rent_Care).point}
                </span>
              )}
              placement="top"
              color="blue"
            >
              <div>
                <p
                  className="c_rate_title"
                  style={{
                    fontSize: '14px',
                    color: '#000',
                    marginBottom: -5,
                    marginLeft: 15,
                    display: 'block',
                  }}
                >
                  AVG.(Rate + Care)
                </p>
              </div>
              <div className="c_price__text_box" style={{ color: '#000' }}>
                <Typography.Paragraph
                  className="c_price_value"
                  style={{
                    color: 'inherit',
                    fontSize: '46px',
                    margin: 0,
                  }}
                >
                  <sup style={{ fontSize: '22px' }}>$</sup>
                  {parseInt(
                    `${getDecimals(community_market?.Avg_Base_Rent + community_market?.Avg_Base_Rent_Care).int}`
                  ).toLocaleString()}
                  <span style={{ fontSize: '16px' }}>
                    .{getDecimals(community_market?.Avg_Base_Rent + community_market?.Avg_Base_Rent_Care).point}
                  </span>
                </Typography.Paragraph>
              </div>
              <div style={{ marginTop: -15, marginLeft: 14 }}>
                <span style={{ color: '#000', fontSize: 16 }}>Market avg. </span>
                <span
                  style={{
                    color: '#d46b08',
                    fontSize: '14px',
                  }}
                >
                  ${' '}
                  {parseFloat(
                    community_market?.market_Avg_base_rent + community_market?.market_Avg_Base_Rent_Care || 0
                  ).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                </span>
              </div>
            </Tooltip>
          </Col>
          {/* 2 */}
          <Col xs={12} sm={12} md={8} lg={4} xl={4}>
            <div>
              <div>
                <p
                  className="c_rate_title"
                  style={{
                    fontSize: '14px',
                    color: '#000',
                    marginBottom: -5,
                    marginLeft: 4,
                    display: 'block',
                  }}
                >
                  AVG. SQ. FT.
                </p>
              </div>
              <div className="c_price__text_box" style={{ color: '#000' }}>
                <Typography.Paragraph
                  className="c_price_value"
                  style={{
                    color: 'inherit',
                    fontSize: '46px',
                    margin: 0,
                  }}
                >
                  {parseInt(`${getDecimals(community_market?.Avg_SQ_Footage).int}`).toLocaleString()}
                  <span style={{ fontSize: '16px' }}>.{getDecimals(community_market?.Avg_SQ_Footage).point}</span>
                </Typography.Paragraph>
              </div>
              <div style={{ marginTop: -15, marginLeft: 4 }}>
                <span style={{ color: '#000', fontSize: 16 }}>Market avg. </span>
                <span
                  style={{
                    color: '#389e0d',
                    fontSize: '14px',
                  }}
                >
                  {parseFloat(community_market?.market_Avg_sq_footage || 0).toLocaleString('en-US', {
                    maximumFractionDigits: 2,
                  })}
                </span>
              </div>
            </div>
          </Col>
          {/* 3 */}
          <Col xs={12} sm={12} md={8} lg={5} xl={5}>
            <div>
              <div>
                <p
                  className="c_rate_title"
                  style={{
                    fontSize: '14px',
                    color: '#000',
                    marginBottom: -5,
                    marginLeft: 15,
                    display: 'block',
                  }}
                >
                  AVG. RATE / SQ. FT.
                </p>
              </div>
              <div className="c_price__text_box" style={{ color: '#000' }}>
                <Typography.Paragraph
                  className="c_price_value"
                  style={{
                    color: 'inherit',
                    fontSize: '46px',
                    margin: 0,
                  }}
                >
                  <sup style={{ fontSize: '22px' }}>$</sup>

                  {parseInt(`${getDecimals(community_market?.Avg_price_per_sq).int}`).toLocaleString()}
                  <span style={{ fontSize: '16px' }}>.{getDecimals(community_market?.Avg_price_per_sq).point}</span>
                </Typography.Paragraph>
              </div>
              <div style={{ marginTop: -15, marginLeft: 14 }}>
                <span style={{ color: '#000', fontSize: 16 }}>Market avg. </span>
                <span
                  style={{
                    color: '#389e0d',
                    fontSize: '14px',
                  }}
                >
                  ${' '}
                  {parseFloat(community_market?.market_Avg_price_per_sq || 0).toLocaleString('en-US', {
                    maximumFractionDigits: 2,
                  })}
                </span>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={8} lg={5} xl={5}>
            <div>
              <span
                className="c_rate_title"
                style={{
                  fontSize: '14px',
                  color: '#000',
                  display: 'block',
                  marginBottom: -5,
                  marginLeft: 4,
                }}
              >
                GOOGLE Rating{' '}
                {ratingLoading ? (
                  <LoadingOutlined />
                ) : (
                  <Tooltip title={communityInfo?.google_map_location} color="blue">
                    <RedoOutlined onClick={updateRating} style={{ cursor: 'pointer' }} />
                  </Tooltip>
                )}
              </span>
              <div className="c_price__text_box" style={{ color: '#000' }}>
                <Typography.Paragraph
                  className="c_price_value"
                  style={{
                    color: 'inherit',
                    fontSize: '46px',
                    margin: 0,
                    position: "relative",
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                >
                  <span
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                    }}
                  >
                    {parseFloat(
                      community_market?.Avg_google_rating || 0
                    ).toFixed(1)}

                    {community_market?.old_google_ratings <=
                    parseFloat(community_market?.Avg_google_rating || 0) ? (
                      <CaretUpOutlined
                        style={{
                          color: "#0ab776",
                          // marginTop: "-5px",
                          fontSize: "12px",
                        }}
                      />
                    ) : (
                      <CaretDownOutlined
                        style={{
                          color: "#fa7550",
                          // marginTop: "-20px",
                          fontSize: "12px",
                        }}
                      />
                    )}
                  </span>{" "}
                  <span style={{ fontSize: "17px" }}>
                    ({parseFloat(community_market?.Sum_google_reviews || 0)})
                    <p style={{ marginBottom: "15px" }}></p>
                  </span>
                  {/* <span
                    className="c_float_points"
                    style={{
                      color: "#389e0d",
                      position: "absolute",
                      right: -20,
                      bottom: 35,
                    }}
                  >
                    {[1, 2, 3, 4, 5].map((s) => (
                      <StarFilled
                        style={{ fontSize: 7, margin: "0px 0px" }}
                        key={s}
                      />
                    ))}
                  </span> */}
                </Typography.Paragraph>
              </div>
              <div style={{ marginTop: -15 }}>
                <span style={{ color: '#000', fontSize: '16px' }}>Competitor avg. </span>
                <span style={{ color: '#389e0d', fontSize: '14px' }}>
                  {community_market?.market_Avg_Google_Rating
                    ? parseFloat(community_market?.market_Avg_Google_Rating || 0).toFixed(1)
                    : 0}
                </span>{' '}
                <span style={{ color: 'inherit', fontSize: '14px' }}>
                  (
                  {community_market?.market_Avg_Google_Reviews
                    ? parseFloat(community_market?.market_Avg_Google_Reviews || 0).toLocaleString('en-US', {
                        maximumFractionDigits: 2,
                      })
                    : 0}
                  )
                </span>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div
        style={{
          padding: '0px 20px',
          boxSizing: 'border-box',
          overflow: 'hidden',
          marginTop: '5px',
        }}
      >
        <Row gutter={[50, 30]} style={{ marginTop: '5px' }}>
          <Col xs={24} lg={12}>
            <h4
              style={{
                textTransform: 'uppercase',
                marginBottom: 10,
                display: 'block',
                fontWeight: 'bolder',
                fontSize: '14px',
              }}
            >
              <strong>GOOGLE RATING Comparison</strong>
            </h4>
            {loading ? (
              <Spin
                style={{
                  position: 'absolute',
                  top: '55%',
                  left: '50%',
                  zIndex: '9999',
                }}
              />
            ) : null}
            <div
              style={{
                display: 'flex',
              }}
            >
              <div
                id="guage-container"
                className="hide-scrollbar"
                style={{
                  display: 'flex',
                  overflow: 'auto',
                }}
              >
                <div style={{ textAlign: 'center', width: '150px' }}>
                  <Tooltip title={communityInfo?.comunity_name} color="blue">
                    {' '}
                    <p
                      className="c_rate_title"
                      style={{
                        fontSize: '14px',
                        color: '#000',
                        marginTop: 0,
                        display: 'block',
                      }}
                    >
                      {communityInfo?.comunity_name.slice(0, 15)}...
                    </p>
                  </Tooltip>

                  <GaugeGraph percentage={(communityInfo?.google_rating || 0) / 5} community={true} />
                  <p
                    style={{
                      marginTop: '-9px',
                      color: communityInfo?.highScore && '#389e0d',
                      fontSize: '18px',
                    }}
                  >
                    {(communityInfo?.google_rating || 0).toFixed(2)}
                  </p>
                  <Rate
                    allowHalf
                    defaultValue={communityInfo?.google_rating || 0}
                    style={{ fontSize: '12px' }}
                    disabled
                  />
                  <p>{communityInfo?.google_reviews || 0} Reviews</p>
                </div>
                {ratingData.map((item, idx) => (
                  <div style={{ textAlign: 'center', width: '150px' }} key={idx + 'rating'}>
                    <Tooltip title={item.comunity_name} color="blue">
                      <p
                        className="c_rate_title"
                        style={{
                          fontSize: '14px',
                          color: '#000',
                          marginTop: 0,
                          display: 'block',
                        }}
                      >
                        {item.comunity_name.slice(0, 15)}...
                      </p>
                    </Tooltip>
                    <GaugeGraph percentage={(item.google_rating || 0) / 5} />
                    <p
                      style={{
                        marginTop: '-9px',
                        color: item.highScore && '#389e0d',
                        fontSize: '18px',
                      }}
                    >
                      {(item.google_rating || 0).toFixed(2)}
                    </p>
                    <Rate allowHalf defaultValue={item.google_rating || 0} style={{ fontSize: '12px' }} disabled />
                    <p>{item.google_reviews || 0} Reviews</p>
                  </div>
                ))}
              </div>
            </div>
            {ratingData.length > 3 ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <span
                  style={{
                    width: '30px',
                    height: '30px',
                    borderRadius: '50%',
                    background: '#275AFF',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: '5px',
                  }}
                >
                  <LeftOutlined
                    style={{
                      cursor: 'pointer',
                      color: '#fff',
                    }}
                    onClick={(event) => {
                      event.preventDefault();
                      const container = document.getElementById('guage-container');
                      if (container) {
                        container.scrollLeft -= 200;
                      }
                    }}
                  />
                </span>
                <span
                  style={{
                    width: '30px',
                    height: '30px',
                    borderRadius: '50%',
                    background: '#275AFF',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <RightOutlined
                    style={{
                      cursor: 'pointer',
                      color: '#fff',
                    }}
                    onClick={(event) => {
                      event.preventDefault();
                      const container = document.getElementById('guage-container');
                      if (container) {
                        container.scrollLeft += 200;
                      }
                    }}
                  />
                </span>
              </div>
            ) : null}
          </Col>
          <Col xs={24} lg={12}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <h4
                style={{
                  textTransform: 'uppercase',
                  marginBottom: 10,
                  display: 'block',
                  fontWeight: 'bolder',
                  fontSize: '14px',
                }}
              >
                <strong>
                  Qualitative Score Comparison{' '}
                  <Tooltip
                    title={() => (
                      <span>
                        Qualitative score is based on Google rating and reviews <br /> of a community and Average rating
                        and reviews of the Market
                      </span>
                    )}
                    color="blue"
                  >
                    <InfoCircleOutlined
                      style={{
                        color: 'rgba(0,0,0,.45)',
                        cursor: 'pointer',
                      }}
                    />
                  </Tooltip>
                </strong>
              </h4>
              {/* <FullscreenOutlined
                style={{ marginBottom: 10, cursor: "pointer" }}
                onClick={() => {
                  
                }}
              /> */}
            </div>
            {qlScoreLoading ? (
              <Spin
                style={{
                  position: 'absolute',
                  top: '55%',
                  left: '50%',
                  zIndex: '9999',
                }}
              />
            ) : null}
            <div
              style={{
                display: 'flex',
              }}
            >
              <div
                id="score-guage-container"
                className="hide-scrollbar"
                style={{
                  display: 'flex',
                  // maxWidth: "37vw",
                  overflow: 'auto',
                  // border: "1px solid red",
                }}
              >
                {qualitativeScoreData.map((item, idx) => (
                  <div style={{ textAlign: 'center', width: '150px' }} key={idx + 'rating'}>
                    <Tooltip title={item.comunity_name} color="blue">
                      <p
                        className="c_rate_title"
                        style={{
                          fontSize: '14px',
                          color: '#000',
                          marginTop: 0,
                          display: 'block',
                        }}
                        title={item.comunity_name}
                      >
                        {item.comunity_name.slice(0, 15)}...
                      </p>
                    </Tooltip>
                    <GaugeGraph
                      percentage={(item.qualitativescores?.toFixed(2) || 0) / 5}
                      community={item.type == 'apartment' ? true : false}
                    />
                    <p
                      style={{
                        marginTop: '-9px',
                        color: item.highScore && '#389e0d',
                        fontSize: '18px',
                      }}
                    >
                      {item.qualitativescores?.toFixed(2) || 0}
                    </p>
                    <p>&nbsp;</p>
                  </div>
                ))}
              </div>
            </div>
            <p>&nbsp;</p>
            {qualitativeScoreData.length > 4 ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <span
                  style={{
                    width: '30px',
                    height: '30px',
                    borderRadius: '50%',
                    background: '#275AFF',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: '5px',
                  }}
                >
                  <LeftOutlined
                    style={{
                      cursor: 'pointer',
                      color: '#fff',
                    }}
                    onClick={(event) => {
                      event.preventDefault();
                      const container = document.getElementById('score-guage-container');
                      if (container) {
                        container.scrollLeft -= 200;
                      }
                    }}
                  />
                </span>
                <span
                  style={{
                    width: '30px',
                    height: '30px',
                    borderRadius: '50%',
                    background: '#275AFF',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <RightOutlined
                    style={{
                      cursor: 'pointer',
                      color: '#fff',
                    }}
                    onClick={(event) => {
                      event.preventDefault();
                      const container = document.getElementById('score-guage-container');
                      if (container) {
                        container.scrollLeft += 200;
                      }
                    }}
                  />
                </span>
              </div>
            ) : null}
          </Col>
        </Row>
      </div>
      <div
        style={{
          padding: '0px 20px',
          boxSizing: 'border-box',
          overflow: 'hidden',
          marginTop: '10px',
        }}
      >
        <Row gutter={[50, 30]}>
          <Col xs={24} lg={12} style={{ height: '320px' }}>
            <h4
              style={{
                textTransform: 'uppercase',
                // marginBottom: 20,
                display: 'block',
                fontWeight: 'bolder',
                fontSize: '14px',
              }}
            >
              <strong>Community Fee Comparison</strong>
            </h4>
            {leftGraphLoading ? (
              <Spin
                style={{
                  position: 'absolute',
                  top: '55%',
                  left: '50%',
                  zIndex: '9999',
                }}
              />
            ) : null}
            {leftGraphData.length > 0 && (
              <>
                <Column {...leftGraphConfig} />
              </>
            )}
          </Col>
          <Col xs={24} lg={12} style={{ height: '320px' }}>
            <h4
              style={{
                textTransform: 'uppercase',
                // marginBottom: 20,
                display: 'block',
                fontWeight: 'bolder',
                fontSize: '14px',
              }}
            >
              Market rate Comparison
            </h4>
            {rightGraphLoading ? (
              <Spin
                style={{
                  position: 'absolute',
                  top: '55%',
                  left: '50%',
                  zIndex: '9999',
                }}
              />
            ) : null}

            <MemoColumn config={rightGraphConfig} />
          </Col>
          {swotBarData.length > 0 ? (
            <Col xs={24} lg={12} style={{ height: '400px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h4
                  style={{
                    textTransform: 'uppercase',
                    // marginBottom: 20,
                    display: 'block',
                    fontWeight: 'bolder',
                    fontSize: '14px',
                  }}
                >
                  SWOT Score Comparison
                </h4>
                <span
                  style={{
                    border: '1px solid #D4D4D4',
                    padding: '2px 10px',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    getScatterGraphData(0);
                    getSwot4GraphData(0);
                  }}
                >
                  Reset
                </span>
              </div>
              {swotBarLoading && (
                <Spin
                  style={{
                    position: 'absolute',
                    top: '55%',
                    left: '50%',
                    zIndex: '9999',
                  }}
                />
              )}
              {/* @ts-ignore */}
              <Column
                {...swotBarConfig}
                onReady={(plot: any) => {
                  plot.on('element:click', (ev: any) => {
                    const { competitor_id } = ev.data.data;
                    //
                    setSwotCompetitor(ev.data.data.competitor_name);
                    getScatterGraphData(competitor_id);
                    getSwot4GraphData(competitor_id);
                  });
                }}
              />
            </Col>
          ) : null}
          {scatterData.length > 0 ? (
            <Col xs={24} lg={12} style={{ height: '400px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h4
                  style={{
                    textTransform: 'uppercase',
                    display: 'block',
                    fontWeight: 'bolder',
                    fontSize: '14px',
                  }}
                >
                  MARKET RATING BY CATEGORY
                </h4>
                {swotCompetitor && <h6 style={{ color: '#aaa' }}>{swotCompetitor}</h6>}
              </div>
              <div
                style={{
                  marginLeft: '40px',
                  marginRight: '20px',
                  marginBottom: '-10px',
                  marginTop: '10px',
                  color: '#aaa',
                }}
              >
                <Row>
                  <Col span={7}></Col>
                  <Col span={3} style={{ textAlign: 'center' }}>
                    WORSE
                  </Col>
                  <Col span={1}></Col>
                  <Col span={3} style={{ textAlign: 'center' }}>
                    SAME
                  </Col>
                  <Col span={4} style={{ textAlign: 'center' }}>
                    BETTER
                  </Col>
                </Row>
              </div>
              <span>
                {scatterLoading ? (
                  <Spin
                    style={{
                      position: 'absolute',
                      top: '55%',
                      left: '50%',
                      zIndex: '9999',
                    }}
                  />
                ) : null}
                <Scatter
                  {...scatterGraphConfig}
                  style={{ opacity: scatterLoading ? '0.4' : '1' }}
                  onReady={(plot: any) => {
                    plot.on('element:click', (ev: any) => {
                      const { category_id } = ev.data.data;
                      filteredSwot4Data(category_id);
                    });
                  }}
                />
              </span>
            </Col>
          ) : null}
          {swot4FilteredBarData.length > 0 ? (
            <Col lg={24} style={{ minHeight: '500px' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '20px',
                  marginBottom: '10px',
                }}
              >
                <h4
                  style={{
                    textTransform: 'uppercase',
                    display: 'block',
                    fontWeight: 'bolder',
                    fontSize: '14px',
                  }}
                >
                  ALL CATEGORY DETAILS
                </h4>
                <h4
                  style={{
                    textTransform: 'uppercase',
                    display: 'block',
                    fontWeight: 'bolder',
                    fontSize: '14px',
                  }}
                >
                  --MY COMMUNITY --IMPORTANCE TO FAMILY
                </h4>
                <h4
                  style={{
                    textTransform: 'uppercase',
                    // marginBottom: 20,
                    display: 'block',
                    fontWeight: 'bolder',
                    fontSize: '14px',
                  }}
                >
                  AVG. MARKET COMPETITION RATING
                </h4>
              </div>
              {categoryGraphLoading ? (
                <Spin
                  style={{
                    position: 'absolute',
                    top: '55%',
                    left: '50%',
                    zIndex: '9999',
                  }}
                />
              ) : null}
              {/* @ts-ignore */}
              <Column {...swot4BarConfig} />
              {swotCompetitor && (
                <h6
                  style={{
                    color: '#aaa',
                    textAlign: 'center',
                    marginTop: '10px',
                  }}
                >
                  {swotCompetitor}
                </h6>
              )}
            </Col>
          ) : null}
        </Row>
      </div>
      <br />

      <div ref={ref} style={{ background: '#fff' }}>
        <Tabs
          defaultActiveKey={selectedTab}
          tabPosition="top"
          tabBarGutter={10}
          size="small"
          items={[
            {
              label: (
                <Button
                  type="text"
                  className={selectedTab === 'accommodation' ? 'p_healing_active_tab' : 'btnStyle'}
                  shape="round"
                  style={{
                    marginRight: 20,
                    height: 'auto',
                    padding: '5px 20px',
                    fontSize: 18,
                  }}
                  onClick={() => setSelectedTab('accommodation')}
                >
                  Compare Accommodation
                </Button>
              ),
              key: 'Compare Accommodation',
              children: <CompareAccommodation community_id={community_id} />,
              style: {
                padding: 0,
              },
            },
            {
              label: (
                <Button
                  type="text"
                  className={selectedTab === 'care_levels' ? 'p_healing_active_tab' : 'btnStyle'}
                  shape="round"
                  style={{
                    marginRight: 20,
                    height: 'auto',
                    padding: '5px 20px',
                    fontSize: 18,
                  }}
                  onClick={() => setSelectedTab('care_levels')}
                >
                  Compare Care
                </Button>
              ),
              key: 'care_levels',
              children: <CompareCare community_id={community_id} />,
              style: {
                padding: 0,
              },
            },
            {
              label: (
                <Button
                  type="text"
                  className={selectedTab === 'images' ? 'p_healing_active_tab' : 'btnStyle'}
                  shape="round"
                  style={{
                    marginRight: 20,
                    height: 'auto',
                    padding: '5px 20px',
                    fontSize: 18,
                  }}
                  onClick={() => setSelectedTab('images')}
                >
                  Compare Images
                </Button>
              ),
              key: 'images',
              children: <CompareImage community_id={community_id} />,
              style: {
                padding: 0,
              },
            },

            {
              label: (
                <Button
                  type="text"
                  className={selectedTab === 'community_details' ? 'p_healing_active_tab' : 'btnStyle'}
                  shape="round"
                  style={{
                    marginRight: 20,
                    height: 'auto',
                    padding: '5px 20px',
                    fontSize: 18,
                  }}
                  onClick={() => setSelectedTab('community_details')}
                >
                  Compare Community Details
                </Button>
              ),
              key: 'community_details',
              children: <CommunityDetailsComparison community_id={community_id} />,
              style: {
                padding: 0,
              },
            },
          ]}
          tabBarExtraContent={
            <FullscreenOutlined
              style={{ fontSize: '18px', cursor: 'pointer' }}
              title="FullScreen"
              onClick={makeFullscreen}
            />
          }
        />
      </div>
      {openPdfModal && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 9999,
            background: '#fff',
            padding: '10px 20px',
            boxSizing: 'border-box',
            overflow: 'scroll',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 10,
            }}
          >
            <Button onClick={handlePrint} type="primary">
              Download
            </Button>
            <Button
              icon={<CloseCircleFilled />}
              onClick={() => (setOpenPdfModal ? setOpenPdfModal(false) : null)}
              type="primary"
            />
          </div>
          <div ref={componentRef} style={{ padding: '20px 10px' }}>
            <Watermark
              width={110}
              height={40}
              // content={communityInfo.water_mark ? ["Pricing", "Assistant"] : []}
              image={communityInfo?.water_mark ? '/pricing_assistant_logo.png' : ''}
            >
              <Row style={{ marginTop: '10px', marginBottom: '20px' }}>
                <Col lg={3}>
                  {communityInfo?.logo_url && (
                    <img
                      src={`${process.env.REACT_APP_API}/media/${communityInfo?.logo_url}`}
                      height="40"
                      width="auto"
                      style={{ borderRadius: '6px' }}
                    />
                  )}
                </Col>
                <Col lg={18}>
                  <Typography.Title level={1} style={{ textAlign: 'center' }}>
                    {communityInfo?.comunity_name}
                  </Typography.Title>
                </Col>
                <Col lg={3}>
                  <img src="/pricing_assistant_logo.png" height="40" width="110" />
                </Col>
              </Row>
              <Row className="rowgap-vbox" gutter={[16, 16]}>
                {/* 1 */}
                <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                  <div>
                    <div>
                      <p
                        className="c_rate_title"
                        style={{
                          fontSize: '14px',
                          color: '#000',
                          marginBottom: -5,
                          marginLeft: 15,
                          display: 'block',
                        }}
                      >
                        AVG. RATE
                      </p>
                    </div>
                    <div className="c_price__text_box" style={{ color: '#000' }}>
                      <Typography.Paragraph
                        className="c_price_value"
                        style={{
                          color: 'inherit',
                          fontSize: '46px',
                          margin: 0,
                        }}
                      >
                        <sup style={{ fontSize: '22px' }}>$</sup>
                        {parseInt(`${getDecimals(community_market?.Avg_Base_Rent).int}`).toLocaleString()}
                        <span style={{ fontSize: '16px' }}>.{getDecimals(community_market?.Avg_Base_Rent).point}</span>
                      </Typography.Paragraph>
                    </div>
                    <div style={{ marginTop: -15, marginLeft: 14 }}>
                      <span style={{ color: '#000', fontSize: 16 }}>Market avg. </span>
                      <span
                        style={{
                          color: '#d46b08',
                          fontSize: '14px',
                        }}
                      >
                        ${' '}
                        {parseFloat(community_market?.market_Avg_base_rent || 0).toLocaleString('en-US', {
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                  <Tooltip
                    title={() => (
                      <span>
                        Rate: ${parseInt(`${getDecimals(community_market?.Avg_Base_Rent).int}`).toLocaleString()}.
                        {getDecimals(community_market?.Avg_Base_Rent).point} <br /> Care: $
                        {parseInt(`${getDecimals(community_market?.Avg_Base_Rent_Care).int}`).toLocaleString()}.
                        {getDecimals(community_market?.Avg_Base_Rent_Care).point}
                      </span>
                    )}
                    placement="top"
                    color="blue"
                  >
                    <div>
                      <p
                        className="c_rate_title"
                        style={{
                          fontSize: '14px',
                          color: '#000',
                          marginBottom: -5,
                          marginLeft: 15,
                          display: 'block',
                        }}
                      >
                        AVG.(Rate + Care)
                      </p>
                    </div>
                    <div className="c_price__text_box" style={{ color: '#000' }}>
                      <Typography.Paragraph
                        className="c_price_value"
                        style={{
                          color: 'inherit',
                          fontSize: '46px',
                          margin: 0,
                        }}
                      >
                        <sup style={{ fontSize: '22px' }}>$</sup>
                        {parseInt(
                          `${getDecimals(community_market?.Avg_Base_Rent + community_market?.Avg_Base_Rent_Care).int}`
                        ).toLocaleString()}
                        <span style={{ fontSize: '16px' }}>
                          .{getDecimals(community_market?.Avg_Base_Rent + community_market?.Avg_Base_Rent_Care).point}
                        </span>
                      </Typography.Paragraph>
                    </div>
                    <div style={{ marginTop: -15, marginLeft: 14 }}>
                      <span style={{ color: '#000', fontSize: 16 }}>Market avg. </span>
                      <span
                        style={{
                          color: '#d46b08',
                          fontSize: '14px',
                        }}
                      >
                        ${' '}
                        {parseFloat(
                          community_market?.market_Avg_base_rent + community_market?.market_Avg_Base_Rent_Care || 0
                        ).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                      </span>
                    </div>
                  </Tooltip>
                </Col>
                {/* 2 */}
                <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                  <div>
                    <div>
                      <p
                        className="c_rate_title"
                        style={{
                          fontSize: '14px',
                          color: '#000',
                          marginBottom: -5,
                          marginLeft: 4,
                          display: 'block',
                        }}
                      >
                        AVG. SQ. FT.
                      </p>
                    </div>
                    <div className="c_price__text_box" style={{ color: '#000' }}>
                      <Typography.Paragraph
                        className="c_price_value"
                        style={{
                          color: 'inherit',
                          fontSize: '46px',
                          margin: 0,
                        }}
                      >
                        {parseInt(`${getDecimals(community_market?.Avg_SQ_Footage).int}`).toLocaleString()}
                        <span style={{ fontSize: '16px' }}>.{getDecimals(community_market?.Avg_SQ_Footage).point}</span>
                      </Typography.Paragraph>
                    </div>
                    <div style={{ marginTop: -15, marginLeft: 4 }}>
                      <span style={{ color: '#000', fontSize: 16 }}>Market avg. </span>
                      <span
                        style={{
                          color: '#389e0d',
                          fontSize: '14px',
                        }}
                      >
                        {parseFloat(community_market?.market_Avg_sq_footage || 0).toLocaleString('en-US', {
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    </div>
                  </div>
                </Col>
                {/* 3 */}
                <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                  <div>
                    <div>
                      <p
                        className="c_rate_title"
                        style={{
                          fontSize: '14px',
                          color: '#000',
                          marginBottom: -5,
                          marginLeft: 15,
                          display: 'block',
                        }}
                      >
                        AVG. RATE / SQ. FT.
                      </p>
                    </div>
                    <div className="c_price__text_box" style={{ color: '#000' }}>
                      <Typography.Paragraph
                        className="c_price_value"
                        style={{
                          color: 'inherit',
                          fontSize: '46px',
                          margin: 0,
                        }}
                      >
                        <sup style={{ fontSize: '22px' }}>$</sup>

                        {parseInt(`${getDecimals(community_market?.Avg_price_per_sq).int}`).toLocaleString()}
                        <span style={{ fontSize: '16px' }}>
                          .{getDecimals(community_market?.Avg_price_per_sq).point}
                        </span>
                      </Typography.Paragraph>
                    </div>
                    <div style={{ marginTop: -15, marginLeft: 14 }}>
                      <span style={{ color: '#000', fontSize: 16 }}>Market avg. </span>
                      <span
                        style={{
                          color: '#389e0d',
                          fontSize: '14px',
                        }}
                      >
                        ${' '}
                        {parseFloat(community_market?.market_Avg_price_per_sq || 0).toLocaleString('en-US', {
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                  <div>
                    <span
                      className="c_rate_title"
                      style={{
                        fontSize: '14px',
                        color: '#000',
                        display: 'block',
                        marginBottom: -5,
                        marginLeft: 4,
                      }}
                    >
                      GOOGLE Rating{' '}
                    </span>
                    <div className="c_price__text_box" style={{ color: '#000' }}>
                      <Typography.Paragraph
                        className="c_price_value"
                        style={{
                          color: 'inherit',
                          fontSize: '46px',
                          margin: 0,
                          position: 'relative',
                          display: 'inline-block',
                        }}
                      >
                        {parseFloat(community_market?.Avg_google_rating || 0).toFixed(1)}
                        <span style={{ fontSize: '17px' }}>
                          ({parseFloat(community_market?.Sum_google_reviews || 0)})
                        </span>
                      </Typography.Paragraph>
                    </div>
                    <div style={{ marginTop: -15 }}>
                      <span style={{ color: '#000', fontSize: '16px' }}>Competitor avg. </span>
                      <span style={{ color: '#389e0d', fontSize: '14px' }}>
                        {community_market?.market_Avg_Google_Rating
                          ? parseFloat(community_market?.market_Avg_Google_Rating || 0).toFixed(1)
                          : 0}
                      </span>{' '}
                      <span style={{ color: 'inherit', fontSize: '14px' }}>
                        (
                        {community_market?.market_Avg_Google_Reviews
                          ? parseFloat(community_market?.market_Avg_Google_Reviews || 0).toLocaleString('en-US', {
                              maximumFractionDigits: 2,
                            })
                          : 0}
                        )
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
              <div
                style={{
                  padding: '30px 20px',
                  boxSizing: 'border-box',
                  overflow: 'hidden',
                }}
              >
                {/* <h1>{swotCompetitor}</h1> */}
                <Row style={{ width: '100%' }} gutter={[16, 50]}>
                  <Col xs={24} lg={24}>
                    <h4
                      style={{
                        textTransform: 'uppercase',
                        marginBottom: 10,
                        display: 'block',
                        fontWeight: 'bolder',
                        fontSize: '14px',
                      }}
                    >
                      <strong>GOOGLE RATING Comparison</strong>
                    </h4>
                    <div>
                      <div
                        id="guage-container"
                        className="hide-scrollbar"
                        style={{
                          display: 'flex',
                          // maxWidth: "37vw"
                          // border: "1px solid red",
                        }}
                      >
                        <div style={{ textAlign: 'center', width: '150px' }}>
                          <p
                            className="c_rate_title"
                            style={{
                              fontSize: '14px',
                              color: '#000',
                              marginTop: 0,
                              display: 'block',
                            }}
                          >
                            {communityInfo?.comunity_name.slice(0, 15)}...
                          </p>
                          <GaugeGraph percentage={(communityInfo?.google_rating || 0) / 5} community={true} />
                          <p
                            style={{
                              marginTop: '-9px',
                              color: communityInfo?.highScore && '#275AFF',
                            }}
                          >
                            {communityInfo?.google_rating.toFixed(2) || 0}
                          </p>
                          <Rate
                            allowHalf
                            defaultValue={communityInfo?.google_rating || 0}
                            style={{ fontSize: '12px' }}
                            disabled
                          />
                          <p>{communityInfo?.google_reviews || 0} Reviews</p>
                        </div>
                        {ratingData.map((item, idx) => (
                          <div style={{ textAlign: 'center', width: '150px' }} key={idx + 'rating'}>
                            <p
                              className="c_rate_title"
                              style={{
                                fontSize: '14px',
                                color: '#000',
                                marginTop: 0,
                                display: 'block',
                              }}
                            >
                              {item.comunity_name.slice(0, 15)}...
                            </p>
                            <GaugeGraph percentage={(item.google_rating || 0) / 5} />
                            <p
                              style={{
                                marginTop: '-9px',
                                color: item.highScore && '#275AFF',
                              }}
                            >
                              {item.google_rating?.toFixed(2) || 0}
                            </p>
                            <Rate
                              allowHalf
                              defaultValue={item.google_rating || 0}
                              style={{ fontSize: '12px' }}
                              disabled
                            />
                            <p>{item.google_reviews || 0} Reviews</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} lg={24}>
                    <h4
                      style={{
                        textTransform: 'uppercase',
                        marginBottom: 10,
                        display: 'block',
                        fontWeight: 'bolder',
                        fontSize: '14px',
                      }}
                    >
                      <strong>QUALITATIVE SCORE COMPARISON</strong>
                    </h4>
                    <div>
                      <div
                        id="guage-container"
                        className="hide-scrollbar"
                        style={{
                          display: 'flex',
                        }}
                      >
                        {qualitativeScoreData.map((item, idx) => (
                          <div style={{ textAlign: 'center', width: '150px' }} key={idx + 'rating'}>
                            <p
                              className="c_rate_title"
                              style={{
                                fontSize: '14px',
                                color: '#000',
                                marginTop: 0,
                                display: 'block',
                              }}
                            >
                              {item.comunity_name.slice(0, 15)}...
                            </p>
                            <GaugeGraph
                              percentage={(item.qualitativescores?.toFixed(2) || 0) / 5}
                              community={item.type == 'apartment' ? true : false}
                            />
                            <p
                              style={{
                                marginTop: '-9px',
                                color: item.highScore && '#275AFF',
                              }}
                            >
                              {item.qualitativescores?.toFixed(2) || 0}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} lg={24} style={{ height: '320px' }}>
                    <h4
                      style={{
                        textTransform: 'uppercase',
                        // marginBottom: 20,
                        display: 'block',
                        fontWeight: 'bolder',
                        fontSize: '14px',
                      }}
                    >
                      <strong>Community Fee Comparison</strong>
                    </h4>

                    {leftGraphData.length > 0 && (
                      <>
                        <Column {...leftGraphConfig} />
                      </>
                    )}
                  </Col>
                </Row>
                <Row className="page-break" style={{ marginTop: '30px' }}>
                  <Typography.Title level={3}>Market Incentives</Typography.Title>
                  <Table
                    columns={FeeIncentivesColumns}
                    dataSource={feeIncentiveData}
                    pagination={false}
                    style={{ width: '100%' }}
                    showHeader={true}
                  />
                </Row>
                <Row className="page-break">
                  <CompareAccommodationGraph community_id={community_id} />
                </Row>
                <Row className="page-break" style={{ marginTop: '30px' }}>
                  <Typography.Title level={3}>Compare Accomodation</Typography.Title>
                  <CompareAccommodation community_id={community_id} pdf={true} />
                </Row>
                <Row className="page-break" style={{ marginTop: '30px' }}>
                  <Typography.Title level={3}>Compare Care</Typography.Title>
                  <CompareCare community_id={community_id} pdf={true} />
                </Row>
              </div>
            </Watermark>
          </div>
        </div>
      )}
    </div>
  );
};

export default ComparisonTab;
