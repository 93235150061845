import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Avatar, Button, message, Select, Spin, Typography } from 'antd';
import { format } from 'date-fns';
import GoogleMapReact from 'google-map-react';

import axios from 'config/axiosPrivate';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { handleClearCommunityData } from 'store/slices/communitySlice';

interface Props {
  current: number;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
}

type CenterType = { lat: number; lng: number };

const AnyReactComponent = ({ ...rest }: any) => (
  <div>
    <Avatar src="/further_logo.png" size={60} />
  </div>
);

const CommunitySelectForm = ({ current, setCurrent }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();
  const community_id = params.get('cid') as string;
  const [messageApi, contextHolder] = message.useMessage();
  const basic_info = useAppSelector((state) => state.community.basic_info);

  const accommodation_and_care = useAppSelector((state) => state.community.cares_info);
  const communityFees = useAppSelector((state) => state.community.communityFees);
  const careFees = useAppSelector((state) => state.community.careFees);
  const [communityList, setCommunityList] = React.useState<any[]>([]);
  const [selectedCommunity, setSelectedCommunity] = React.useState<any>(null);
  const [center, setCenter] = React.useState<CenterType>({
    lat: 44.5,
    lng: -89.5,
  });
  const [loading, setLoading] = React.useState<boolean>(false);

  const cm_state = useQuery({
    queryKey: 'Get_FullHomeCommunities',
    queryFn: () =>
      axios
        .post('/communities/Get_FullHomeCommunities/', {
          comm_list: [],
          caretype_list: [],
          company_list: [],
        })
        .then((res) => {
          // return res.data?.payload?.Community?.map((ele: any, idx: number) => ({
          //   ...ele,
          //   index: idx + 1,
          // }));

          return res.data?.payload?.Community;
        }),
    onSuccess: (data) => {
      const communities = data?.filter((ele: any) => ele.apartmenttype === 'apartment');
      const options: any[] = communities?.map((ele: any) => ({
        ...ele,
        label: ele?.apartmentname,
        value: ele?.Id,
      }));
      if (community_id) {
        const selected = options.find((el) => `${el?.value}` === `${community_id}`);
        if (selected) {
          setSelectedCommunity(selected);
        }
      }
      setCommunityList(options);
    },
  });

  const handleCreateCompetitor = async () => {
    const formatted_data: any[] = [];
    accommodation_and_care.forEach((row: any) => {
      const type_data: any[] = [];
      row.living_type.forEach((rowType: any) => {
        if (rowType.value) {
          const obj = {
            id: 1,
            apartment_id: 1,
            apartment_type_id: row.accommodation,
            living_type_id: rowType.id,
            base_rent: rowType.value,
            sq_footage: row.sq_ft,
            price_per_sq: rowType.value / row.sq_ft,
            status: 1,
            notes: '',
            op: 'added',
            disabled: false,
          };
          type_data.push(obj);
        }
      });
      formatted_data.push(...type_data);
    });

    try {
      setLoading(true);
      const basicResponse = await axios
        .post('/communities/Save_AddComm_CompetitorInfo/', {
          data: [
            {
              ...basic_info,
              apartment_id: selectedCommunity?.Id,
              company_id: selectedCommunity?.company_id,
              management_company_id: selectedCommunity?.management_company_id,
            },
          ],
        })
        .then((res) => res.data);

      const payloadForAccAndCare = formatted_data.map((el: any) => ({
        ...el,
        apartment_id: basicResponse?.id,
        updated_at: format(new Date(), 'dd-MM-yyyy hh:mm:ss'),
      }));

      if (payloadForAccAndCare.length > 0) {
        await axios
          .post('communities/Save_AddComm_LivingInfo/', {
            data: payloadForAccAndCare,
          })
          .then((res) => res.data);
      }

      const careFees_data: any[] = careFees.map((el: any) => ({
        ...el,
        level_1: el?.level_1 || 0,
        level_2: el?.level_2 || 0,
        level_3: el?.level_3 || 0,
        level_4: el?.level_4 || 0,
        level_5: el?.level_5 || 0,
        level_6: el?.level_6 || 0,
        level_7: el?.level_7 || 0,
        level_8: el?.level_8 || 0,
        medication_management_fee: el?.medication_management_fee || 0,
        average_care_cost: el?.average_care_cost || 0,
        id: 1,
        apartment_id: basicResponse?.id,
        updated_at: format(new Date(), 'dd-MM-yyyy hh:mm:ss'),
        status: 1,
      }));

      if (careFees_data.length > 0) {
        await axios
          .post('communities/Save_AddComm_CareFees/', {
            data: careFees_data,
          })
          .then((res) => res.data);
      }

      const com_data: any[] = communityFees.map((el: any) => ({
        ...el,
        additional_fee: el?.additional_fee || 0,
        community_fee: el?.community_fee || 0,
        pet_fee: el?.pet_fee || 0,
        respite_fee: el?.respite_fee || 0,
        second_person_fee: el?.second_person_fee || 0,
        id: 1,
        apartment_id: basicResponse?.id,
        updated_at: format(new Date(), 'dd-MM-yyyy hh:mm:ss'),
        status: 1,
      }));

      if (com_data.length > 0) {
        await await axios
          .post('communities/Save_AddComm_FeeIncentives/', {
            data: com_data,
          })
          .then((res) => res.data);
      }

      dispatch(handleClearCommunityData());
      setLoading(false);
      navigate(`/view-apartments?id=${selectedCommunity?.Id}`);
    } catch (err: any) {
      setLoading(false);
      messageApi.open({
        type: 'error',
        content: err?.response?.data[0],
        duration: 5,
      });
    }
  };

  return (
    <div>
      {contextHolder}
      {loading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgb(240 240 240 / 71%)',
            zIndex: 99,
          }}
        >
          <Spin size="large" />
        </div>
      )}
      <div
        style={{
          marginBottom: 10,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Select
          placeholder="Select"
          options={communityList}
          value={selectedCommunity}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => (option?.label ?? '').includes(input)}
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          style={{ width: '100%', maxWidth: 450 }}
          onSelect={(_, option) => {
            setSelectedCommunity(option);
            setCenter({
              lat: parseFloat(option?.latitude || 0),
              lng: parseFloat(option?.longitude || 0),
            });
          }}
          loading={cm_state.isLoading}
        />
      </div>
      {selectedCommunity && (
        <Typography.Paragraph style={{ textAlign: 'center', marginBottom: 20, fontSize: 16 }}>
          <strong>Address: </strong>
          {selectedCommunity?.apartmentaddress}
        </Typography.Paragraph>
      )}

      <div style={{ width: '100%', height: '550px', position: 'relative' }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: '',
          }}
          defaultZoom={8}
          center={center}
          key={selectedCommunity?.id}
        >
          {selectedCommunity && (
            <AnyReactComponent lat={selectedCommunity?.latitude} lng={selectedCommunity?.longitude} />
          )}
        </GoogleMapReact>
      </div>
      <div
        style={{
          width: '100%',
          borderTop: '2px solid #eee',
          padding: '10px 30px',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          minHeight: '80px',
          marginTop: 30,
        }}
      >
        <Button onClick={() => navigate('/home')}>Cancel</Button>
        <div style={{ textAlign: 'center', padding: '20px 50px', maxWidth: '70%' }}>
          <Typography.Title level={4} style={{ margin: 0 }}>
            {basic_info?.comunity_name}
          </Typography.Title>
          <Typography.Paragraph style={{ color: '#ccc', fontSize: 16 }}>{basic_info?.address}</Typography.Paragraph>
        </div>
        <div>
          {current !== 0 && (
            <Button style={{ marginRight: 10 }} onClick={() => setCurrent((prevState) => prevState - 1)}>
              Previous Step
            </Button>
          )}
          <Button
            type="primary"
            htmlType="button"
            onClick={handleCreateCompetitor}
            loading={loading}
            disabled={loading || !selectedCommunity}
          >
            Finish
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CommunitySelectForm;
