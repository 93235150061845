import React, { useEffect, useState } from 'react';

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';

import axios from 'config/axiosPrivate';

function groupByApartmentId(data: any[]) {
  const groups: any = {};

  data.forEach(function (val) {
    const category = val.apartment_id;
    if (category) {
      if (category in groups) {
        groups[category].push(val);
      } else {
        groups[category] = new Array(val);
      }
    }
  });
  return groups;
}

interface DataType {
  id: any;
  CommunityName: string;
  Type: 'apartment' | 'competitor';
  careFee: {
    id: number;
    amount: string;
    fee_note: string;
    units: string;
    occupancy: string;
    unit_note: '';
    level_1: number;
    level_2: number;
    level_3: number;
    level_4: number;
    level_5: number;
    level_6: number;
    level_7: number;
    level_8: number;
    medication_management_fee: number;
    care_pricing_type: string;
    additional_care_levels_rates: string;
    additional_care_costs: string;
    status: 1;
    created_by: string;
    updated_by: string;
    created_at: string;
    updated_at: string;
    average_care_cost: number;
    Community: string;
    LivingType: string;
    abv: string;
    LivingTypeid: number;
    FeeType: string;
    FeeTypeid: number;
  };
  key: string;
}

interface Props {
  community_id: number;
  pdf?: boolean;
}

const CompareAccommodation = ({ community_id, pdf }: Props) => {
  const [tableCollumns, settableCollumns] = useState<any[]>([]);
  const [tableData, settableData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [splitCollumns, setSplitCollumns] = useState<any[]>([]);
  let myCommunity: any = [];
  const getColor = (data: any) => {
    let color = '#000';
    myCommunity.forEach((community: any) => {
      if (community.ApartmentTypeName == data.ApartmentTypeName && community.LivingTypeName == data.LivingTypeName) {
        // if (community.ApartmentTypeName == "One Bedroom")
        //
        if (community.base_rent < data.base_rent) {
          color = '#0ab776';
        } else {
          color = '#fa7550';
        }
      }
    });
    return color;
  };
  // useEffect(() => {
  //   if (community_id) {
  //     axios
  //       .get(
  //         `/communities/Get_CommunityCompareAccommodationTest/${community_id}/`
  //       )
  //       .then((res) => {
  //
  //       });
  //   }
  // }, [community_id]);
  useEffect(() => {
    if (community_id) {
      setLoading(true);
      axios
        .get(`/communities/Get_CommunityCompareAccommodation/${community_id}/`)
        .then((res) => {
          //
          const headerData: any[] = res.data?.Header;
          const Combination: any[] = res.data?.Combination;
          const Data: any[] = res.data?.Data;
          setSplitCollumns(splitArray(headerData));
          const table_columns: ColumnsType<DataType> = headerData.map((collumn: any) => ({
            title: (
              <div
                style={{
                  padding: '10px 4px',
                  whiteSpace: 'normal',
                }}
              >
                <Typography.Title style={{ margin: 0 }} level={4}>
                  {collumn?.CommunityName}

                  {collumn?.CommunityType === 'apartment' && (
                    <span
                      style={{
                        background: '#4CD7D2',
                        color: '#fff',
                        fontSize: '12px',
                        padding: '5px 9px',
                        borderRadius: '10px',
                        marginLeft: 6,
                        whiteSpace: 'normal',
                      }}
                    >
                      ME
                    </span>
                  )}
                </Typography.Title>
              </div>
            ),
            dataIndex: collumn?.CommunityID,
            key: collumn?.CommunityID,
            width: collumn?.CommunityType === 'apartment' ? (pdf ? '400px' : 400) : !pdf ? 300 : 'auto',
            render: (value) => {
              if (value) {
                const data = value[0];
                if (data?.Type === 'apartment') {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        minWidth: '400px',
                        width: pdf ? '400px' : 'auto',
                        paddingTop: '7px',
                        paddingBottom: '7px',
                      }}
                      className="healing__active"
                    >
                      <div>
                        <Typography.Title level={4} style={{ marginTop: '0px', marginBottom: '0px' }}>
                          {data?.ApartmentTypeName}
                        </Typography.Title>
                        <Typography.Title
                          level={5}
                          style={{
                            margin: 0,
                            fontSize: 16,
                            color: '#989696',
                            marginTop: 0,
                          }}
                        >
                          {data?.LivingTypeName}
                        </Typography.Title>
                      </div>
                      <div style={{ textAlign: 'right' }}>
                        {data?.base_rent > 0 && (
                          <Typography.Title level={3} style={{ margin: '0px' }}>
                            <sup style={{ fontSize: '13px' }}>$ </sup>
                            {parseFloat(data?.base_rent || 0).toLocaleString()}
                          </Typography.Title>
                        )}

                        {(data?.sq_footage > 0 || data?.price_per_sq > 0) && (
                          <Typography.Text style={{ color: '#989696' }}>
                            {parseFloat(data?.sq_footage || 0).toLocaleString()} sq. ft. (${' '}
                            {parseFloat(data?.price_per_sq || 0).toLocaleString()})
                          </Typography.Text>
                        )}
                      </div>
                    </div>
                  );
                } else if (data?.Type === 'competitor') {
                  return (
                    <div>
                      <Typography.Title
                        level={3}
                        style={{
                          display: 'flex',
                          alignItems: 'start',
                          minWidth: !pdf ? '300px' : '',
                          marginBottom: '0px',
                        }}
                      >
                        <span style={{ fontSize: '12px' }}>$ </span>
                        {parseFloat(data?.base_rent || 0).toLocaleString()}
                        {getColor(data) == '#0ab776' ? (
                          <CaretUpOutlined
                            style={{
                              color: '#0ab776',
                              marginLeft: 5,
                              fontSize: '12px',
                            }}
                          />
                        ) : getColor(data) == '#fa7550' ? (
                          <CaretDownOutlined
                            style={{
                              color: '#fa7550',
                              marginLeft: 5,
                              fontSize: '12px',
                            }}
                          />
                        ) : null}
                      </Typography.Title>
                      <Typography.Title style={{ margin: 0 }} level={5}>
                        {data?.ApartmentTypeName}, {data?.LivingTypeName}
                      </Typography.Title>
                      {(data?.sq_footage > 0 || data?.price_per_sq > 0) && (
                        <Typography.Text style={{ color: '#989696' }}>
                          {parseFloat(data?.sq_footage || 0).toLocaleString()} sq. ft. (${' '}
                          {(data?.price_per_sq || 0).toLocaleString()})
                        </Typography.Text>
                      )}
                    </div>
                  );
                } else {
                  return <div style={{ padding: 20 }}></div>;
                }
              }
            },
          }));
          settableCollumns(table_columns);

          const my_Community = Data.filter((el) => el?.Type === 'apartment');

          myCommunity = my_Community;
          // set Table data
          const apartmentData = myCommunity[0];
          const rows = Combination.map((cmb: any) => {
            const cmb_data = Data.filter((d) => d?.Combination_Id === cmb?.Combination_Id);

            const hasCommunity = cmb_data?.find((el) => el?.Type === 'apartment');

            if (cmb_data.length > 0 && !hasCommunity) {
              const communityData = {
                id: `${Date.now() + Math.random()}`,
                apartment_id: apartmentData ? apartmentData?.apartment_id : headerData[0]?.CommunityID,
                living_type_id: cmb_data[0]?.living_type_id,
                apartment_type_id: cmb_data[0]?.apartment_type_id,
                base_rent: 0,
                sq_footage: 0,
                price_per_sq: 0,
                amount: null,
                comments: '',
                created_at: '2023-04-18T18:47:21.520353',
                updated_at: '2023-04-20T20:09:57.209667',
                created_by: 'paadmin',
                updated_by: 'paadmin',
                status: 1,
                CommunityName: apartmentData?.CommunityName,
                CommunityId: apartmentData?.CommunityId,
                LivingTypeId: cmb_data[0]?.LivingTypeId,
                abv: cmb_data[0]?.abv,
                LivingTypeName: cmb_data[0]?.LivingTypeName,
                ApartmentTypeName: cmb_data[0]?.ApartmentTypeName,
                ApartmentTypeId: cmb_data[0]?.ApartmentTypeId,
                Type: 'apartment',
                Combination_Id: cmb_data[0]?.Combination_Id,
              };

              cmb_data.push(communityData);
            }
            return {
              ...groupByApartmentId(cmb_data),
            };
          });

          const filtered = rows.filter((r) => Object.keys(r).length !== 0);
          //
          //
          settableData(filtered);

          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  }, [community_id]);
  const splitArray = (array: any) => {
    const splitedArray = [];
    const limit = array.length > 1 ? array.length - 1 : array.length;
    for (let i = 0; i < limit; ) {
      const subArray = array.slice(i + 1, i + 4);
      const withComArr = [array[0], ...subArray];
      const table_column: ColumnsType<DataType> = withComArr.map((collumn: any) => ({
        title: (
          <div
            style={{
              padding: '10px 4px',
              whiteSpace: 'normal',
            }}
          >
            <Typography.Title style={{ margin: 0 }} level={4}>
              {collumn?.CommunityName}

              {collumn?.CommunityType === 'apartment' && (
                <span
                  style={{
                    background: '#4CD7D2',
                    color: '#fff',
                    fontSize: '12px',
                    padding: '5px 9px',
                    borderRadius: '10px',
                    marginLeft: 6,
                    whiteSpace: 'normal',
                  }}
                >
                  ME
                </span>
              )}
            </Typography.Title>
          </div>
        ),
        dataIndex: collumn?.CommunityID,
        key: collumn?.CommunityID,
        width: collumn?.CommunityType === 'apartment' ? (pdf ? '400px' : 400) : !pdf ? 300 : 'auto',
        render: (value) => {
          if (value) {
            const data = value[0];
            if (data?.Type === 'apartment') {
              return (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    minWidth: '400px',
                    width: pdf ? '400px' : 'auto',
                    paddingTop: '7px',
                    paddingBottom: '7px',
                  }}
                  className="healing__active"
                >
                  <div>
                    <Typography.Title level={4} style={{ marginTop: '0px', marginBottom: '0px' }}>
                      {data?.ApartmentTypeName}
                    </Typography.Title>
                    <Typography.Title
                      level={5}
                      style={{
                        margin: 0,
                        fontSize: 16,
                        color: '#989696',
                        marginTop: 0,
                      }}
                    >
                      {data?.LivingTypeName}
                    </Typography.Title>
                  </div>
                  <div style={{ textAlign: 'right' }}>
                    {data?.base_rent > 0 && (
                      <Typography.Title level={3} style={{ margin: '0px' }}>
                        <sup style={{ fontSize: '13px' }}>$ </sup>
                        {parseFloat(data?.base_rent || 0).toLocaleString()}
                      </Typography.Title>
                    )}

                    {(data?.sq_footage > 0 || data?.price_per_sq > 0) && (
                      <Typography.Text style={{ color: '#989696' }}>
                        {parseFloat(data?.sq_footage || 0).toLocaleString()} sq. ft. (${' '}
                        {parseFloat(data?.price_per_sq || 0).toLocaleString()})
                      </Typography.Text>
                    )}
                  </div>
                </div>
              );
            } else if (data?.Type === 'competitor') {
              return (
                <div>
                  <Typography.Title
                    level={3}
                    style={{
                      display: 'flex',
                      alignItems: 'start',
                      minWidth: !pdf ? '300px' : '',
                      marginBottom: '0px',
                    }}
                  >
                    <span style={{ fontSize: '12px' }}>$ </span>
                    {parseFloat(data?.base_rent || 0).toLocaleString()}
                    {getColor(data) == '#0ab776' ? (
                      <CaretUpOutlined
                        style={{
                          color: '#0ab776',
                          marginLeft: 5,
                          fontSize: '12px',
                        }}
                      />
                    ) : getColor(data) == '#fa7550' ? (
                      <CaretDownOutlined
                        style={{
                          color: '#fa7550',
                          marginLeft: 5,
                          fontSize: '12px',
                        }}
                      />
                    ) : null}
                  </Typography.Title>
                  <Typography.Title style={{ margin: 0 }} level={5}>
                    {data?.ApartmentTypeName}, {data?.LivingTypeName}
                  </Typography.Title>
                  {(data?.sq_footage > 0 || data?.price_per_sq > 0) && (
                    <Typography.Text style={{ color: '#989696' }}>
                      {parseFloat(data?.sq_footage || 0).toLocaleString()} sq. ft. (${' '}
                      {(data?.price_per_sq || 0).toLocaleString()})
                    </Typography.Text>
                  )}
                </div>
              );
            } else {
              return <div style={{ padding: 20 }}></div>;
            }
          }
        },
      }));
      splitedArray.push(table_column);
      i = i + 3;
    }
    //
    //
    return splitedArray;
  };
  return (
    <div>
      {pdf ? (
        <div>
          {splitCollumns.map((item, index) => (
            <div key={index} style={{ marginBottom: '20px' }}>
              <Table
                columns={item}
                style={{ minWidth: '92vw' }}
                dataSource={tableData}
                // scroll={{ x: 1200 }}
                scroll={{ x: '1300' }}
                // scroll={{ x: "1300px", y: "57vh" }}
                size="middle"
                bordered={true}
                className="p_healing_table"
                showHeader={true}
                loading={loading}
                pagination={false}
                rowKey={(record) => (record[community_id] ? record[community_id][0]?.id : Math.random())}
              />
            </div>
          ))}
        </div>
      ) : (
        <Table
          columns={tableCollumns}
          dataSource={tableData}
          // scroll={{ x: 1200 }}
          scroll={!pdf ? { x: 'max-content', y: '80vh' } : { x: '1300' }}
          // scroll={{ x: "1300px", y: "57vh" }}
          size="middle"
          bordered={true}
          className="p_healing_table"
          showHeader={true}
          loading={loading}
          pagination={false}
          rowKey={(record) => (record[community_id] ? record[community_id][0]?.id : Math.random())}
        />
      )}
    </div>
  );
};

export default CompareAccommodation;
