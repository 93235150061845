import React from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

import { useAppSelector } from 'store/hook';

import AppLayout from '.';

// chatbot settings

const PrivateRoot = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.auth.currentUser);

  React.useEffect(() => {
    if (!currentUser) {
      navigate('/sign-in');
    }
  }, [currentUser, navigate]);

  if (location.pathname === '/') {
    // return <Navigate to="/sign-in" replace />;
    return <Navigate to="/home" replace />;
  }

  return (
    <AppLayout>
      <Outlet />
    </AppLayout>
  );
};

export default PrivateRoot;
