import React, { useState } from 'react';

import { CloseCircleFilled, PlusOutlined } from '@ant-design/icons';
import { Button, DatePicker, InputNumber, message, Select, Spin, Switch, Table, Tooltip, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { ColumnsType } from 'antd/es/table';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import shortUUID from 'short-uuid';

import axios from 'config/axiosPrivate';
import { useAppDispatch, useAppSelector } from 'store/hook';
import './styles.css';
dayjs.extend(customParseFormat);

const dateFormat = 'MM-DD-YYYY';
interface DataType {
  key: React.Key;
  created_by: string;
  Communitytypename: string;
  Livingtypename: string;
  base_rent: number;
}

type CommunityFeesDataType = {
  id: number;
  key: string;
  living_type_id: number;
  community_fee: number;
  second_person_fee: number;
  pet_fee: number;
  respite_fee: number;
  additional_fee: number;
  incentive: string;
  incentive_value: number;
  updated_at: string;
  status: 1 | 0;
  frequency: string;
  op: 'added' | 'edited' | 'deleted' | null;
};

type CareFeesDataType = {
  id: number;
  key: string;
  living_type_id: number;
  fees_type_id: number;
  fee_note: string;
  level_1: number;
  level_2: number;
  level_3: number;
  level_4: number;
  level_5: number;
  level_6: number;
  level_7: number;
  level_8: number;
  medication_management_fee: number;
  average_care_cost: number;
  updated_at: string;
  status: 0 | 1;
  op: 'added' | 'edited' | 'deleted' | null;
};

interface Props {
  community_id: any;
}

const FeesForm = ({ community_id }: Props) => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const [messageApi, contextHolder] = message.useMessage();
  const living_types = useAppSelector((state) => state.global.livingtypes);
  const feeTypes = useAppSelector((state) => state.global.FeeTypes);
  // const careFees = useAppSelector((state) => state.community.careFees);
  // const communityFees = useAppSelector(
  //   (state) => state.community.communityFees
  // );
  const [historyData, setHistoryData] = React.useState<any[]>([]);
  const [careHistoryData, setCareHistoryData] = React.useState<any[]>([]);
  const [selectedTab, setSelectedTab] = React.useState('EDIT');

  // ====
  const [communityFees, setcommunityFees] = useState<CommunityFeesDataType[]>([]);
  const [careFees, setCareFees] = useState<CareFeesDataType[]>([]);

  const [dataLoading, setdataLoading] = useState(false);

  const living_options = living_types.map((val) => ({
    label: val.type,
    value: +val.id,
  }));
  const fee_type_options = feeTypes.map((val) => ({
    label: val.type,
    value: val.id,
  }));

  // for community fees
  const handleAddCommunityRow = () => {
    const newData: CommunityFeesDataType = {
      id: -1,
      key: shortUUID.generate(),
      living_type_id: 2,
      community_fee: 0,
      second_person_fee: 0,
      pet_fee: 0,
      respite_fee: 0,
      additional_fee: 0,
      incentive: "",
      incentive_value: 0,
      status: 1,
      updated_at: new Date().toDateString(),
      frequency: '',
      op: 'added',
    };
    setcommunityFees((prev) => [...prev, newData]);
  };
  const removeCommunityRow = (row_data: CommunityFeesDataType, row_index: number) => {
    const cp = [...communityFees];
    const index = cp.findIndex((el) => el?.key === row_data.key);
    if (index !== -1) {
      if (cp[index].id === -1 && row_data.op === 'added') {
        cp.splice(index, 1);
      } else {
        cp[index].op = 'deleted';
      }
      setcommunityFees(cp);
    }
  };
  const handleChangeCommunityLivingType = (value: any, row_index: number) => {
    const cp = [...communityFees];
    cp[row_index].living_type_id = value;
    if (cp[row_index].id !== -1) {
      cp[row_index].op = 'edited';
    }
    setcommunityFees(cp);
  };

  const handleUpdateCommunityData = (value: any, key: keyof CommunityFeesDataType, row_index: number) => {
    const cp: any[] = [...communityFees];
    cp[row_index][key] = value;
    if (cp[row_index].id !== -1) {
      cp[row_index].op = 'edited';
    }
    setcommunityFees(cp);
  };

  const handleUpdateCommunityRowStatus = (checked: boolean, row_index: number) => {
    const cp = [...communityFees];
    cp[row_index].status = checked ? 0 : 1;
    if (cp[row_index].id !== -1) {
      cp[row_index].op = 'edited';
    }
    setcommunityFees(cp);
  };
  const handleUpdateHistoryCommunityRowStatus = (checked: boolean, row_index: number) => {
    const cp = [...historyData];
    cp[row_index].status = checked ? 0 : 1;
    cp[row_index].living_type_id = cp[row_index].living_type;
    cp[row_index].op = 'edited';
    setcommunityFees(cp);
  };
  const handleUpdateCommunityRowFrequency = (val: any, row_index: number) => {
    const cp = [...communityFees];
    cp[row_index].frequency = val;
    if (cp[row_index].id !== -1) {
      cp[row_index].op = 'edited';
    }
    setcommunityFees(cp);
  };

  // for care fees
  const handleAddRow = () => {
    const newData: CareFeesDataType = {
      id: -1,
      key: shortUUID.generate(),
      living_type_id: 2,
      fees_type_id: 1,
      fee_note: '',
      level_1: 0,
      level_2: 0,
      level_3: 0,
      level_4: 0,
      level_5: 0,
      level_6: 0,
      level_7: 0,
      level_8: 0,
      medication_management_fee: 0,
      average_care_cost: 0,
      status: 1,
      updated_at: new Date().toDateString(),
      op: 'added',
    };

    setCareFees((prev) => [...prev, newData]);
  };
  const handleChangeCareLivingType = (value: any, row_index: number) => {
    const cp = [...careFees];
    cp[row_index].living_type_id = value;
    if (cp[row_index].id !== -1) {
      cp[row_index].op = 'edited';
    }
    setCareFees(cp);
  };

  const handleChangeCareFeeType = (value: any, row_index: number) => {
    const cp = [...careFees];
    cp[row_index].fees_type_id = value;
    if (cp[row_index].id !== -1) {
      cp[row_index].op = 'edited';
    }
    setCareFees(cp);
  };

  const removeRow = (row_data: CareFeesDataType, row_index: number) => {
    const cp = [...careFees];
    const index = cp.findIndex((el) => el?.key === row_data.key);
    if (index !== -1) {
      if (cp[index].id === -1 && row_data.op === 'added') {
        cp.splice(index, 1);
      } else {
        cp[index].op = 'deleted';
      }
      setCareFees(cp);
    }
  };

  const handleUpdateCareFees = (
    value: any,
    row_index: number,
    field_type: string
  ) => {
    //
    const cp: any[] = [...careFees];
    cp[row_index][field_type] = value;
    if (cp[row_index].id !== -1) {
      cp[row_index].op = 'edited';
    }
    setCareFees(cp);
  };

  const handleUpdateCareRowStatus = (checked: boolean, row_index: number) => {
    const cp = [...careFees];
    cp[row_index].status = checked ? 0 : 1;
    if (cp[row_index].id !== -1) {
      cp[row_index].op = 'edited';
    }
    setCareFees(cp);
  };

  const handleUpdateHistoryCareRowStatus = (checked: boolean, row_index: number) => {
    const cp = [...careHistoryData];
    cp[row_index].status = checked ? 0 : 1;
    cp[row_index].living_type_id = cp[row_index].LivingTypeId;
    cp[row_index].fees_type_id = cp[row_index].FeeTypeId;
    cp[row_index].op = 'edited';
    setCareHistoryData(cp);
  };

  // ===========================

  React.useEffect(() => {
    getFeesIncentives();
    getCareFees();
  }, [community_id]);
  const getFeesIncentives = () => {
    setdataLoading(true);
    axios
      .get(`/communities/Get_CommunityFeeIncentives/${community_id}`)
      .then((res) => {
        setdataLoading(false);
        const data = res.data?.payload;
        const activeData = data.filter((el: any) => el?.status === 1);
        const historyData = data.filter((el: any) => el?.status === 0);
        setHistoryData(historyData);
        if (activeData && activeData.length > 0) {
          const formatted_data: CommunityFeesDataType[] = activeData?.map(
            (el: any) => ({
              id: el?.id,
              key: shortUUID.generate(),
              living_type_id: el?.living_type,
              community_fee: el?.community_fee,
              second_person_fee: el?.second_person_fee,
              pet_fee: el?.pet_fee,
              respite_fee: el?.respite_fee,
              additional_fee: el?.additional_fee,
              incentive: el?.incentive,
              incentive_value: el?.incentive_value,
              status: el?.status,
              updated_at: el?.updated_at,
              updated_by: el?.updated_by,
              frequency: el?.frequency || "",
              op: null,
            })
          );
          setcommunityFees(formatted_data);
        } else {
          setcommunityFees([]);
        }
      })
      .catch((err) => {
        setdataLoading(false);
      });
  };
  const getCareFees = () => {
    axios
      .get(`/communities/Get_CommunityCareFees/${community_id}`)
      .then((res) => {
        const data = res.data?.payload;
        const activeData = data.filter((el: any) => el?.status === 1);
        const historyData = data.filter((el: any) => el?.status === 0);
        setCareHistoryData(historyData);

        if (activeData && activeData.length > 0) {
          const formatted_data = activeData?.map((el: any) => ({
            id: el?.id,
            key: shortUUID.generate(),
            living_type_id: el?.LivingTypeId,
            fees_type_id: el?.FeeTypeId,
            fee_note: el?.fee_note,
            level_1: el?.level_1,
            level_2: el?.level_2,
            level_3: el?.level_3,
            level_4: el?.level_4,
            level_5: el?.level_5,
            level_6: el?.level_6,
            level_7: el?.level_7,
            level_8: el?.level_8,
            medication_management_fee: el?.medication_management_fee,
            average_care_cost: el?.average_care_cost,
            status: el?.status,
            updated_at: el?.updated_at,
            updated_by: el?.updated_by,
            op: null,
          }));
          setCareFees(formatted_data);
        } else {
          setCareFees([]);
        }
      })
      .catch((err) => {});
  };

  const [loading, setLoading] = React.useState(false);
  const updateCareFeesAndCommunities = async () => {
    // community fees
    const community_payload = communityFees.filter(
      (fee: any) => fee.op === 'edited' || fee.op === 'added' || fee.op === 'deleted'
    );

    // care fees
    const care_payload = careFees.filter(
      (fee: any) => fee.op === 'edited' || fee.op === 'added' || fee.op === 'deleted'
    );

    try {
      setLoading(true);

      if (community_payload?.length > 0) {
        await axios
          .post('communities/Save_AddComm_FeeIncentives/', {
            data: community_payload.map((el: any) => ({
              ...el,
              updated_at: el?.updated_at
                ? format(new Date(el?.updated_at), 'dd-MM-yyyy hh:mm:ss')
                : format(new Date(), 'dd-MM-yyyy hh:mm:ss'),
              apartment_id: community_id,
            })),
          })
          .then((res) => res.data);

        if (care_payload.length < 1) {
          setTimeout(() => {
            getFeesIncentives();
          }, 1000);
        } else {
          getFeesIncentives();
        }
      }

      if (care_payload.length > 0) {
        await axios
          .post('communities/Save_AddComm_CareFees/', {
            data: care_payload.map((el: any) => ({
              ...el,
              updated_at: el?.updated_at
                ? format(new Date(el?.updated_at), 'dd-MM-yyyy hh:mm:ss')
                : format(new Date(), 'dd-MM-yyyy hh:mm:ss'),
              apartment_id: community_id,
            })),
          })
          .then((res) => res.data);
        getCareFees();
      }

      showMessage('success', 'Saved successfully!');
      setLoading(false);
    } catch (err: any) {
      showMessage("error", err?.response?.data[0]);
      setLoading(false);
    }
  };

  const updateHistoryCareFeesAndCommunities = async () => {
    // community fees
    const community_payload = historyData.filter((fee: any) => fee.op === 'edited');

    // care fees
    const care_payload = careHistoryData.filter((fee: any) => fee.op === 'edited');

    try {
      setLoading(true);

      if (community_payload?.length > 0) {
        await axios
          .post('communities/Save_AddComm_FeeIncentives/', {
            data: community_payload.map((el: any) => ({
              ...el,
              updated_at: el?.updated_at
                ? format(new Date(el?.updated_at), 'dd-MM-yyyy hh:mm:ss')
                : format(new Date(), 'dd-MM-yyyy hh:mm:ss'),
              apartment_id: community_id,
            })),
          })
          .then((res) => res.data);
        getFeesIncentives();
      }

      if (care_payload.length > 0) {
        await axios
          .post('communities/Save_AddComm_CareFees/', {
            data: care_payload.map((el: any) => ({
              ...el,
              updated_at: el?.updated_at
                ? format(new Date(el?.updated_at), 'dd-MM-yyyy hh:mm:ss')
                : format(new Date(), 'dd-MM-yyyy hh:mm:ss'),
              apartment_id: community_id,
            })),
          })
          .then((res) => res.data);
        getCareFees();
      }

      showMessage('success', 'Saved successfully!');
      setLoading(false);
    } catch (err: any) {
      showMessage("error", err?.response?.data[0]);
      setLoading(false);
    }
  };

  const showMessage = (type: 'success' | 'error', mgs: string) => {
    messageApi.open({
      type: type,
      content: mgs,
    });
  };

  const columns: ColumnsType<DataType> = [
    {
      title: 'Living Type',
      dataIndex: 'LivingTypeName',
      filterSearch: true,
      onFilter: (value: any, record: any) => record?.Livingtypename?.startsWith(value),
    },
    {
      title: 'Community Fee',
      dataIndex: 'community_fee',
      filterSearch: true,
      render: (value: number) => {
        return <span>${value?.toLocaleString()}</span>;
      },
      onFilter: (value: any, record: any) => record?.Communitytypename?.startsWith(value),
      filterMode: 'menu',
    },
    {
      title: '2nd Person Fee',
      dataIndex: 'second_person_fee',
      render: (value: number) => {
        return <span>${value?.toLocaleString()}</span>;
      },
    },
    {
      title: 'Pet Fee',
      dataIndex: 'pet_fee',
      render: (value: number) => {
        return <span>${value?.toLocaleString()}</span>;
      },
    },
    {
      title: 'Respite Fee',
      dataIndex: 'respite_fee',
      render: (value: number) => {
        return <span>${value?.toLocaleString()}</span>;
      },
    },
    {
      title: 'Additional Fee',
      dataIndex: 'additional_fee',
      render: (value: number) => {
        return <span>${value?.toLocaleString()}</span>;
      },
    },
    {
      title: "Incentives Value",
      dataIndex: "incentive_value",
      render: (value: number) => {
        return <span>${value?.toLocaleString()}</span>;
      },
    },
    {
      title: "Incentives",
      dataIndex: "incentive",
      // render: (value: string) => {
      //   return <span>{value?.incentive}</span>;
      // },
    },
    {
      title: "Updated By",
      dataIndex: "updated_by",
    },
    {
      title: 'Updated Date',
      dataIndex: 'updated_at',
      render: (value: string) => {
        return (
          <div>
            <span>{value && format(new Date(value), 'MMM dd, yyyy')}</span>
            <br />
            <span>{value && format(new Date(value), 'hh:mm a')}</span>
          </div>
        );
      },
    },
  ];
  const careColumns: ColumnsType<DataType> = [
    {
      title: 'Living Type',
      dataIndex: 'LivingTypeName',
      filterSearch: true,
      onFilter: (value: any, record: any) => record?.Livingtypename?.startsWith(value),
    },
    {
      title: 'Fee Type',
      dataIndex: 'FeeType',
      filterSearch: true,
      render: (value: number) => {
        return <span>${value?.toLocaleString()}</span>;
      },
      onFilter: (value: any, record: any) => record?.Communitytypename?.startsWith(value),
      filterMode: 'menu',
    },
    {
      title: 'Fee Note',
      dataIndex: 'fee_note',
      render: (value: number) => {
        return <span>${value?.toLocaleString()}</span>;
      },
    },
    {
      title: 'Medication Management Fee',
      dataIndex: 'medication_management_fee',
      render: (value: number) => {
        return <span>${value?.toLocaleString()}</span>;
      },
    },
    {
      title: 'Average Care Cost',
      dataIndex: 'average_care_cost',
      render: (value: number) => {
        return <span>${value?.toLocaleString()}</span>;
      },
    },
    {
      title: 'Level 1',
      dataIndex: 'level_1',
      render: (value: number) => {
        return <span>${value?.toLocaleString()}</span>;
      },
    },
    {
      title: 'Level 2',
      dataIndex: 'level_2',
      render: (value: number) => {
        return <span>${value?.toLocaleString()}</span>;
      },
    },
    {
      title: 'Level 3',
      dataIndex: 'level_3',
      render: (value: number) => {
        return <span>${value?.toLocaleString()}</span>;
      },
    },
    {
      title: 'Level 4',
      dataIndex: 'level_4',
      render: (value: number) => {
        return <span>${value?.toLocaleString()}</span>;
      },
    },
    {
      title: 'Level 5',
      dataIndex: 'level_5',
      render: (value: number) => {
        return <span>${value?.toLocaleString()}</span>;
      },
    },
    {
      title: 'Level 6',
      dataIndex: 'level_6',
      render: (value: number) => {
        return <span>${value?.toLocaleString()}</span>;
      },
    },
    {
      title: 'Level 7',
      dataIndex: 'level_7',
      render: (value: number) => {
        return <span>${value?.toLocaleString()}</span>;
      },
    },
    {
      title: 'Level 8',
      dataIndex: 'level_8',
      render: (value: number) => {
        return <span>${value?.toLocaleString()}</span>;
      },
    },
    {
      title: 'Updated By',
      dataIndex: 'updated_by',
    },
    {
      title: 'Updated Date',
      dataIndex: 'updated_at',
      render: (value: string) => {
        return (
          <div>
            <span>{value && format(new Date(value), 'MMM dd, yyyy')}</span>
            <br />
            <span>{value && format(new Date(value), 'hh:mm a')}</span>
          </div>
        );
      },
    },
  ];

  if (currentUser?.isSuperuser) {
    columns.push({
      title: 'History',
      dataIndex: 'status',
      render: (value: number, record: any, row_index: number) => {
        return (
          <Switch
            checked={value == 1 ? false : true}
            onChange={(checked: boolean) => handleUpdateHistoryCommunityRowStatus(checked, row_index)}
          />
        );
      },
    });
    careColumns.push({
      title: 'History',
      dataIndex: 'status',
      render: (value: number, record: any, row_index: number) => {
        return (
          <Switch
            checked={value == 1 ? false : true}
            onChange={(checked: boolean) => handleUpdateHistoryCareRowStatus(checked, row_index)}
          />
        );
      },
    });
  }

  const filtered_community_data = communityFees.filter((fee) => fee.op !== 'deleted');
  const filtered_care_data = careFees.filter((fee) => fee.op !== 'deleted');

  if (dataLoading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: 200,
        }}
      >
        <Spin />
      </div>
    );
  }

  return (
    <div>
      {contextHolder}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 20,
          padding: '0px 30px ',
          marginTop: 0,
          width: '100%',
          overflowX: 'auto',
        }}
      >
        <div>
          <Button
            type="text"
            className={selectedTab === 'EDIT' ? 'p_healing_active_tab' : 'btnStyle'}
            shape="round"
            style={{
              marginRight: 20,
              height: 'auto',
              padding: '5px 20px',
              fontSize: 18,
            }}
            onClick={() => setSelectedTab('EDIT')}
          >
            Edit
          </Button>
          <Button
            type="text"
            shape="round"
            style={{
              marginRight: 20,
              height: 'auto',
              padding: '5px 20px',
              fontSize: 18,
            }}
            className={selectedTab === 'HISTORY' ? 'p_healing_active_tab' : 'btnStyle'}
            onClick={() => setSelectedTab('HISTORY')}
          >
            History
          </Button>
        </div>
      </div>
      {selectedTab === 'EDIT' && (
        <div style={{ minHeight: 'calc(100vh - 430px)', padding: '0px 10px' }}>
          <div>
            <div>
              <div
                style={{
                  background: '#f7f7f7',
                  marginBottom: 10,
                }}
              >
                <Typography.Title level={3}>Community Fees</Typography.Title>
              </div>
              <div
                style={{
                  margin: '0 auto',
                  position: 'relative',
                  overflowX: 'auto',
                  paddingBottom: 20,
                }}
              >
                <div
                  style={{
                    position: 'relative',
                    display: 'inline-block',
                  }}
                >
                  <table
                    className="acc_table_wrapper"
                    style={{
                      borderSpacing: 0,
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          style={{
                            borderLeft: 0,
                            borderTop: 0,
                            borderRight: 0,
                            width: 150,
                          }}
                          align="left"
                        >
                          LIVING TYPE
                        </th>

                        <th
                          style={{
                            borderLeft: 0,
                            borderTop: 0,
                            borderRight: 0,
                            width: 100,
                          }}
                          align="left"
                        >
                          COMMUNITY <br /> FEE
                        </th>
                        <th
                          style={{
                            borderLeft: 0,
                            borderTop: 0,
                            borderRight: 0,
                            position: 'relative',
                          }}
                          align="left"
                        >
                          ONE MONTH
                        </th>
                        <th
                          style={{
                            borderLeft: 0,
                            borderTop: 0,
                            borderRight: 0,
                            position: "relative",
                            width: 200,
                          }}
                          align="left"
                        >
                          2ND PERSON <br /> FEE
                        </th>
                        <th
                          style={{
                            borderLeft: 0,
                            borderTop: 0,
                            borderRight: 0,
                            position: 'relative',
                            width: 100,
                          }}
                          align="left"
                        >
                          PET FEE
                        </th>
                        <th
                          style={{
                            borderLeft: 0,
                            borderTop: 0,
                            borderRight: 0,
                            width: 100,
                          }}
                          align="left"
                        >
                          RESPITE <br /> FEES
                        </th>
                        <th
                          style={{
                            borderLeft: 0,
                            borderTop: 0,
                            borderRight: 0,
                            width: 100,
                          }}
                          align="left"
                        >
                          ADDITIONAL <br /> FEES
                        </th>
                        <th
                          style={{
                            borderLeft: 0,
                            borderTop: 0,
                            borderRight: 0,
                            width: 100,
                          }}
                          align="left"
                        >
                          INCENTIVES
                          <br /> VALUE
                        </th>
                        <th
                          style={{
                            borderLeft: 0,
                            borderTop: 0,
                            borderRight: 0,
                            width: 100,
                          }}
                          align="left"
                        >
                          INCENTIVES
                        </th>

                        {currentUser?.isSuperuser && (
                          <th
                            style={{
                              borderLeft: 0,
                              borderTop: 0,
                              borderRight: 0,
                              padding: 0,
                              width: 150,
                            }}
                            align="left"
                          >
                            UPDATED <br /> DATE
                          </th>
                        )}
                        <th
                          style={{
                            borderLeft: 0,
                            borderTop: 0,
                            borderRight: 0,
                            padding: 0,
                            width: 100,
                          }}
                        >
                          HISTORY
                        </th>
                        <th
                          style={{
                            border: 0,
                          }}
                        ></th>
                      </tr>
                    </thead>
                    <tbody>
                      {filtered_community_data.map((row_data: any, row_index: number) => (
                        <Tooltip
                          key={row_index}
                          title={() => (
                            <span>
                              Updated User: {row_data.updated_by} <br /> Updated Date:{' '}
                              {format(new Date(row_data.updated_at), 'MM-dd-yyyy hh:mm:a')}
                            </span>
                          )}
                          placement="topLeft"
                          color="blue"
                          overlayStyle={{
                            maxWidth: '280px',
                            display: row_data.op != 'added' ? '' : 'none',
                          }}
                          overlayInnerStyle={{ width: '260px' }}
                        >
                          <tr className="acc_row" key={row_index}>
                            <td
                              style={{
                                borderLeft: '1px solid #ccc',
                                padding: 0,
                              }}
                            >
                              <Select
                                bordered={false}
                                value={parseInt(row_data.living_type_id)}
                                options={living_options}
                                style={{ width: '100%', padding: 0 }}
                                onChange={(val) => handleChangeCommunityLivingType(val, row_index)}
                              />
                            </td>

                            <td
                              style={{
                                padding: 0,
                              }}
                            >
                              <InputNumber
                                prefix="$"
                                value={row_data.community_fee > 0 ? row_data.community_fee : ''}
                                onChange={(value) => handleUpdateCommunityData(value, 'community_fee', row_index)}
                                style={{
                                  border: 0,
                                  width: '100%',
                                  borderRadius: 0,
                                  height: '35px',
                                }}
                              />
                            </td>
                            <td
                              style={{
                                padding: 0,
                                width: 100,
                                textAlign: 'center',
                              }}
                            >
                              <Switch
                                checked={row_data?.frequency === '1 month' ? true : false}
                                onChange={(checked: boolean) => handleUpdateCommunityRowFrequency(checked, row_index)}
                                title="Community Fee is Equal to One Month’s Rent"
                              />
                            </td>
                            <td style={{ padding: 0 }}>
                              <InputNumber
                                prefix="$"
                                value={row_data.second_person_fee > 0 ? row_data.second_person_fee : ''}
                                onChange={(value) => handleUpdateCommunityData(value, 'second_person_fee', row_index)}
                                style={{
                                  border: 0,
                                  width: '100%',
                                  borderRadius: 0,
                                  height: '35px',
                                }}
                              />
                            </td>
                            <td style={{ padding: 0 }}>
                              <InputNumber
                                prefix="$"
                                value={row_data.pet_fee > 0 ? row_data.pet_fee : ''}
                                onChange={(value) => handleUpdateCommunityData(value, 'pet_fee', row_index)}
                                style={{
                                  border: 0,
                                  width: '100%',
                                  borderRadius: 0,
                                  height: '35px',
                                }}
                              />
                            </td>
                            <td style={{ padding: 0 }}>
                              <InputNumber
                                prefix="$"
                                value={row_data.respite_fee > 0 ? row_data.respite_fee : ''}
                                onChange={(value) => handleUpdateCommunityData(value, 'respite_fee', row_index)}
                                style={{
                                  border: 0,
                                  width: '100%',
                                  borderRadius: 0,
                                  height: '35px',
                                }}
                              />
                            </td>
                            <td style={{ padding: 0 }}>
                              <InputNumber
                                prefix="$"
                                value={row_data.additional_fee > 0 ? row_data.additional_fee : ''}
                                onChange={(value) => handleUpdateCommunityData(value, 'additional_fee', row_index)}
                                style={{
                                  border: 0,
                                  width: '100%',
                                  borderRadius: 0,
                                  height: '35px',
                                }}
                              />
                            </td>
                            <td style={{ padding: 0 }}>
                              <TextArea
                                value={row_data.incentive}
                                onChange={(e) => handleUpdateCommunityData(e.target.value, 'incentive', row_index)}
                                style={{
                                  border: 0,
                                  width: '350px',
                                  borderRadius: 0,
                                  height: '35px',
                                }}
                              />
                            </td>
                            {currentUser?.isSuperuser && (
                              <td style={{ padding: 0 }}>
                                <DatePicker
                                  value={dayjs(new Date(row_data.updated_at).toDateString())}
                                  format={dateFormat}
                                  onChange={(value) => handleUpdateCommunityData(value, 'updated_at', row_index)}
                                  style={{
                                    border: 0,
                                    borderRadius: 0,
                                    height: '35px',
                                    width: 150,
                                  }}
                                />
                              </td>
                            )}

                              <td
                                style={{
                                  padding: 0,
                                }}
                              >
                                <InputNumber
                                  prefix="$"
                                  value={
                                    row_data.community_fee > 0
                                      ? row_data.community_fee
                                      : ""
                                  }
                                  onChange={(value) =>
                                    handleUpdateCommunityData(
                                      value,
                                      "community_fee",
                                      row_index
                                    )
                                  }
                                  style={{
                                    border: 0,
                                    width: "100%",
                                    borderRadius: 0,
                                    height: "35px",
                                  }}
                                />
                              </td>
                              <td
                                style={{
                                  padding: 0,
                                  width: 200,
                                  // textAlign: "center",
                                }}
                                // title="Community Fee is Equal to One Month’s Rent"
                              >
                                <Select
                                  bordered={false}
                                  value={row_data?.frequency}
                                  placeholder="Select"
                                  options={[
                                    {
                                      value: "Nil",
                                      label: "",
                                    },
                                    {
                                      value: "1",
                                      label: "1 Month",
                                    },
                                    {
                                      label: "2 Month",
                                      value: "2",
                                    },
                                    {
                                      label: "3 Month",
                                      value: "3",
                                    },
                                    {
                                      label: "4 Month",
                                      value: "4",
                                    },
                                    {
                                      label: "5 Month",
                                      value: "5",
                                    },
                                    {
                                      label: "6 Month",
                                      value: "6",
                                    },
                                    {
                                      label: "7 Month",
                                      value: "7",
                                    },
                                    {
                                      label: "8 Month",
                                      value: "8",
                                    },
                                    {
                                      label: "9 Month",
                                      value: "9",
                                    },
                                    {
                                      label: "10 Month",
                                      value: "10",
                                    },
                                    {
                                      label: "11 Month",
                                      value: "11",
                                    },
                                    {
                                      label: "12 Month",
                                      value: "12",
                                    },
                                  ]}
                                  style={{ width: "100%", padding: 0 }}
                                  onChange={(val) =>
                                    handleUpdateCommunityRowFrequency(
                                      val,
                                      row_index
                                    )
                                  }
                                />
                              </td>
                              <td style={{ padding: 0 }}>
                                <InputNumber
                                  prefix="$"
                                  value={
                                    row_data.second_person_fee > 0
                                      ? row_data.second_person_fee
                                      : ""
                                  }
                                  onChange={(value) =>
                                    handleUpdateCommunityData(
                                      value,
                                      "second_person_fee",
                                      row_index
                                    )
                                  }
                                  style={{
                                    border: 0,
                                    width: "100%",
                                    borderRadius: 0,
                                    height: "35px",
                                  }}
                                />
                              </td>
                              <td style={{ padding: 0 }}>
                                <InputNumber
                                  prefix="$"
                                  value={
                                    row_data.pet_fee > 0 ? row_data.pet_fee : ""
                                  }
                                  onChange={(value) =>
                                    handleUpdateCommunityData(
                                      value,
                                      "pet_fee",
                                      row_index
                                    )
                                  }
                                  style={{
                                    border: 0,
                                    width: "100%",
                                    borderRadius: 0,
                                    height: "35px",
                                  }}
                                />
                              </td>
                              <td style={{ padding: 0 }}>
                                <InputNumber
                                  prefix="$"
                                  value={
                                    row_data.respite_fee > 0
                                      ? row_data.respite_fee
                                      : ""
                                  }
                                  onChange={(value) =>
                                    handleUpdateCommunityData(
                                      value,
                                      "respite_fee",
                                      row_index
                                    )
                                  }
                                  style={{
                                    border: 0,
                                    width: "100%",
                                    borderRadius: 0,
                                    height: "35px",
                                  }}
                                />
                              </td>
                              <td style={{ padding: 0 }}>
                                <InputNumber
                                  prefix="$"
                                  value={
                                    row_data.additional_fee > 0
                                      ? row_data.additional_fee
                                      : ""
                                  }
                                  onChange={(value) =>
                                    handleUpdateCommunityData(
                                      value,
                                      "additional_fee",
                                      row_index
                                    )
                                  }
                                  style={{
                                    border: 0,
                                    width: "100%",
                                    borderRadius: 0,
                                    height: "35px",
                                  }}
                                />
                              </td>
                              <td style={{ padding: 0 }}>
                                <InputNumber
                                  prefix="$"
                                  value={
                                    row_data.incentive_value > 0
                                      ? row_data.incentive_value
                                      : ""
                                  }
                                  onChange={(value) =>
                                    handleUpdateCommunityData(
                                      value,
                                      "incentive_value",
                                      row_index
                                    )
                                  }
                                  style={{
                                    border: 0,
                                    width: "100%",
                                    borderRadius: 0,
                                    height: "35px",
                                  }}
                                />
                              </td>
                              <td style={{ padding: 0 }}>
                                <TextArea
                                  value={row_data.incentive}
                                  onChange={(e) =>
                                    handleUpdateCommunityData(
                                      e.target.value,
                                      "incentive",
                                      row_index
                                    )
                                  }
                                  style={{
                                    border: 0,
                                    width: "450px",
                                    borderRadius: 0,
                                    height: "35px",
                                  }}
                                />
                              </td>
                              {currentUser?.isSuperuser && (
                                <td style={{ padding: 0 }}>
                                  <DatePicker
                                    value={dayjs(
                                      new Date(
                                        row_data.updated_at
                                      ).toDateString()
                                    )}
                                    format={dateFormat}
                                    onChange={(value) =>
                                      handleUpdateCommunityData(
                                        value,
                                        "updated_at",
                                        row_index
                                      )
                                    }
                                    style={{
                                      border: 0,
                                      borderRadius: 0,
                                      height: "35px",
                                      width: 150,
                                    }}
                                  />
                                </td>
                              )}

                              <td
                                style={{
                                  padding: 0,
                                  width: 100,
                                  textAlign: "center",
                                }}
                              >
                                <Switch
                                  checked={
                                    row_data?.status === 1 ? false : true
                                  }
                                  onChange={(checked: boolean) =>
                                    handleUpdateCommunityRowStatus(
                                      checked,
                                      row_index
                                    )
                                  }
                                />
                              </td>
                              <td style={{ border: 0 }}>
                                <Button
                                  icon={<CloseCircleFilled />}
                                  size="small"
                                  type="link"
                                  danger
                                  onClick={() =>
                                    removeCommunityRow(row_data, row_index)
                                  }
                                />
                              </td>
                            </tr>
                          </Tooltip>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
                <br />
                <Button
                  icon={<PlusOutlined />}
                  type="default"
                  shape="round"
                  style={{
                    fontSize: 14,
                    height: 'max-content',
                    padding: '6px 10px 4px 10px',
                    marginTop: 0,
                  }}
                  onClick={handleAddCommunityRow}
                >
                  Add
                </Button>
              </div>
              <br />
              <br />

              {/* care fees */}
              <div
                style={{
                  background: '#f7f7f7',
                  marginBottom: 10,
                }}
              >
                <Typography.Title level={3}>Care Fees</Typography.Title>
              </div>
              <div
                style={{
                  margin: '0 auto',
                  position: 'relative',
                  overflowX: 'auto',
                  paddingBottom: 20,
                }}
              >
                <div
                  style={{
                    position: 'relative',
                  }}
                >
                  <table
                    className="acc_table_wrapper"
                    style={{
                      borderSpacing: 0,
                      width: '100%',
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          style={{
                            borderLeft: 0,
                            borderTop: 0,
                            borderRight: '1px solid #ccc',
                            textTransform: 'uppercase',
                          }}
                        >
                          Living Type
                        </th>
                        <th
                          style={{
                            border: '1px solid #ccc',
                          }}
                          align="left"
                        >
                          FEE TYPE
                        </th>
                        <th
                          style={{
                            border: '1px solid #ccc',
                          }}
                          align="left"
                        >
                          FEE NOTE
                        </th>
                        <th
                          style={{
                            borderLeft: 0,
                            position: 'relative',
                            textTransform: 'uppercase',
                          }}
                        >
                          Medication Management Fee
                        </th>
                        <th
                          style={{
                            borderLeft: 0,
                            position: 'relative',
                            textTransform: 'uppercase',
                          }}
                        >
                          Average Care Cost
                        </th>
                        {[1, 2, 3, 4, 5, 6, 7, 8].map((lt: any, idx: number) => (
                          <th
                            key={lt}
                            style={{
                              borderLeft: 0,
                              position: 'relative',
                              textTransform: 'uppercase',
                            }}
                          >
                            Level {lt}
                          </th>
                        ))}
                        {currentUser?.isSuperuser && (
                          <th
                            style={{
                              borderLeft: 0,
                              borderRight: '1px solid #ccc',
                              borderTop: '1px solid #ccc',
                              borderBottom: '1px solid #ccc',
                              padding: 10,
                              position: 'relative',
                            }}
                          >
                            UPDATED DATE
                          </th>
                        )}

                        <th
                          style={{
                            borderLeft: 0,
                            borderRight: '1px solid #ccc',
                            borderTop: '1px solid #ccc',
                            borderBottom: '1px solid #ccc',
                            padding: 10,
                            position: 'relative',
                          }}
                        >
                          HISTORY
                        </th>

                        <th style={{ border: 0 }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {filtered_care_data.map((row_data: any, row_index: number) => (
                        <Tooltip
                          key={row_index}
                          title={() => (
                            <span>
                              Updated User: {row_data.updated_by} <br /> Updated Date:{' '}
                              {format(new Date(row_data.updated_at), 'MM-dd-yyyy hh:mm:a')}
                            </span>
                          )}
                          placement="topLeft"
                          color="blue"
                          overlayStyle={{
                            maxWidth: '280px',
                            display: row_data.op != 'added' ? '' : 'none',
                          }}
                          overlayInnerStyle={{ width: '260px' }}
                        >
                          <tr key={row_index} className="acc_row">
                            <td
                              style={{
                                borderLeft: '1px solid #ccc',
                                padding: 0,
                              }}
                            >
                              <Select
                                bordered={false}
                                value={parseInt(row_data.living_type_id)}
                                options={living_options}
                                style={{ width: '200px', padding: 0 }}
                                onChange={(val) => handleChangeCareLivingType(val, row_index)}
                              />
                            </td>
                            <td style={{ padding: 0 }}>
                              <Select
                                bordered={false}
                                value={parseInt(row_data.fees_type_id)}
                                options={fee_type_options}
                                style={{ width: '130px', padding: 0 }}
                                onChange={(val) => handleChangeCareFeeType(val, row_index)}
                              />
                            </td>
                            <td style={{ padding: 0 }}>
                              <TextArea
                                value={row_data.fee_note}
                                onChange={(e) => handleUpdateCareFees(e.target.value, row_index, 'fee_note')}
                                style={{
                                  border: 0,
                                  width: '200px',
                                  borderRadius: 0,
                                  height: '35px',
                                }}
                              />
                            </td>
                            <td style={{ padding: 0 }}>
                              <InputNumber
                                prefix="$"
                                value={row_data.medication_management_fee > 0 ? row_data.medication_management_fee : ''}
                                onChange={(value) =>
                                  handleUpdateCareFees(value, row_index, 'medication_management_fee')
                                }
                                style={{
                                  border: 0,
                                  width: '100%',
                                  borderRadius: 0,
                                  height: '35px',
                                }}
                              />
                            </td>
                            <td style={{ padding: 0 }}>
                              <InputNumber
                                prefix="$"
                                value={row_data.average_care_cost > 0 ? row_data.average_care_cost : ''}
                                onChange={(value) => handleUpdateCareFees(value, row_index, 'average_care_cost')}
                                style={{
                                  border: 0,
                                  width: '100%',
                                  borderRadius: 0,
                                  height: '35px',
                                }}
                              />
                            </td>
                            <td style={{ padding: 0 }}>
                              <InputNumber
                                prefix="$"
                                value={row_data.level_1 > 0 ? row_data.level_1 : ''}
                                onChange={(value) => handleUpdateCareFees(value, row_index, 'level_1')}
                                style={{
                                  border: 0,
                                  width: '90px',
                                  borderRadius: 0,
                                  height: '35px',
                                }}
                              />
                            </td>
                            <td style={{ padding: 0 }}>
                              <InputNumber
                                prefix="$"
                                value={row_data.level_2 > 0 ? row_data.level_2 : ''}
                                onChange={(value) => handleUpdateCareFees(value, row_index, 'level_2')}
                                style={{
                                  border: 0,
                                  width: '90px',
                                  borderRadius: 0,
                                  height: '35px',
                                }}
                              />
                            </td>
                            <td style={{ padding: 0 }}>
                              <InputNumber
                                prefix="$"
                                value={row_data.level_3 > 0 ? row_data.level_3 : ''}
                                onChange={(value) => handleUpdateCareFees(value, row_index, 'level_3')}
                                style={{
                                  border: 0,
                                  width: '90px',
                                  borderRadius: 0,
                                  height: '35px',
                                }}
                              />
                            </td>
                            <td style={{ padding: 0 }}>
                              <InputNumber
                                prefix="$"
                                value={row_data.level_4 > 0 ? row_data.level_4 : ''}
                                onChange={(value) => handleUpdateCareFees(value, row_index, 'level_4')}
                                style={{
                                  border: 0,
                                  width: '90px',
                                  borderRadius: 0,
                                  height: '35px',
                                }}
                              />
                            </td>
                            <td style={{ padding: 0 }}>
                              <InputNumber
                                prefix="$"
                                value={row_data.level_5 > 0 ? row_data.level_5 : ''}
                                onChange={(value) => handleUpdateCareFees(value, row_index, 'level_5')}
                                style={{
                                  border: 0,
                                  width: '90px',
                                  borderRadius: 0,
                                  height: '35px',
                                }}
                              />
                            </td>
                            <td style={{ padding: 0 }}>
                              <InputNumber
                                prefix="$"
                                value={row_data.level_6 > 0 ? row_data.level_6 : ''}
                                onChange={(value) => handleUpdateCareFees(value, row_index, 'level_6')}
                                style={{
                                  border: 0,
                                  width: '90px',
                                  borderRadius: 0,
                                  height: '35px',
                                }}
                              />
                            </td>
                            <td style={{ padding: 0 }}>
                              <InputNumber
                                prefix="$"
                                value={row_data.level_7 > 0 ? row_data.level_7 : ''}
                                onChange={(value) => handleUpdateCareFees(value, row_index, 'level_7')}
                                style={{
                                  border: 0,
                                  width: '90px',
                                  borderRadius: 0,
                                  height: '35px',
                                }}
                              />
                            </td>
                            <td style={{ padding: 0 }}>
                              <InputNumber
                                prefix="$"
                                value={row_data.level_8 > 0 ? row_data.level_8 : ''}
                                onChange={(value) => handleUpdateCareFees(value, row_index, 'level_8')}
                                style={{
                                  border: 0,
                                  width: '90px',
                                  borderRadius: 0,
                                  height: '35px',
                                }}
                              />
                            </td>
                            {currentUser?.isSuperuser && (
                              <td style={{ padding: 0 }}>
                                <DatePicker
                                  value={dayjs(new Date(row_data.updated_at).toDateString())}
                                  format={dateFormat}
                                  onChange={(value) => handleUpdateCareFees(value, row_index, 'updated_at')}
                                  style={{
                                    border: 0,
                                    width: '200px',
                                    borderRadius: 0,
                                    height: '35px',
                                  }}
                                />
                              </td>
                            )}
                            <td
                              style={{
                                padding: 0,
                                width: 150,
                                textAlign: 'center',
                              }}
                            >
                              <Switch
                                checked={row_data?.status === 1 ? false : true}
                                onChange={(checked: boolean) => handleUpdateCareRowStatus(checked, row_index)}
                              />
                            </td>
                            <td style={{ border: 0 }}>
                              <Button
                                icon={<CloseCircleFilled />}
                                size="small"
                                type="link"
                                danger
                                // style={{
                                //   opacity:
                                //     row_index === 0 && careFees.length === 1
                                //       ? 0
                                //       : 1,
                                // }}
                                onClick={() => removeRow(row_data, row_index)}
                              />
                            </td>
                          </tr>
                        </Tooltip>
                      ))}
                    </tbody>
                  </table>
                  <Button
                    icon={<PlusOutlined />}
                    type="default"
                    shape="round"
                    style={{
                      fontSize: 14,
                      height: 'max-content',
                      padding: '6px 10px 4px 10px',
                      marginTop: 10,
                    }}
                    onClick={handleAddRow}
                  >
                    Add
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              borderTop: '1px solid #eee',
              marginTop: 30,
            }}
          >
            <div
              style={{
                padding: '50px 0px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={loading}
                onClick={updateCareFeesAndCommunities}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      )}
      {selectedTab === 'HISTORY' && (
        <>
          <div
            style={{
              // maxWidth: 991,
              margin: '0 auto',
              padding: '10px 30px',
            }}
          >
            <div
              style={{
                background: '#f7f7f7',
                marginBottom: 10,
                padding: '0px 10px',
              }}
            >
              <Typography.Title level={3}>Community Fees</Typography.Title>
            </div>
            <Table columns={columns} dataSource={historyData} />
          </div>
          <div
            style={{
              // maxWidth: 991,
              margin: '0 auto',
              padding: '10px 30px',
              overflowX: 'auto',
            }}
          >
            <div
              style={{
                background: '#f7f7f7',
                marginBottom: 10,
                padding: '0px 10px',
              }}
            >
              <Typography.Title level={3}>Care Fees</Typography.Title>
            </div>
            <Table columns={careColumns} dataSource={careHistoryData} />
          </div>
          <div
            style={{
              borderTop: '1px solid #eee',
              marginTop: 30,
              maxWidth: 1291,
            }}
          >
            <div
              style={{
                padding: '10px 0px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <Button type="primary" loading={loading} disabled={loading} onClick={updateHistoryCareFeesAndCommunities}>
                Save
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
export default FeesForm;
