import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { message, Spin } from 'antd';

import axios from 'config/axiosPrivate';
import { useAppSelector } from 'store/hook';

import SwotTable from './SwotTable';
import { CategoryType, TableType } from './types';

const SwotSettings = () => {
  const navigate = useNavigate();
  const companyId = useAppSelector((state) => state.auth.currentUser?.company);
  const [dataSource, setDataSource] = useState<TableType[]>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  // get Total occupancies units
  useEffect(() => {
    getInitialData();
    // getSeedData();
  }, []);

  const getInitialData = () => {
    setDataLoading(true);
    axios
      .get(`/communities/Get_ImportanceRating`)
      .then((res) => {
        setDataLoading(false);
        const data: any[] = res.data?.payload;

        const updated_data = data.map((el: any) => ({
          ...el,
          category: el?.category?.map((cat: any, index: number) => ({
            ...cat,
            key: `${index + 1}`,
            op: null,
          })),
        }));
        setDataSource(updated_data);
      })
      .catch((err) => {
        setDataLoading(false);
      });
  };

  // const getSeedData = () => {
  //   axios
  //     .get(`/communities/Get_SeedImportanceRating`)
  //     .then((res) => {
  //       setDataLoading(false);
  //       const data: any[] = res.data?.payload;

  //       // const updated_data = data.map((el: any) => ({
  //       //   ...el,
  //       //   category: el?.category?.map((cat: any) => ({
  //       //     ...cat,
  //       //     key: shortUUID.generate(),
  //       //     op: null,
  //       //   })),
  //       // }));
  //       // setDataSource(updated_data);
  //     })
  //     .catch((err) => {
  //       setDataLoading(false);
  //
  //     });
  // };

  const handleUpdateSwot = (option: any, record: CategoryType) => {
    const cp = [...dataSource];
    const index = cp.findIndex((p) => p.id === record.category_id);
    if (index !== -1) {
      const parent_obj = cp[index];
      if (parent_obj) {
        const categories = parent_obj.category;
        const cat_index = categories.findIndex((cat) => cat.key === record.key);
        const target: CategoryType = categories[cat_index];
        target.sub_category_score = option.value;
        target.important_score = option.label;

        // update api
        const { category, ManagementCompanyId, ManagementCompanyName, CompanyName, CompanyId, ...p_rest } = parent_obj;
        const payload: any = {
          ...p_rest,
          company_id: CompanyId,
          management_company_id: ManagementCompanyId,
          op: 'edited',
        };
        const { key, ...rest } = target;
        rest.op = 'edited';
        payload.SubRatings = [rest];
        setLoading(true);
        axios
          .post('/communities/Save_ImportanceRating/', {
            data: [payload],
          })
          .then((res) => {
            showMessage('success', 'Saved Successfully.');
            setLoading(false);
          })
          .catch((err) => {
            showMessage('error', 'Failed to update');
            setLoading(false);
          });
      }
    }
    setDataSource(cp);
  };

  const showMessage = (type: 'success' | 'error', mgs: string) => {
    messageApi.open({
      type: type,
      content: mgs,
    });
  };

  return (
    <>
      <div
        style={{
          padding: '20px 30px 0px',
        }}
      >
        <div>
          <span>
            <span style={{ color: '#1f58b0', cursor: 'pointer' }} onClick={() => navigate('/')}>
              HOME
            </span>
            <span
              style={{
                color: '#222',
                margin: '0px 10px',
                textTransform: 'uppercase',
              }}
            >
              Swot settings
            </span>
          </span>
        </div>
      </div>

      <div>
        {contextHolder}

        {dataLoading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: 200,
            }}
          >
            <Spin />
          </div>
        ) : (
          dataSource &&
          dataSource.map((data, idx) => (
            <SwotTable key={idx} data={data.category} title={data?.category_name} handleUpdateSwot={handleUpdateSwot} />
          ))
        )}
      </div>
    </>
  );
};

export default SwotSettings;
