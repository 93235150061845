import React from 'react';
import ReactApexChart from 'react-apexcharts';

import marketTrendsChat from './configs/marketTrends';

function MarketRateTrends() {
  return (
    <>
      <div
        id="charti"
        style={{
          overflowY: 'hidden',
          width: '100%',
        }}
      >
        {/* @ignore-ts */}
        <ReactApexChart options={marketTrendsChat.options} series={marketTrendsChat.series} type="bar" height={420} />
      </div>
    </>
  );
}

export default MarketRateTrends;
