import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from '../index';

type DataType = {
  id: number;
  type: string;
  created_at: string;
  updated_at: string;
};

type ApartmentDataType = {
  id: number;
  rating_name: string;
  order_no: number;
  rate_pct: number;
  updated_by: string;
  created_by: string;
  updated_at: string;
  created_at: string;
  status: number;
  company_id: number;
};

interface UniqueListType {
  FeeTypes: DataType[];
  LevelTypes: DataType[];
  OccupancyTypes: DataType[];
  PaymentTypes: DataType[];
  apartmenttypes: DataType[];
  livingtypes: DataType[];
  qsunittypes?: DataType[];
  business_models: DataType[];
  apartment_ratings: ApartmentDataType[];
  selectedComunityCompanyName: string;
}

interface GlobalType extends UniqueListType {
  communityList: any[];
  companyList: any[];
}

// Define the initial state using that type
const initialState: GlobalType = {
  FeeTypes: [],
  LevelTypes: [],
  OccupancyTypes: [],
  PaymentTypes: [],
  apartmenttypes: [],
  livingtypes: [],
  qsunittypes: [],
  business_models: [],
  communityList: [],
  companyList: [],
  apartment_ratings: [],
  selectedComunityCompanyName: '',
};

export const globalSlice = createSlice({
  name: 'global',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleSetOptionTypes: (state, action: PayloadAction<UniqueListType>) => {
      state.FeeTypes = action.payload.FeeTypes;
      state.LevelTypes = action.payload.LevelTypes;
      state.OccupancyTypes = action.payload.OccupancyTypes;
      state.PaymentTypes = action.payload.PaymentTypes;
      state.apartmenttypes = action.payload.apartmenttypes;
      state.livingtypes = action.payload.livingtypes;
      state.business_models = action.payload.business_models;
      state.apartment_ratings = action.payload.apartment_ratings;
      state.selectedComunityCompanyName = action.payload.selectedComunityCompanyName;
    },
    handleStoreUserCommunities: (state, action: PayloadAction<any[]>) => {
      state.communityList = action.payload;
    },
    handleStoreUserCompanies: (state, action: PayloadAction<any[]>) => {
      state.companyList = action.payload;
    },
  },
});

export const { handleSetOptionTypes, handleStoreUserCommunities, handleStoreUserCompanies } = globalSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAuth = (state: RootState) => state.auth;

export default globalSlice.reducer;
