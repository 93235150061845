import React from 'react';

import MarketRateTrends from 'components/chart/MarketRateTrends';

import CommunityTitleBar from './PriceHealing/common/CommunityTitleBar';

function Home() {
  return (
    <>
      <CommunityTitleBar title="Avg Total Market Rate Trends" textAlign="left" />
      <br />
      <MarketRateTrends />
    </>
  );
}

export default Home;
