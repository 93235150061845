import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingFilled,
  UnlockOutlined,
  UploadOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Avatar, Badge, Button, Dropdown, MenuProps, Space, Typography } from 'antd';

import ChangeAvatarModal from 'modals/ChangeAvatar';
import ChangePasswordModal from 'modals/ChangePassword';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { handleLogout } from 'store/slices/authSlice';

import classes from './styles.module.scss';

interface Props {
  setCollapsed: (s: boolean) => void;
  collapsed: boolean;
}

const MainHeader = ({ setCollapsed, collapsed }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const companyName = useAppSelector((state) => state.global.selectedComunityCompanyName);
  const [openAvatarModal, setOpenAvatarModal] = useState(false);
  const [openPasswordModal, setOpenPasswordModal] = useState(false);

  const onLogoutUser = () => {
    dispatch(handleLogout());
    navigate('/sign-in');
  };
  const items: MenuProps['items'] = [
    {
      label: 'Settings',
      key: '1',
      icon: <SettingFilled />,
      style: {
        minWidth: 200,
      },
      onClick: () => console.log(''),
      children: [
        {
          label: 'Apartment Ratings',
          key: 'Settings_1',
          style: {
            minWidth: 200,
          },
          onClick: () => navigate(`/settings/apartment-ratings`),
        },
        {
          label: 'Occupancy Tiers',
          key: 'Settings_2',
          style: {
            minWidth: 200,
          },
          onClick: () => navigate(`/settings/occupancy-tiers`),
        },
        {
          label: 'SWOT Settings',
          key: 'Settings_3',
          style: {
            minWidth: 200,
          },
          onClick: () => navigate(`/settings/swot-settings`),
        },
        {
          label: 'SWOT Survey',
          key: 'Swot Survey',
          style: {
            minWidth: 200,
          },
          onClick: () => navigate(`/swot-survey`),
        },
        {
          label: 'SWOT Survey List',
          key: 'Swot Survey List',
          style: {
            minWidth: 200,
          },
          onClick: () => navigate(`/swot-survey-list`),
        },
      ],
    },

    {
      label: 'Change Avatar',
      key: 'Change Avatar',
      icon: <UploadOutlined />,
      style: {
        minWidth: 200,
      },
      onClick: () => setOpenAvatarModal(true),
    },
    {
      label: 'Change Password',
      key: 'Change Password',
      icon: <UnlockOutlined />,
      style: {
        minWidth: 200,
      },
      onClick: () => setOpenPasswordModal(true),
    },
    {
      label: 'Logout',
      key: '0',
      icon: <LogoutOutlined />,
      style: {
        minWidth: 200,
      },
      onClick: onLogoutUser,
    },
  ];

  return (
    <div className={classes.header_main} color="light">
      <ChangePasswordModal
        open={openPasswordModal}
        handleClose={() => setOpenPasswordModal(false)}
        title="Change Password"
      />
      <ChangeAvatarModal open={openAvatarModal} handleClose={() => setOpenAvatarModal(false)} title="Change Avatar" />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 'max-content',
          height: '100%',
        }}
      >
        {currentUser?.isSuperuser && (
          <Button
            type="link"
            className="sidebar-toggler"
            onClick={() => setCollapsed(!collapsed)}
            icon={
              collapsed ? (
                <MenuUnfoldOutlined style={{ fontSize: 22 }} />
              ) : (
                <MenuFoldOutlined style={{ fontSize: 22 }} />
              )
            }
            size="large"
          />
        )}
        <Link to="/home" style={{ height: 40 }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src="/pricing_assistant_logo.png"
              alt="Further"
              style={{
                objectFit: 'contain',
                width: 110,
                height: 40,
              }}
            />
            <img
              src="/beta.png"
              alt="Further"
              style={{
                marginRight: 10,
                objectFit: 'contain',
                width: 25,
                height: 25,
              }}
            />
          </div>
        </Link>
      </div>

      <div>
        {companyName ? (
          <h2>{companyName}</h2>
        ) : (
          <h2>
            {currentUser?.companyName}
            {currentUser && currentUser?.ManagementcompanyName ? (
              <span>-{currentUser?.ManagementcompanyName}</span>
            ) : null}{' '}
          </h2>
        )}
      </div>
      {/* <div className={classes.select_inputs}>
        <Select
          showSearch
          style={{
            width: 200,
            marginRight: 10,
          }}
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          placeholder="Any community"
          options={community_options}
          size="middle"
        />
        <Select
          showSearch
          style={{ width: 200 }}
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          placeholder="Any care type"
          options={livingtypesOptions}
          size="middle"
        />
      </div> */}

      <div className={classes.header_right}>
        <div style={{ marginRight: 31 }}>
          <Badge dot>
            <Button
              type="link"
              size="small"
              icon={
                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <g fill="none" fillRule="evenodd">
                    <path d="M0 0h24v24H0z" />
                    <path
                      d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-2 1H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6z"
                      fill="#222"
                      fillRule="nonzero"
                    />
                  </g>
                </svg>
              }
            />
          </Badge>
        </div>
        <Dropdown menu={{ items }} placement="bottomRight" arrow trigger={['click']}>
          <a onClick={(e) => e.preventDefault()} href="#!">
            <Space>
              <Typography.Text className={classes.username_text}>{currentUser?.name}</Typography.Text>
              {currentUser?.avatar ? (
                <Avatar size={30} src={currentUser?.avatar} />
              ) : (
                <Avatar size={30} icon={<UserOutlined />} />
              )}
            </Space>
          </a>
        </Dropdown>
      </div>
    </div>
  );
};

export default MainHeader;
