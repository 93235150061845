import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import {
  BuildFilled,
  ContainerOutlined,
  ControlFilled,
  CreditCardFilled,
  FundFilled,
  GoldenFilled,
  ProjectFilled,
  ScheduleFilled,
  SmileFilled,
} from '@ant-design/icons';
import { Layout, MenuProps } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';

const { Content } = Layout;

const subItems: ItemType[] = [
  {
    key: '1',
    label: 'Fee Types',
    icon: <ContainerOutlined />,
  },
  {
    key: '2',
    label: 'Payment Types',
    icon: <CreditCardFilled />,
  },
  {
    key: '3',
    label: 'Apartment Types',
    icon: <ProjectFilled />,
  },
  {
    key: '4',
    label: 'Care Types',
    icon: <SmileFilled />,
  },
  {
    key: '5',
    label: 'Competition Level',
    icon: <GoldenFilled />,
  },
  {
    key: '6',
    label: 'Occupancy',
    icon: <BuildFilled />,
  },
  {
    key: '7',
    label: 'Business Model',
    icon: <FundFilled />,
  },
  {
    key: '8',
    label: 'SWOT Settings',
    icon: <ControlFilled />,
  },
  {
    key: '9',
    label: 'Positions-Rights',
    icon: <ScheduleFilled />,
  },
];

const SettingsPage = () => {
  const navigate = useNavigate();
  const onClick: MenuProps['onClick'] = (e) => {
    // console.log("click ", e);
    if (e.key === '1') {
      navigate('/settings/fee-types');
    } else if (e.key === '2') {
      navigate('/settings/payment-types');
    } else if (e.key === '3') {
      navigate('/settings/apartment-types');
    } else if (e.key === '4') {
      navigate('/settings/care-types');
    } else if (e.key === '5') {
      navigate('/settings/competition-level');
    } else if (e.key === '6') {
      navigate('/settings/occupancy');
    } else if (e.key === '7') {
      navigate('/settings/business-model');
    } else if (e.key === '8') {
      navigate('/settings/swot-settings');
    } else if (e.key === '9') {
      navigate('/settings/position-rights');
    }
  };
  return (
    <Layout style={{ marginLeft: '0px', background: '#eee' }}>
      {/* <Sider
        theme="light"
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          
        }}
        onCollapse={(collapsed, type) => {
          
        }}
        style={{ height: "100vh" }}
      >
        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={["4"]}
          items={subItems}
          onClick={onClick}
        />
      </Sider> */}
      <Content style={{ margin: '0px' }}>
        <div style={{ padding: 24, background: '#fff' }}>
          <Outlet />
        </div>
      </Content>
    </Layout>
  );
};

export default SettingsPage;
