import React, { useEffect, useState } from 'react';

import { CloseCircleFilled, PlusOutlined } from '@ant-design/icons';
import { Button, DatePicker, Input, InputNumber, message, Select, Spin, Switch, Tooltip } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import shortUUID from 'short-uuid';

import axios from 'config/axiosPrivate';
import { useAppSelector } from 'store/hook';

dayjs.extend(customParseFormat);
const dateFormat = 'MM-DD-YYYY';

interface HistoryDataType {
  key: React.Key;
  created_by: string;
  Communitytypename: string;
  Livingtypename: string;
  base_rent: number;
}

interface DataType {
  id: number;
  key: string;
  apartment_id: number;
  apartment_type_id: number;
  living_type_id: number;
  base_rent: number;
  sq_footage: number;
  price_per_sq: number;
  notes: string | '';
  status: 0 | 1;
  op: 'added' | 'edited' | 'deleted' | null;
  updated_at: string;
  updated_by: string;
}

interface Props {
  community_id: number;
  livingType: number;
}

const IndependentTab = ({ community_id, livingType }: Props) => {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const names = useAppSelector((state) => state.global.livingtypes);
  const [messageApi, contextHolder] = message.useMessage();
  const apartment_types = useAppSelector((state) => state.global.apartmenttypes);
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [historyData, setHistoryData] = useState<any[]>([]);
  const [selectedTab, setSelectedTab] = useState('EDIT');
  const [dataLoading, setDataLoading] = useState(false);

  const [loading, setLoading] = useState(false);
  const acc_options = apartment_types.map((val) => ({
    label: val.type,
    value: val.id,
  }));

  const handleAddRow = () => {
    const newData: DataType = {
      id: -1,
      key: shortUUID.generate(),
      apartment_id: community_id,
      apartment_type_id: 1,
      living_type_id: livingType,
      base_rent: 0,
      notes: '',
      sq_footage: 0,
      price_per_sq: 0,
      status: 1,
      op: 'added',
      updated_at: new Date().toDateString(),
      updated_by: currentUser?.name || '',
    };
    setDataSource((prev) => [...prev, newData]);
  };

  const removeRow = (row_data: DataType) => {
    const cp = [...dataSource];
    const index = cp.findIndex((el) => el?.key === row_data.key);
    if (index !== -1) {
      if (cp[index].id === -1 && row_data.op === 'added') {
        cp.splice(index, 1);
      } else {
        cp[index].op = 'deleted';
      }
      setDataSource(cp);
    }
  };

  const handleUpdateRowData = (value: any, row_index: number, key: keyof DataType) => {
    const cp = [...dataSource];
    const target_row: any = cp[row_index];
    if (key === 'status') {
      target_row.status = value ? 0 : 1;
    } else if (key === 'updated_at') {
      target_row.updated_at = new Date(value).toString();
    } else if (key === 'notes') {
      target_row.notes = value;
    } else {
      target_row[key] = parseFloat(value);
    }
    if (target_row.op === null || target_row.op === 'edited') {
      target_row.op = 'edited';
    }
    setDataSource(cp);
  };

  const handleUpdateHistoryRowData = (value: any, row_index: number) => {
    const cp = [...historyData];
    const target_row: any = cp[row_index];
    target_row.status = value ? 0 : 1;
    target_row.apartment_id = target_row.CommunityId;
    target_row.apartment_type_id = target_row.ApartmentTypeId;
    target_row.living_type_id = target_row.LivingTypeId;
    target_row.op = 'edited';
    setHistoryData(cp);
  };

  const showMessage = (type: 'success' | 'error', mgs: string) => {
    messageApi.open({
      type: type,
      content: mgs,
    });
  };

  const saveFormData = () => {
    const data_to_save = dataSource.filter((el) => el?.op === 'deleted' || el?.op === 'edited' || el?.op === 'added');
    const payload = data_to_save.map((el) => ({
      ...el,
      sq_footage: el?.sq_footage > 0 ? el?.sq_footage : null,
      price_per_sq: el?.sq_footage > 0 ? el?.base_rent / el?.sq_footage : null,
      updated_at: format(new Date(el?.updated_at), 'dd-MM-yyyy hh:mm:ss'),
    }));

    let isValid = true;
    payload.forEach((el) => {
      if (el.base_rent === 0 || el?.sq_footage === 0) {
        isValid = false;
      }
    });
    if (!isValid) {
      showMessage('error', 'Please enter values');
      return;
    }

    if (payload.length > 0) {
      setLoading(true);
      axios
        .post('communities/Save_AddComm_LivingInfo/', {
          data: payload,
        })
        .then((res) => {
          setLoading(false);
          showMessage('success', 'Saved successfully!');
          getAccommodationFees();
        })
        .catch((err) => {
          setLoading(false);
          showMessage('error', 'Something went wrong');
        });
    }
  };

  const saveHistoryData = () => {
    const data_to_save = historyData.filter((el) => el?.op === 'edited');
    const payload = data_to_save.map((el) => ({
      ...el,
      updated_at: format(new Date(el?.updated_at), 'dd-MM-yyyy hh:mm:ss'),
    }));
    if (payload.length > 0) {
      setLoading(true);
      axios
        .post('communities/Save_AddComm_LivingInfo/', {
          data: payload,
        })
        .then((res) => {
          setLoading(false);
          showMessage('success', 'Saved successfully!');
          getAccommodationFees();
        })
        .catch((err) => {
          setLoading(false);
          showMessage('error', 'Something went wrong');
        });
    }
  };

  useEffect(() => {
    if (community_id) {
      getAccommodationFees();
    }
  }, [community_id]);

  const getAccommodationFees = () => {
    setDataLoading(true);
    axios
      .get(`/communities/Get_CommunityLivingInfo/${community_id}/`)
      .then((res) => {
        setDataLoading(false);
        const data: any[] = res.data?.payload;
        if (data.length > 0) {
          const Independent_living_data = data.filter((el) => `${el?.LivingTypeId}` === `${livingType}`);

          const activeData = Independent_living_data.filter((el) => el?.status === 1);
          const historyData = Independent_living_data.filter((el) => el?.status === 0);
          setHistoryData(historyData);
          const formatted_data = activeData.map((info: any) => ({
            id: info?.id,
            key: shortUUID.generate(),
            apartment_id: info?.CommunityId,
            apartment_type_id: parseInt(info?.ApartmentTypeId),
            living_type_id: parseInt(info?.LivingTypeId),
            base_rent: parseFloat(info?.base_rent),
            notes: info?.notes ? info.notes : '',
            sq_footage: parseFloat(info?.sq_footage),
            price_per_sq: parseFloat(info?.base_rent) / parseFloat(info?.sq_footage),
            status: info?.status,
            updated_at: info?.updated_at ? new Date(info?.updated_at).toDateString() : new Date().toDateString(),
            updated_by: info?.updated_by,
            op: null,
          }));
          setDataSource(formatted_data);
        } else {
          setHistoryData([]);
          setDataSource([]);
        }
      })
      .catch((err) => {
        setDataLoading(false);
      });
  };

  // format(new Date(), "dd-MM-yyyy hh:mm:ss")

  const columns: ColumnsType<HistoryDataType> = [
    {
      title: 'Living Type',
      dataIndex: 'LivingTypeName',
      filterSearch: true,
      onFilter: (value: any, record: any) => record?.Livingtypename?.startsWith(value),
    },
    {
      title: 'Accomodation',
      dataIndex: 'ApartmentTypeName',
      filterSearch: true,
      onFilter: (value: any, record: any) => record?.ApartmentTypeName?.startsWith(value),
      filterMode: 'menu',
    },
    {
      title: names[livingType - 1].type,
      dataIndex: 'base_rent',
      render: (value: number) => {
        return <span>${value?.toLocaleString()}</span>;
      },
    },
    {
      title: 'Updated By',
      dataIndex: 'updated_by',
    },
    {
      title: 'Updated Date',
      dataIndex: 'updated_at',
      render: (value: string) => {
        return (
          <div>
            <span>{value && format(new Date(value), 'MMM dd, yyyy')}</span>
            <br />
            <span>{value && format(new Date(value), 'hh:mm a')}</span>
          </div>
        );
      },
    },
  ];
  if (currentUser?.isSuperuser) {
    columns.push({
      title: 'History',
      dataIndex: 'status',
      render: (value: number, record: any, row_index: number) => {
        return (
          <Switch
            checked={value == 0 ? true : false}
            onChange={(checked: boolean) => handleUpdateHistoryRowData(checked, row_index)}
          />
        );
      },
    });
  }
  const tableData = dataSource.filter((el) => el?.op !== 'deleted');

  return (
    <div>
      {contextHolder}
      {dataLoading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 200,
          }}
        >
          <Spin />
        </div>
      ) : (
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: 20,
              padding: '0px 30px ',
              marginTop: 0,
              width: '100%',
            }}
          >
            <div>
              <Button
                type="text"
                className={selectedTab === 'EDIT' ? 'p_healing_active_tab' : 'btnStyle'}
                shape="round"
                style={{
                  marginRight: 20,
                  height: 'auto',
                  padding: '5px 20px',
                  fontSize: 18,
                }}
                onClick={() => setSelectedTab('EDIT')}
              >
                Edit
              </Button>
              <Button
                type="text"
                shape="round"
                style={{
                  marginRight: 20,
                  height: 'auto',
                  padding: '5px 20px',
                  fontSize: 18,
                }}
                className={selectedTab === 'HISTORY' ? 'p_healing_active_tab' : 'btnStyle'}
                onClick={() => setSelectedTab('HISTORY')}
              >
                History
              </Button>
            </div>
          </div>
          <div>
            {selectedTab === 'EDIT' && (
              <div style={{ minHeight: 'calc(100vh - 430px)', paddingBottom: 50 }}>
                <div
                  style={{
                    margin: 'auto',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    style={{
                      minWidth: '767px',
                      margin: '0 auto',
                      position: 'relative',
                    }}
                  >
                    <div
                      style={{
                        position: 'relative',
                        display: 'inline-block',
                      }}
                    >
                      <table
                        className="acc_table_wrapper"
                        style={{
                          borderSpacing: 0,
                        }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                borderLeft: 0,
                                borderTop: 0,
                                borderRight: 0,
                                width: 300,
                              }}
                            >
                              Accommodation
                            </th>
                            <th
                              style={{
                                borderLeft: 0,
                                borderTop: 0,
                                borderRight: '1px solid #ccc',
                                width: 150,
                              }}
                            >
                              SQ. FT.
                            </th>
                            <th
                              style={{
                                borderLeft: 0,
                                padding: 0,
                                position: 'relative',
                                width: 200,
                              }}
                            >
                              {names[livingType - 1].type}
                            </th>
                            {currentUser?.isSuperuser && (
                              <th
                                style={{
                                  borderLeft: 0,
                                  padding: 0,
                                  position: 'relative',
                                  width: 200,
                                }}
                              >
                                Updated Date
                              </th>
                            )}
                            <th
                              style={{
                                borderLeft: 0,
                                padding: 0,
                                position: 'relative',
                                width: 250,
                              }}
                            >
                              Notes
                            </th>
                            <th
                              style={{
                                borderLeft: 0,
                                padding: 0,
                                position: 'relative',
                                width: 150,
                              }}
                            >
                              History
                            </th>
                            <th style={{ border: 0, width: 60 }}></th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableData.map((row_data: DataType, row_index: number) => (
                            <Tooltip
                              key={row_index}
                              title={() => (
                                <span>
                                  Updated User: {row_data.updated_by} <br /> Updated Date:{' '}
                                  {format(new Date(row_data.updated_at), 'MM-dd-yyyy hh:mm:a')}
                                </span>
                              )}
                              placement="topLeft"
                              color="blue"
                              overlayStyle={{
                                maxWidth: '280px',
                                display: row_data.op != 'added' ? '' : 'none',
                              }}
                              overlayInnerStyle={{ maxWidth: '260px' }}
                            >
                              <tr className="acc_row">
                                <td
                                  style={{
                                    borderLeft: '1px solid #ccc',
                                    padding: 0,
                                  }}
                                >
                                  <Select
                                    showSearch
                                    bordered={false}
                                    value={row_data.apartment_type_id}
                                    options={acc_options}
                                    style={{ width: '300px', padding: 0 }}
                                    onChange={(value) => handleUpdateRowData(value, row_index, 'apartment_type_id')}
                                    filterOption={(input, option) =>
                                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                  />
                                </td>
                                <td style={{ padding: 0 }}>
                                  <InputNumber
                                    value={row_data.sq_footage || ''}
                                    onChange={(value) => {
                                      if (value) {
                                        handleUpdateRowData(value, row_index, 'sq_footage');
                                      }
                                    }}
                                    style={{
                                      border: 0,
                                      width: '150px',
                                      borderRadius: 0,
                                      height: '35px',
                                    }}
                                  />
                                </td>
                                <td style={{ padding: 0 }}>
                                  <InputNumber
                                    prefix="$"
                                    value={row_data.base_rent || ''}
                                    onChange={(value) => {
                                      if (value) {
                                        handleUpdateRowData(value, row_index, 'base_rent');
                                      }
                                    }}
                                    style={{
                                      border: 0,
                                      width: '200px',
                                      borderRadius: 0,
                                      height: '35px',
                                    }}
                                  />
                                </td>
                                {currentUser?.isSuperuser && (
                                  <td style={{ padding: 0 }}>
                                    <DatePicker
                                      value={dayjs(new Date(row_data.updated_at).toDateString())}
                                      format={dateFormat}
                                      onChange={(value) => {
                                        if (value) {
                                          handleUpdateRowData(value, row_index, 'updated_at');
                                        }
                                      }}
                                      style={{
                                        border: 0,
                                        width: '200px',
                                        borderRadius: 0,
                                        height: '35px',
                                      }}
                                    />
                                  </td>
                                )}
                                <td
                                  style={{
                                    padding: 0,
                                    width: 100,
                                    textAlign: 'center',
                                  }}
                                >
                                  <Input.TextArea
                                    value={row_data.notes || ''}
                                    rows={1}
                                    onChange={(e) => {
                                      handleUpdateRowData(e.target.value, row_index, 'notes');
                                    }}
                                    style={{
                                      width: '250px',
                                    }}
                                  />
                                </td>

                                <td
                                  style={{
                                    padding: 0,
                                    width: 100,
                                    textAlign: 'center',
                                  }}
                                >
                                  <Switch
                                    checked={row_data?.status === 0 ? true : false}
                                    onChange={(checked: boolean) => handleUpdateRowData(checked, row_index, 'status')}
                                  />
                                </td>
                                <td style={{ border: 0, width: 60 }}>
                                  <Button
                                    icon={<CloseCircleFilled />}
                                    size="small"
                                    type="link"
                                    danger
                                    onClick={() => removeRow(row_data)}
                                  />
                                </td>
                              </tr>
                            </Tooltip>
                          ))}
                        </tbody>
                      </table>
                      <Button
                        icon={<PlusOutlined />}
                        type="default"
                        shape="round"
                        style={{
                          fontSize: 14,
                          height: 'max-content',
                          padding: '6px 10px 4px 10px',
                          marginTop: 10,
                        }}
                        onClick={handleAddRow}
                      >
                        Add
                      </Button>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    borderTop: '1px solid #eee',
                    marginTop: 30,
                    padding: '0px 50px',
                  }}
                >
                  <div
                    style={{
                      padding: '50px 0px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                      disabled={loading}
                      onClick={saveFormData}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            )}
            {selectedTab === 'HISTORY' && (
              <div
                style={{
                  // maxWidth: 991,
                  margin: '0 auto',
                  padding: '10px 30px',
                }}
              >
                <Table columns={columns} dataSource={historyData} />
                {currentUser?.isSuperuser && (
                  <div
                    style={{
                      borderTop: '1px solid #eee',
                      marginTop: 10,
                      padding: '0px 0px',
                    }}
                  >
                    <div
                      style={{
                        padding: '10px 0px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Button type="primary" loading={loading} disabled={loading} onClick={saveHistoryData}>
                        Save
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default IndependentTab;
