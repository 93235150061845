import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  DashboardFilled,
  FileSearchOutlined,
  GoogleCircleFilled,
  HomeFilled,
  LogoutOutlined,
  MediumSquareFilled,
  PictureFilled,
  QuestionCircleFilled,
  SearchOutlined,
  ShopFilled,
  UserOutlined,
} from '@ant-design/icons';
import { Divider, Layout, Menu, MenuProps, Typography } from 'antd';

import { useAppDispatch, useAppSelector } from 'store/hook';
import { handleLogout } from 'store/slices/authSlice';

import MainHeader from './Header';
const { Header, Sider, Content } = Layout;

interface Props {
  children: React.ReactNode;
}

const AppLayout: React.FC<Props> = ({ children }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const [collapsed, setCollapsed] = useState(true);
  const [selectedKeys, setSelectedKeys] = useState(['1']);

  const onClick: MenuProps['onClick'] = (e) => {
    setSelectedKeys([e.key]);
    if (e.key === '1') {
      navigate('/home');
      // } else if (e.key === "2") {
      //   navigate("/dashboard");
      // } else if (e.key === "3") {
      //   navigate("/dashboard");
      // } else if (e.key === "4") {
      //   navigate("/overview");
      // } else if (e.key === "5") {
      //   navigate("/find-communities");
      // } else if (e.key === "search_my_community") {
      //   navigate("/search-communities");
      // } else if (e.key === "search_competitors") {
      //   navigate("/search-competitors");
      // } else if (e.key === "6") {
      //   navigate("/users");
      // } else if (e.key === "7") {
      //   navigate("/swot-survey");
      // } else if (e.key === "8") {
      //   navigate("/data-scraping");
      // } else if (e.key === "9") {
      //   navigate("/settings/fee-types");
      // } else if (e.key === "10") {
      //   navigate("/companies");
      // } else if (e.key === "11") {
      //   navigate("/vendors");
      // } else if (e.key === "12") {
      //   navigate("/management-companies");
      // } else if (e.key === "13") {
      //   navigate("/my-communities");
      // } else if (e.key === "14") {
      //   navigate("/search-gallery");
    } else if (e.key === '15') {
      navigate('/shop-requests');
    } else if (e.key === '16') {
      navigate('/google-metrics-reviews');
    } else if (e.key === '17') {
      navigate('/user-log-dashboard');
    } else if (e.key === '18') {
      navigate('/sentiment-analysis');
    } else if (e.key === '19') {
      navigate('/user-log-report');
    } else if (e.key === '20') {
      navigate('/help');
    }
  };

  const getSelectedKey = () => {
    const route = location.pathname;
    if (route == '/shop-requests') {
      setSelectedKeys(['15']);
    } else if (route == '/google-metrics-reviews') {
      setSelectedKeys(['16']);
    } else if (route == '/user-log-dashboard') {
      setSelectedKeys(['17']);
    } else if (route == '/sentiment-analysis') {
      setSelectedKeys(['18']);
    } else if (route == '/user-log-report') {
      setSelectedKeys(['19']);
    } else if (route == '/help') {
      setSelectedKeys(['20']);
    }
  };

  const onLogoutUser = () => {
    dispatch(handleLogout());
    navigate('/sign-in');
  };

  const items: MenuProps['items'] = [
    {
      label: 'Logout',
      key: '0',
      icon: <LogoutOutlined />,
      style: {
        minWidth: 200,
      },
      onClick: onLogoutUser,
    },
  ];

  useEffect(() => {
    getSelectedKey();
  }, []);

  return (
    <Layout>
      {currentUser?.isSuperuser && (
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          style={{
            background: '#002c8c',
            minHeight: '100vh',
            width: 40,
          }}
          collapsedWidth={50}
          width={250}
        >
          <div
            className="brand"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: 60,
              padding: '6px',
              minWidth: 40,
            }}
          >
            <Typography.Title
              level={5}
              style={{
                color: '#fff',
                margin: '0px',
                fontSize: collapsed ? '14px' : '16px',
                display: collapsed ? 'none' : 'block',
              }}
            >
              Pricing Assistant
            </Typography.Title>
          </div>
          <Divider style={{ margin: '0px 0px 10px 0px', background: '#333' }} />
          <Menu
            mode="inline"
            defaultSelectedKeys={['1']}
            selectedKeys={selectedKeys}
            style={{ background: 'transparent', color: '#fff', paddingLeft: 4 }}
            onClick={onClick}
            items={[
              {
                key: '1',
                icon: <HomeFilled style={{ fontSize: 18 }} />,
                label: 'Home',
              },
              // {
              //   key: "3",
              //   icon: <DashboardFilled />,
              //   label: "Mgmt Dashboard",
              // },
              // {
              //   key: "5",
              //   icon: <SearchOutlined style={{ fontSize: 18 }} />,
              //   label: "Find Communities",
              // },
              // {
              //   key: "13",
              //   icon: <MediumSquareFilled style={{ fontSize: 18 }} />,
              //   label: "My Communities",
              //   children: [
              //     {
              //       key: "search_my_community",
              //       icon: <SearchOutlined style={{ fontSize: 18 }} />,
              //       label: "My Community",
              //     },
              //     {
              //       key: "search_competitors",
              //       icon: <SearchOutlined style={{ fontSize: 18 }} />,
              //       label: "Competitors",
              //     },
              //   ],
              // },
              // {
              //   key: "14",
              //   icon: <PictureFilled />,
              //   label: "Search Gallery",
              // },
              {
                key: '15',
                icon: <ShopFilled />,
                label: 'Shop Requests',
              },
              {
                key: '16',
                icon: <GoogleCircleFilled style={{ fontSize: 18 }} />,
                label: 'Google Metrics',
              },
              {
                key: '17',
                icon: <UserOutlined style={{ fontSize: 18 }} />,
                label: 'User Log Dashboard',
              },
              {
                key: '18',
                icon: <FileSearchOutlined style={{ fontSize: 18 }} />,
                label: 'Sentiment Analysis',
              },
              {
                key: '19',
                icon: <UserOutlined style={{ fontSize: 18 }} />,
                label: 'User Log Report',
              },
              {
                key: '20',
                icon: <QuestionCircleFilled style={{ fontSize: 18 }} />,
                label: 'Help',
              },
            ]}
          />
        </Sider>
      )}
      <Layout className="site-layout">
        <MainHeader setCollapsed={setCollapsed} collapsed={collapsed} />
        <Content
          style={{
            margin: '0px',
            padding: '0px ',
            height: 'calc(100vh - 50px)',
            overflowY: 'auto',
            boxSizing: 'border-box',
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
