import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Column, Pie } from '@ant-design/plots';
import { Col, DatePicker, Row, SelectProps, Table, Typography } from 'antd';
import type { DatePickerProps } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { format } from 'date-fns';
import dayjs from 'dayjs';

import axios from 'config/axiosPrivate';
import SelectInput from 'formComponents/SelectInput';
import { useAppSelector } from 'store/hook';

interface LoginDataType {
  company_id: number;
  company_name: string;
  created_at: string;
  id: number;
  management_company_id: number;
  management_company_name: string;
  screen_name: string;
}

interface ActiveUsersDataType {
  activeusers: number;
  company_name: string;
  totaluserss: number;
  company_id: number;
}

interface TransactionHistoryDataType {
  apartment_id: number;
  apartment_name: string;
  apartment_type: null;
  company_name: string;
  created_at: string;
  id: number;
  operation: string;
  transaction: string;
  user_id: number;
  user_name: string;
}

interface TransactionHistoryCountDataType {
  company_name: string;
  operation: string;
  transaction: string;
  transactions_count: number;
}

const UserLogDashboard = () => {
  const navigate = useNavigate();
  const userCompanies = useAppSelector((state) => state.global.companyList);
  const [userLoginData, setUserLoginData] = useState<LoginDataType[]>([]);
  const [activeUsersData, setActiveUsersData] = useState<ActiveUsersDataType[]>([]);
  const [transactionHistoryData, setTransactionHistoryData] = useState<TransactionHistoryDataType[]>([]);
  const [transactionHistoryCountData, setTransactionHistoryCountData] = useState<TransactionHistoryCountDataType[]>([]);
  const [companyList, setCompanyList] = useState<any[]>([]);
  const [companyOptions, setCompanyOptions] = useState<SelectProps['options']>(userCompanies);
  const [fromDate, setFromDate] = useState('2023-01-01');
  const [toDate, setToDate] = useState(format(new Date(), 'yyyy-MM-dd').toString());

  const columns: ColumnsType<LoginDataType> = [
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            Company
          </Typography.Title>
        </div>
      ),
      dataIndex: 'company_name',
      key: 'company_name',
      width: 300,
    },
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            Management Company
          </Typography.Title>
        </div>
      ),
      dataIndex: 'management_company_name',
      key: 'management_company_name',
      width: 200,
    },
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            User
          </Typography.Title>
        </div>
      ),
      dataIndex: 'user',
      key: 'user',
      width: 100,
      render: (_, row) => (
        <div style={{ height: '100%', width: '100%' }}>
          <Typography.Paragraph style={{ fontSize: '14px' }}>{''}</Typography.Paragraph>
        </div>
      ),
    },
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            Date
          </Typography.Title>
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      width: 100,
    },
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            In/Out
          </Typography.Title>
        </div>
      ),
      dataIndex: 'screen_name',
      key: 'screen_name',
      width: 100,
    },
  ];

  const activeUsersColumns: ColumnsType<ActiveUsersDataType> = [
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            Company
          </Typography.Title>
        </div>
      ),
      dataIndex: 'company_name',
      key: 'company_name',
      width: 100,
    },
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            Users
          </Typography.Title>
        </div>
      ),
      dataIndex: 'totalusers',
      key: 'totalusers',
      width: 100,
    },
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            Active
          </Typography.Title>
        </div>
      ),
      dataIndex: 'activeusers',
      key: 'activeusers',
      width: 100,
    },
  ];

  const transactionHistoryColumns: ColumnsType<TransactionHistoryDataType> = [
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            Company
          </Typography.Title>
        </div>
      ),
      dataIndex: 'company_name',
      key: 'company_name',
      width: 300,
    },
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            User
          </Typography.Title>
        </div>
      ),
      dataIndex: 'user_name',
      key: 'user_name',
      width: 200,
    },
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            Buisness Function
          </Typography.Title>
        </div>
      ),
      dataIndex: 'transaction',
      key: 'transaction',
      width: 100,
    },
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            Opertion
          </Typography.Title>
        </div>
      ),
      dataIndex: 'operation',
      key: 'operation',
      width: 100,
    },
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            Date
          </Typography.Title>
        </div>
      ),
      dataIndex: 'created_at',
      key: 'created_at',
      width: 100,
    },
  ];

  const transactionHistoryCountColumns: ColumnsType<TransactionHistoryCountDataType> = [
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            Company
          </Typography.Title>
        </div>
      ),
      dataIndex: 'company_name',
      key: 'company_name',
      width: 300,
    },
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            Buisness Function
          </Typography.Title>
        </div>
      ),
      dataIndex: 'transaction',
      key: 'transaction',
      width: 100,
    },
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            Opertion
          </Typography.Title>
        </div>
      ),
      dataIndex: 'operation',
      key: 'operation',
      width: 100,
    },
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            Count
          </Typography.Title>
        </div>
      ),
      dataIndex: 'transactions_count',
      key: 'transactions_count',
      width: 100,
      align: 'center',
    },
  ];

  const config = {
    appendPadding: 10,
    data: activeUsersData,
    angleField: 'activeusers',
    colorField: 'company_name',
    radius: 0.9,
    label: {
      type: 'inner',
      offset: '-30%',
      content: ({ percent }: any) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  };

  const historyCountConfig = {
    appendPadding: 10,
    data: transactionHistoryCountData,
    angleField: 'transactions_count',
    colorField: 'transaction',
    radius: 0.9,
    label: {
      type: 'inner',
      offset: '-30%',
      content: ({ percent }: any) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  };
  const historyCountBarConfig = {
    data: transactionHistoryCountData,
    xField: 'transaction',
    yField: 'transactions_count',
    label: {
      // 可手动配置 label 数据标签位置
      // position: 'middle',
      // 'top', 'bottom', 'middle',
      // 配置样式
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      type: {
        alias: '类别',
      },
      sales: {
        alias: '销售额',
      },
    },
  };

  const onFDateChange: DatePickerProps['onChange'] = (date, dateString) => {
    // console.log(dateString);
    setFromDate(dateString);
  };

  const onTDateChange: DatePickerProps['onChange'] = (date, dateString) => {
    //  console.log(dateString);
    setToDate(dateString);
  };

  const getUserLoginDetails = () => {
    axios
      .get(`/api/Get_Userlog_LoginDetails/${fromDate}/${toDate}/`)
      .then((res) => {
        const data = res.data.payload;
        setUserLoginData(data);
        //
      })
      .catch((err) => {});
  };

  const getActiveUsers = () => {
    axios
      .get(`/api/Get_Userlog_ActiveUsers/`)
      .then((res) => {
        const data = res.data.payload;
        setActiveUsersData(data);
        //
      })
      .catch((err) => {});
  };

  const getUserTransactionHistory = () => {
    axios
      .get(`/api/Get_UserLog_Transaction_History/${fromDate}/${toDate}/`)
      .then((res) => {
        const data = res.data.payload;
        setTransactionHistoryData(data);
      })
      .catch((err) => {});
  };

  const getUserTransactionHistoryCount = () => {
    axios
      .get(`/api/Get_UserLog_Transaction_History_counts/${fromDate}/${toDate}/`)
      .then((res) => {
        const data = res.data.payload;
        const newData = data.map((item: any) => ({
          ...item,
          transaction: item.transaction.replace(/_/g, ' '),
        }));
        setTransactionHistoryCountData(newData);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getActiveUsers();
    getUserTransactionHistoryCount();
  }, []);

  useEffect(() => {
    getUserLoginDetails();
    getUserTransactionHistory();
  }, [fromDate, toDate]);

  return (
    <div
      style={{
        padding: '20px 30px 0px',
      }}
    >
      <div>
        <div>
          <span>
            <span style={{ color: '#1f58b0', cursor: 'pointer' }} onClick={() => navigate('/')}>
              HOME
            </span>
            <span
              style={{
                color: '#222',
                margin: '0px 10px',
                textTransform: 'uppercase',
              }}
            >
              User Logs Dashboard
            </span>
          </span>
        </div>
      </div>
      <Row gutter={[16, 16]}>
        <Col offset={4} xs={24} lg={6}>
          <SelectInput
            placeholder="Company"
            options={companyOptions}
            onApply={(value) => {
              setCompanyList(value);
            }}
            // loading={loading}
            initValue={companyList}
          />
        </Col>
        <Col xs={24} lg={6}>
          <DatePicker
            onChange={onFDateChange}
            style={{ width: '100%' }}
            placeholder="From"
            defaultValue={dayjs(fromDate)}
          />
        </Col>
        <Col xs={24} lg={6}>
          <DatePicker
            onChange={onTDateChange}
            style={{ width: '100%' }}
            placeholder="To"
            defaultValue={dayjs(toDate)}
          />
        </Col>
      </Row>
      <div style={{ marginTop: '10px' }}>
        <Typography.Title level={3}>User Log Details</Typography.Title>
        <Table columns={columns} dataSource={userLoginData} />
      </div>
      <div style={{ marginTop: '10px' }}>
        <Typography.Title level={3}>Active Users</Typography.Title>
        <Row gutter={[16, 16]}>
          <Col lg={12}>
            <Table columns={activeUsersColumns} dataSource={activeUsersData} />
          </Col>
          <Col lg={12}>
            <Pie {...config} />
          </Col>
        </Row>
      </div>
      <div style={{ marginTop: '10px' }}>
        <Typography.Title level={3}>Transaction History</Typography.Title>
        <Table columns={transactionHistoryColumns} dataSource={transactionHistoryData} />
      </div>
      <div style={{ marginTop: '10px' }}>
        <Typography.Title level={3}>Buisness Function Usage</Typography.Title>
        <Row gutter={[16, 16]}>
          <Col lg={12}>
            <Table columns={transactionHistoryCountColumns} dataSource={transactionHistoryCountData} />
          </Col>
          <Col lg={12}>
            <Pie {...historyCountConfig} />
          </Col>
        </Row>
      </div>
      <div style={{ marginTop: '10px' }}>
        <Typography.Title level={3}>Buisness Function Type Comparison</Typography.Title>
        <Row gutter={[16, 16]}>
          <Col lg={12}>
            <Column {...historyCountBarConfig} />
          </Col>
          <Col lg={12}></Col>
        </Row>
      </div>
    </div>
  );
};

export default UserLogDashboard;
