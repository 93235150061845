import React from 'react';
import { useQuery } from 'react-query';

import { Col, Row, SelectProps, Tabs } from 'antd';

import axios from 'config/axiosPrivate';
import SelectInput from 'formComponents/SelectInput';
import Competitortab from 'pageComponents/home/CompetitorTab';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { handleStoreUserCommunities, handleStoreUserCompanies } from 'store/slices/globalSlice';

const Home = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const userInfo: any = useAppSelector((state) => state.auth.currentUser);
  const [companyLogo, setCompanyLogo] = React.useState('');
  const [communityList, setCommunityList] = React.useState<any[]>([]);
  let initCompanyList: any[];
  let initCompanyOptions: any[];
  if (currentUser && !currentUser.isSuperuser) {
    const company = currentUser.company;
    if (company) {
      initCompanyList = [company];
      initCompanyOptions = [{ value: company, label: currentUser.companyName }];
    } else {
      initCompanyList = [];
      initCompanyOptions = [];
    }
  } else {
    initCompanyList = [];
    initCompanyOptions = [];
  }
  const [companyList, setCompanyList] = React.useState<any[]>(initCompanyList);
  const [careList, setCareList] = React.useState<any[]>([]);
  const [communityOptions, setCommunityOptions] = React.useState<SelectProps['options']>([]);
  const [companyOptions, setCompanyOptions] = React.useState<SelectProps['options']>(initCompanyOptions);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [state, setState] = React.useState<any>(null);
  const livingtypes = useAppSelector((state) => state.global.livingtypes);

  function groupByApartmentId(data: any[], key: any) {
    const groups: any = {};

    data.forEach(function (val) {
      const category = val[key];
      if (category) {
        if (category in groups) {
          groups[category].push(val);
        } else {
          groups[category] = new Array(val);
        }
      }
    });
    return groups;
  }

  function removeDuplicates(arr: any[]) {
    const uniqueArray = arr.filter((value, index) => {
      const _value = JSON.stringify(value);
      return (
        index ===
        arr.findIndex((obj) => {
          return JSON.stringify(obj) === _value;
        })
      );
    });
    return uniqueArray;
  }
  const getFullHomeCommunities = () => {
    setLoading(true);
    axios
      .post('/communities/Get_FullHomeCommunities/', {
        comm_list: communityList,
        caretype_list: careList,
        company_list: companyList,
      })
      .then((res) => {
        const data = res.data?.payload;
        const google_rating: any[] = data?.Community_Competitor_Google_Values[0];
        const google_reviews: any[] = data?.Community_Competitor_Google_Values[1];

        const communities = data.Community.map((ele: any, idx: number) => ({
          ...ele,
          index: idx + 1,
        }));
        // const companies = data.Community.map((ele: any, idx: number) => ({
        //   value: ele.company_id,
        //   label: ele.CompanyName,
        // }));
        // setCommunityList(data.Community);
        // setCompatitorList(data.Competitor);

        dispatch(handleStoreUserCommunities(communities));

        const options = communities?.map((ele: any) => ({
          label: ele?.apartmentname,
          value: ele?.Id,
        }));
        setCommunityOptions(options);
        // const without_duplicates_companies = removeDuplicates(companies);
        // setCompanyOptions(without_duplicates_companies);
        // dispatch(handleStoreUserCompanies(without_duplicates_companies));
        const updated_competitor = data.Competitor?.map((cm: any) => {
          const avg_value = data?.Competitor_avg?.find((cm_avg: any) => cm_avg?.apartment_id === cm?.Id);

          return {
            id: cm?.Id,
            community: cm,
            ...cm,
            apartmenttype: cm?.apartmenttype,
            Avg_base_rent: avg_value?.Avg_base_rent || 0,
            Avg_sq_footage: avg_value?.Avg_sq_footage || 0,
            Avg_price_per_sq: avg_value?.Avg_price_per_sq || 0,
            Avg_google_rating: avg_value?.Avg_google_rating || 0,
            Sum_google_reviews: avg_value?.Sum_google_reviews || 0,

            market_Avg_base_rent: 0,
            market_Avg_sq_footage: 0,
            market_Avg_price_per_sq: 0,
            market_Avg_google_rating: 0,
            market_Sum_google_reviews: 0,
          };
        });
        const grup__apartment = groupByApartmentId(updated_competitor, 'parent_id');
        const updated_communities = data.Community?.map((cm: any) => {
          const avg_value = data?.community_avg?.find((cm_avg: any) => cm_avg?.apartment_id === cm?.Id);
          const market_value = data?.Community_Competitor_avg?.find((cm_avg: any) => cm_avg?.community_id === cm?.Id);

          return {
            id: cm?.Id,
            community: cm,
            ...cm,
            competitors: grup__apartment[cm.Id] ? grup__apartment[cm.Id] : [],
            // competitors: updated_competitor.filter(
            //   (items: any) => items.parent_id == cm.Id
            // ),
            cmp_google_rating: google_rating.find((el) => el?.parent_id === cm?.Id),
            cmp_google_reviews: google_reviews.find((el) => el?.parent_id === cm?.Id),
            apartmenttype: cm?.apartmenttype,
            Avg_base_rent: avg_value?.Avg_base_rent || 0,
            Avg_sq_footage: avg_value?.Avg_sq_footage || 0,
            Avg_price_per_sq: avg_value?.Avg_price_per_sq || 0,
            Avg_google_rating: avg_value?.Avg_google_rating || 0,
            Sum_google_reviews: avg_value?.Sum_google_reviews || 0,

            market_Avg_base_rent: market_value?.Avg_base_rent || 0,
            market_Avg_sq_footage: market_value?.Avg_sq_footage || 0,
            market_Avg_price_per_sq: market_value?.Avg_price_per_sq || 0,
            market_Avg_google_rating: market_value?.Avg_google_rating || 0,
            market_Sum_google_reviews: market_value?.Sum_google_reviews || 0,
          };
        });

        setState({
          communities: updated_communities,
          competitors: updated_competitor,
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  // useQuery({
  //   queryKey: "Get_UserManagementCompanies",
  //   queryFn: () =>
  //     axios
  //       .get(`/company/Get_UserManagementCompanies/`)
  //       .then((res) => res.data?.payload),
  //   onSuccess: (data) => {
  //     //
  //     setCompanyLogo(data.company1?.logo_url);
  //   },
  //   retry: false,
  // });

  useQuery({
    queryKey: 'Get_UserCompanies',
    queryFn: () => axios.get(`/company/Get_UserCompanies/`).then((res) => res.data?.payload),
    onSuccess: (data) => {
      if (data.length == 1) {
        setCompanyLogo(data[0].logo_url);
      }
      const companies = data.map((item: any) => ({
        label: item.name,
        value: item.id,
      }));
      setCompanyOptions(companies);
      dispatch(handleStoreUserCompanies(companies));
    },
    retry: false,
  });

  // const state = useQuery({
  //   queryKey: `home__community__competitor_${
  //     companyList.length + communityList.length + careList.length
  //   }`,
  //   queryFn: ()=>getFullHomeCommunities

  // });

  // React.useEffect(() => {
  //   setLoading(true);
  //   axios
  //     .get("/communities/Get_FullHomeCommunities/")
  //     .then((res) => {
  //       setLoading(false);

  //       const data = res.data?.payload;
  //       const communities = data.Community.map((ele: any, idx: number) => ({
  //         ...ele,
  //         index: idx + 1,
  //       }));

  //       setCommunityList(data.Community);
  //       setCompatitorList(data.Competitor);

  //       dispatch(handleStoreUserCommunities(communities));

  //       const options = communities?.map((ele: any) => ({
  //         label: ele?.apartmentname,
  //         value: ele?.Id,
  //       }));
  //
  //       setCommunityOptions(options);
  //     })
  //     .catch((err) => {
  //
  //       setLoading(false);
  //     });
  // }, []);
  React.useEffect(() => {
    getFullHomeCommunities();
  }, [companyList, communityList, careList]);
  const livingtypesOptions = livingtypes.map((el) => ({
    label: el?.type,
    value: el?.id,
  }));

  return (
    <div>
      <div
        style={{
          padding: '15px 45px 0px 45px',
          // width: "1200px",
          margin: '0 auto',
          marginBottom: 10,
        }}
      >
        <Row gutter={[10, 0]}>
          <Col sm={4}>
            {companyLogo && (
              <img
                src={`${process.env.REACT_APP_API}${companyLogo}`}
                height="40"
                width="auto"
                style={{ borderRadius: '6px' }}
              />
            )}
          </Col>
          <Col xs={24} sm={6}>
            <SelectInput
              placeholder="Company"
              options={companyOptions}
              onApply={(value) => {
                setCompanyList(value);
                setCommunityList([]);
              }}
              loading={loading}
              initValue={companyList}
            />
          </Col>
          <Col xs={24} sm={6}>
            <SelectInput
              placeholder="Communities"
              options={communityOptions}
              onApply={(value) => setCommunityList(value)}
              loading={loading}
              initValue={communityList}
            />
          </Col>
          <Col xs={24} sm={6}>
            <SelectInput
              placeholder="Care Type"
              options={livingtypesOptions}
              onApply={(value) => setCareList(value)}
              loading={loading}
            />
          </Col>
        </Row>
      </div>
      <Tabs
        defaultActiveKey="Competitive"
        tabBarStyle={{ padding: '0px 15px 0px 15px' }}
        className="home__competitor__tab"
        style={{ padding: 0 }}
        items={[
          {
            label: 'Market Overview',
            key: 'Competitive',
            children: (
              <Competitortab
                communityList={state?.communities || []}
                commpetitorList={state?.competitors || []}
                filterCommunity={communityList}
                filterCompany={companyList}
                filterCare={careList}
                loading={loading}
              />
            ),
          },
          {
            label: 'Dashboard',
            key: 'Dashboard',
            children: userInfo?.dashboard_url ? (
              <iframe
                title="dashboard"
                src={userInfo?.dashboard_url}
                style={{ border: 0, width: '100%', height: '100vh' }}
              ></iframe>
            ) : (
              <div style={{ textAlign: 'center', minHeight: 100 }}>
                <h2>No dashboard found</h2>
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default Home;
