import React from 'react';
import { useNavigate } from 'react-router-dom';

import { CloseCircleFilled, PlusOutlined } from '@ant-design/icons';
import { Button, Input, Select, Switch, Typography } from 'antd';
import TextArea from 'antd/es/input/TextArea';

import { useAppDispatch, useAppSelector } from 'store/hook';
import { handleStoreCareFees, handleStoreCommunityFees } from 'store/slices/communitySlice';
import './styles.css';

type CommunityDataType = {
  id: number;
  living_type_id: any;
  community_fee: any;
  second_person_fee: any;
  pet_fee: any;
  respite_fee: any;
  additional_fee: any;
  incentive: any;
  incentive_value: any;
  status: number;
  updated_at: string;
  frequency: string;
  op: 'added' | 'edited' | 'deleted' | '';
};

type CareDataType = {
  id: number;
  living_type_id: any;
  fees_type_id: any;
  fee_note: string;
  level_1: any;
  level_2: any;
  level_3: any;
  level_4: any;
  level_5: any;
  level_6: any;
  level_7: any;
  level_8: any;
  medication_management_fee: any;
  average_care_cost: any;
  status: number;
  updated_at: string;
  op: 'added' | 'edited' | 'deleted' | '';
};

interface Props {
  current: number;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
}

const FeesForm = ({ current, setCurrent }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const living_types = useAppSelector((state) => state.global.livingtypes);
  const feeTypes = useAppSelector((state) => state.global.FeeTypes);
  const basic_info = useAppSelector((state) => state.community.basic_info);

  const communityFees: CommunityDataType[] = useAppSelector(
    (state) => state.community.communityFees
  );
  const careFees: CareDataType[] = useAppSelector(
    (state) => state.community.careFees
  );
  //
  const living_options = living_types.map((val) => ({
    label: val.type,
    value: val.id,
  }));
  const fee_type_options = feeTypes.map((val) => ({
    label: val.type,
    value: val.id,
  }));

  // for community fees
  const handleAddCommunityRow = () => {
    const jsData = JSON.parse(JSON.stringify(communityFees));
    const cp = [...jsData];
    cp.push({
      id: -1,
      living_type_id: 2,
      community_fee: "0",
      second_person_fee: "0",
      pet_fee: "0",
      respite_fee: "0",
      additional_fee: "0",
      incentive: "",
      incentive_value: "0",
      frequency: "",
      op: "added",
    });
    dispatch(handleStoreCommunityFees(cp));
  };
  const removeCommunityRow = (row_index: number) => {
    const jsData = JSON.parse(JSON.stringify(communityFees));
    const cp = [...jsData];
    cp.splice(row_index, 1);
    dispatch(handleStoreCommunityFees(cp));
  };
  const handleChangeCommunityLivingType = (value: any, row_index: number) => {
    const jsData = JSON.parse(JSON.stringify(communityFees));
    const cp = [...jsData];
    cp[row_index].living_type_id = value;
    dispatch(handleStoreCommunityFees(cp));
  };

  const handleUpdateCommunityRowFrequency = (val: any, row_index: number) => {
    const jsData = JSON.parse(JSON.stringify(communityFees));
    const cp = [...jsData];
    cp[row_index].frequency = val ? val : "";
    // if (cp[row_index].id !== -1) {
    //   cp[row_index].op = "edited";
    // }
    dispatch(handleStoreCommunityFees(cp));
  };

  const handleUpdateCommunityData = (
    value: any,
    key: string,
    row_index: number
  ) => {
    const jsData = JSON.parse(JSON.stringify(communityFees));
    const cp = [...jsData];
    cp[row_index][key] = value;
    dispatch(handleStoreCommunityFees(cp));
  };

  // for care fees
  const handleAddRow = () => {
    const jsData = JSON.parse(JSON.stringify(careFees));
    const cp = [...jsData];
    cp.push({
      id: -1,
      living_type_id: 2,
      fees_type_id: '1',
      fee_note: '',
      level_1: '',
      level_2: '',
      level_3: '',
      level_4: '',
      level_5: '',
      level_6: '',
      level_7: '',
      level_8: '',
      medication_management_fee: '',
      average_care_cost: '',
      op: 'added',
    });
    dispatch(handleStoreCareFees(cp));
  };
  const handleChangeCareLivingType = (value: any, row_index: number) => {
    const jsData = JSON.parse(JSON.stringify(careFees));
    const cp = [...jsData];
    cp[row_index].living_type_id = value;
    dispatch(handleStoreCareFees(cp));
  };

  const handleChangeCareFeeType = (value: any, row_index: number) => {
    const jsData = JSON.parse(JSON.stringify(careFees));
    const cp = [...jsData];
    cp[row_index].fees_type_id = value;
    dispatch(handleStoreCareFees(cp));
  };

  const removeRow = (row_index: number) => {
    const jsData = JSON.parse(JSON.stringify(careFees));
    const cp = [...jsData];
    cp.splice(row_index, 1);
    dispatch(handleStoreCareFees(cp));
  };

  const handleUpdateCareFees = (value: any, row_index: number, field_type: string) => {
    const jsData = JSON.parse(JSON.stringify(careFees));
    const cp = [...jsData];
    cp[row_index][field_type] = value;
    dispatch(handleStoreCareFees(cp));
  };

  const storeFees = () => {
    setCurrent((prevState) => prevState + 1);
  };
  return (
    <div style={{ padding: '0px 10px', overflowX: 'auto', width: '100%' }}>
      <div style={{ minHeight: 'calc(100vh - 430px)' }}>
        <div>
          <div>
            <div
              style={{
                background: '#f7f7f7',
                marginBottom: 10,
              }}
            >
              <Typography.Title level={3}>Community Fees</Typography.Title>
            </div>
            <div
              style={{
                margin: '0 auto',
                position: 'relative',
                overflowX: 'auto',
                paddingBottom: 20,
              }}
            >
              <div
                style={{
                  position: 'relative',
                  display: 'inline-block',
                }}
              >
                <table
                  className="acc_table_wrapper"
                  style={{
                    borderSpacing: 0,
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          borderLeft: 0,
                          borderTop: 0,
                          borderRight: 0,
                        }}
                        align="left"
                      >
                        LIVING TYPE
                      </th>
                      <th
                        style={{
                          borderLeft: 0,
                          borderTop: 0,
                          borderRight: 0,
                        }}
                        align="left"
                      >
                        COMMUNITY FEE
                      </th>
                      <th
                        style={{
                          borderLeft: 0,
                          borderTop: 0,
                          borderRight: 0,
                          position: 'relative',
                        }}
                        align="left"
                      >
                        ONE MONTH
                      </th>
                      <th
                        style={{
                          borderLeft: 0,
                          borderTop: 0,
                          borderRight: 0,
                          position: 'relative',
                        }}
                        align="left"
                      >
                        2ND PERSON FEE
                      </th>
                      <th
                        style={{
                          borderLeft: 0,
                          borderTop: 0,
                          borderRight: 0,
                          position: 'relative',
                        }}
                        align="left"
                      >
                        PET FEE
                      </th>
                      <th
                        style={{
                          borderLeft: 0,
                          borderTop: 0,
                          borderRight: 0,
                          position: 'relative',
                        }}
                        align="left"
                      >
                        RESPITE FEES
                      </th>
                      <th
                        style={{
                          borderLeft: 0,
                          borderTop: 0,
                          borderRight: 0,
                          position: 'relative',
                        }}
                        align="left"
                      >
                        ADDITIONAL FEES
                      </th>
                      <th
                        style={{
                          borderLeft: 0,
                          borderTop: 0,
                          borderRight: 0,
                          position: 'relative',
                        }}
                        align="left"
                      >
                        INCENTIVE VALUE
                      </th>
                      <th
                        style={{
                          borderLeft: 0,
                          borderTop: 0,
                          borderRight: 0,
                          position: "relative",
                        }}
                        align="left"
                      >
                        INCENTIVES
                      </th>
                      <th
                        style={{
                          border: 0,
                        }}
                      ></th>
                    </tr>
                  </thead>
                  <tbody>
                    {communityFees.map((row_data: any, row_index: number) => (
                      <tr className="acc_row" key={row_index}>
                        <td style={{ borderLeft: '1px solid #ccc', padding: 0 }}>
                          <Select
                            bordered={false}
                            value={row_data.living_type_id}
                            options={living_options}
                            style={{ width: '200px', padding: 0 }}
                            onChange={(val) => handleChangeCommunityLivingType(val, row_index)}
                          />
                        </td>
                        <td
                          style={{
                            padding: 0,
                          }}
                        >
                          <Input
                            prefix="$"
                            value={row_data.community_fee > 0 ? row_data.community_fee : ''}
                            onChange={(e) => handleUpdateCommunityData(e.target.value, 'community_fee', row_index)}
                            style={{
                              border: 0,
                              width: '100%',
                              borderRadius: 0,
                              height: '35px',
                            }}
                            type="number"
                          />
                        </td>
                        <td
                          style={{
                            padding: 0,
                            width: 100,
                            textAlign: "left",
                          }}
                        >
                          <Select
                            bordered={false}
                            value={row_data?.frequency}
                            placeholder="Select"
                            options={[
                              {
                                value: "Nil",
                                label: "",
                              },
                              {
                                value: "1",
                                label: "1 Month",
                              },
                              {
                                label: "2 Month",
                                value: "2",
                              },
                              {
                                label: "3 Month",
                                value: "3",
                              },
                              {
                                label: "4 Month",
                                value: "4",
                              },
                              {
                                label: "5 Month",
                                value: "5",
                              },
                              {
                                label: "6 Month",
                                value: "6",
                              },
                              {
                                label: "7 Month",
                                value: "7",
                              },
                              {
                                label: "8 Month",
                                value: "8",
                              },
                              {
                                label: "9 Month",
                                value: "9",
                              },
                              {
                                label: "10 Month",
                                value: "10",
                              },
                              {
                                label: "11 Month",
                                value: "11",
                              },
                              {
                                label: "12 Month",
                                value: "12",
                              },
                            ]}
                            style={{ width: "100%", padding: 0 }}
                            onChange={(val) =>
                              handleUpdateCommunityRowFrequency(val, row_index)
                            }
                          />
                          {/* <Switch
                            checked={
                              row_data?.frequency === "1 month" ? true : false
                            }
                            onChange={(checked: boolean) =>
                              handleUpdateCommunityRowFrequency(
                                checked,
                                row_index
                              )
                            }
                            title="Community Fee is Equal to One Month’s Rent"
                          /> */}
                        </td>
                        <td style={{ padding: 0 }}>
                          <Input
                            prefix="$"
                            value={row_data.second_person_fee > 0 ? row_data.second_person_fee : ''}
                            onChange={(e) => handleUpdateCommunityData(e.target.value, 'second_person_fee', row_index)}
                            style={{
                              border: 0,
                              width: '100%',
                              borderRadius: 0,
                              height: '35px',
                            }}
                            type="number"
                          />
                        </td>
                        <td style={{ padding: 0 }}>
                          <Input
                            prefix="$"
                            value={row_data.pet_fee > 0 ? row_data.pet_fee : ''}
                            onChange={(e) => handleUpdateCommunityData(e.target.value, 'pet_fee', row_index)}
                            style={{
                              border: 0,
                              width: '100%',
                              borderRadius: 0,
                              height: '35px',
                            }}
                            type="number"
                          />
                        </td>
                        <td style={{ padding: 0 }}>
                          <Input
                            prefix="$"
                            value={row_data.respite_fee > 0 ? row_data.respite_fee : ''}
                            onChange={(e) => handleUpdateCommunityData(e.target.value, 'respite_fee', row_index)}
                            style={{
                              border: 0,
                              width: '100%',
                              borderRadius: 0,
                              height: '35px',
                            }}
                            type="number"
                          />
                        </td>
                        <td style={{ padding: 0 }}>
                          <Input
                            prefix="$"
                            value={row_data.additional_fee > 0 ? row_data.additional_fee : ''}
                            onChange={(e) => handleUpdateCommunityData(e.target.value, 'additional_fee', row_index)}
                            style={{
                              border: 0,
                              width: '100%',
                              borderRadius: 0,
                              height: '35px',
                            }}
                            type="number"
                          />
                        </td>
                        <td style={{ padding: 0 }}>
                          <Input
                            prefix="$"
                            value={
                              row_data.incentive_value > 0
                                ? row_data.incentive_value
                                : ""
                            }
                            onChange={(e) =>
                              handleUpdateCommunityData(
                                e.target.value,
                                "incentive_value",
                                row_index
                              )
                            }
                            style={{
                              border: 0,
                              width: "100%",
                              borderRadius: 0,
                              height: "35px",
                            }}
                            type="number"
                          />
                        </td>
                        <td style={{ padding: 0 }}>
                          <TextArea
                            value={row_data.incentive}
                            onChange={(e) => handleUpdateCommunityData(e.target.value, 'incentive', row_index)}
                            style={{
                              border: 0,
                              width: '350px',
                              borderRadius: 0,
                              height: '35px',
                            }}
                          />
                        </td>
                        <td style={{ border: 0 }}>
                          <Button
                            icon={<CloseCircleFilled />}
                            size="small"
                            type="link"
                            danger
                            onClick={() => removeCommunityRow(row_index)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <br />
              <Button
                icon={<PlusOutlined />}
                type="default"
                shape="round"
                style={{
                  fontSize: 14,
                  height: 'max-content',
                  padding: '6px 10px 4px 10px',
                  marginTop: 0,
                }}
                onClick={handleAddCommunityRow}
              >
                Add
              </Button>
            </div>
            <br />
            <br />

            {/* care fees */}
            <div
              style={{
                background: '#f7f7f7',
                marginBottom: 10,
              }}
            >
              <Typography.Title level={3}>Care Fees</Typography.Title>
            </div>
            <div
              style={{
                margin: '0 auto',
                position: 'relative',
                overflowX: 'auto',
                paddingBottom: 20,
              }}
            >
              <div
                style={{
                  position: 'relative',
                }}
              >
                <table
                  className="acc_table_wrapper"
                  style={{
                    borderSpacing: 0,
                    width: '100%',
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          borderLeft: 0,
                          borderTop: 0,
                          borderRight: '1px solid #ccc',
                          textTransform: 'uppercase',
                        }}
                      >
                        Living Type
                      </th>
                      <th
                        style={{
                          border: '1px solid #ccc',
                        }}
                        align="left"
                      >
                        FEE TYPE
                      </th>
                      <th
                        style={{
                          border: '1px solid #ccc',
                        }}
                        align="left"
                      >
                        FEE NOTE
                      </th>
                      <th
                        style={{
                          borderLeft: 0,
                          position: 'relative',
                          textTransform: 'uppercase',
                        }}
                      >
                        Medication Management Fee
                      </th>
                      <th
                        style={{
                          borderLeft: 0,
                          position: 'relative',
                          textTransform: 'uppercase',
                        }}
                      >
                        Average Care Cost
                      </th>
                      {[1, 2, 3, 4, 5, 6, 7, 8].map((lt: any, idx: number) => (
                        <th
                          key={lt}
                          style={{
                            borderLeft: 0,
                            position: 'relative',
                            textTransform: 'uppercase',
                            width: 90,
                          }}
                        >
                          Level {lt}
                        </th>
                      ))}

                      <th style={{ border: 0 }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {careFees.map((row_data: any, row_index: number) => (
                      <tr key={row_index} className="acc_row">
                        <td
                          style={{
                            borderLeft: '1px solid #ccc',
                            padding: 0,
                          }}
                        >
                          <Select
                            bordered={false}
                            // value={`${row_data.living_type_id}`}
                            value={row_data.living_type_id}
                            options={living_options}
                            style={{ width: '200px', padding: 0 }}
                            onChange={(val) => handleChangeCareLivingType(val, row_index)}
                          />
                        </td>
                        <td style={{ padding: 0 }}>
                          <Select
                            bordered={false}
                            value={parseInt(row_data.fees_type_id)}
                            options={fee_type_options}
                            style={{ width: '130px', padding: 0 }}
                            onChange={(val) => handleChangeCareFeeType(val, row_index)}
                          />
                        </td>
                        <td style={{ padding: 0 }}>
                          <TextArea
                            value={row_data.fee_note}
                            onChange={(e) => handleUpdateCareFees(e.target.value, row_index, 'fee_note')}
                            style={{
                              border: 0,
                              width: '200px',
                              borderRadius: 0,
                              height: '35px',
                            }}
                          />
                        </td>
                        <td style={{ padding: 0 }}>
                          <Input
                            prefix="$"
                            value={row_data.medication_management_fee}
                            onChange={(e) =>
                              handleUpdateCareFees(e.target.value, row_index, 'medication_management_fee')
                            }
                            style={{
                              border: 0,
                              width: '100%',
                              borderRadius: 0,
                              height: '35px',
                            }}
                            type="number"
                          />
                        </td>
                        <td style={{ padding: 0 }}>
                          <Input
                            prefix="$"
                            value={row_data.average_care_cost}
                            onChange={(e) => handleUpdateCareFees(e.target.value, row_index, 'average_care_cost')}
                            style={{
                              border: 0,
                              width: '100%',
                              borderRadius: 0,
                              height: '35px',
                            }}
                            type="number"
                          />
                        </td>
                        <td style={{ padding: 0 }}>
                          <Input
                            prefix="$"
                            value={row_data.level_1}
                            onChange={(e) => handleUpdateCareFees(e.target.value, row_index, 'level_1')}
                            style={{
                              border: 0,
                              width: '90px',
                              borderRadius: 0,
                              height: '35px',
                            }}
                            type="number"
                          />
                        </td>
                        <td style={{ padding: 0 }}>
                          <Input
                            prefix="$"
                            value={row_data.level_2}
                            onChange={(e) => handleUpdateCareFees(e.target.value, row_index, 'level_2')}
                            style={{
                              border: 0,
                              width: '90px',
                              borderRadius: 0,
                              height: '35px',
                            }}
                            type="number"
                          />
                        </td>
                        <td style={{ padding: 0 }}>
                          <Input
                            prefix="$"
                            value={row_data.level_3}
                            onChange={(e) => handleUpdateCareFees(e.target.value, row_index, 'level_3')}
                            style={{
                              border: 0,
                              width: '90px',
                              borderRadius: 0,
                              height: '35px',
                            }}
                            type="number"
                          />
                        </td>
                        <td style={{ padding: 0 }}>
                          <Input
                            prefix="$"
                            value={row_data.level_4}
                            onChange={(e) => handleUpdateCareFees(e.target.value, row_index, 'level_4')}
                            style={{
                              border: 0,
                              width: '90px',
                              borderRadius: 0,
                              height: '35px',
                            }}
                            type="number"
                          />
                        </td>
                        <td style={{ padding: 0 }}>
                          <Input
                            prefix="$"
                            value={row_data.level_5}
                            onChange={(e) => handleUpdateCareFees(e.target.value, row_index, 'level_5')}
                            style={{
                              border: 0,
                              width: '90px',
                              borderRadius: 0,
                              height: '35px',
                            }}
                            type="number"
                          />
                        </td>
                        <td style={{ padding: 0 }}>
                          <Input
                            prefix="$"
                            value={row_data.level_6}
                            onChange={(e) => handleUpdateCareFees(e.target.value, row_index, 'level_6')}
                            style={{
                              border: 0,
                              width: '90px',
                              borderRadius: 0,
                              height: '35px',
                            }}
                            type="number"
                          />
                        </td>
                        <td style={{ padding: 0 }}>
                          <Input
                            prefix="$"
                            value={row_data.level_7}
                            onChange={(e) => handleUpdateCareFees(e.target.value, row_index, 'level_7')}
                            style={{
                              border: 0,
                              width: '90px',
                              borderRadius: 0,
                              height: '35px',
                            }}
                            type="number"
                          />
                        </td>
                        <td style={{ padding: 0 }}>
                          <Input
                            prefix="$"
                            value={row_data.level_8}
                            onChange={(e) => handleUpdateCareFees(e.target.value, row_index, 'level_8')}
                            style={{
                              border: 0,
                              width: '90px',
                              borderRadius: 0,
                              height: '35px',
                            }}
                            type="number"
                          />
                        </td>
                        <td style={{ border: 0 }}>
                          <Button
                            icon={<CloseCircleFilled />}
                            size="small"
                            type="link"
                            danger
                            onClick={() => removeRow(row_index)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Button
                  icon={<PlusOutlined />}
                  type="default"
                  shape="round"
                  style={{
                    fontSize: 14,
                    height: 'max-content',
                    padding: '6px 10px 4px 10px',
                    marginTop: 10,
                  }}
                  onClick={handleAddRow}
                >
                  Add
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: '100%',
          borderTop: '2px solid #eee',
          padding: '10px 30px',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          minHeight: '80px',
          marginTop: 30,
        }}
      >
        <Button onClick={() => navigate('/home')}>Cancel</Button>
        <div style={{ textAlign: 'center', padding: '20px 50px', maxWidth: '70%' }}>
          <Typography.Title level={4} style={{ margin: 0 }}>
            {basic_info?.comunity_name}
          </Typography.Title>
          <Typography.Paragraph style={{ color: '#ccc', fontSize: 16 }}>{basic_info?.address}</Typography.Paragraph>
        </div>
        <div>
          {current !== 0 && (
            <Button style={{ marginRight: 10 }} onClick={() => setCurrent((prevState) => prevState - 1)}>
              Previous Step
            </Button>
          )}
          {current === 3 ? (
            <Button type="primary">Finish</Button>
          ) : (
            <Button type="primary" htmlType="button" onClick={storeFees}>
              Next Step
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
export default FeesForm;
