import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { Button, Checkbox, Col, DatePicker, Drawer, Form, Input, Radio, Row, Select, Space, Typography } from 'antd';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import axios from 'config/axiosPrivate';
import LocationSearchInput from 'formComponents/LocationSearchInput';
import { useAppSelector } from 'store/hook';

dayjs.extend(customParseFormat);

const dateFormat = 'MM dd, yyyy';
interface Props {
  open: boolean;
  handleClose: () => void;
  title?: string;
  onSuccess: () => void;
  selectedData: any;
  companies: any[];
}

const AddMystryShopModal: React.FC<Props> = ({ open, handleClose, title = '', onSuccess, selectedData, companies }) => {
  const [form] = Form.useForm();
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  // const [companies, setCompanies] = React.useState<any[]>([]);
  // const [communitiesOptions, setCommunities] = React.useState<any[]>([]);
  const [companyCommunities, setCompanyCommunities] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [loadingCommunities, setLoadingCommunities] = React.useState(false);
  const [initialValues, setInitialValues] = useState<any>({});
  const getTypes = (detail: any) => {
    const values: any[] = [];
    Object.keys(detail).forEach((key) => {
      if (detail[key] === 'Yes') {
        values.push(key);
      }
    });
    return values;
  };
  const Cancel = () => {
    form.resetFields();
    setInitialValues({});
    handleClose();
  };
  const onFinish = async (values: any) => {
    const addresses: any[] = [];
    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].forEach((idx: any) => {
      const Details = selectedData?.Details;
      const obj = {
        id: Details ? (Details[idx - 1] ? (Details[idx - 1].id ? Details[idx - 1].id : 1) : 1) : 1,
        company_id: values?.company_id,
        user_id: selectedData?.user_id || 1,
        mystery_shop_id: selectedData?.id || -1,
        competitor_name: values[`competitor_${idx}`],
        competitor_address: values[`address_${idx}`],
        care_type_independent: values[`living_type_${idx}`]
          ? values[`living_type_${idx}`].includes('care_type_independent')
            ? 'Yes'
            : 'No'
          : 'No',
        care_type_assisted: values[`living_type_${idx}`]
          ? values[`living_type_${idx}`].includes('care_type_assisted')
            ? 'Yes'
            : 'No'
          : 'No',
        care_type_memory: values[`living_type_${idx}`]
          ? values[`living_type_${idx}`].includes('care_type_memory')
            ? 'Yes'
            : 'No'
          : 'No',
        care_type_other: values[`living_type_${idx}`]
          ? values[`living_type_${idx}`].includes('care_type_memory')
            ? 'Yes'
            : 'No'
          : 'No',
        status: '1',
        complete_status: 50,
        op: Details ? (Details[idx - 1] ? (Details[idx - 1].id ? 'edited' : 'added') : 'added') : 'added',
        disabled: false,
      };
      addresses.push(obj);
    });
    const filteredDetails = addresses
      .filter((el) => el?.competitor_address !== undefined)
      .map((el, idx) => ({
        ...el,
        // mystery_shop_id: shopResponse?.id,
        mystery_shop_id: selectedData ? selectedData?.id : 1,
        id: selectedData ? selectedData?.Details[idx]?.id : 1,
      }));
    const shop_payload = {
      id: selectedData ? selectedData?.id : 1,
      company_id: values?.company_id,
      apartment_id: values?.apartment_id,
      apartment_name: companyCommunities.filter((community) => community.value == values?.apartment_id)[0].label,
      shopdetails: filteredDetails,
      // user_id: selectedData?.user_id || 1,
      user_id: currentUser?.user_id || 1,
      user_name: currentUser?.name,
      requested_date: values?.requested_date ? format(new Date(values?.requested_date?.toDate()), 'yyyy/MM/dd') : '',
      comments: values?.comments || null,
      status: '1',
      email_from: null,
      email_to: null,
      request_deadline: values?.care_levels || null,
      unique_id: Date.now().toString(),
      token: '',
      complete_status: 50,
      op: selectedData ? 'edited' : 'added',
      disabled: false,
    };

    try {
      setLoading(true);
      const shopResponse = await axios.post('/communities/Save_MysteryShop/', { data: [shop_payload] }).then((res) => {
        // res.data[0];
        Cancel();
        onSuccess();
      });
      // const filteredDetails = addresses
      //   .filter((el) => el?.competitor_address !== undefined)
      //   .map((el, idx) => ({
      //     ...el,
      //     // mystery_shop_id: shopResponse?.id,
      //     mystery_shop_id: selectedData ? selectedData?.id : shopResponse?.id,
      //     id: selectedData ? selectedData?.Details[idx]?.id : 1,
      //   }));
      // await axios
      //   .post("/communities/Save_MysteryShopDetail/", { data: filteredDetails })
      //   .then((res) => {
      //     Cancel();
      //     onSuccess();
      //   });
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    const initialValues: any = {
      user_name: '',
      requested_date: '',
      company_id: 1,
      apartment_id: 1,
      care_levels: '',
      comments: '',
      address_1: undefined,
      address_2: undefined,
      address_3: undefined,
      address_4: undefined,
      address_5: undefined,
      address_6: undefined,
      address_7: undefined,
      address_8: undefined,
      address_9: undefined,
      address_10: undefined,
      competitor_1: undefined,
      competitor_2: undefined,
      competitor_3: undefined,
      competitor_4: undefined,
      competitor_5: undefined,
      competitor_6: undefined,
      competitor_7: undefined,
      competitor_8: undefined,
      competitor_9: undefined,
      competitor_10: undefined,
      living_type_1: undefined,
      living_type_2: undefined,
      living_type_3: undefined,
      living_type_4: undefined,
      living_type_5: undefined,
      living_type_6: undefined,
      living_type_7: undefined,
      living_type_8: undefined,
      living_type_9: undefined,
      living_type_10: undefined,
    };
    if (selectedData) {
      initialValues.user_name = selectedData?.user_name;
      initialValues.requested_date = selectedData?.requested_date
        ? dayjs(selectedData?.requested_date, dateFormat)
        : dayjs();
      initialValues.company_id = selectedData?.company;
      getCompanyCommunities(selectedData?.company);
      initialValues.apartment_id = selectedData?.apartment;
      initialValues.care_levels = selectedData?.request_deadline;
      if (selectedData.request_deadline != '') {
        const radio = document.getElementById(selectedData.request_deadline) as HTMLInputElement;
        if (radio) {
          radio.classList.add('ant-radio-checked');
        }
      }
      initialValues.comments = selectedData?.comments || '';
      selectedData?.Details?.forEach((dtl: any, idx: number) => {
        initialValues[`competitor_${idx + 1}`] = dtl?.competitor_name;
        initialValues[`address_${idx + 1}`] = dtl?.competitor_address;
        initialValues[`living_type_${idx + 1}`] = getTypes(dtl);
      });
      setTimeout(() => {
        form.setFieldsValue({ ...initialValues });
      }, 1000);

      setInitialValues(initialValues);
    }
  }, [selectedData]);

  React.useEffect(() => {
    if (currentUser) {
      setTimeout(() => {
        form.setFieldValue('user_name', currentUser.name);
      }, 1000);
    }
  }, [currentUser]);

  // get companies
  // useQuery({
  //   queryKey: "Get_UserManagementCompanies",
  //   queryFn: () =>
  //     axios
  //       .get(`/company/Get_UserManagementCompanies/`)
  //       .then((res) => res.data?.payload),
  //   onSuccess: (data) => {
  //     const companiesList = [];
  //     for (let key in data) {
  //       companiesList.push(data[key]);
  //     }
  //     setCompanies(companiesList);
  //   },
  //   retry: false,
  // });
  // // get communities
  // useQuery({
  //   queryKey: "Get_UserCommunities",
  //   queryFn: () =>
  //     axios
  //       .get(`/communities/Get_UserCommunities/`)
  //       .then((res) => res.data?.payload),
  //   onSuccess: (data) => {
  //     // const list = data?.map((el: any) => ({
  //     //   label: el?.comunity_name,
  //     //   value: el?.id,
  //     // }));
  //     setCommunities(data);
  //   },
  //   retry: false,
  // });
  const getCompanyCommunities = async (value: any) => {
    form.resetFields(['apartment_id']);
    setLoadingCommunities(true);
    try {
      const res = await axios.get(`/communities/Get_CompanyCommunities/${value}/`);
      const data = res.data?.payload;
      if (data) {
        //
        const list = data?.map((el: any) => {
          if (el.type == 'apartment') {
            return {
              label: el?.comunity_name,
              value: el?.id,
            };
          }
        });
        //
        setCompanyCommunities(list);
      } else {
        setCompanyCommunities([]);
      }
      setLoadingCommunities(false);
    } catch (error) {
      setLoadingCommunities(false);
    }
  };

  const companyOptions = companies.map((pt: any) => ({
    label: pt.name,
    value: pt.id,
  }));

  const handleCompetitorAddress = (value: any, index: number) => {
    form.setFieldValue(`address_${index}`, value.formatted_address);
    form.setFieldValue(`competitor_${index}`, value.name);
  };

  return (
    <>
      <Drawer
        title={selectedData ? 'Edit Mystery Shop Request' : 'Add Mystery Shop Request'}
        height={'100%'}
        onClose={handleClose}
        open={open}
        bodyStyle={{ paddingBottom: 80 }}
        placement="bottom"
      >
        <Form layout="vertical" requiredMark={false} onFinish={onFinish} form={form}>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item
                name="user_name"
                label=<Typography.Paragraph
                  style={{
                    marginBottom: 14,
                    marginTop: 5,
                    fontSize: 18,
                    fontWeight: 600,
                  }}
                >
                  User Name
                </Typography.Paragraph>
              >
                <Input placeholder="Please enter user name" disabled />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="requested_date"
                label={
                  <Typography.Paragraph
                    style={{
                      marginBottom: 14,
                      marginTop: 5,
                      fontSize: 18,
                      fontWeight: 600,
                    }}
                  >
                    Shop Request Date
                  </Typography.Paragraph>
                }
                rules={[{ required: true, message: 'Please requested date' }]}
              >
                <DatePicker style={{ width: '100%' }} format={'MM-DD-YYYY'} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="company_id"
                label={
                  <Typography.Paragraph
                    style={{
                      marginBottom: 14,
                      marginTop: 5,
                      fontSize: 18,
                      fontWeight: 600,
                    }}
                  >
                    Company Name
                  </Typography.Paragraph>
                }
                rules={[{ required: true, message: 'Please select a company' }]}
              >
                <Select
                  placeholder="Select"
                  options={companyOptions}
                  showSearch
                  onSelect={getCompanyCommunities}
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label ?? '').includes(input)}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="apartment_id"
                label={
                  <Typography.Paragraph
                    style={{
                      marginBottom: 14,
                      marginTop: 5,
                      fontSize: 18,
                      fontWeight: 600,
                    }}
                  >
                    Which Community is the shop for?
                  </Typography.Paragraph>
                }
                rules={[{ required: true, message: 'Please select a community' }]}
              >
                <Select
                  placeholder="Select"
                  options={companyCommunities}
                  showSearch
                  loading={loadingCommunities}
                  optionFilterProp="children"
                  filterOption={(input, option) => (option?.label ?? '').includes(input)}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <br />
          <Row gutter={[16, 16]} style={{ marginBottom: 10 }}>
            <Col span={6}>
              <Form.Item name={`competitor_1`} style={{ display: 'none' }}>
                <Input style={{ display: 'none' }} />
              </Form.Item>
              <Typography.Paragraph
                style={{
                  marginBottom: 10,
                  fontSize: 18,
                  fontWeight: 600,
                }}
              >
                Competitor
              </Typography.Paragraph>
              <LocationSearchInput
                getLocationBySearch={(value) => handleCompetitorAddress(value, 1)}
                placeholder="Competitor Name you want shopped?"
                style={{
                  borderRadius: 4,
                  height: 40,
                }}
                defaultValue={initialValues[`competitor_1`]}
              />
            </Col>
            <Col span={8}>
              <Form.Item
                name={`address_1`}
                label={
                  <Typography.Paragraph
                    style={{
                      marginBottom: 14,
                      marginTop: 5,
                      fontSize: 18,
                      fontWeight: 600,
                    }}
                  >
                    Address
                  </Typography.Paragraph>
                }
              >
                <Input placeholder="Competitor address" />
              </Form.Item>
            </Col>
            <Col span={1}></Col>
            <Col span={9}>
              <Form.Item
                name={`living_type_1`}
                label={
                  <Typography.Paragraph
                    style={{
                      marginBottom: 14,
                      marginTop: 5,
                      fontSize: 18,
                      fontWeight: 600,
                    }}
                  >
                    What Care Levels Do You Want Shopped?
                  </Typography.Paragraph>
                }
              >
                <Checkbox.Group style={{ width: '100%' }}>
                  <Row style={{ width: '100%' }}>
                    <Col span={7}>
                      <Checkbox value="care_type_independent">Independent Living</Checkbox>
                    </Col>
                    <Col span={6}>
                      <Checkbox value="care_type_assisted">Assisted Living</Checkbox>
                    </Col>
                    <Col span={6}>
                      <Checkbox value="care_type_memory">Memory Care</Checkbox>
                    </Col>
                    <Col span={5}>
                      <Checkbox value="care_type_other">Other</Checkbox>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </Row>
          {[2, 3, 4, 5, 6, 7, 8, 9, 10].map((el, index) => (
            <Row gutter={[16, 0]} key={index} style={{ marginBottom: 10 }}>
              <Col span={6}>
                <Form.Item name={`competitor_${el}`} label="" style={{ display: 'none' }}>
                  <Input style={{ display: 'none' }} />
                </Form.Item>
                <LocationSearchInput
                  getLocationBySearch={(value) => handleCompetitorAddress(value, el)}
                  placeholder="Competitor Name you want shopped?"
                  style={{
                    borderRadius: 4,
                    height: 40,
                  }}
                  defaultValue={initialValues[`competitor_${el}`]}
                />
              </Col>
              <Col span={8}>
                <Form.Item name={`address_${el}`} label="">
                  <Input placeholder="Competitor address" />
                </Form.Item>
              </Col>
              <Col span={1}></Col>
              <Col span={9} style={{ marginBottom: 10 }}>
                <Form.Item
                  name={`living_type_${el}`}

                  // label="What Care Levels Do You Want Shopped ?"
                  // label={
                  //   <Typography.Paragraph
                  //     style={{
                  //       marginBottom: 14,
                  //       marginTop: 5,
                  //       fontSize: 18,
                  //       fontWeight: 600,
                  //     }}
                  //   >
                  //     What Care Levels Do You Want Shopped?
                  //   </Typography.Paragraph>
                  // }
                >
                  <Checkbox.Group style={{ width: '100%' }}>
                    <Row style={{ width: '100%' }}>
                      <Col span={7}>
                        <Checkbox value="care_type_independent">Independent Living</Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox value="care_type_assisted">Assisted Living</Checkbox>
                      </Col>
                      <Col span={6}>
                        <Checkbox value="care_type_memory">Memory Care</Checkbox>
                      </Col>
                      <Col span={5}>
                        <Checkbox value="care_type_other">Other</Checkbox>
                      </Col>
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </Col>
            </Row>
          ))}
          <br />
          <Col span={24}>
            <Form.Item
              name="care_levels"
              // label="What Care Levels Do You Want Shopped ?"
              label={
                <Typography.Paragraph
                  style={{
                    marginBottom: 14,
                    marginTop: 5,
                    fontSize: 18,
                    fontWeight: 600,
                  }}
                >
                  What do you need the shops completed?
                </Typography.Paragraph>
              }
            >
              <Radio.Group>
                <Radio id="ASAP" value="ASAP">
                  ASAP
                </Radio>
                <Radio id="With-in 2 Weeks" value="With-in 2 Weeks">
                  With-in 2 Weeks
                </Radio>
                <Radio id="With-in 1 Month" value="With-in 1 Month">
                  With-in 1 Month
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <br />
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="comments" label="Comments">
                <Input.TextArea rows={4} placeholder="Comments..." />
              </Form.Item>
            </Col>
          </Row>
          <br />
          <Row gutter={16}>
            <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Space>
                <Button onClick={Cancel}>Cancel</Button>
                <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
                  Save
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default AddMystryShopModal;
