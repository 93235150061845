import React from 'react';

import { UploadOutlined } from '@ant-design/icons';
import { Button, Col, Form, message, Modal, Row, Select, Upload } from 'antd';

const { Dragger } = Upload;
interface Props {
  open: boolean;
  handleClose: () => void;
  title?: string;
  handleAddData: (values: any) => void;
  loading?: boolean;
}

const UploadImageModal: React.FC<Props> = ({ open, handleClose, title = '', handleAddData, loading }) => {
  const [file, setFile] = React.useState<any>(null);
  const onUpload = (files: any) => {
    //
    if (files.length > 0) {
      setFile(files[0]);
    }
  };
  const onFinish = (values: any) => {
    if (!file) return;
    values.file = file;
    const payload = {
      data: values,
      type: 'image_upload',
    };

    handleAddData(payload);
  };

  const handleCloseModal = () => {
    setFile(null);
    handleClose();
  };
  const onChange = (info: any) => {
    const { status } = info.file;
    if (status !== 'uploading') {
      // Do nothing
    }
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      // Do nothing
    }
  };
  const dummyRequest = (options: any) => {
    setTimeout(() => {
      if (options.file.size / (1024 * 1024) > 25) {
        options.onError('Size exeed 25 Mb');
        message.error(`${options.file.name} file upload failed.Size exeed 25 Mb`);
      } else {
        setFile(options.file);
        options.onSuccess('ok');
      }
    }, 1000);
  };
  const beforeUpload = (file: any) => {
    const isJPG = file.type === 'image/jpeg';
    // if (file.size / (1024 * 1024) > 25) {
    //   message.error(`${file.name} file upload failed.Size exeed 25 Mb`);
    //   return false;
    // }
    // if (!isJPG) {
    //   message.error("You can only upload JPG file!");
    // }
    return true;
  };

  return (
    <>
      <Modal title={title} centered open={open} onCancel={handleCloseModal} width={500} footer={false}>
        <Form
          onFinish={onFinish}
          layout="vertical"
          requiredMark={false}
          autoComplete="off"
          initialValues={{
            category: 'Other Image',
          }}
        >
          <Row gutter={[20, 10]}>
            <Col xs={24}>
              <Form.Item
                name="category"
                label={<p className="form_label">Image Category:</p>}
                rules={[{ required: true, message: 'Please Select Image Category' }]}
              >
                <Select
                  placeholder="Select"
                  options={[
                    { label: 'Exterior Front', value: 'Exterior Front' },
                    { label: 'Common Area', value: 'Common Area' },
                    { label: 'Apartment Image', value: 'Apartment Image' },
                    { label: 'Dining Room', value: 'Dining Room' },
                    {
                      label: 'Menu',
                      value: 'Menu',
                    },
                    {
                      label: 'Activity Calendar',
                      value: 'Activity Calendar',
                    },
                    { label: 'Other Image', value: 'Other Image' },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Dragger
                multiple={false}
                maxCount={1}
                onChange={(info: any) => onChange(info)}
                onDrop={(acceptedFiles) => onUpload(acceptedFiles.dataTransfer.files)}
                beforeUpload={beforeUpload}
                customRequest={dummyRequest}
                onRemove={() => setFile(null)}
              >
                {/* {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "4px dotted #eee",
                          padding: 20,
                          borderRadius: 8,
                        }}
                      > */}
                <p className="ant-upload-drag-icon">
                  <UploadOutlined style={{ fontSize: '45px' }} />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-text">{'[Max 25MB]'}</p>
                {/* </div>
                    </div>
                  </section>
                )} */}
              </Dragger>
            </Col>
            {/* <Col xs={24}>
              {file && (
                <div
                  key={file?.name}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    background: "#eee",
                    padding: "4px",
                    marginBottom: 5,
                    borderRadius: "5px",
                  }}
                >
                  <p>{file?.name}</p>
                  <Button
                    icon={<CloseCircleFilled />}
                    size="middle"
                    danger
                    className="p-button-link"
                    style={{
                      padding: 0,
                      width: "auto",
                      height: "auto",
                      border: 0,
                      display: "block",
                      boxShadow: "none",
                      background: "none",
                    }}
                    onClick={() => setFile(null)}
                    htmlType="button"
                  />
                </div>
              )}
            </Col> */}
          </Row>

          <br />
          <Row>
            <Col xs={24}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  borderRadius: 8,
                  padding: 10,
                }}
              >
                <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default UploadImageModal;
