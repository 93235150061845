import axios from "config/axiosPrivate";
import { useEffect } from "react";
// import { Navigate, Route, Routes } from "react-router-dom";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import "./assets/styles/main.css";


import AddCommunity from 'pages/AddCommunity';
import AddCompetitor from 'pages/AddCompetitor';
import GoogleMatrixReviews from 'pages/GoogleMatrixReviews';
import MysteryShop from 'pages/MysteryShop/MysteryShop';
import PriceHealing from 'pages/price_healing';
import SearchCommunities from 'pages/SearchCommunities';
import SearchCompatitors from 'pages/SearchCompatitors';
import Gallery from 'pages/SearchGallery/Gallery';
import SentimentAnalysis from 'pages/SentimentAnalysis';
import SettingsPage from 'pages/Settings';
import OccupancyTiers from 'pages/Settings/OccupancyTiers';
import SwotSettings from 'pages/Settings/SwotSettings';
import SwotSurvey from 'pages/SwotSurvey/Swot';
import './assets/styles/main.css';
import SwotList from 'pages/SwotSurvey/SwotList';
import UserLogReport from 'pages/UserLogReport';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { handleSetOptionTypes } from 'store/slices/globalSlice';

import PrivateRoot from './components/layout/PrivateRoot';
import Dashboard from './pages/Dashboard';
import FindCommunities from './pages/FindCommunities';
import ForgotPassword from './pages/ForgotPassword';
import Home from './pages/Home';
import Profile from './pages/Profile';
import ApartmentRatings from './pages/Settings/ApartmentRatings';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import UserLogDashboard from './pages/UserLogDashboard';
import { useQuery } from "react-query";

// const Dashboard = React.lazy(() => import("./pages/Dashboard"));
// const Home = React.lazy(() => import("./pages/Home"));
// const Profile = React.lazy(() => import("./pages/Profile"));
// const SignIn = React.lazy(() => import("./pages/SignIn"));
// const SignUp = React.lazy(() => import("./pages/SignUp"));
// const FindCommunities = React.lazy(() => import("./pages/FindCommunities"));
// const MysteryShop = React.lazy(() => import("pages/MysteryShop/MysteryShop"));
// const SuperPriceHealing = React.lazy(() => import("pages/PriceHealing"));
// const PriceHealing = React.lazy(() => import("pages/price_healing"));
// const SearchCommunities = React.lazy(() => import("pages/SearchCommunities"));
// const SearchCompatitors = React.lazy(() => import("pages/SearchCompatitors"));
// const Gallery = React.lazy(() => import("pages/SearchGallery/Gallery"));
// const SwotSurvey = React.lazy(() => import("pages/SwotSurvey/Swot"));
// const AddCommunity = React.lazy(() => import("pages/AddCommunity"));

// const ComparisonTab = React.lazy(
//   () => import("pages/price_healing/ComparisonTab")
// );
// const FeesAndIncentives = React.lazy(
//   () => import("pages/PriceHealing/FeesAndIncentives")
// );
// const ApartmentRates = React.lazy(
//   () => import("pages/PriceHealing/ApartmentRates")
// );
// const AdditionalLinks = React.lazy(
//   () => import("pages/PriceHealing/AdditionalLinks")
// );

// const BasicInfo = React.lazy(() => import("pages/PriceHealing/BasicInfo"));
// const BrochureInfo = React.lazy(
//   () => import("pages/PriceHealing/BrochureInfo")
// );
// const Calculator = React.lazy(() => import("pages/PriceHealing/Calculator"));
// const CareFees = React.lazy(() => import("pages/PriceHealing/CareFees"));
// const CommunityDetails = React.lazy(
//   () => import("pages/PriceHealing/CommunityDetails")
// );
// const Competitors = React.lazy(() => import("pages/PriceHealing/Competitors"));

// const PriceHealingGallery = React.lazy(
//   () => import("pages/PriceHealing/Gallery")
// );
// const ImageComponent = React.lazy(
//   () => import("pages/PriceHealing/ImageComponent")
// );
// const Occupancies = React.lazy(() => import("pages/PriceHealing/Occupancies"));
// const RentRole = React.lazy(() => import("pages/PriceHealing/RentRole"));
// const ShopNotes = React.lazy(() => import("pages/PriceHealing/ShopNotes"));
// const CMASummery = React.lazy(() => import("pages/PriceHealing/CMASummery"));
// const ValueRatings = React.lazy(
//   () => import("pages/PriceHealing/ValueRatings")
// );

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/sign-in" element={<SignIn />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />

      <Route path="/" element={<PrivateRoot />}>
        <Route path="home" element={<Home />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="user-log-dashboard" element={<UserLogDashboard />} />
        <Route path="user-log-report" element={<UserLogReport />} />
        <Route path="sentiment-analysis" element={<SentimentAnalysis />} />
        <Route path="search-communities" element={<SearchCommunities />} />
        <Route path="search-competitors" element={<SearchCompatitors />} />
        <Route path="find-communities" element={<FindCommunities />} />
        <Route path="create-competitors" element={<PriceHealing />} />
        <Route path="users" element={<Dashboard />} />
        <Route path="add-community" element={<AddCommunity />} />
        <Route path="add-competitor" element={<AddCompetitor />} />
        <Route path="settings" element={<SettingsPage />}>
          <Route path="apartment-ratings" element={<ApartmentRatings />} />
          <Route path="occupancy-tiers" element={<OccupancyTiers />} />
          <Route path="swot-settings" element={<SwotSettings />}></Route>
        </Route>
        {/* <Route path="create-apartments" element={<SuperPriceHealing />}>
          <Route path="comparison" element={<SuperComparison />} />
          <Route path="basic-info" element={<BasicInfo />} />
          <Route path="fees-and-incentives" element={<FeesAndIncentives />} />
          <Route path="apartment-rates" element={<ApartmentRates />} />
          <Route path="care-fees" element={<CareFees />} />
          <Route path="occupancies" element={<Occupancies community_id="" />} />
          <Route path="community-details" element={<CommunityDetails />} />
          <Route path="value-ratings" element={<ValueRatings />} />
          <Route path="images" element={<ImageComponent />} />
          <Route path="brochure-info" element={<BrochureInfo />} />
          <Route path="additional-links" element={<AdditionalLinks />} />
          <Route path="cma-summery" element={<CMASummery />} />
          <Route path="competitors" element={<Competitors />} />
          <Route path="shop-notes" element={<ShopNotes />} />
          <Route path="gallery" element={<PriceHealingGallery />} />
          <Route path="calculator" element={<Calculator />} />
          <Route path="rent-roll" element={<RentRole />} />
        </Route> */}
        <Route path="view-apartments" element={<PriceHealing />}></Route>
        <Route path="profile" element={<Profile />} />
        <Route path="search-gallery" element={<Gallery />} />
        <Route path="swot-survey" element={<SwotSurvey />} />
        <Route path="swot-survey-list" element={<SwotList />} />
        <Route path="google-metrics-reviews" element={<GoogleMatrixReviews />} />
        <Route path="shop-requests" element={<MysteryShop />} />
      </Route>
      <Route path="*" element={<Navigate to="/home" replace />} />
    </>
  )
);

function App() {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => state.auth.currentUser);

  // get apartment types
  useQuery({
    queryKey: 'Get_UniqueList',
    queryFn: () => axios.get(`/communities/Get_UniqueList/`).then((res) => res.data?.payload),
    onSuccess: (data) => {
      const formatted_types = {
        FeeTypes: data?.FeeTypes,
        LevelTypes: data?.LevelTypes,
        OccupancyTypes: data?.OccupancyTypes,
        PaymentTypes: data?.PaymentTypes,
        apartmenttypes: data?.ApartmentTypes,
        livingtypes: data?.LivingTypes,
        business_models: data?.BusinessModels,
        apartment_ratings: data?.ApartmentRating,
        selectedComunityCompanyName: '',
      };
      dispatch(handleSetOptionTypes(formatted_types));
    },
    enabled: currentUser !== null,
    refetchOnWindowFocus: false,
  });
  useEffect(() => {
    return () => {
      // window.alert("tab closed");
    };
  }, []);
  return (
    <RouterProvider router={router} />
    // <Routes>
    //   <Route path="/sign-up" element={<SignUp />} />
    //   <Route path="/sign-in" element={<SignIn />} />
    //   <Route path="/forgot-password" element={<ForgotPassword />} />

    //   <Route path="/" element={<PrivateRoot />}>
    //     <Route path="home" element={<Home />} />
    //     <Route path="dashboard" element={<Dashboard />} />
    //     <Route path="search-communities" element={<SearchCommunities />} />
    //     <Route path="search-competitors" element={<SearchCompatitors />} />
    //     <Route path="find-communities" element={<FindCommunities />} />
    //     <Route path="create-competitors" element={<PriceHealing />} />
    //     <Route path="users" element={<Dashboard />} />
    //     <Route path="add-community" element={<AddCommunity />} />
    //     <Route path="add-competitor" element={<AddCompetitor />} />
    //     <Route path="settings" element={<SettingsPage />}>
    //       <Route path="apartment-ratings" element={<ApartmentRatings />} />
    //       <Route path="occupancy-tiers" element={<OccupancyTiers />} />
    //       <Route path="swot-settings" element={<SwotSettings />}></Route>
    //     </Route>
    //     {/* <Route path="create-apartments" element={<SuperPriceHealing />}>
    //       <Route path="comparison" element={<SuperComparison />} />
    //       <Route path="basic-info" element={<BasicInfo />} />
    //       <Route path="fees-and-incentives" element={<FeesAndIncentives />} />
    //       <Route path="apartment-rates" element={<ApartmentRates />} />
    //       <Route path="care-fees" element={<CareFees />} />
    //       <Route path="occupancies" element={<Occupancies community_id="" />} />
    //       <Route path="community-details" element={<CommunityDetails />} />
    //       <Route path="value-ratings" element={<ValueRatings />} />
    //       <Route path="images" element={<ImageComponent />} />
    //       <Route path="brochure-info" element={<BrochureInfo />} />
    //       <Route path="additional-links" element={<AdditionalLinks />} />
    //       <Route path="cma-summery" element={<CMASummery />} />
    //       <Route path="competitors" element={<Competitors />} />
    //       <Route path="shop-notes" element={<ShopNotes />} />
    //       <Route path="gallery" element={<PriceHealingGallery />} />
    //       <Route path="calculator" element={<Calculator />} />
    //       <Route path="rent-roll" element={<RentRole />} />
    //     </Route> */}
    //     <Route path="view-apartments" element={<PriceHealing />}></Route>
    //     <Route path="profile" element={<Profile />} />
    //     <Route path="search-gallery" element={<Gallery />} />
    //     <Route path="swot-survey" element={<SwotSurvey />} />
    //     <Route path="swot-survey-list" element={<SwotList />} />
    //     <Route path="shop-requests" element={<MysteryShop />} />
    //   </Route>
    //   <Route path="*" element={<Navigate to="/home" replace />} />
    // </Routes>
  );
}

export default App;
