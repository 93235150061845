import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';

import { UploadOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Image, Input, message, Modal, Row, Upload } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
const { TextArea } = Input;
const { Dragger } = Upload;
dayjs.extend(customParseFormat);
const dateFormat = 'MM-DD-YYYY';

interface Props {
  open: boolean;
  handleClose: () => void;
  title?: string;
  handleAddNote: (value: any) => void;
  loading?: boolean;
  initialValues?: any;
}

const AddShopNoteModal: React.FC<Props> = ({
  open,
  handleClose,
  title = '',
  handleAddNote,
  loading,
  initialValues,
}) => {
  const [form] = Form.useForm();
  const [file, setFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState('');

  const onFinish = (values: any) => {
    //
    handleAddNote({ ...values, file });
  };

  const onChange = (info: any) => {
    const { status } = info.file;

    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    }
  };
  const dummyRequest = (options: any) => {
    setTimeout(() => {
      if (options.file.size / (1024 * 1024) > 25) {
        options.onError('Size exeed 25 Mb');
        message.error(`${options.file.name} file upload failed.Size exeed 25 Mb`);
      } else {
        options.onSuccess('ok');
        setFile(options.file);
      }
    }, 1000);
  };
  useEffect(() => {
    return () => {
      form.resetFields();
      setFile(null);
      setPreviewUrl('');
    };
  }, [open]);

  useEffect(() => {
    if (initialValues) {
      form.setFieldValue('request_date', dayjs(new Date(initialValues.request_date).toDateString()));
      form.setFieldValue('file_url', initialValues.file_url);
      form.setFieldValue('request_notes', initialValues.request_notes);
    }
  }, [initialValues]);

  return (
    <>
      <Modal title={title} centered open={open} onOk={handleClose} onCancel={handleClose} width={700} footer={false}>
        <Form onFinish={onFinish} layout="vertical" requiredMark={false} autoComplete="off" form={form}>
          <Row gutter={[20, 10]}>
            <Col xs={24}>
              <Form.Item
                name="request_date"
                label={<p className="form_label">Requested Date:</p>}
                rules={[{ required: true, message: 'Please Enter Request Date' }]}
              >
                <DatePicker format={dateFormat} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="request_notes"
                label={<p className="form_label">Notes:</p>}
                rules={[{ required: false, message: 'Please Enter Notes' }]}
              >
                <TextArea placeholder="Write Your Notes Here..." maxLength={10000} showCount />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item
                name="file_url"
                label={<p className="form_label">File URL:</p>}
                rules={[{ required: false, message: 'Please Enter File URL' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item label={<p className="form_label">Drag or Click to upload:</p>}>
                <Dragger
                  multiple={false}
                  maxCount={1}
                  onChange={(info: any) => onChange(info)}
                  customRequest={dummyRequest}
                  onRemove={() => setFile(null)}
                >
                  <p className="ant-upload-drag-icon">
                    <UploadOutlined style={{ fontSize: '45px' }} />
                  </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  <p className="ant-upload-text">{'[Max 25MB]'}</p>
                </Dragger>
              </Form.Item>
            </Col>
          </Row>

          <br />
          <Row>
            <Col xs={24}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  borderRadius: 8,
                  padding: 10,
                }}
              >
                <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default AddShopNoteModal;
