import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { Button, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import axios from 'config/axiosPrivate';

import classes from './cma.module.scss';

// const ExcelJS = require('exceljs');

function groupByApartmentId(data: any[], key: any) {
  const groups: any = {};

  data.forEach(function (val) {
    const category = val[key];
    if (category) {
      if (category in groups) {
        groups[category].push(val);
      } else {
        groups[category] = new Array(val);
      }
    }
  });
  return groups;
}

async function exportToExcell(table_data: any[], dataHeaders: any[], fileName: string) {
  const hedersNames = ['Community Name', ...dataHeaders.map((el) => el.comunity_name)];
  const updated_data = table_data.map((el) => {
    let obj = {};
    //
    Object.keys(el)
      .reverse()
      .forEach((key) => {
        if (key !== 'dataKey') {
          const header_name = el[key][0].comunity_name || el[key][0].CommunityName;

          obj = {
            ...obj,
            [header_name]: el[key][0][el['dataKey']],
          };
        } else {
          obj = {
            ...obj,
            ['Community Name']: el[key],
          };
        }
      });
    return obj;
  });

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  console.log({ updated_data });

  const ws = XLSX.utils.json_to_sheet(updated_data);
  const wb = { Sheets: { data: ws }, SheetNames: ['data'], origin: 'I1' };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
}

interface Props {
  community_id: number;
}

const numberToDoller = [
  'parking_attached_garage',
  'parking_carport',
  'parking_detached_garage',
  'parking_storage',
  'parking_storage_rate',
  'services_additional_meal_charges',
];

const CommunityDetailsComparison = ({ community_id }: Props) => {
  const [tableCollumns, settableCollumns] = useState<any[]>([]);
  const [dataSource, setDataSource] = useState<any[]>([]);

  const { isLoading } = useQuery({
    queryKey: `Get_CommunityDetailComparison_${community_id}`,
    queryFn: () =>
      axios.get(`/communities/Get_CommunityDetailComparison/${community_id}`).then((res) => res.data?.payload),
    onSuccess: (data) => {
      const Columns: any[] = data[0]?.Columns;
      const cmm: any[] = data[0]?.CommunityDetail?.map((t: any) => ({
        ...t,
        type: 'apartment',
      }));
      // const comp: any[] = data[0]?.CompetitorDetail?.map((t: any) => ({
      //   ...t,
      //   type: "competitor",
      // }));

      const comm_comp_list = [];
      if (cmm && cmm.length > 0) {
        comm_comp_list.push(...cmm);
      }
      // if (comp && comp.length > 0) {
      //   comm_comp_list.push(...comp);
      // }

      const grup__apartment = groupByApartmentId(comm_comp_list, 'apartment');

      const collumns_updated = Columns.map((cl) => (cl?.title ? { ...cl[0] } : { ...cl[0], Title: cl[0]?.Data }));

      const grup__collumn = groupByApartmentId(collumns_updated, 'Category');
      const { Communityid, Communityname, Communitytype, ...rest } = grup__collumn;

      const table_columns: ColumnsType<any> = [
        {
          title: (
            <div
              style={{
                padding: '10px 4px',
                whiteSpace: 'normal',
              }}
            >
              <Typography.Title style={{ margin: 0 }} level={4}>
                Offerings
              </Typography.Title>
            </div>
          ),
          dataIndex: 'dataKey',
          key: 'dataKey',
          render(value, record, index) {
            if (Object.keys(grup__collumn).find((key) => key === record.dataKey)) {
              return (
                <Button
                  type="text"
                  className="p_healing_active_tab"
                  shape="round"
                  style={{
                    marginRight: 20,
                    height: 'auto',
                    padding: '5px 20px',
                    fontSize: 20,
                    pointerEvents: 'none',
                  }}
                >
                  {value}
                </Button>
              );
            } else {
              return (
                <Typography.Title style={{ margin: 0 }} level={4}>
                  {record?.Title == 'Comupter For Residents' ? 'Computer For Residents' : record?.Title}
                </Typography.Title>
              );
            }
          },
          fixed: 'left',
          width: 400,
        },
        ...Object.keys(grup__apartment)?.map((cm_id: any) => ({
          title: (
            <div
              style={{
                padding: '10px 4px',
              }}
            >
              <Typography.Title style={{ margin: 0, whiteSpace: 'break-spaces' }} level={4}>
                {grup__apartment[cm_id][0]?.CommunityName}
                {grup__apartment[cm_id][0]?.CommunityType === 'apartment' && (
                  <span
                    style={{
                      background: '#4CD7D2',
                      color: '#fff',
                      fontSize: '12px',
                      padding: '5px 9px',
                      borderRadius: '10px',
                      marginLeft: 6,
                    }}
                  >
                    ME
                  </span>
                )}
              </Typography.Title>
            </div>
          ),
          dataIndex: cm_id,
          key: cm_id,
          width: 300,
          render(value: any, record: any) {
            if (value && value.length > 0) {
              const rowData = value[0];
              //
              if (numberToDoller.indexOf(record.dataKey) !== -1) {
                return (
                  <Typography.Title level={4} style={{ margin: 0, marginBottom: -10 }}>
                    {rowData[record?.dataKey] && `$ ${rowData[record?.dataKey]}`}
                  </Typography.Title>
                );
              } else {
                return (
                  <Typography.Title level={4} style={{ margin: 0, marginBottom: -10 }}>
                    {rowData[record?.dataKey]}
                  </Typography.Title>
                );
              }
            }
            return <span></span>;
          },
        })),
      ];
      settableCollumns(table_columns);

      //======

      //
      //   grup__collumn,
      //   grup__apartment,
      //   collumns_updated,
      //   comm_comp_list,
      // });
      const table_apartment_data: any[] = [];

      if (Object.keys(rest).length > 0) {
        Object.keys(rest).forEach((key) => {
          table_apartment_data.push({ dataKey: key });

          const type_data_arr: any[] = rest[key];
          const updated_arr = type_data_arr.map((el: any) => ({
            ...el,
            ...grup__apartment,
          }));

          table_apartment_data.push(...updated_arr);
        });
      }

      // Columns.forEach((cl) => {
      //   const newRow = {
      //     name: cl?.
      //     dataKey: `${cl.Title}`.toLocaleLowerCase(),
      //     ...grup__apartment,
      //   };
      //
      //   table_apartment_data.push(newRow);
      // });

      // for fee/inc
      // const grup__feeInc = groupByApartmentId(feeInc, "CommunityId");
      table_apartment_data.push({ dataKey: 'Fees/Incentives' });
      // console.log({ feeInc });
      // [
      //   "community_fee",
      //   "pet_fee",
      //   "second_person_fee",
      //   "additional_fee",
      // ].forEach((name) => {
      //   const newRow = {
      //     dataKey: name,
      //     ...grup__feeInc,
      //   };
      //   table_apartment_data.push(newRow);
      // });

      // for care fees
      if (
        data[0].Columns.length == 0 &&
        data[0].CommunityDetail.length == 0
        // data[0].CompetitorDetail.length == 0
      ) {
        setDataSource([]);
      } else {
        setDataSource(table_apartment_data);
      }
    },
  });

  // pdf modal
  const [openPdfModal, setOpenPdfModal] = useState(false);
  const DATA = [
    {
      STUDENT_DETAILS: {
        id: 101,
        name: 'Suman Kumar',
        parentName: 'Suresh Kumar',
        classroom: '12th',
        subject: 'Non Medical',
        division: '1st',
        status: 'Pass',
      },
      MARKS: {
        maths: 75,
        physics: 65,
        chemistry: 72,
        english: 62,
        computerScience: 80,
      },
    },
    {
      STUDENT_DETAILS: {
        id: 102,
        name: 'Rahul Kumar',
        parentName: 'Aatma Ram',
        classroom: '12th',
        subject: 'Non Medical',
        division: '1st',
        status: 'Pass',
      },
      MARKS: {
        maths: 70,
        physics: 75,
        chemistry: 82,
        english: 72,
        computerScience: 60,
      },
    },
    {
      STUDENT_DETAILS: {
        id: 103,
        name: 'Anuj Kumar',
        parentName: 'Ashok Kumar',
        classroom: '12th',
        subject: 'Non Medical',
        division: '1st',
        status: 'Pass',
      },
      MARKS: {
        maths: 60,
        physics: 65,
        chemistry: 92,
        english: 77,
        computerScience: 80,
      },
    },
  ];

  return (
    <div className={classes.table_wrapper}>
      <Table
        pagination={false}
        columns={tableCollumns}
        dataSource={dataSource}
        bordered={true}
        loading={isLoading}
        showHeader={true}
        scroll={{ x: 'max-content', y: '80vh' }}
        rowKey="dataKey"
      />
    </div>
  );
};

export default CommunityDetailsComparison;
