import React, { useEffect, useState } from 'react';

import { Button, Select, Space } from 'antd';

interface ComponentProps {
  placeholder?: string;
  options?: any[];
  loading?: boolean;
  initValue?: any[];
  onApply: (values: any[]) => void;
}

const SelectInput: React.FC<ComponentProps> = ({
  placeholder = 'Select',
  options = [],
  loading = false,
  initValue,
  onApply,
}) => {
  const [value, setValue] = useState<string[]>(initValue ? initValue : []);
  const handleSelectAll = () => {
    setValue(options);
  };
  const handleSelectNone = () => {
    setValue([]);
    onApply([]);
  };
  useEffect(() => {
    //
    if (!initValue) return;
    setValue(initValue);
  }, [initValue ? initValue : null]);
  return (
    <Select
      showSearch
      mode="multiple"
      value={value}
      options={options}
      onChange={(newValue: string[]) => {
        setValue(newValue);
      }}
      placeholder={placeholder}
      maxTagCount="responsive"
      style={{ width: '100%' }}
      listItemHeight={45}
      listHeight={600}
      onClear={() => onApply(value)}
      showArrow
      allowClear
      loading={loading}
      optionFilterProp="children"
      filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
      }
      dropdownRender={(menu) => (
        <div style={{ padding: '10px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            <Space>
              <Button size="small" onClick={handleSelectAll}>
                Select All
              </Button>
              <Button size="small" onClick={handleSelectNone}>
                Select None
              </Button>
            </Space>
            <Space>
              <Button size="small" onClick={() => onApply(value)}>
                Apply
              </Button>
            </Space>
          </div>
          <br />
          {menu}
        </div>
      )}
    />
  );
};

export default SelectInput;
