import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import axios from 'config/axiosPrivate';

import type { RootState } from '../index';

export interface UserType {
  user_id: number;
  name: string;
  email: string;
  isSuperuser: boolean;
  isStaff: boolean;
  groups: any[];
  company?: number;
  management_company?: any;
  ManagementcompanyName?: string;
  level?: string;
  avatar?: string;
  companyName?: string;
  PAUser_Id: any;
  all_permissions: string[];
}

interface AuthType {
  currentUser: UserType | null;
  refreshToken: string;
  accessToken: string;
}

// Define the initial state using that type
const initialState: AuthType = {
  currentUser: null,
  refreshToken: '',
  accessToken: '',
};

export const authSlice = createSlice({
  name: 'auth',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleLogin: (state, action: PayloadAction<AuthType>) => {
      state.currentUser = action.payload.currentUser;
      state.refreshToken = action.payload.refreshToken;
      state.accessToken = action.payload.accessToken;
    },

    handleUpdateAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
    handleUpdateAvatar: (state, action: PayloadAction<string>) => {
      if (state.currentUser) {
        state.currentUser = {
          ...state.currentUser,
          avatar: action.payload,
        };
      }
    },

    handleLogout: (state) => {
      const username = state.currentUser?.name;
      const formData = new FormData();
      formData.append('username', username || '');
      axios.post(`api/jwt/auth/logout/`, formData);

      state.currentUser = null;
      state.refreshToken = '';
      state.accessToken = '';
    },
  },
});

export const { handleLogin, handleLogout, handleUpdateAccessToken, handleUpdateAvatar } = authSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;
