import React, { useEffect, useState } from 'react';
import { geocodeByAddress } from 'react-places-autocomplete';
import { useNavigate } from 'react-router-dom';

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Button, Col, message, Row, Select, SelectProps, Table, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { format } from 'date-fns';

import axios from 'config/axiosPrivate';
import UnsavedChangesPrompt from 'modals/UnsavedChangesPrompt';
import { useAppSelector } from 'store/hook';

import classes from '../SwotSurvey/styles.module.scss';

interface MaticsDataType {
  serial_no: number;
  CompanyID: number;
  CompanyName: string;
  address: string;
  apartment_id: number;
  company: number;
  comunity: string;
  comunity_name: string;
  google_formatted_address: string;
  google_map_location: string;
  google_rating: number;
  google_reviews: number;
  id: number;
  latitude: string;
  longitude: string;
  management_company: number;
  primary_address: string;
  type: string;
}

interface ReviewsDataType {
  apartment: number;
  apartment_id: number;
  created_at: string;
  created_by: string;
  google_review_date: string;
  google_review_id: string;
  google_review_text: string;
  google_review_url: string;
  google_review_user: string;
  id: string;
  updated_at: string;
  updated_by: string;
}
interface GroupReviewsDataType {
  [key: string | number]: ReviewsDataType[];
}

const GoogleMatrixReviews = () => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const userCompanies = useAppSelector((state) => state.global.companyList);
  const [type, setType] = useState<string>('');
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [matricsDataSource, setMatricsDataSource] = useState<MaticsDataType[]>([]);
  const [reviews, setReviews] = useState<any | null>(null);
  const [metrics, setMetrics] = useState<any>(null);
  const [company, setCompany] = useState<number | null>(null);
  const [updates, setUpdates] = useState<any[]>([]);
  const [reviewsUpdates, setReviewsUpdates] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [filteredMatricsDataSource, setFilteredMatricsDataSource] = useState<MaticsDataType[]>([]);
  // const [companyList, setCompanyList] = useState<any[]>([]);
  const [companyOptions, setCompanyOptions] = useState<SelectProps['options']>(userCompanies);

  const fixRating = (rating: any | null) => {
    if (rating) {
      return Number(rating).toFixed(2);
    } else {
      return '';
    }
  };

  const columns: ColumnsType<MaticsDataType> = [
    Table.EXPAND_COLUMN,
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            Company
          </Typography.Title>
        </div>
      ),
      dataIndex: 'CompanyName',
      key: 'CompanyName',
      width: 100,
    },
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            Community
          </Typography.Title>
        </div>
      ),
      dataIndex: 'comunity_name',
      key: 'comunity_name',
      width: 200,
    },
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            Type
          </Typography.Title>
        </div>
      ),
      dataIndex: 'type',
      key: 'type',
      width: 100,
      render: (_, row) => (
        <div style={{ height: '100%', width: '100%' }}>
          <Typography.Paragraph style={{ fontSize: '14px' }}>
            {''}
            {row.type == 'apartment' ? 'Community' : 'Competitor'}
          </Typography.Paragraph>
        </div>
      ),
    },
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            Address
          </Typography.Title>
        </div>
      ),
      dataIndex: 'address',
      key: 'address',
      width: 300,
    },
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            Latitude
          </Typography.Title>
        </div>
      ),
      dataIndex: 'latitude',
      key: 'latitude',
      width: 100,
    },
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            Longitude
          </Typography.Title>
        </div>
      ),
      dataIndex: 'longitude',
      key: 'longitude',
      width: 100,
    },
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            Old Rating
          </Typography.Title>
        </div>
      ),
      dataIndex: 'google_rating',
      key: 'google_rating',
      render: (_, row) => (
        <div style={{ height: '100%', width: '100%' }}>
          <Typography.Paragraph style={{ fontSize: '14px' }}>
            {''}
            {fixRating(metrics[row.id] ? metrics[row.id][0].old_google_ratings : null)}
            {/* {metrics[row.id] ? metrics[row.id][0].old_google_ratings : ""} */}
          </Typography.Paragraph>
        </div>
      ),
    },
    {
      title: 'Old Reviews',
      dataIndex: 'google_reviews',
      key: 'google_reviews',
      render: (_, row) => (
        <div style={{ height: '100%', width: '100%' }}>
          <Typography.Paragraph style={{ fontSize: '14px' }}>
            {''}
            {metrics[row.id] ? metrics[row.id][0].old_google_reviews : ''}
          </Typography.Paragraph>
        </div>
      ),
    },
    {
      title: 'Rating',
      dataIndex: 'google_rating',
      key: 'google_rating',
      render: (_, row) => (
        <div style={{ height: '100%', width: '100%' }}>
          <Typography.Paragraph style={{ fontSize: '14px' }}>
            {''}
            {row.google_rating?.toFixed(2)}
          </Typography.Paragraph>
        </div>
      ),
    },
    {
      title: 'Reviews',
      dataIndex: 'google_reviews',
      key: 'google_reviews',
    },
    Table.SELECTION_COLUMN,
  ];
  const showMessage = (type: 'success' | 'error', mgs: string) => {
    messageApi.open({
      type: type,
      content: mgs,
    });
  };

  function groupByApartmentId(data: any[], key: any) {
    const groups: any = {};

    data.forEach(function (val) {
      const category = val[key];
      if (category) {
        if (category in groups) {
          groups[category].push(val);
        } else {
          groups[category] = new Array(val);
        }
      }
    });
    return groups;
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const getMatrics = (company_id: number) => {
    setDataLoading(true);
    axios
      .get(`/communities/Get_UserGoogleMetrics`)
      .then((res) => {
        const data = res.data.payload;
        if (data.Metrics.length > 0) {
          const grup__metrics = groupByApartmentId(data.Metrics, 'apartment_id');
          setMetrics(grup__metrics);
        }
        if (data.Communities.length > 0) {
          setMatricsDataSource(
            data.Communities.map((item: any, index: number) => ({
              serial_no: index,
              ...item,
            }))
          );
        } else {
          setMatricsDataSource([]);
        }
        setDataLoading(false);
      })
      .catch((err) => {
        setDataLoading(false);
      });
  };

  const getReviews = () => {
    // setDataLoading(true);
    axios
      .get(`/communities/Get_UserGoogleReviews`)
      .then((res) => {
        const data = res.data.payload;
        if (data.Reviews.length > 0) {
          const grup__reviews = groupByApartmentId(data.Reviews, 'apartment_id');
          setReviews(grup__reviews);
        }
      })
      .catch((err) => {
        // setDataLoading(false);
      });
  };

  const saveGoogleMatrics = () => {
    setLoading(true);
    saveGoogleReviews();
    axios
      .post(`communities/Save_GoogleMetrics/`, { data: updates })
      .then((res) => {
        setLoading(false);
        showMessage('success', 'Saved successfully!');
        getMatrics(0);
        setUpdates([]);
        setSelectedRowKeys([]);
      })
      .catch((err) => {
        setLoading(false);
        showMessage('error', 'Something went wrong');
      });
  };
  const saveGoogleReviews = () => {
    if (reviewsUpdates.length < 1) {
      return;
    }
    axios
      .post(`communities/Save_GoogleReviews/`, { data: reviewsUpdates })
      .then((res) => {
        getReviews();
        setReviewsUpdates([]);
      })
      .catch((err) => {
        setLoading(false);
        showMessage('error', 'Something went wrong');
      });
  };

  const getGoogleMatrics = () => {
    // setDataLoading(true);
    const updates: any = [];
    let reviewUpdates: any = [];
    let place_id: any = null;
    let count = 0;

    const service = new google.maps.places.PlacesService(document.createElement('div'));
    selectedRowKeys.forEach(async (key: any, index: number) => {
      if (matricsDataSource[key].google_map_location) {
        place_id = matricsDataSource[key].google_map_location;
      } else {
        try {
          const results = await geocodeByAddress(matricsDataSource[key].comunity_name);
          place_id = results[0]?.place_id || '';
        } catch (e) {
          count++;

          return;
        }
      }
      try {
        service.getDetails(
          {
            placeId: place_id,
          },
          async function callback(place: any, status: any) {
            if (status == "INVALID_REQUEST") {
              try {
                const results = await geocodeByAddress(matricsDataSource[key].comunity_name);
                place_id = results[0]?.place_id || '';
                service.getDetails(
                  {
                    placeId: place_id,
                  },
                  callback
                );
                return;
              } catch (e) {}
            }
            count++;
            if (place) {
              updates.push({
                id: 1,
                apartment_id: matricsDataSource[key].id,
                old_google_ratings: matricsDataSource[key].google_rating,
                old_google_reviews: matricsDataSource[key].google_reviews,
                op: 'added',
                google_ratings: place.rating || 0,
                google_reviews: place.user_ratings_total || 0,
              });
              matricsDataSource[key].google_rating = place.rating;
              matricsDataSource[key].google_reviews = place.user_ratings_total || 0;

              const placeReviews = place.reviews?.map((_r: any) => ({
                id: 1,
                apartment_id: matricsDataSource[key].id,
                google_review_id: '12345678',
                google_review_user: _r.author_name,
                google_review_date: new Date(_r.time * 1000),
                google_review_text: _r.text,
                google_review_url: _r.author_url,
                op: 'added',
              }));
              reviewUpdates = [...reviewUpdates, ...(placeReviews || [])];
              if (reviews) {
                reviews[matricsDataSource[key].id] = placeReviews || [];
              } else {
                const obj: any = {};
                obj[matricsDataSource[key].id] = placeReviews || [];
                setReviews(obj);
              }
            }

            if (count == selectedRowKeys.length) {
              setUpdates(updates);
              setReviewsUpdates(reviewUpdates);
            }
          }
        );
        // setDataLoading(false);
      } catch (err) {
        // setDataLoading(false);
      }
    });
  };

  // const filterData = (val:number){
  //   const filterRecord=matricsDataSource.filter(_d=>_d.CompanyID==val)
  // }
  useEffect(() => {
    if (company || type) {
      const filterRecord = matricsDataSource.filter((_d) => {
        if (company && type) {
          return _d.CompanyID == company && _d.type == type;
        } else if (company) {
          return _d.CompanyID == company;
        } else {
          return _d.type == type;
        }
      });
      setFilteredMatricsDataSource(filterRecord.reverse());
    } else if (matricsDataSource.length > 0) {
      setFilteredMatricsDataSource(matricsDataSource);
    }
  }, [company, type, matricsDataSource]);
  useEffect(() => {
    //
    // setCompanyOptions(userCompanies)
    getMatrics(0);
    getReviews();
  }, []);

  return (
    <div>
      {contextHolder}
      <UnsavedChangesPrompt unsaved={updates.length > 0} />
      <div
        style={{
          padding: '20px 30px 0px',
        }}
      >
        <div>
          <span>
            <span style={{ color: '#1f58b0', cursor: 'pointer' }} onClick={() => navigate('/')}>
              HOME
            </span>
            <span
              style={{
                color: '#222',
                margin: '0px 10px',
                textTransform: 'uppercase',
              }}
            >
              Google Metrics | Google Reviews
            </span>
          </span>
        </div>
      </div>
      <div>
        <div className={classes.survey__text}>
          <span>Google Metrics</span>
          <br />
          {/* <label>Compare your community to a competitor’s.</label> */}
        </div>
      </div>
      <div
        style={{
          padding: '15px 15px 15px 15px',
          // width: "1191px",
          margin: '20px auto',
          // marginBottom: 10,
        }}
      >
        <Row gutter={[16, 16]}>
          <Col offset={4} xs={24} lg={6}>
            <Select
              showSearch
              optionFilterProp="children"
              filterOption={(input: any, option: any) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={companyOptions}
              placeholder="Company"
              style={{ width: '100%' }}
              onSelect={(val) => {
                setCompany(val);
                // getMatrics(val);
              }}
            />
            {/* <SelectInput
              placeholder="Company"
              options={companyOptions}
              onApply={(value) => {
                setCompanyList(value);
              }}
              // loading={loading}
              initValue={companyList}
            /> */}
          </Col>
          <Col xs={24} lg={6}>
            <Select
              options={[
                {
                  label: 'Community',
                  value: 'apartment',
                },
                {
                  label: 'Competitor',
                  value: 'competitor',
                },
              ]}
              placeholder="Type"
              style={{ width: '100%' }}
              onSelect={(val) => setType(val)}
            />
          </Col>
          <Col xs={24} lg={8}>
            <Button
              style={{ float: 'right', padding: '0 32px', marginRight: '15px' }}
              onClick={getGoogleMatrics}
              disabled={!hasSelected}
            >
              Get Metrics
            </Button>
          </Col>
        </Row>
      </div>
      <Table
        dataSource={filteredMatricsDataSource}
        columns={columns}
        loading={dataLoading}
        expandable={{
          expandedRowRender: (record) => (
            <div style={{ margin: '0 30px 20px' }}>
              <Row gutter={[16, 16]}>
                {/* <Col span={4}>
                  <h4>Id</h4>
                </Col> */}
                <Col span={4}>
                  <h4>Author</h4>
                </Col>
                <Col span={10}>
                  <h4>Text</h4>
                </Col>
                <Col span={6}>
                  <h4>Url</h4>
                </Col>
                <Col span={4}>
                  <h4>Date</h4>
                </Col>
              </Row>
              {reviews[record.id].map((item: any, index: any) => (
                <Row key={'id' + index} gutter={[16, 16]}>
                  {/* <Col span={4}>
                    <p>{item.google_review_id}</p>
                  </Col> */}
                  <Col span={4}>
                    <p>{item.google_review_user}</p>
                  </Col>
                  <Col span={10}>
                    <Typography.Paragraph ellipsis={{ rows: 6, expandable: true, symbol: 'more' }}>
                      {item.google_review_text}
                    </Typography.Paragraph>
                    {/* <p>{item.google_review_text}</p> */}
                  </Col>
                  <Col span={6}>
                    <p>{item.google_review_url}</p>
                  </Col>
                  <Col span={4}>
                    {/* <p>{item.google_review_date.toString()}</p> */}
                    <p>{format(new Date(item.google_review_date), 'MM-dd-yyyy:hh:mm:ss')}</p>
                  </Col>
                </Row>
              ))}
            </div>
          ),
          rowExpandable: (record) => (reviews && reviews[record.id] ? true : false),
          expandIcon: ({ expanded, onExpand, record }) => (
            <>
              {reviews && reviews[record.id] ? (
                <div>
                  {expanded ? (
                    <CaretUpOutlined onClick={(e) => onExpand(record, e)} />
                  ) : (
                    <CaretDownOutlined onClick={(e) => onExpand(record, e)} />
                  )}
                </div>
              ) : null}
            </>
          ),
        }}
        // pagination={false}
        rowKey="serial_no"
        rowSelection={rowSelection}
      />
      <Button
        type="primary"
        onClick={saveGoogleMatrics}
        loading={loading}
        disabled={updates.length < 1}
        style={{ float: 'right', marginRight: '20px', marginTop: '20px' }}
      >
        Save
      </Button>
    </div>
  );
};

export default GoogleMatrixReviews;
