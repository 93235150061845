import React, { useRef, useState } from 'react';
import type { DraggableData, DraggableEvent } from 'react-draggable';
import Draggable from 'react-draggable';

import { CloseCircleFilled } from '@ant-design/icons';
import { Button, Image, Modal, Typography } from 'antd';

interface GalleryProps {
  url: string;
  title: string;
}

const GalleryPopup: React.FC<GalleryProps> = ({ url, title }) => {
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef<HTMLDivElement>(null);

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = (e: React.MouseEvent<HTMLElement>) => {
    //
    setOpen(false);
  };

  const handleCancel = (e: React.MouseEvent<HTMLElement>) => {
    //
    setOpen(false);
  };

  const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  return (
    <>
      <Image onClick={showModal} src={url} width={150} height={110} preview={false} />
      <Modal
        title={
          <div
            style={{
              width: '100%',
              cursor: 'move',
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
            // fix eslintjsx-a11y/mouse-events-have-key-events
            // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
            onFocus={() => {
              // Does nothing
            }}
            onBlur={() => {
              // Does nothing
            }}
            // end
          >
            <Typography.Title level={4} style={{ margin: '0px', padding: '10px 15px' }}>
              {title}
            </Typography.Title>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                icon={<CloseCircleFilled />}
                shape="circle"
                type="primary"
                style={{
                  position: 'absolute',
                  top: '-15px',
                  right: '-15px',
                  zIndex: 99,
                }}
                onClick={() => setOpen(false)}
              />
            </div>
          </div>
        }
        closable={false}
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        // bilal
        maskClosable={false}
        mask={false}
        modalRender={(modal) => (
          <Draggable disabled={disabled} bounds={bounds} onStart={(event, uiData) => onStart(event, uiData)}>
            <div ref={draggleRef} className="img__modal">
              {modal}
            </div>
          </Draggable>
        )}
        width={'700px'}
        footer={false}
        bodyStyle={{
          padding: '0px',
        }}
        style={{
          padding: 0,
        }}
      >
        <div
          style={{
            position: 'relative',
            borderRadius: '5px',
            overflow: 'hidden',
          }}
        >
          <Image src={url} width={'100%'} height={560} preview={false} />
        </div>
      </Modal>
    </>
  );
};

export default GalleryPopup;
