import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button, Col, Form, message, Radio, Row, Select, SelectProps, Spin } from 'antd';

import axios from 'config/axiosPrivate';
import UnsavedChangesPrompt from 'modals/UnsavedChangesPrompt';
import { useAppSelector } from 'store/hook';

import classes from './styles.module.scss';
let formFields = [
  {
    id: 1,
    title: 'Apartment Amenities',
    fields: [
      { name: 'Apartment Size Options', value: 3, key: 'aa_apartment_size' },
      { name: 'Apartment Flooring', value: 3, key: 'aa_apartment_flooring' },
      {
        name: 'Kitchen Cabinets/Countertops',
        value: 3,
        key: 'aa_kitchen_cabinets_countertops',
      },
      { name: 'Apartment Views', value: 3, key: 'aa_apartment_views' },
      { name: 'Apartment Lighting', value: 3, key: 'aa_apartment_lighting' },
      {
        name: 'Washer/Dryer/Laundry Options',
        value: 3,
        key: 'aa_washer_dryer_laundry',
      },
      {
        name: 'Cable/Internet Access',
        value: 3,
        key: 'aa_cable_internet_access',
      },
    ],
  },
  {
    id: 2,
    title: 'Community Amenities',
    fields: [
      {
        name: 'Lobby/Hallway Aesthetics',
        value: 3,
        key: 'ca_lobby_hallway_aesthetics',
      },
      {
        name: 'Fitness/Exercise Space',
        value: 3,
        key: 'ca_fitness_exercise_space',
      },
      {
        name: 'On-Site Physical Therapy',
        value: 3,
        key: 'ca_onsite_physical_therapy',
      },
      {
        name: 'Outdoor Amenities (Pool, Sitting Areas, Shuffleboard, etc)',
        value: 3,
        key: 'ca_outdoor_amenities',
      },
      {
        name: 'Serene Outdoor Space/Walking Paths',
        value: 3,
        key: 'ca_serene_outdoor_space',
      },
      {
        name: 'Indoor Amenities (Theatre, Bar, Bistro, etc)',
        value: 3,
        key: 'ca_indoor_amenities',
      },
      {
        name: 'Laundry Room',
        value: 3,
        key: 'ca_laundry_room',
      },
      { name: 'Pet Friendliness', value: 3, key: 'ca_pet_friendliness' },
    ],
  },
  {
    id: 3,
    title: 'Dining Experience',
    fields: [
      { name: 'Service Experience', value: 3, key: 'de_service_experience' },
      {
        name: 'Multiple Dining Venues',
        value: 3,
        key: 'de_multiple_dining_venues',
      },
      {
        name: 'Menu Options',
        value: 3,
        key: 'de_menu_options',
      },
      { name: 'Food Quality', value: 3, key: 'de_food_quality' },
      { name: 'Dining Hours', value: 3, key: 'de_dining_hours' },
    ],
  },
  {
    id: 4,
    title: 'Care Services',
    fields: [
      {
        name: 'Breadth of Clinical Services (Continuum of Care, Specialized Care)',
        value: 3,
        key: 'cs_breadth_of_clinical_services',
      },
      {
        name: 'Nursing Coverage (RN, LVN, LPN, etc)',
        value: 3,
        key: 'cs_nursing_coverage',
      },
      {
        name: 'State Survey Results',
        value: 3,
        key: 'cs_state_survey_results',
      },
      {
        name: 'Levels of Care/All Inclusive',
        value: 3,
        key: 'cs_levels_of_care',
      },
      { name: 'Care Staff Ratio', value: 3, key: 'cs_care_staff_ratio' },
    ],
  },
  {
    id: 5,
    title: 'Activity Program',
    fields: [
      {
        name: 'Diversity of Programming',
        value: 3,
        key: 'ap_diversity_of_programming',
      },
      { name: 'Outings (Quality/Quantity)', value: 3, key: 'ap_outings' },
      {
        name: 'Unique Programming',
        value: 3,
        key: 'ap_unique_programming',
      },
      {
        name: 'Physical/Exercise Programming',
        value: 3,
        key: 'ap_physical_exercise_programming',
      },
      {
        name: 'Transportation Services',
        value: 3,
        key: 'ap_transportation_services',
      },
      {
        name: 'Spiritual/Religious Programming',
        value: 3,
        key: 'ap_spiritual_religious_programming',
      },
    ],
  },
  {
    id: 6,
    title: 'Reputation',
    fields: [
      {
        name: 'General Reputation',
        value: 3,
        key: 'reputation_general',
      },
      { name: 'Google Rating', value: 3, key: 'reputation_google_rating' },
      {
        name: 'A Place for Mom Rating',
        value: 3,
        key: 'reputation_place_for_mom_rating',
      },
      { name: 'Caring.com', value: 3, key: 'reputation_caring_com' },
    ],
  },
  {
    id: 7,
    title: "Community 'Feel'",
    fields: [
      {
        name: 'First Impressions',
        value: 3,
        key: 'community_feel_first_impressions',
      },
      {
        name: 'Front Desk Experience',
        value: 3,
        key: 'community_feel_front_desk_experience',
      },
      {
        name: 'Associate Engagement (Friendliness)',
        value: 3,
        key: 'community_feel_associate_engagement',
      },
      {
        name: 'Associate Knowledge',
        value: 3,
        key: 'community_feel_associate_knowledge',
      },
      {
        name: 'Associate Tenure',
        value: 3,
        key: 'community_feel_associate_tenure',
      },
    ],
  },
  {
    id: 8,
    title: 'Location',
    fields: [
      {
        name: 'Proximity to Healthcare Services',
        value: 3,
        key: 'location_healthcare_services',
      },
      {
        name: 'Proximity to Shopping Options',
        value: 3,
        key: 'location_shopping_options',
      },
    ],
  },
  {
    id: 9,
    title: 'Pricing Options',
    fields: [
      {
        name: 'Competitive Pricing',
        value: 3,
        key: 'po_competitive_pricing',
      },
      {
        name: 'Access to Government Funding (Medicaid, State Aid,etc)',
        value: 3,
        key: 'po_access_to_government_funding',
      },
      {
        name: 'Care Pricing',
        value: 3,
        key: 'po_care_pricing',
      },
      {
        name: 'Reaccuring Fees',
        value: 3,
        key: 'po_reaccuring_fees',
      },
    ],
  },
  {
    id: 10,
    title: 'Internet/Social Media Presence',
    fields: [
      {
        name: 'SEO Ranking on Key Search Terms (Senior Housing, AL, MC,etc)',
        value: 3,
        key: 'internet_seo_ranking',
      },
      { name: 'Website Design', value: 3, key: 'internet_website_design' },
      {
        name: 'Social Media Presence',
        value: 3,
        key: 'internet_social_media',
      },
      {
        name: 'Email Marketing Campaigns',
        value: 3,
        key: 'internet_email_marketing_campaigns',
      },
      {
        name: 'Community Pictures/Videos',
        value: 3,
        key: 'internet_community_pictures_videos',
      },
      {
        name: 'Access to Virtual Tour',
        value: 3,
        key: 'internet_access_to_virtual_tour',
      },
      {
        name: 'Website Navigation',
        value: 3,
        key: 'internet_website_navigation',
      },
      {
        name: 'Traditional Marketing',
        value: 3,
        key: 'internet_traditional_marketing',
      },
      {
        name: 'Website Content',
        value: 3,
        key: 'internet_website_content',
      },
    ],
  },
];
const SwotSurvey = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const strengthFinder_id = params.get('id') as string;
  const community_id = params.get('cid') as string;
  const userInfo = useAppSelector((state) => state.auth.currentUser);
  const [formValues, setFormValues] = useState(formFields);
  const [selectedCommunity, setSetselectedCommunity] = useState<any>(null);
  const [selectedCompetitor, setSelectedCompetitor] = useState<any>(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [data_loading, setDataLoading] = useState(true);
  const [unsaved, setUnSaved] = useState<boolean>(false);

  const [communityOptions, setCommunityOptions] = useState<SelectProps['options']>([]);
  const [competitorOptions, setCompetitorOptions] = useState<SelectProps['options']>([]);

  const getStrengthFinder = () => {
    setDataLoading(true);
    axios
      .get(`/communities/Get_StrengthFinderRec/${strengthFinder_id}/`)
      .then((res) => {
        //
        setDataLoading(false);
        if (res.data.payload && res.data?.payload?.StrengthFinder && res.data?.payload?.StrengthFinder?.length > 0) {
          const data: any = res.data?.payload?.StrengthFinder[0];
          const cp: any[] = [...formValues];

          const updated_data = cp.map((field) => {
            const updated_fields = field.fields?.map((row: any) => ({
              ...row,
              value: data[row.key],
            }));

            field.fields = updated_fields;

            return field;
          });

          const active_cm = communityOptions?.find((c: any) => `${c.Id}` === `${data?.MyCommunityId}`);
          const active_cmp = competitorOptions?.find((c: any) => `${c.Id}` === `${data?.CompetitorId}`);

          if (active_cm) {
            setSetselectedCommunity(active_cm);
          }
          if (active_cmp) {
            setSelectedCompetitor(active_cmp);
          }
          setFormValues(updated_data);
        }
      })
      .catch((err) => {
        setDataLoading(false);
      });
  };

  useQuery({
    queryKey: `community__competitor_list`,
    queryFn: () =>
      axios
        .post('/communities/Get_FullHomeCommunities/', {
          comm_list: [],
          caretype_list: [],
          company_list: [],
        })
        .then((res) => res.data?.payload),
    onSuccess: (data) => {
      const community_options = data?.Community?.map((ele: any) => ({
        label: ele?.apartmentname,
        value: ele?.Id,
        ...ele,
      }));
      const competitor_options = data?.Competitor?.map((ele: any) => ({
        label: ele?.apartmentname,
        value: ele?.Id,
        ...ele,
      }));

      setCommunityOptions(community_options);
      setCompetitorOptions(competitor_options);
      if (params.get('cid')) {
        const cid = params.get('cid');
        const community = community_options.filter((item: any) => item.Id == cid);
        if (community.length > 0) {
          setSetselectedCommunity(community[0]);
        }
      }
    },
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (strengthFinder_id && communityOptions && communityOptions?.length > 0) {
      getStrengthFinder();
    }
  }, [strengthFinder_id, communityOptions]);
  useEffect(() => {
    if (!params.get('id')) {
      formFields = [
        {
          id: 1,
          title: 'Apartment Amenities',
          fields: [
            {
              name: 'Apartment Size Options',
              value: 3,
              key: 'aa_apartment_size',
            },
            {
              name: 'Apartment Flooring',
              value: 3,
              key: 'aa_apartment_flooring',
            },
            {
              name: 'Kitchen Cabinets/Countertops',
              value: 3,
              key: 'aa_kitchen_cabinets_countertops',
            },
            { name: 'Apartment Views', value: 3, key: 'aa_apartment_views' },
            {
              name: 'Apartment Lighting',
              value: 3,
              key: 'aa_apartment_lighting',
            },
            {
              name: 'Washer/Dryer/Laundry Options',
              value: 3,
              key: 'aa_washer_dryer_laundry',
            },
            {
              name: 'Cable/Internet Access',
              value: 3,
              key: 'aa_cable_internet_access',
            },
          ],
        },
        {
          id: 2,
          title: 'Community Amenities',
          fields: [
            {
              name: 'Lobby/Hallway Aesthetics',
              value: 3,
              key: 'ca_lobby_hallway_aesthetics',
            },
            {
              name: 'Fitness/Exercise Space',
              value: 3,
              key: 'ca_fitness_exercise_space',
            },
            {
              name: 'On-Site Physical Therapy',
              value: 3,
              key: 'ca_onsite_physical_therapy',
            },
            {
              name: 'Outdoor Amenities (Pool, Sitting Areas, Shuffleboard, etc)',
              value: 3,
              key: 'ca_outdoor_amenities',
            },
            {
              name: 'Serene Outdoor Space/Walking Paths',
              value: 3,
              key: 'ca_serene_outdoor_space',
            },
            {
              name: 'Indoor Amenities (Theatre, Bar, Bistro, etc)',
              value: 3,
              key: 'ca_indoor_amenities',
            },
            {
              name: 'Laundry Room',
              value: 3,
              key: 'ca_laundry_room',
            },
            { name: 'Pet Friendliness', value: 3, key: 'ca_pet_friendliness' },
          ],
        },
        {
          id: 3,
          title: 'Dining Experience',
          fields: [
            {
              name: 'Service Experience',
              value: 3,
              key: 'de_service_experience',
            },
            {
              name: 'Multiple Dining Venues',
              value: 3,
              key: 'de_multiple_dining_venues',
            },
            {
              name: 'Menu Options',
              value: 3,
              key: 'de_menu_options',
            },
            { name: 'Food Quality', value: 3, key: 'de_food_quality' },
            { name: 'Dining Hours', value: 3, key: 'de_dining_hours' },
          ],
        },
        {
          id: 4,
          title: 'Care Services',
          fields: [
            {
              name: 'Breadth of Clinical Services (Continuum of Care, Specialized Care)',
              value: 3,
              key: 'cs_breadth_of_clinical_services',
            },
            {
              name: 'Nursing Coverage (RN, LVN, LPN, etc)',
              value: 3,
              key: 'cs_nursing_coverage',
            },
            {
              name: 'State Survey Results',
              value: 3,
              key: 'cs_state_survey_results',
            },
            {
              name: 'Levels of Care/All Inclusive',
              value: 3,
              key: 'cs_levels_of_care',
            },
            { name: 'Care Staff Ratio', value: 3, key: 'cs_care_staff_ratio' },
          ],
        },
        {
          id: 5,
          title: 'Activity Program',
          fields: [
            {
              name: 'Diversity of Programming',
              value: 3,
              key: 'ap_diversity_of_programming',
            },
            { name: 'Outings (Quality/Quantity)', value: 3, key: 'ap_outings' },
            {
              name: 'Unique Programming',
              value: 3,
              key: 'ap_unique_programming',
            },
            {
              name: 'Physical/Exercise Programming',
              value: 3,
              key: 'ap_physical_exercise_programming',
            },
            {
              name: 'Transportation Services',
              value: 3,
              key: 'ap_transportation_services',
            },
            {
              name: 'Spiritual/Religious Programming',
              value: 3,
              key: 'ap_spiritual_religious_programming',
            },
          ],
        },
        {
          id: 6,
          title: 'Reputation',
          fields: [
            {
              name: 'General Reputation',
              value: 3,
              key: 'reputation_general',
            },
            {
              name: 'Google Rating',
              value: 3,
              key: 'reputation_google_rating',
            },
            {
              name: 'A Place for Mom Rating',
              value: 3,
              key: 'reputation_place_for_mom_rating',
            },
            { name: 'Caring.com', value: 3, key: 'reputation_caring_com' },
          ],
        },
        {
          id: 7,
          title: "Community 'Feel'",
          fields: [
            {
              name: 'First Impressions',
              value: 3,
              key: 'community_feel_first_impressions',
            },
            {
              name: 'Front Desk Experience',
              value: 3,
              key: 'community_feel_front_desk_experience',
            },
            {
              name: 'Associate Engagement (Friendliness)',
              value: 3,
              key: 'community_feel_associate_engagement',
            },
            {
              name: 'Associate Knowledge',
              value: 3,
              key: 'community_feel_associate_knowledge',
            },
            {
              name: 'Associate Tenure',
              value: 3,
              key: 'community_feel_associate_tenure',
            },
          ],
        },
        {
          id: 8,
          title: 'Location',
          fields: [
            {
              name: 'Proximity to Healthcare Services',
              value: 3,
              key: 'location_healthcare_services',
            },
            {
              name: 'Proximity to Shopping Options',
              value: 3,
              key: 'location_shopping_options',
            },
          ],
        },
        {
          id: 9,
          title: 'Pricing Options',
          fields: [
            {
              name: 'Competitive Pricing',
              value: 3,
              key: 'po_competitive_pricing',
            },
            {
              name: 'Access to Government Funding (Medicaid, State Aid,etc)',
              value: 3,
              key: 'po_access_to_government_funding',
            },
            {
              name: 'Care Pricing',
              value: 3,
              key: 'po_care_pricing',
            },
            {
              name: 'Reaccuring Fees',
              value: 3,
              key: 'po_reaccuring_fees',
            },
          ],
        },
        {
          id: 10,
          title: 'Internet/Social Media Presence',
          fields: [
            {
              name: 'SEO Ranking on Key Search Terms (Senior Housing, AL, MC,etc)',
              value: 3,
              key: 'internet_seo_ranking',
            },
            {
              name: 'Website Design',
              value: 3,
              key: 'internet_website_design',
            },
            {
              name: 'Social Media Presence',
              value: 3,
              key: 'internet_social_media',
            },
            {
              name: 'Email Marketing Campaigns',
              value: 3,
              key: 'internet_email_marketing_campaigns',
            },
            {
              name: 'Community Pictures/Videos',
              value: 3,
              key: 'internet_community_pictures_videos',
            },
            {
              name: 'Access to Virtual Tour',
              value: 3,
              key: 'internet_access_to_virtual_tour',
            },
            {
              name: 'Website Navigation',
              value: 3,
              key: 'internet_website_navigation',
            },
            {
              name: 'Traditional Marketing',
              value: 3,
              key: 'internet_traditional_marketing',
            },
            {
              name: 'Website Content',
              value: 3,
              key: 'internet_website_content',
            },
          ],
        },
      ];
      setFormValues(formFields);
      setDataLoading(false);
    }
  }, []);

  const onFinish = (values: any) => {
    //
  };

  type Props = {
    title: string;
    textAlign?: 'left' | 'center' | 'right';
  };

  const CategoryTitleBar = ({ title, textAlign = 'center' }: Props) => {
    return (
      <div className={classes.title__bar}>
        <label>{title}</label>
      </div>
    );
  };

  const updateFormInput = (id: number, key: string, value: number) => {
    const cp: any[] = [...formValues];

    const field_index = cp.findIndex((f) => f.id === id);
    if (field_index !== -1) {
      const target_field = cp[field_index];

      const target_row_index = target_field.fields.findIndex((row: any) => row.key === key);
      if (target_row_index !== -1) {
        const target_row = target_field.fields[target_row_index];
        target_row.value = value;
        setFormValues(cp);
      }
    }
  };

  const [loading, setLoading] = useState(false);
  const handleSave = () => {
    let payload = {
      id: parseInt(strengthFinder_id) || 1,
      timestamp: new Date().toISOString(),
      email_address: userInfo?.email,
      my_community_id: selectedCommunity?.Id,
      my_community_name: selectedCommunity?.apartmentname,
      competitor_id: selectedCompetitor?.Id,
      competitor_name: selectedCompetitor?.apartmentname,
      company_id: userInfo?.company,
      management_company_id: userInfo?.management_company || 1,
      op: strengthFinder_id ? 'edited' : 'added',
    };

    formFields.forEach((field) => {
      field.fields.forEach((row) => {
        payload = {
          ...payload,
          [row.key]: row.value,
        };
      });
    });
    setLoading(true);
    axios
      .post('/communities/Save_StrengthFinder/', { data: [payload] })
      .then((res) => {
        setLoading(false);
        setUnSaved(false);
        showMessage('success', 'Data saved successfully');
        formFields = [
          {
            id: 1,
            title: 'Apartment Amenities',
            fields: [
              {
                name: 'Apartment Size Options',
                value: 3,
                key: 'aa_apartment_size',
              },
              {
                name: 'Apartment Flooring',
                value: 3,
                key: 'aa_apartment_flooring',
              },
              {
                name: 'Kitchen Cabinets/Countertops',
                value: 3,
                key: 'aa_kitchen_cabinets_countertops',
              },
              { name: 'Apartment Views', value: 3, key: 'aa_apartment_views' },
              {
                name: 'Apartment Lighting',
                value: 3,
                key: 'aa_apartment_lighting',
              },
              {
                name: 'Washer/Dryer/Laundry Options',
                value: 3,
                key: 'aa_washer_dryer_laundry',
              },
              {
                name: 'Cable/Internet Access',
                value: 3,
                key: 'aa_cable_internet_access',
              },
            ],
          },
          {
            id: 2,
            title: 'Community Amenities',
            fields: [
              {
                name: 'Lobby/Hallway Aesthetics',
                value: 3,
                key: 'ca_lobby_hallway_aesthetics',
              },
              {
                name: 'Fitness/Exercise Space',
                value: 3,
                key: 'ca_fitness_exercise_space',
              },
              {
                name: 'On-Site Physical Therapy',
                value: 3,
                key: 'ca_onsite_physical_therapy',
              },
              {
                name: 'Outdoor Amenities (Pool, Sitting Areas, Shuffleboard, etc)',
                value: 3,
                key: 'ca_outdoor_amenities',
              },
              {
                name: 'Serene Outdoor Space/Walking Paths',
                value: 3,
                key: 'ca_serene_outdoor_space',
              },
              {
                name: 'Indoor Amenities (Theatre, Bar, Bistro, etc)',
                value: 3,
                key: 'ca_indoor_amenities',
              },
              {
                name: 'Laundry Room',
                value: 3,
                key: 'ca_laundry_room',
              },
              {
                name: 'Pet Friendliness',
                value: 3,
                key: 'ca_pet_friendliness',
              },
            ],
          },
          {
            id: 3,
            title: 'Dining Experience',
            fields: [
              {
                name: 'Service Experience',
                value: 3,
                key: 'de_service_experience',
              },
              {
                name: 'Multiple Dining Venues',
                value: 3,
                key: 'de_multiple_dining_venues',
              },
              {
                name: 'Menu Options',
                value: 3,
                key: 'de_menu_options',
              },
              { name: 'Food Quality', value: 3, key: 'de_food_quality' },
              { name: 'Dining Hours', value: 3, key: 'de_dining_hours' },
            ],
          },
          {
            id: 4,
            title: 'Care Services',
            fields: [
              {
                name: 'Breadth of Clinical Services (Continuum of Care, Specialized Care)',
                value: 3,
                key: 'cs_breadth_of_clinical_services',
              },
              {
                name: 'Nursing Coverage (RN, LVN, LPN, etc)',
                value: 3,
                key: 'cs_nursing_coverage',
              },
              {
                name: 'State Survey Results',
                value: 3,
                key: 'cs_state_survey_results',
              },
              {
                name: 'Levels of Care/All Inclusive',
                value: 3,
                key: 'cs_levels_of_care',
              },
              {
                name: 'Care Staff Ratio',
                value: 3,
                key: 'cs_care_staff_ratio',
              },
            ],
          },
          {
            id: 5,
            title: 'Activity Program',
            fields: [
              {
                name: 'Diversity of Programming',
                value: 3,
                key: 'ap_diversity_of_programming',
              },
              {
                name: 'Outings (Quality/Quantity)',
                value: 3,
                key: 'ap_outings',
              },
              {
                name: 'Unique Programming',
                value: 3,
                key: 'ap_unique_programming',
              },
              {
                name: 'Physical/Exercise Programming',
                value: 3,
                key: 'ap_physical_exercise_programming',
              },
              {
                name: 'Transportation Services',
                value: 3,
                key: 'ap_transportation_services',
              },
              {
                name: 'Spiritual/Religious Programming',
                value: 3,
                key: 'ap_spiritual_religious_programming',
              },
            ],
          },
          {
            id: 6,
            title: 'Reputation',
            fields: [
              {
                name: 'General Reputation',
                value: 3,
                key: 'reputation_general',
              },
              {
                name: 'Google Rating',
                value: 3,
                key: 'reputation_google_rating',
              },
              {
                name: 'A Place for Mom Rating',
                value: 3,
                key: 'reputation_place_for_mom_rating',
              },
              { name: 'Caring.com', value: 3, key: 'reputation_caring_com' },
            ],
          },
          {
            id: 7,
            title: "Community 'Feel'",
            fields: [
              {
                name: 'First Impressions',
                value: 3,
                key: 'community_feel_first_impressions',
              },
              {
                name: 'Front Desk Experience',
                value: 3,
                key: 'community_feel_front_desk_experience',
              },
              {
                name: 'Associate Engagement (Friendliness)',
                value: 3,
                key: 'community_feel_associate_engagement',
              },
              {
                name: 'Associate Knowledge',
                value: 3,
                key: 'community_feel_associate_knowledge',
              },
              {
                name: 'Associate Tenure',
                value: 3,
                key: 'community_feel_associate_tenure',
              },
            ],
          },
          {
            id: 8,
            title: 'Location',
            fields: [
              {
                name: 'Proximity to Healthcare Services',
                value: 3,
                key: 'location_healthcare_services',
              },
              {
                name: 'Proximity to Shopping Options',
                value: 3,
                key: 'location_shopping_options',
              },
            ],
          },
          {
            id: 9,
            title: 'Pricing Options',
            fields: [
              {
                name: 'Competitive Pricing',
                value: 3,
                key: 'po_competitive_pricing',
              },
              {
                name: 'Access to Government Funding (Medicaid, State Aid,etc)',
                value: 3,
                key: 'po_access_to_government_funding',
              },
              {
                name: 'Care Pricing',
                value: 3,
                key: 'po_care_pricing',
              },
              {
                name: 'Reaccuring Fees',
                value: 3,
                key: 'po_reaccuring_fees',
              },
            ],
          },
          {
            id: 10,
            title: 'Internet/Social Media Presence',
            fields: [
              {
                name: 'SEO Ranking on Key Search Terms (Senior Housing, AL, MC,etc)',
                value: 3,
                key: 'internet_seo_ranking',
              },
              {
                name: 'Website Design',
                value: 3,
                key: 'internet_website_design',
              },
              {
                name: 'Social Media Presence',
                value: 3,
                key: 'internet_social_media',
              },
              {
                name: 'Email Marketing Campaigns',
                value: 3,
                key: 'internet_email_marketing_campaigns',
              },
              {
                name: 'Community Pictures/Videos',
                value: 3,
                key: 'internet_community_pictures_videos',
              },
              {
                name: 'Access to Virtual Tour',
                value: 3,
                key: 'internet_access_to_virtual_tour',
              },
              {
                name: 'Website Navigation',
                value: 3,
                key: 'internet_website_navigation',
              },
              {
                name: 'Traditional Marketing',
                value: 3,
                key: 'internet_traditional_marketing',
              },
              {
                name: 'Website Content',
                value: 3,
                key: 'internet_website_content',
              },
            ],
          },
        ];

        setTimeout(() => {
          navigate('/swot-survey-list');
        }, 1000);
      })
      .catch((err) => {
        setLoading(false);
        showMessage('error', 'Something error to save');
        console.log(err);
      });
  };

  const showMessage = (type: 'success' | 'error', mgs: string) => {
    messageApi.open({
      type: type,
      content: mgs,
    });
  };

  return (
    <>
      <div
        style={{
          padding: '20px 30px 0px 40px',
        }}
      >
        <div>
          <span>
            <span style={{ color: '#1f58b0', cursor: 'pointer' }} onClick={() => navigate('/')}>
              HOME
            </span>
            <span
              style={{
                color: '#222',
                marginTop: '0px',
                marginRight: '10px',
                marginBottom: '0px',
                marginLeft: '10px',
                textTransform: 'uppercase',
              }}
            >
              Market Survey
            </span>
          </span>
        </div>
      </div>
      {contextHolder}

      <div>
        <div>
          <div className={classes.survey__text}>
            <span>Market Survey</span>
            <br />
            <label>Compare your community to a competitor’s.</label>
          </div>
        </div>
        <UnsavedChangesPrompt unsaved={unsaved} />
        <Form
          onFinish={onFinish}
          layout="vertical"
          requiredMark={false}
          onValuesChange={() => (!unsaved ? setUnSaved(true) : null)}
          autoComplete="off"
          initialValues={{
            my_community_id: selectedCommunity ? parseInt(selectedCommunity.Id) : null,
            competitor_id: selectedCompetitor ? parseInt(selectedCompetitor.Id) : null,
          }}
        >
          <Row
            gutter={[20, 10]}
            style={{
              padding: '5px 25px',
              maxWidth: 776,
              marginTop: '0px',
              marginRight: 'auto',
              marginBottom: '0px',
              marginLeft: 'auto',
            }}
          >
            <Col xs={24} md={12}>
              <p className="form_label">My Competitor</p>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                placeholder="Select"
                options={competitorOptions}
                onChange={(value, option) => {
                  setSelectedCompetitor(option);
                  if (!unsaved) {
                    setUnSaved(true);
                  }
                }}
                value={selectedCompetitor}
                style={{ width: '100%' }}
              />
            </Col>
            <Col xs={24} md={12}>
              <p className="form_label">My Community</p>
              <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                placeholder="Select"
                options={communityOptions}
                onChange={(value, option) => {
                  setSetselectedCommunity(option);
                  if (!unsaved) {
                    setUnSaved(true);
                  }
                }}
                value={selectedCommunity}
                style={{ width: '100%' }}
              />
            </Col>
          </Row>
          {data_loading ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: 500,
              }}
            >
              <Spin size="small" />
            </div>
          ) : (
            <div
              style={{
                width: 796,
                marginTop: '0px',
                marginRight: 'auto',
                marginBottom: '0px',
                marginLeft: 'auto',
              }}
            >
              <Row>
                <Col offset={16}>
                  <h3 style={{ marginBottom: '-25px' }}>My Competitor is...</h3>
                </Col>
              </Row>

              {formFields.map((field) => (
                <div key={field.title}>
                  <CategoryTitleBar title={field.title} textAlign="left" />
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div style={{ flex: 1 }}></div>
                    <div>
                      <div className={classes.table__header}>
                        <div>
                          <span>Much Worse</span>
                        </div>
                        <div>
                          <span>Worse</span>
                        </div>
                        <div>
                          <span>Same</span>
                        </div>
                        <div>
                          {' '}
                          <span>Better</span>
                        </div>
                        <div>
                          <span>Much Better</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {field.fields.map((row) => (
                    <div
                      key={row.key}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '10px 0px',
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        <span className={classes.option_label_name}>{row.name}</span>
                      </div>
                      <div>
                        <Radio.Group
                          className={classes.radio__wrapper}
                          value={row.value}
                          onChange={(e) => updateFormInput(field.id, row.key, e.target.value)}
                        >
                          <Radio value={1}></Radio>
                          <Radio value={2}></Radio>
                          <Radio value={3}></Radio>
                          <Radio value={4}></Radio>
                          <Radio value={5}></Radio>
                        </Radio.Group>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
          <div style={{ paddingBottom: 150 }}></div>
          {selectedCommunity && selectedCompetitor && (
            <div
              style={{
                position: 'fixed',
                bottom: 0,
                padding: '30px',
                background: '#fff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                borderTop: '1px solid #d7d7d7',
                boxSizing: 'border-box',
              }}
            >
              <div>
                <Button onClick={() => navigate(-1)}>Cancel</Button>
              </div>
              <div>
                <label className={classes.comunity__text}>
                  <span>{selectedCommunity?.apartmentname}</span>
                  <div className={classes.me__box}>
                    <span>ME</span>
                  </div>
                  <br />
                  {selectedCommunity?.apartmentaddress}
                </label>
              </div>
              <div>
                <span className={classes.vs__text}>vs</span>
              </div>
              <div>
                <label className={classes.comunity__text} style={{ textAlign: 'right' }}>
                  <span>{selectedCompetitor?.apartmentname}</span>
                  <br />
                  {selectedCompetitor?.apartmentaddress}
                </label>
              </div>

              <div style={{ width: 127 }}>
                <Button type="primary" onClick={handleSave} loading={loading} disabled={loading}>
                  Save
                </Button>
              </div>
            </div>
          )}
        </Form>
      </div>
    </>
  );
};

export default SwotSurvey;
