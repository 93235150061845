import React, { useState } from 'react';

import { LoadingOutlined, RedoOutlined } from '@ant-design/icons';
import { Col, message, Row, Tooltip, Typography } from 'antd';

import axios from 'config/axiosPrivate';
import { getDecimals } from 'helpers';
import SearchCommunityModal from 'modals/SearchCommunityModal';

interface Props {
  avgData: any;
  communityInfo: any;
  getCompetitorInfo: any;
}

const ComparisonTab = ({ avgData, communityInfo, getCompetitorInfo }: Props) => {
  const [ratingLoading, setRatingLoading] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [openSearchCommunityModal, setOpenSearchCommunityModal] = useState<boolean>(false);

  const showMessage = (type: 'success' | 'error', mgs: string) => {
    messageApi.open({
      type: type,
      content: mgs,
    });
  };

  const handleSetLocation = (data: any) => {
    //
    communityInfo.GoogleMapLocation = data.place_id;
    communityInfo.ApartmentAddress = data.formatted_address;
    communityInfo.Latitude = data.lat;
    communityInfo.Longitude = data.lng;
    communityInfo.ApartmentName = data.name;
    communityInfo.PhoneNumber = data.phone_number;
    communityInfo.State = data.state;
    communityInfo.Website = data.website;
    communityInfo.ZipCode = data.zip_code;
    communityInfo.City = data.city;
    updateRating();
    setOpenSearchCommunityModal(false);
  };

  const updateRating = async () => {
    const updates: any = [];
    let place_id: any = null;
    const service = new google.maps.places.PlacesService(document.createElement('div'));
    if (communityInfo.GoogleMapLocation) {
      place_id = communityInfo.GoogleMapLocation;
    } else {
      setOpenSearchCommunityModal(true);
      return;
    }
    setRatingLoading(true);
    try {
      service.getDetails(
        {
          placeId: place_id,
        },
        async function (place: any, status: any) {
          if (status == 'INVALID_REQUEST') {
            setOpenSearchCommunityModal(true);
            setRatingLoading(false);
            return;
          }
          updates.push({
            id: 1,
            apartment_id: communityInfo.Id,
            old_google_ratings: communityInfo.GoogleRating,
            old_google_reviews: communityInfo.GoogleReviews,
            op: 'added',
            google_ratings: place.rating || 0,
            google_reviews: place.user_ratings_total || 0,
            place_id: communityInfo.GoogleMapLocation,
            formatted_address: communityInfo.ApartmentAddress,
            lat: communityInfo.Latitude,
            lng: communityInfo.Longitude,
            name: communityInfo.ApartmentName,
            city: communityInfo.City,
            phone_number: communityInfo.PhoneNumber,
            state: communityInfo.State,
            website: communityInfo.Website,
            zip_code: communityInfo.ZipCode,
          });

          const placeReviews = place.reviews?.map((_r: any) => ({
            id: 1,
            apartment_id: communityInfo.Id,
            google_review_id: '12345678',
            google_review_user: _r.author_name,
            google_review_date: new Date(_r.time * 1000),
            google_review_text: _r.text,
            google_review_url: _r.author_url,
            op: 'added',
          }));
          saveGoogleMatrics(updates, placeReviews || []);
        }
      );
    } catch (err) {
      setRatingLoading(false);
    }
  };
  const saveGoogleMatrics = (updates: any, reviews: any) => {
    axios
      .post(`communities/Save_GoogleMetrics/`, { data: updates })
      .then((res) => {
        saveGoogleReviews(reviews);
      })
      .catch((err) => {
        setRatingLoading(false);
        showMessage('error', 'Something went wrong');
      });
  };
  const saveGoogleReviews = (reviews: any) => {
    if (reviews.length < 1) {
      getCompetitorInfo();
      showMessage('success', 'Saved successfully!');
      setRatingLoading(false);
      return;
    }
    axios
      .post(`communities/Save_GoogleReviews/`, { data: reviews })
      .then((res) => {
        getCompetitorInfo();
        showMessage('success', 'Saved successfully!');
        setRatingLoading(false);
      })
      .catch((err) => {
        setRatingLoading(false);
        showMessage('error', 'Something went wrong');
      });
  };
  return (
    <div>
      {contextHolder}
      <SearchCommunityModal
        loading={false}
        open={openSearchCommunityModal}
        handleClose={() => setOpenSearchCommunityModal(false)}
        handleSetLocation={handleSetLocation}
        searchKeyword={communityInfo?.ApartmentName}
      />
      <div
        style={{
          maxWidth: '900px',
          padding: '0px 20px',
        }}
      >
        <Row className="rowgap-vbox" gutter={[5, 16]}>
          {/* 1 */}
          <Col xs={24} sm={24} md={12} lg={5} xl={5}>
            <div>
              <div>
                <p
                  className="c_rate_title"
                  style={{
                    fontSize: '14px',
                    color: '#000',
                    marginBottom: -5,
                    marginLeft: 15,
                    display: 'block',
                  }}
                >
                  AVG. RATE
                </p>
              </div>
              <div className="c_price__text_box" style={{ color: '#000' }}>
                <Typography.Paragraph
                  className="c_price_value"
                  style={{
                    color: 'inherit',
                    fontSize: '46px',
                    margin: 0,
                  }}
                >
                  <sup style={{ fontSize: '22px' }}>$</sup>
                  {parseInt(avgData?.avgBaseRent || 0).toLocaleString()}
                  <span style={{ fontSize: '16px' }}>.{getDecimals(avgData?.avgBaseRent).point}</span>
                </Typography.Paragraph>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <Tooltip
              title={() => (
                <span>
                  Rate: ${parseInt(avgData?.avgBaseRent || 0).toLocaleString()}.
                  {getDecimals(avgData?.avgBaseRent).point} <br /> Care: $
                  {parseInt((avgData?.avgBaseRentCare - avgData?.avgBaseRent).toString()).toLocaleString()}.
                  {getDecimals(avgData?.avgBaseRentCare - avgData?.avgBaseRent).point}
                </span>
              )}
              placement="top"
              color="blue"
            >
              <div>
                <p
                  className="c_rate_title"
                  style={{
                    fontSize: '14px',
                    color: '#000',
                    marginBottom: -5,
                    marginLeft: 15,
                    display: 'block',
                  }}
                >
                  AVG.(RATE + CARE)
                </p>
              </div>
              <div className="c_price__text_box" style={{ color: '#000' }}>
                <Typography.Paragraph
                  className="c_price_value"
                  style={{
                    color: 'inherit',
                    fontSize: '46px',
                    margin: 0,
                  }}
                >
                  <sup style={{ fontSize: '22px' }}>$</sup>
                  {parseInt(avgData?.avgBaseRentCare || 0).toLocaleString()}
                  <span style={{ fontSize: '16px' }}>.{getDecimals(avgData?.avgBaseRentCare).point}</span>
                </Typography.Paragraph>
              </div>
            </Tooltip>
          </Col>
          {/* 2 */}
          <Col xs={24} sm={24} md={12} lg={4} xl={4}>
            <div>
              <div>
                <p
                  className="c_rate_title"
                  style={{
                    fontSize: '14px',
                    color: '#000',
                    marginBottom: -5,
                    marginLeft: 4,
                    display: 'block',
                  }}
                >
                  AVG. SQ. FT.
                </p>
              </div>
              <div className="c_price__text_box" style={{ color: '#000' }}>
                <Typography.Paragraph
                  className="c_price_value"
                  style={{
                    color: 'inherit',
                    fontSize: '46px',
                    margin: 0,
                  }}
                >
                  {parseInt(avgData?.avgSqFt || 0).toLocaleString()}
                  <span style={{ fontSize: '16px' }}>.{getDecimals(avgData?.avgSqFt).point}</span>
                </Typography.Paragraph>
              </div>
            </div>
          </Col>
          {/* 3 */}
          <Col xs={24} sm={24} md={12} lg={5} xl={5}>
            <div>
              <div>
                <p
                  className="c_rate_title"
                  style={{
                    fontSize: '14px',
                    color: '#000',
                    marginBottom: -5,
                    marginLeft: 15,
                    display: 'block',
                  }}
                >
                  AVG. RATE / SQ. FT.
                </p>
              </div>
              <div className="c_price__text_box" style={{ color: '#000' }}>
                <Typography.Paragraph
                  className="c_price_value"
                  style={{
                    color: 'inherit',
                    fontSize: '46px',
                    margin: 0,
                  }}
                >
                  <sup style={{ fontSize: '22px' }}>$</sup>

                  {parseInt(avgData?.avgRatePerSqFt || 0).toLocaleString()}
                  <span style={{ fontSize: '16px' }}> .{getDecimals(avgData?.avgRatePerSqFt).point}</span>
                </Typography.Paragraph>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={4} xl={4}>
            <div>
              <span
                className="c_rate_title"
                style={{
                  fontSize: '14px',
                  color: '#000',
                  display: 'block',
                  marginBottom: -5,
                  marginLeft: 4,
                }}
              >
                GOOGLE Rating{' '}
                {ratingLoading ? (
                  <LoadingOutlined />
                ) : (
                  <Tooltip title={communityInfo?.GoogleMapLocation} color="blue">
                    <RedoOutlined onClick={updateRating} style={{ cursor: 'pointer' }} />
                  </Tooltip>
                )}
              </span>
              <div className="c_price__text_box" style={{ color: '#000' }}>
                <Typography.Paragraph
                  className="c_price_value"
                  style={{
                    color: 'inherit',
                    fontSize: '46px',
                    margin: 0,
                    position: 'relative',
                    display: 'inline-block',
                  }}
                >
                  {avgData?.google_rating ? avgData?.google_rating.toFixed(1) : 0}{' '}
                  <span style={{ fontSize: '17px' }}>({avgData?.google_reviews ? avgData?.google_reviews : 0})</span>
                </Typography.Paragraph>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ComparisonTab;
