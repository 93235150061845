import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import * as Sentry from "@sentry/react";
import { ConfigProvider } from "antd";
import ReactDOM from "react-dom/client";

import App from "./App";
import "./assets/styles/index.css";
import { store } from "./store/";

// Sentry.init({
//   dsn: "https://ee8b61c9c5277ad88a0f3dc52d99f9a4@o4505826675916800.ingest.sentry.io/4505878937468928",
//   integrations: [
//     new Sentry.BrowserTracing({
//       // See docs for support of different versions of variation of react router
//       // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
//       routingInstrumentation: Sentry.reactRouterV6Instrumentation(
//         React.useEffect,
//         useLocation,
//         useNavigationType,
//         createRoutesFromChildren,
//         matchRoutes
//       ),
//     }),
//     new Sentry.Replay(),
//   ],

// Set tracesSampleRate to 1.0 to capture 100%
// of transactions for performance monitoring.
// tracesSampleRate: 1.0,

// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
// tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

// Capture Replay for 10% of all sessions,
// plus for 100% of sessions with an error
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });

// Create a client
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <QueryClientProvider client={queryClient}>
    {/* <BrowserRouter> */}
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#285aff",
            colorTextBase: "#000",
            colorTextSecondary: "#002c8c",
            colorTextHeading: "#333",
            colorTextPlaceholder: "#8c8c8c",
            fontFamily:
              "AvenirLTPro-Medium, open sans, Helvetica, Arial, sans-serif",
          },
        }}
        componentSize="large"
      >
        <App />
      </ConfigProvider>
    </Provider>
    {/* </BrowserRouter> */}
  </QueryClientProvider>
);
