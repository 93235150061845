export const limitDecimalPoint = (element: any, event: any) => {
  // console.log({ element });
  const result = (event.charCode >= 48 && event.charCode <= 57) || event.charCode === 46;
  if (result) {
    const t = element.value;
    if (t === '' && event.charCode === 46) {
      return false;
    }
    const dotIndex = t.indexOf('.');
    const valueLength = t.length;
    if (dotIndex > 0) {
      if (dotIndex + 2 < valueLength) {
        return false;
      } else {
        return true;
      }
    } else if (dotIndex === 0) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export const getDecimals = (value: number) => {
  if (!value)
    return {
      int: 0,
      point: '00',
    };
  const values = value.toFixed(2).split('.');

  return {
    int: values[0],
    point: values[1],
  };
};
