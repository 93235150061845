import React from 'react';

import { Button, Checkbox, Col, Form, Input, Row, Select, SelectProps, Slider, Tag, Typography } from 'antd';

import SelectInput from 'formComponents/SelectInput';
import CommunityTitleBar from 'pages/PriceHealing/common/CommunityTitleBar';

import GMap from './GMap';
import SearchItem from './SearchItem';

const options: SelectProps['options'] = [];

for (let i = 10; i < 36; i++) {
  options.push({
    value: i.toString(36) + i,
    label: i.toString(36) + i,
  });
}

const FindCommunities = () => {
  const [radius, setRadius] = React.useState<number>(0);

  const onSliderChange = (newValue: number) => {
    setRadius(newValue);
  };
  const onFinish = (values: any) => {
    console.log(values);
  };

  return (
    <div>
      <CommunityTitleBar title="Find Communities" textAlign="left" />

      <Form onFinish={onFinish} layout="vertical" requiredMark={false} autoComplete="off">
        <Row gutter={[10, 10]}>
          <Col xs={24} md={8}>
            <Form.Item
              name="location"
              label={<p className="form_label">Search around the location:</p>}
              extra="Please allow browser settings for location permissions"
            >
              <Input placeholder="Enter any address" />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name="Community"
              label={<p className="form_label">OR: Select a Community:</p>}
              extra="Select a community to populate it's address in the search field"
            >
              <Select placeholder="Select Community" />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item name="radius" label={<p className="form_label">Within the radius of:</p>}>
              <Slider value={radius} onChange={onSliderChange} />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <Tag color="blue">{radius} Miles</Tag>
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[20, 10]}>
          <Col xs={24} md={8}>
            <Form.Item name="bedrooms" label={<p className="form_label">Bedrooms:</p>}>
              <SelectInput placeholder="Select Bedrooms" onApply={(value) => console.log(value)} />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item name="FeeTypes" label={<p className="form_label">Fee Types:</p>}>
              <SelectInput placeholder="Select Fee Types" onApply={(value) => console.log(value)} />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item name="MinimumRating" label={<p className="form_label">Minimum Rating:</p>}>
              <SelectInput placeholder="Select Minimum Rating" onApply={(value) => console.log(value)} />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item name="checkbox-group" label="">
              <Checkbox value="Communities" style={{ lineHeight: '32px' }}>
                Communities
              </Checkbox>
              <Checkbox value="Competitors" style={{ lineHeight: '32px' }}>
                Competitors
              </Checkbox>
            </Form.Item>
          </Col>
          <Col xs={24}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                background: '#eee',
                borderRadius: 8,
                padding: 10,
              }}
            >
              <div>
                <Typography.Text style={{ margin: 0, fontWeight: 'normal' }}>
                  Results: <strong>3</strong>
                </Typography.Text>
              </div>
              <Button type="primary" htmlType="submit">
                Search
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
      <br />
      <Row gutter={[10, 10]}>
        <Col
          xs={24}
          md={8}
          style={{
            minHeight: '650px',
            height: 'calc(100vh - 100px)',
            overflowY: 'auto',
          }}
        >
          <Row gutter={[10, 10]}>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((el) => (
              <Col xs={24} key={el}>
                <SearchItem />
              </Col>
            ))}
          </Row>
        </Col>
        <Col xs={24} md={16}>
          <GMap />
        </Col>
      </Row>
    </div>
  );
};

export default FindCommunities;
