import React from 'react';
import { Link } from 'react-router-dom';

import { DeleteFilled } from '@ant-design/icons';
import { Button, Space, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';

interface DataType {
  id: string;
  companyName: string;
  communityName: string;
  address: string;
  state: string;
  region: string;
  createdBy: string;
  updatedDate: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: 'No',
    dataIndex: 'id',
    key: 'id',
    render: (text) => <span>{text}</span>,
  },
  {
    title: 'Company Name',
    dataIndex: 'companyName',
    key: 'companyName',
  },
  {
    title: 'My Community Name',
    dataIndex: 'communityName',
    key: 'communityName',
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
  },
  {
    title: 'Region',
    dataIndex: 'region',
    key: 'region',
  },
  {
    title: 'Created By',
    dataIndex: 'createdBy',
    key: 'createdBy',
  },
  {
    title: 'Date Updated',
    dataIndex: 'updatedDate',
    key: 'updatedDate',
  },
  {
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <Space size="middle">
        <Link to="/create-apartments">
          <Button size="small">Pricing Info</Button>
        </Link>
        <Link to="/create-competitors">
          <Button size="small">Community Info</Button>
        </Link>
        <Button size="small" icon={<DeleteFilled />} danger>
          Delete
        </Button>
      </Space>
    ),
  },
];

const data: DataType[] = [
  {
    id: '1',
    companyName: 'Further Admin',
    communityName: 'Benton House',
    address: 'New York No. 1 Lake Park',
    state: 'Dhaka',
    region: 'Rajbari',
    createdBy: 'Zaman',
    updatedDate: new Date().toDateString(),
  },
  {
    id: '2',
    companyName: 'Further Admin',
    communityName: "John's Creek",
    address: 'New York No. 1 Lake Park',
    state: 'Dhaka',
    region: 'Rajbari',
    createdBy: 'Zaman',
    updatedDate: new Date().toDateString(),
  },
  {
    id: '3',
    companyName: 'Further Admin',
    communityName: "Jimmy's Creek",
    address: 'New York No. 1 Lake Park',
    state: 'Dhaka',
    region: 'Rajbari',
    createdBy: 'Zaman',
    updatedDate: new Date().toDateString(),
  },
];

const CommunityTable: React.FC = () => (
  <Table columns={columns} dataSource={data} scroll={{ x: 'max-content', y: '50vh' }} bordered />
);

export default CommunityTable;
