import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, message, Space, Switch, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import shortUUID from 'short-uuid';

import axios from 'config/axiosPrivate';
import { useAppSelector } from 'store/hook';

import classes from './rentroll.module.scss';

interface DataType {
  id: number;
  key: string;
  company: number;
  order_no: number;
  tier_name: string;
  from_pct: number | null;
  to_pct: number | null;
  impact_pct: number | null;
  status: 1 | 0;
  op: 'added' | 'edited' | 'deleted' | null;
  disabled: false;
}

// main component

const OccupancyTiers = () => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const currentUser = useAppSelector((state) => state.auth.currentUser);

  const companyId = useAppSelector((state) => state.auth.currentUser?.company);
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  // get Total occupencies units
  useEffect(() => {
    getInitialData();
  }, []);

  const getInitialData = () => {
    setDataLoading(true);
    axios
      .get(`/communities/Get_OccupancyTiers/${companyId}/`)
      .then((res) => {
        setDataLoading(false);
        const data: any[] = res.data?.payload?.CompanyOccupanyTiers;

        const updated_data = data.map((el) => ({
          ...el,
          key: shortUUID.generate(),
          op: null,
        }));
        setDataSource(updated_data);
      })
      .catch((err) => {
        setDataLoading(false);
      });
  };

  const handleDeleteRentRoll = (row: DataType) => {
    const cp = [...dataSource];
    const index = cp.findIndex((el) => el.key === row.key);
    if (index !== -1) {
      if (cp[index].id === -1 && row.op === 'added') {
        cp.splice(index, 1);
      } else {
        cp[index].op = 'deleted';
      }
      setDataSource(cp);
    }
  };

  // handle form functions
  const handleUpdateInput = (val: any, row: DataType, key: string) => {
    const cp: DataType[] = [...dataSource];
    const index = cp.findIndex((el) => el.key === row.key);
    if (index !== -1) {
      if (key === 'status') {
        // @ts-ignore
        cp[index][key] = val ? 1 : 0;
        setDataSource(cp);
        if (cp[index].op === null) {
          cp[index].op = 'edited';
        }
      } else {
        // @ts-ignore
        cp[index][key] = val;
        setDataSource(cp);
        if (cp[index].op === null) {
          cp[index].op = 'edited';
        }
      }
    }
  };

  const columns: ColumnsType<DataType> = [
    {
      title: <span style={{ fontSize: 16, fontWeight: 600 }}>Order No</span>,
      dataIndex: 'order_no',
      key: 'order_no',
      width: '100px',
      align: 'center',
      render(value, record: any, index) {
        return (
          <Input
            type="text"
            value={value}
            onChange={(e) => {
              handleUpdateInput(e.target.value, record, 'order_no');
            }}
            style={{ width: '100%', textAlign: 'center' }}
          />
        );
      },
    },
    {
      title: <span style={{ fontSize: 16, fontWeight: 600 }}>Tier Name</span>,
      dataIndex: 'tier_name',
      key: 'tier_name',
      width: '300px',
      align: 'left',
      render(value, record: any, index) {
        return (
          <Input
            type="text"
            value={value}
            onChange={(e) => {
              handleUpdateInput(e.target.value, record, 'tier_name');
            }}
            style={{ width: '100%' }}
          />
        );
      },
    },

    {
      title: <span style={{ fontSize: 16, fontWeight: 600 }}>From %</span>,
      dataIndex: 'from_pct',
      key: 'from_pct',
      width: '180px',
      align: 'left',
      render(value, record: any, index) {
        return (
          <Input
            type="text"
            value={value}
            onChange={(e) => {
              handleUpdateInput(e.target.value, record, 'from_pct');
            }}
            style={{ width: '100%' }}
            addonAfter="%"
          />
        );
      },
    },

    {
      title: <span style={{ fontSize: 16, fontWeight: 600 }}>To %</span>,
      dataIndex: 'to_pct',
      key: 'to_pct',
      width: '180px',
      align: 'left',
      render(value, record: any, index) {
        return (
          <Input
            type="text"
            value={value}
            onChange={(e) => {
              handleUpdateInput(e.target.value, record, 'to_pct');
            }}
            style={{ width: '100%' }}
            addonAfter="%"
          />
        );
      },
    },

    {
      title: <span style={{ fontSize: 16, fontWeight: 600 }}>Impact %</span>,
      dataIndex: 'impact_pct',
      key: 'impact_pct',
      width: '180px',
      align: 'left',
      render(value, record: any, index) {
        return (
          <Input
            type="text"
            value={value}
            onChange={(e) => {
              handleUpdateInput(e.target.value, record, 'impact_pct');
            }}
            style={{ width: '100%' }}
            addonAfter="%"
          />
        );
      },
    },
    {
      title: <span style={{ fontSize: 16, fontWeight: 600 }}>Active</span>,
      dataIndex: 'status',
      key: 'status',
      width: '100px',
      align: 'center',
      render(value, record: any, index) {
        return (
          <Switch
            checked={parseInt(value) === 1 ? true : false}
            onChange={(checked: boolean) => handleUpdateInput(checked, record, 'status')}
          />
        );
      },
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (_, record: any) =>
        dataSource.length >= 1 ? (
          <Space size="small" className="actions">
            <Button type="link" danger onClick={() => handleDeleteRentRoll(record)} icon={<DeleteOutlined />} />
          </Space>
        ) : null,
    },
  ];

  const handleAdd = () => {
    if (companyId) {
      const newData: DataType = {
        id: 1,
        key: shortUUID.generate(),
        company: companyId,
        order_no: dataSource.length + 1,
        tier_name: '',
        from_pct: null,
        to_pct: null,
        impact_pct: null,
        status: 1,
        op: 'added',
        disabled: false,
      };
      setDataSource((prevData) => [...prevData, newData]);
    }
  };

  const [loading, setLoading] = useState(false);
  const handleSave = () => {
    const data_to_save = dataSource.filter((d) => d.op === 'added' || d.op === 'edited' || d.op === 'deleted');
    setLoading(true);
    axios
      .post('/communities/Save_OccupancyTiers/', {
        data: data_to_save.map((el) => ({
          id: el?.id ? el.id : 1,
          company_id: el?.company,
          order_no: el?.order_no,
          tier_name: el?.tier_name,
          from_pct: el?.from_pct,
          to_pct: el?.to_pct,
          impact_pct: el?.impact_pct,
          status: el?.status,
          op: el.op,
          disabled: false,
        })),
      })
      .then((res) => {
        setLoading(false);
        showMessage('success', 'Saved successfully!');
        getInitialData();
      })
      .catch((err) => {
        showMessage("error", err?.response?.data[0]);
        setLoading(false);
      });
  };

  const showMessage = (type: 'success' | 'error', mgs: string) => {
    messageApi.open({
      type: type,
      content: mgs,
    });
  };

  const deletedDatasource: DataType[] = dataSource.filter((d) => d.op !== 'deleted');

  return (
    <>
      <div
        style={{
          padding: '20px 30px 0px',
        }}
      >
        <div>
          <span>
            <span style={{ color: '#1f58b0', cursor: 'pointer' }} onClick={() => navigate('/')}>
              HOME
            </span>
            <span
              style={{
                color: '#222',
                margin: '0px 10px',
                textTransform: 'uppercase',
              }}
            >
              Occupancy Tiers
            </span>
          </span>
        </div>
      </div>

      <div
        style={{
          padding: '0px 10px 30px 10px',
          maxWidth: 1140,
          margin: '0px auto',
        }}
      >
        {contextHolder}
        <div style={{ textAlign: 'center' }}>
          <h2>Occupancy Tiers</h2>
        </div>
        <br />
        <Table
          className={classes.rentroll_table}
          // components={components}
          rowClassName={() => 'editable-row'}
          bordered
          dataSource={deletedDatasource}
          columns={columns}
          pagination={false}
          scroll={{ y: 500 }}
          loading={dataLoading}
        />
        <br />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '0px 30px',
          }}
        >
          <Button
            icon={<PlusOutlined />}
            type="default"
            shape="round"
            style={{
              fontSize: 14,
              height: 'max-content',
              padding: '6px 10px 4px 10px',
              marginTop: '15px',
            }}
            onClick={() => handleAdd()}
          >
            Add
          </Button>
          <div>
            <Button
              type="default"
              style={{
                fontSize: 14,
                height: 'max-content',
                padding: '6px 10px 4px 10px',
                marginTop: '15px',
                marginRight: '6px',
                minWidth: 100,
              }}
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              style={{
                fontSize: 14,
                height: 'max-content',
                padding: '6px 10px 4px 10px',
                marginTop: '15px',
                minWidth: 130,
              }}
              onClick={handleSave}
              loading={loading}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default OccupancyTiers;
