import React from 'react';

import { Steps, Typography } from 'antd';

import { useAppDispatch } from 'store/hook';
import { handleClearCommunityData } from 'store/slices/communitySlice';

import BasicInfoForm from './BasicInfoForm';
import CommunitySelectForm from './CommunitySelectForm';
import AccommodationForm from '../../pageComponents/AddCommunityCompetitor/AccommodationForm';
import FeesForm from '../../pageComponents/AddCommunityCompetitor/FeesForm';

const AddCompetitor = () => {
  const dispatch = useAppDispatch();
  const [current, setCurrent] = React.useState(0);

  const renderComponent = () => {
    if (current === 0) {
      return <BasicInfoForm current={current} setCurrent={setCurrent} />;
    } else if (current === 1) {
      return <AccommodationForm current={current} setCurrent={setCurrent} />;
    } else if (current === 2) {
      return <FeesForm current={current} setCurrent={setCurrent} />;
    } else if (current === 3) {
      return <CommunitySelectForm current={current} setCurrent={setCurrent} />;
    }
    return null;
  };

  React.useEffect(() => {
    dispatch(handleClearCommunityData());
    return () => {
      dispatch(handleClearCommunityData());
    };
  }, []);

  return (
    <div>
      <div
        style={{
          margin: '30px auto',
          padding: '0px 30px',
          position: 'relative',
          minHeight: 'calc(100vh - 190px)',
        }}
      >
        <Typography.Title style={{ textAlign: 'center', fontSize: '56px' }}>Add Competitor</Typography.Title>
        <div style={{ maxWidth: 570, margin: '0 auto' }}>
          <Steps
            current={current}
            // onChange={onChange}
            labelPlacement="vertical"
            size="small"
            items={[
              {
                title: 'Basic Info',
              },
              {
                title: (
                  <span
                    style={{
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Accommodation
                  </span>
                ),
              },
              {
                title: 'Fees & Care',
              },
              {
                title: 'My Community',
              },
            ]}
          />
        </div>
        {/* render components */}
        <div style={{ margin: '40px 0px 30px 0px' }}>{renderComponent()}</div>
      </div>
    </div>
  );
};

export default AddCompetitor;
