import axios from 'axios';
import mem from 'mem';

import { store } from 'store';
import { handleLogout, handleUpdateAccessToken } from 'store/slices/authSlice';

import axiosPublic from './axiosPublic';

axios.defaults.baseURL = process.env.REACT_APP_API;
// process.env.NODE_ENV === "production" ? process.env.REACT_APP_API : "/";

const handleRefreshToken = async () => {
  const refreshToken = store.getState().auth.refreshToken;

  try {
    const response = await axiosPublic.post('/api/jwt/refresh/', {
      refresh: refreshToken,
    });

    const { access } = response.data;
    if (!access) {
      // remove login session from local storage--loggedout
      store.dispatch(handleLogout());
    }
    // update access token with new token
    store.dispatch(handleUpdateAccessToken(access));

    return access;
  } catch (err) {
    console.log('Refresh token Expired:', err);
    store.dispatch(handleLogout());
  }
};

const maxAge = 10000;

export const memorizedRefreshToken = mem(handleRefreshToken, {
  maxAge,
});

axios.interceptors.request.use(
  async (config) => {
    const accessToken = store.getState().auth.accessToken;

    if (accessToken) {
      // @ts-ignore
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${accessToken}`,
      };
    }

    return config;
  },
  (err) => Promise.reject(err)
);

axios.interceptors.response.use(
  (response) => response,
  async (err) => {
    const config = { ...err?.config };

    if (err?.response?.status === 401 && !config?.sent) {
      config.sent = true;
      const result = await memorizedRefreshToken();
      if (result) {
        // @ts-ignore
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${result}`,
        };
      }

      return axios(config);
    }
    return Promise.reject(err);
  }
);

export default axios;
