import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { CloseCircleFilled, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Popconfirm, Select, Spin, Switch, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import axios from 'axios';
import { format } from 'date-fns';

import { useAppSelector } from 'store/hook';

interface DataType {
  id: string | number;
  total_apartments_units: string;
  free_apartments_units: string;
  ApartmentTypeName: string;
  livingTypeName: string;
  pct_occupancy: string;
  IL_OCC: string;
  IL_Aparts: string;
  AL_OCC: string;
  AL_Aparts: string;
  MC_OCC: string;
  MC_Aparts: string;
  Nursing_OCC: string;
  Nursing_Aparts: string;
  updated_by: string;
  updated_at: string;
  created_at: string;
}

interface OccupancyType {
  ApartmentTypeId: string;
  ApartmentTypeName: string;
  CommunityId: string;
  CommunityName: string;
  LivingTypeId: string;
  LivingTypeName: string;
  abv: string;
  apartment: number;
  apartment_type: number;
  created_at: string;
  created_by: string;
  free_apartments_units: number;
  id: number;
  living_type: number;
  pct_occupancy: string;
  status: number;
  total_apartments_units: number;
  updated_at: string;
  updated_by: string;
  op?: string;
}

interface SelectType {
  label: string;
  value: number;
}

interface Props {
  community_id: any;
  type: 'community' | 'competitor';
}

const Occupancies = ({ community_id, type }: Props) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [selectedTab, setSelectedTab] = useState('EDIT');
  const livingTypes = useAppSelector((state) => state.global.livingtypes);
  const [add_loading, setAddLoading] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);

  const [data, setData] = useState<any[]>([]);

  const [living_options, setLivingOptions] = useState<Array<SelectType>>([]);
  const [apartment_options, setApartmentOptions] = useState<Array<SelectType>>([]);
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const [historyData, setHistoryData] = useState<any[]>([]);
  const showMessage = (type: 'success' | 'error', mgs: string) => {
    messageApi.open({
      type: type,
      content: mgs,
    });
  };

  const state = useQuery({
    queryKey: 'Get_CommunityOccupancies',
    queryFn: () => axios.get(`/communities/Get_CommunityOccupancies/${community_id}`).then((res) => res.data?.payload),
    onSuccess: (data) => {
      //
      const activeData = data.filter((el: any) => el?.status === 1);
      const historyData = data.filter((el: any) => el?.status === 0);
      setHistoryData(historyData);
      setData(activeData.map((ele: any, idx: number) => ({ ...ele, op: '' })));
      setLoading(false);
    },
    enabled: !!livingTypes,
  });
  useEffect(() => {
    setLoading(true);
    state.refetch();
  }, [community_id]);

  useEffect(() => {
    axios
      .get(`/communities/Get_UniqueList/`)
      .then((res: any) => {
        const careType = res.data?.payload['LivingTypes'];
        const floorPlan = res.data?.payload['ApartmentTypes'];

        const modifiedCareTypes: any = [
          ...careType.map((service: any) => ({
            label: service.type,
            value: Math.floor(service.id),
          })),
        ];

        const modifiedFloorPlans: any = [
          ...floorPlan.map((floorPlan: any) => ({
            label: floorPlan.type,
            value: Math.floor(floorPlan.id),
          })),
        ];

        setLivingOptions(modifiedCareTypes);
        setApartmentOptions(modifiedFloorPlans);
      })
      .catch((err) => {});
  }, []);

  const columns: ColumnsType<DataType> = [
    {
      title: "Living Type",
      dataIndex: "LivingTypeName",
      key: "livingTypeName",
      align: "center",
      width: 250,
      filters: livingTypes.map((el: any) => ({
        text: el.type,
        value: el.type,
      })),
      onFilter: (value: any, record) => record.livingTypeName?.startsWith(value),
    },
    {
      title: "Accomodation",
      dataIndex: "ApartmentTypeName",
      key: "ApartmentTypeName",
      align: "left",
      width: 300,
    },
    {
      title: "Total Units",
      dataIndex: "total_apartments_units",
      key: "total_apartments_units",
      align: "right",
      width: 200,
    },
    {
      title: 'Vacant Units',
      dataIndex: 'free_apartments_units',
      key: 'free_apartments_units',
      align: 'right',
      width: 200,
    },
    {
      title: 'Occupancy %',
      dataIndex: 'pct_occupancy',
      key: 'pct_occupancy',
      align: 'right',
    },
    {
      title: 'Date Created',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 270,
      render: (_, record) => (
        <span>{record.created_at ? format(new Date(record.created_at), 'MM-dd-yyy hh:mm aaa') : '-'}</span>
      ),
    },
    {
      title: 'Date Updated',
      dataIndex: 'updated_at',
      key: 'updated_at',
      width: 270,
      render: (_, record) => (
        <span>{record.updated_at ? format(new Date(record.updated_at), 'MM-dd-yyy hh:mm aaa') : '-'}</span>
      ),
    },
  ];
  if (currentUser?.isSuperuser) {
    columns.push({
      title: 'History',
      dataIndex: 'status',
      key: 'staus',
      width: 200,
      render: (value, record, row_index) => (
        <Switch
          checked={value == 0 ? true : false}
          onChange={(checked: boolean) => handleUpdateHistoryRowStatus(checked, row_index)}
        />
      ),
    });
  }

  const handleTableChange = (val: string | number | boolean, index: number, key: string) => {
    const temp = [...data];

    temp[index][key] = val;
    const occupany = (temp[index]['free_apartments_units'] / temp[index]['total_apartments_units']) * 100;

    temp[index]['pct_occupancy'] = !isNaN(occupany) && (100 - occupany).toFixed(2);
    if (temp[index]['op'] === '') {
      temp[index]['op'] = 'edited';
    }
    setData(temp);
  };

  const handleUpdateRowStatus = (checked: boolean, index: number) => {
    const temp = [...data];
    temp[index].status = checked ? 0 : 1;
    if (temp[index]['op'] === '') {
      temp[index]['op'] = 'edited';
    }
    setData(temp);
  };

  const handleUpdateHistoryRowStatus = (checked: boolean, index: number) => {
    const temp = [...historyData];
    temp[index].status = checked ? 0 : 1;
    temp[index]['op'] = 'edited';

    setHistoryData(temp);
  };

  const handleAddRow = () => {
    const temp = [...data];
    temp.push({
      ApartmentTypeId: 1,
      ApartmentTypeName: '',
      CommunityId: '',
      CommunityName: '',
      LivingTypeId: 1,
      LivingTypeName: '',
      abv: '',
      apartment: 0,
      apartment_type: 0,
      created_at: '',
      created_by: '',
      free_apartments_units: undefined,
      id: undefined,
      living_type: 0,
      pct_occupancy: '',
      status: 1,
      total_apartments_units: undefined,
      updated_at: '',
      updated_by: '',
      op: 'added',
    });
    setData(temp);
  };

  const handleDelete = (payload: any, index: number) => {
    if (payload?.id) {
      const modifiedObject = {
        id: payload?.id,
        apartment_id: payload?.apartment_id ? payload.apartment_id : parseInt(community_id),
        apartment_type_id: payload.ApartmentTypeId,
        living_type_id: payload.LivingTypeId,
        free_apartments_units: parseInt(payload?.free_apartments_units),
        total_apartments_units: parseInt(payload?.total_apartments_units),
        pct_occupancy: parseInt(payload?.pct_occupancy),
        op: 'deleted',
        status: payload.status,
        disabled: false,
      };
      const data_2 = {
        data: [modifiedObject],
      };
      // setAddLoading(true);
      axios
        .post('/communities/Save_CommunityOccupancies/', data_2)
        .then((res) => {
          // setAddLoading(false);
          state.refetch();
          // setOpen(false);
          // setSelectedData(null);
          showMessage('success', 'Deleted successfully!');
        })
        .catch((err) => {
          // setAddLoading(false);
        });
    } else {
      const temp = [...data];
      temp.splice(index, 1);
      setData(temp);
    }
  };

  const handleSave = () => {
    const updatedList = data.map((record) => ({
      id: record?.id || parseInt(community_id),
      apartment_id: record?.apartment_id || parseInt(community_id),
      apartment_type_id: record.ApartmentTypeId,
      living_type_id: record.LivingTypeId,
      free_apartments_units: parseInt(record?.free_apartments_units),
      total_apartments_units: parseInt(record?.total_apartments_units),
      pct_occupancy: parseInt(record?.pct_occupancy),
      op: record.op,
      status: record.status,
      disabled: false,
    }));

    if (updatedList.length > 0) {
      setAddLoading(true);
      axios
        .post('/communities/Save_CommunityOccupancies/', { data: updatedList })
        .then((res) => {
          setAddLoading(false);
          state.refetch();
          showMessage('success', 'Saved successfully!');
          // setSelectedData(null);
        })
        .catch((err) => {
          setAddLoading(false);
          showMessage("error", "Something went wrong");
        });
    }
  };

  const handleHistorySave = () => {
    const updatedList = historyData.map((record) => ({
      id: record?.id || parseInt(community_id),
      apartment_id: record?.apartment_id || parseInt(community_id),
      apartment_type_id: record.ApartmentTypeId,
      living_type_id: record.LivingTypeId,
      free_apartments_units: parseInt(record?.free_apartments_units),
      total_apartments_units: parseInt(record?.total_apartments_units),
      pct_occupancy: parseInt(record?.pct_occupancy),
      op: record.op,
      status: record.status,
      disabled: false,
    }));
    if (updatedList.length > 0) {
      setAddLoading(true);
      axios
        .post('/communities/Save_CommunityOccupancies/', { data: updatedList })
        .then((res) => {
          setAddLoading(false);
          state.refetch();
          showMessage('success', 'Saved successfully!');
          // setSelectedData(null);
        })
        .catch((err) => {
          setAddLoading(false);
          showMessage("error", "Something went wrong");
        });
    }
  };

  return (
    <div
      style={{
        maxWidth: type !== "competitor" ? 1300 : 1500,
        margin: type !== "competitor" ? "0 auto" : "",
      }}
    >
      {contextHolder}
      {/* <div
        className={`tab-occupancy-header ${!currentUser?.isStaff && "single"}`}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Typography.Title level={3} style={{ margin: 0, textAlign: "center" }}>
          Occupancies
        </Typography.Title>
      </div> */}
      {/* <br /> */}
      <div
        className="tabs-buttons"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Button
          type="text"
          className={`
          btn-tag-occupancy ${selectedTab === 'EDIT' ? 'p_healing_active_tab' : 'btnStyle'}
          `}
          shape="round"
          onClick={() => setSelectedTab('EDIT')}
        >
          Edit
        </Button>
        <Button
          type="text"
          shape="round"
          className={`
          btn-tag-occupancy ${selectedTab === 'HISTORY' ? 'p_healing_active_tab' : 'btnStyle'}
          `}
          onClick={() => setSelectedTab('HISTORY')}
        >
          History
        </Button>
      </div>
      <br />
      {selectedTab === 'EDIT' && (
        <Form onFinish={handleSave}>
          <div className="occupancy-edit-wrapper">
            <div
              style={{
                flex: 1,
              }}
            >
              {loading ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: 100,
                  }}
                >
                  <Spin />
                </div>
              ) : (
                <>
                  <table className="acc_table_wrapper" style={{ borderSpacing: 0 }}>
                    <thead>
                      <tr>
                        <th
                          className="table-header"
                          style={{
                            borderLeft: '1px solid #ccc',
                            width: '200px',
                          }}
                          align="left"
                        >
                          Living Type
                        </th>
                        {/* {type !== "competitor" && ( */}
                        <th
                          className="table-header"
                          align="left"
                          style={{ width: "300px" }}
                        >
                          Accommodation
                        </th>
                        {/* )} */}

                        <th className="table-header" align="left" style={{ width: '200px' }}>
                          Total Units
                        </th>
                        <th className="table-header" align="left" style={{ width: '200px' }}>
                          Vacant Units
                        </th>
                        <th className="table-header" align="left" style={{ width: '200px' }}>
                          Occupancy %
                        </th>
                        <th className="table-header" style={{ width: '100px' }}>
                          History
                        </th>
                        <th
                          style={{
                            border: 0,
                            width: '24px',
                          }}
                        ></th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((row_data: OccupancyType, row_index: number) => (
                        <tr className="acc_row" key={row_index}>
                          <td
                            style={{
                              borderLeft: '1px solid #ccc',
                              padding: 0,
                            }}
                          >
                            <Select
                              bordered={false}
                              value={parseInt(row_data.LivingTypeId)}
                              style={{ width: '200px', border: 0 }}
                              options={living_options}
                              onChange={(val) => handleTableChange(val, row_index, 'LivingTypeId')}
                            />
                          </td>
                          {type !== 'competitor' && (
                            <td
                              style={{
                                borderLeft: '1px solid #ccc',
                                padding: 0,
                              }}
                            >
                              <Select
                                bordered={false}
                                value={parseInt(row_data.LivingTypeId)}
                                style={{ width: "200px", border: 0 }}
                                options={living_options}
                                onChange={(val) =>
                                  handleTableChange(
                                    val,
                                    row_index,
                                    "LivingTypeId"
                                  )
                                }
                              />
                            </td>)} 
                            
                            <td
                              style={{
                                borderLeft: "1px solid #ccc",
                                padding: 0,
                              }}
                            >
                              <Select
                                bordered={false}
                                value={parseInt(row_data.ApartmentTypeId)}
                                style={{
                                  width: "300px",
                                  padding: 0,
                                  border: 0,
                                }}
                                options={apartment_options}
                                onChange={(val) =>
                                  handleTableChange(
                                    val,
                                    row_index,
                                    "ApartmentTypeId"
                                  )
                                }
                              />
                            </td>
                            {/* )} */}

                          <td style={{ padding: 0 }}>
                            <Input
                              type="number"
                              value={row_data.total_apartments_units}
                              style={{ width: '200px', border: 0 }}
                              required
                              onChange={(e) => handleTableChange(e.target.value, row_index, 'total_apartments_units')}
                            />
                          </td>
                          <td style={{ padding: 0 }}>
                            <Input
                              type="number"
                              value={row_data.free_apartments_units}
                              style={{ width: '200px', border: 0 }}
                              required
                              onChange={(e) => handleTableChange(e.target.value, row_index, 'free_apartments_units')}
                            />
                          </td>
                          <td style={{ padding: 0 }}>
                            <Input
                              type="number"
                              value={row_data.pct_occupancy}
                              style={{ width: '200px', border: 0 }}
                              addonAfter="%"
                              readOnly
                              onChange={(e) => handleTableChange(e.target.value, row_index, 'pct_occupancy')}
                            />
                          </td>
                          <td
                            style={{
                              padding: 0,
                              width: 100,
                              textAlign: 'center',
                            }}
                          >
                            <Switch
                              checked={row_data?.status === 1 ? false : true}
                              onChange={(checked: boolean) => handleUpdateRowStatus(checked, row_index)}
                            />
                          </td>
                          <td style={{ border: 0 }}>
                            <Popconfirm
                              title="Info"
                              description="Are you sure want to delete?"
                              onConfirm={() => handleDelete(row_data, row_index)}
                              okText="Yes"
                              cancelText="No"
                              placement="topLeft"
                            >
                              <Button
                                icon={<CloseCircleFilled />}
                                size="small"
                                type="link"
                                danger
                                // onClick={() => handleDelete(row_data, row_index)}
                              />
                            </Popconfirm>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <Button
                    icon={<PlusOutlined />}
                    type="default"
                    shape="round"
                    style={{
                      fontSize: 14,
                      height: 'max-content',
                      padding: '6px 10px 4px 10px',
                      marginTop: '5px',
                    }}
                    onClick={handleAddRow}
                  >
                    Add
                  </Button>
                </>
              )}
            </div>
            <div
              className="occupancy-save-btn-head"
              style={{
                borderTop: '1px solid #eee',
                marginTop: 30,
                padding: '0px 50px',
                width: '100%',
              }}
            >
              <div
                style={{
                  padding: '50px 0px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={add_loading}
                  disabled={add_loading}
                  // onClick={handleSave}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </Form>
      )}

      {selectedTab === 'HISTORY' && (
        <div
          style={{
            // maxWidth: 991,
            margin: "0 auto",
            padding: "10px 30px",
          }}
        >
          <Table columns={columns} dataSource={historyData} />
          {currentUser?.isSuperuser && (
            <div
              className="occupancy-save-btn-head"
              style={{
                borderTop: '1px solid #eee',
                marginTop: 30,
                padding: '0px 10px',
                width: '100%',
              }}
            >
              <div
                style={{
                  padding: '10px 0px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <Button type="primary" loading={add_loading} disabled={add_loading} onClick={handleHistorySave}>
                  Save
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Occupancies;
