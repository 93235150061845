import React, { useState } from 'react';

import { DownOutlined, LinkOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Divider, Dropdown, Empty, MenuProps, Radio, RadioChangeEvent, Space, Spin, Typography } from 'antd';

import axios from 'config/axiosPrivate';
import AddBrochureUrlModal from 'modals/AddBrochureUrlModal';
import AddImageUrlModal from 'modals/AddImageUrlModal';
import UploadBrochureFileModal from 'modals/UploadBrochureFileModal';
import UploadImageModal from 'modals/UploadImageModal';
import { useAppSelector } from 'store/hook';

import BrochureFileComp from '../BrochureFileComp';
import BrochureImageComp from '../BrochureImageComp';

function groupByCategory(data: any[]) {
  const groups: any = {};

  data.forEach(function (val) {
    const category = val.image_category;
    if (category) {
      if (category in groups) {
        groups[category].push(val);
      } else {
        groups[category] = new Array(val);
      }
    }
  });

  return groups;
}

const getExtentionFromUrl = (filename: any) => {
  if (!filename) return '';
  const arr = filename?.split('.');
  const ext: string = arr[arr.length - 1];
  return ext.toUpperCase().toString();
};
const getExtentionFromFile = (file: any) => {
  if (!file) return '';
  const arr = file?.name?.split('.');
  const ext: string = arr[arr.length - 1];
  return ext.toUpperCase().toString();
};

interface Props {
  community_id: any;
}

const ImageBrochure = ({ community_id }: Props) => {
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const [openUrlModal, setOpenUrlModal] = React.useState<boolean>(false);
  const [openImageUploadModal, setOpenImageUploadModal] = React.useState<boolean>(false);
  const [openBrochureUrlModal, setOpenBrochureUrldModal] = React.useState<boolean>(false);
  const [openBrochureUploadModal, setOpenBrochureUploaddModal] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<'Images' | 'Brochure'>('Images');
  const [imagesData, setImagesData] = React.useState<any>(null);
  const [imagePath, setImagesPath] = React.useState<any>(null);
  const [brochureData, setBrochureData] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [dataLoading, setdataLoading] = useState(false);
  // get brochure files
  React.useEffect(() => {
    getImagesData();
    getBrochureFiles();
  }, [community_id]);

  const getImagesData = () => {
    setdataLoading(true);
    axios
      .get(`/communities/Get_CommunityPictures/${community_id}`)
      .then((res) => {
        setdataLoading(false);
        const data = res.data.payload;
        setImagesPath(res.data?.BaseURL);
        if (data.length > 0) {
          const group_data = groupByCategory(data);
          setImagesData(group_data);
        } else {
          setImagesData([]);
        }
      })
      .catch((err) => {
        setdataLoading(false);
      });
  };
  const getBrochureFiles = () => {
    axios
      .get(`/communities/Get_CommunityFiles/${community_id}/`)
      .then((res) => {
        const data = res.data.payload;
        if (data.length > 0) {
          setBrochureData(data);
        } else {
          setBrochureData([]);
        }
      })
      .catch((err) => {});
  };

  const onChange = (e: RadioChangeEvent) => {
    //
    setValue(e.target.value);
  };

  const items: MenuProps['items'] = [
    {
      label: 'Add Image URL',
      key: '1',
      icon: <LinkOutlined />,
      onClick: () => setOpenUrlModal(true),
    },
    {
      label: 'Upload Image',
      key: '2',
      icon: <UploadOutlined />,
      onClick: () => setOpenImageUploadModal(true),
    },
    {
      label: 'Add Brochure Url',
      key: '3',
      icon: <LinkOutlined />,
      onClick: () => setOpenBrochureUrldModal(true),
    },
    {
      label: 'Upload Brochure File',
      key: '4',
      icon: <UploadOutlined />,
      onClick: () => setOpenBrochureUploaddModal(true),
    },
  ];

  const handleAddData = (values: any) => {
    const formData = new FormData();
    if (values?.type === 'image_url') {
      formData.append('id', '1');
      formData.append('name', 'image');
      formData.append('image_path', '');
      formData.append('image_based_on', 'url');
      formData.append('image_url', values?.data?.imageUrl || '');
      formData.append('image_category', values?.data?.category || '');
      formData.append('company_id', `${currentUser?.company}`);
      formData.append('apartment_id', `${community_id}`);
      formData.append('op', `added`);
    } else if (values?.type === 'image_upload') {
      formData.append('id', '1');
      formData.append('name', 'image');
      formData.append('image_path', values?.data?.file || '');
      formData.append('image_based_on', 'upload');
      formData.append('image_url', '');
      formData.append('image_category', values?.data?.category || '');
      formData.append('company_id', `${currentUser?.company}`);
      formData.append('apartment_id', community_id || '');
      formData.append('op', `added`);
    }

    setLoading(true);
    axios
      .post(`/communities/Save_CommunityPictures/`, formData)
      .then((res) => {
        setLoading(false);
        setOpenUrlModal(false);
        setOpenImageUploadModal(false);
        getImagesData();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleAddBrochureData = (values: any) => {
    const formData = new FormData();
    if (values?.type === 'url') {
      const ext = getExtentionFromUrl(values?.data?.imageUrl);
      //
      //   type: ["JPG", "JPEG", "PNG", "WEBP"].includes(ext) ? "IMAGE" : "PDF",
      // });
      formData.append('id', '1');
      formData.append('name', ['JPG', 'JPEG', 'PNG', 'WEBP'].includes(ext) ? 'IMAGE' : 'PDF');
      formData.append('file_name', values?.data?.imageUrl);
      formData.append('file_extension', getExtentionFromUrl(values?.data?.imageUrl));
      formData.append('image_path', '');
      formData.append('image_based_on', 'url');
      formData.append('image_url', values?.data?.imageUrl || '');
      formData.append('image_category', 'Document');
      formData.append('company_id', `${currentUser?.company}`);
      formData.append('apartment_id', `${community_id}`);
      formData.append('op', `added`);
    } else if (values?.type === 'upload') {
      const ext = getExtentionFromFile(values?.data);
      const file_type = ['JPG', 'JPEG', 'PNG', 'WEBP'].includes(ext) ? 'IMAGE' : 'PDF';
      // console.log({ ext, file_type, values });
      formData.append('id', '1');
      formData.append('name', file_type);
      formData.append('file_name', values?.data?.name);
      formData.append('file_extension', values?.data?.type);
      formData.append('image_path', values?.data || '');
      formData.append('image_based_on', 'upload');
      formData.append('image_url', '');
      formData.append('image_category', 'Document');
      formData.append('company_id', `${currentUser?.company}`);
      formData.append('apartment_id', `${community_id}`);
      formData.append('op', `added`);
    }
    setLoading(true);
    axios
      .post(`/communities/Save_CommunityDocs/`, formData)
      .then((res) => {
        setLoading(false);
        setOpenBrochureUrldModal(false);
        setOpenBrochureUploaddModal(false);
        getBrochureFiles();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleDeleteData = (data: any, type: 'image' | 'file') => {
    const formData = new FormData();
    formData.append('id', data?.id);
    formData.append('name', data?.name);
    formData.append('image_path', data?.image_path);
    formData.append('image_based_on', data?.image_based_on);
    formData.append('image_url', data?.image_url);
    formData.append('image_category', '');
    formData.append('company_id', data?.company_id);
    formData.append('apartment_id', data?.apartment_id);
    formData.append('op', `deleted`);
    setLoading(true);
    axios
      .post(`/communities/${type === 'file' ? 'Save_CommunityDocs' : 'Save_CommunityPictures'}/`, formData)
      .then((res) => {
        setLoading(false);
        getBrochureFiles();
        getImagesData();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <div style={{ padding: '0px 20px 100px 20px' }}>
      <AddImageUrlModal
        open={openUrlModal}
        handleClose={() => setOpenUrlModal(false)}
        title="Add Url"
        handleAddData={handleAddData}
        loading={loading}
      />
      <UploadImageModal
        open={openImageUploadModal}
        handleClose={() => setOpenImageUploadModal(false)}
        title="Upload Image"
        handleAddData={handleAddData}
        loading={loading}
      />

      <AddBrochureUrlModal
        open={openBrochureUrlModal}
        handleClose={() => setOpenBrochureUrldModal(false)}
        title="Add Brochure URL"
        handleAddData={handleAddBrochureData}
        loading={loading}
      />
      <UploadBrochureFileModal
        open={openBrochureUploadModal}
        handleClose={() => setOpenBrochureUploaddModal(false)}
        title="Upload Brochure File"
        handleAddData={handleAddBrochureData}
        loading={loading}
      />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Radio.Group onChange={onChange} value={value}>
          <Radio value={'Images'}>Images</Radio>
          <Radio value={'Brochure'}>Brochures</Radio>
        </Radio.Group>
        <Dropdown menu={{ items }}>
          <Button size="middle">
            <Space>
              Add
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      </div>
      {dataLoading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 200,
          }}
        >
          <Spin />
        </div>
      ) : (
        <>
          {value === 'Images' && (
            <div>
              {imagesData && Object.keys(imagesData).length > 0 ? (
                Object.keys(imagesData).map((key) => (
                  <div key={key} style={{ marginTop: 30 }}>
                    <Typography.Title level={2} style={{ marginBottom: 0 }}>
                      {key}
                    </Typography.Title>
                    <Divider style={{ margin: 0 }} />
                    <Space wrap>
                      {imagesData[key].map((img: any) => (
                        <BrochureImageComp
                          key={img?.id}
                          img={img}
                          imagePath={imagePath}
                          handleDeleteData={handleDeleteData}
                        />
                      ))}
                    </Space>
                  </div>
                ))
              ) : (
                <div style={{ marginTop: 30 }}>
                  <Empty />
                </div>
              )}
            </div>
          )}

          {/* brochure */}
          {value === 'Brochure' && (
            <div>
              {brochureData && brochureData.length > 0 ? (
                <Space wrap>
                  {brochureData.map((img: any) => (
                    <BrochureFileComp
                      key={img?.id}
                      img={img}
                      imagePath={imagePath}
                      handleDeleteData={handleDeleteData}
                    />
                  ))}
                </Space>
              ) : (
                <div style={{ marginTop: 30, width: '100%' }}>
                  <Empty />
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ImageBrochure;
