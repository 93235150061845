import React, { useEffect, useState } from 'react';

import { CloseCircleFilled, EditFilled, PlusOutlined } from '@ant-design/icons';
import { Button, message, Popconfirm, Space, Typography } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import shortUUID from 'short-uuid';

import axios from 'config/axiosPrivate';
import AddLinkModal from 'modals/AddLinkModal';

interface DataType {
  sr_num?: number;
  id: number;
  key: string;
  apartment_id: number;
  description: string;
  page_url: string | undefined;
  status: 1 | 0;
  editMode: boolean;
  op: 'added' | 'edited' | 'deleted' | null;
}

interface Props {
  community_id: number;
}

const AdditionalLinks = ({ community_id }: Props) => {
  const [messageApi, contextHolder] = message.useMessage();

  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedData, setselectedData] = useState<DataType | null>(null);

  const [openAddLink, setOpenAddLink] = useState(false);

  useEffect(() => {
    if (community_id) {
      getAddLink();
    }
  }, [community_id]);

  const getAddLink = () => {
    setDataLoading(true);
    axios
      .get(`/communities/Get_CommunityAdditionalLinks/${community_id}/`)
      .then((res) => {
        if (res.data?.payload && res.data?.payload?.length > 0) {
          const data = res.data.payload?.map((el: any, idx: number) => ({
            sr_num: idx + 1,
            id: el?.id,
            key: shortUUID.generate(),
            description: el?.description,
            page_url: el?.page_url,
            status: el?.status,
            editMode: false,
            op: null,
          }));
          setDataSource(data);
        } else {
          setDataSource([]);
        }
        setDataLoading(false);
      })
      .catch((err) => {
        setDataLoading(false);
      });
  };

  const showMessage = (type: 'success' | 'error', mgs: string) => {
    messageApi.open({
      type: type,
      content: mgs,
    });
  };

  const handleDeleteData = (values: any) => {
    const payload = {
      id: values?.id,
      apartment_id: community_id,
      page_url: values?.page_url || '',
      description: values?.description || '',
      status: 1,
      op: 'deleted',
    };

    axios
      .post('communities/Save_AdditionalLinks/', { data: [payload] })
      .then((res) => {
        showMessage('success', 'Deleted successfully!');
        getAddLink();
      })
      .catch((err) => {
        showMessage('error', 'Something went wrong');
      });
  };

  const saveFormData = (values: any) => {
    const payload = {
      id: selectedData ? selectedData?.id : 1,
      apartment_id: community_id,
      page_url: values?.page_url || '',
      description: values?.description || '',
      status: 1,
      op: selectedData ? 'edited' : 'added',
    };
    setLoading(true);
    axios
      .post('communities/Save_AdditionalLinks/', { data: [payload] })
      .then((res) => {
        setLoading(false);
        showMessage('success', 'Saved successfully!');
        getAddLink();
        setOpenAddLink(false);
      })
      .catch((err) => {
        setLoading(false);
        showMessage('error', 'Something went wrong');
      });
  };

  const columns: ColumnsType<DataType> = [
    {
      title: (
        <Typography.Title style={{ margin: 0 }} level={4}>
          Sr. No.
        </Typography.Title>
      ),
      dataIndex: 'sr_num',
      key: 'sr_num',
      align: 'center',
      width: 80,
      render(value, record: any, index) {
        return <span style={{ fontSize: 20 }}>{value}</span>;
      },
    },
    {
      title: (
        <Typography.Title style={{ margin: 0 }} level={4}>
          Page Url
        </Typography.Title>
      ),
      dataIndex: 'page_url',
      key: 'page_url',
      width: 300,
      align: 'left',
      render(value, record: any, index) {
        return (
          <a style={{ fontSize: 20, cursor: 'pointer' }} href={value} target="_blank" rel="noreferrer">
            {value}
          </a>
        );
      },
    },
    {
      title: (
        <Typography.Title style={{ margin: 0 }} level={4}>
          Description
        </Typography.Title>
      ),
      dataIndex: 'description',
      key: 'description',
      render(value, record: any, index) {
        return (
          <span
            style={{
              fontSize: 20,
              whiteSpace: 'break-spaces',
            }}
          >
            {value}
          </span>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '100px',
      render: (_, record: any) => (
        <Space size="small" className="actions">
          <Button
            icon={<EditFilled />}
            size="small"
            type="default"
            onClick={() => {
              setOpenAddLink(true);
              setselectedData(record);
            }}
          />
          <Popconfirm
            title="Delete the task"
            description="Are you sure to delete this task?"
            onConfirm={() => handleDeleteData(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button icon={<CloseCircleFilled />} size="small" type="default" danger style={{ margin: '10px 0px' }} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const tableData = dataSource.filter((el) => el?.op !== 'deleted');

  return (
    <div style={{ padding: '0px 20px 50px 20px' }}>
      {contextHolder}
      <AddLinkModal
        open={openAddLink}
        title="Add Additional Link"
        handleClose={() => setOpenAddLink(false)}
        handleAddNote={saveFormData}
        loading={loading}
        initialValues={selectedData}
      />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          style={{ marginBottom: '20px' }}
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setOpenAddLink(true)}
        />
      </div>
      <Table
        bordered
        dataSource={tableData}
        columns={columns}
        pagination={false}
        scroll={{ x: 'scroll', y: '60vh' }}
        loading={dataLoading}
      />
    </div>
  );
};

export default AdditionalLinks;
