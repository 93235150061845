import React from 'react';

import GoogleMap from 'google-map-react';

import Marker from './Marker';

const GMap = () => {
  return (
    <div
      style={{
        width: '100%',
        minHeight: '650px',
        height: 'calc(100vh - 100px)',
      }}
    >
      <GoogleMap
        bootstrapURLKeys={{
          key: '',
          language: 'en',
          libraries: ['places'],
        }}
        center={{
          lat: 31.5204,
          lng: 74.3587,
        }}
        zoom={11}
        //   onBoundsChange={this._onBoundsChange}
        //   onChildClick={this._onChildClick}
        //   onChildMouseEnter={this._onChildMouseEnter}
        //   onChildMouseLeave={this._onChildMouseLeave}
        //   margin={[K_MARGIN_TOP, K_MARGIN_RIGHT, K_MARGIN_BOTTOM, K_MARGIN_LEFT]}
        //   hoverDistance={K_HOVER_DISTANCE}
        //   distanceToMouse={this._distanceToMouse}
      >
        <Marker lat={31.5204} lng={74.3587} data={null} />
      </GoogleMap>
    </div>
  );
};

export default GMap;
