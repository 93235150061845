import React from 'react';
import { useQuery } from 'react-query';

import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, message, Row, Select } from 'antd';
import { matchSorter } from 'match-sorter';

import axios from 'config/axiosPrivate';
import AddMystryShopModal from 'modals/AddMystryShopModal';
import UpdateStatusModal from 'modals/UpdateStatusModal';
import { useAppSelector } from 'store/hook';

import MysteryShopTable from '../MysteryShop/MysteryShopTable';

const MysteryShop = () => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [openUpdateStatus, setOpenUpdateStatus] = React.useState<boolean>(false);
  const [allData, setAllData] = React.useState<any[]>([]);
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const [tableData, setTableData] = React.useState<any[]>([]);
  const [selectedData, setSelectedData] = React.useState<any>(null);
  const [selectedStatus, setStatus] = React.useState(1);
  const [messageApi, contextHolder] = message.useMessage();
  const [companies, setCompanies] = React.useState<any[]>([]);
  const [communitiesOptions, setCommunities] = React.useState<any[]>([]);
  const showMessage = (type: 'success' | 'error', mgs: string) => {
    messageApi.open({
      type: type,
      content: mgs,
    });
  };
  const mystry_state = useQuery({
    queryKey: 'Get_MysteryShops',
    queryFn: () =>
      axios.get('/communities/Get_MysteryShops/').then((res) => {
        const data: any[] = res.data?.payload?.MysteryShop;
        const updatedData = data?.map((el, index) => ({
          ...el?.Shop,
          sr_no: index + 1,
        }));
        return updatedData;
      }),
    onSuccess: (data) => {
      setAllData(data);
      setTableData(data);
    },
  });
  useQuery({
    queryKey: 'Get_UserManagementCompanies',
    queryFn: () => axios.get(`/company/Get_UserManagementCompanies/`).then((res) => res.data?.payload),
    onSuccess: (data) => {
      const companiesList = [];
      for (const key in data) {
        companiesList.push(data[key]);
      }
      setCompanies(companiesList);
    },
    retry: false,
  });
  // get communities

  const onFinish = (values: any) => {
    //
  };

  const handleStatusChange = (value: number) => {
    setStatus(value);
    if (value === 0) {
      setTableData(allData);
      return;
    }
    const selectedData = allData.filter((d) => `${d.status}` === value.toString());
    setTableData(selectedData);
  };

  const handleEdit = (data: any) => {
    setSelectedData(data);
    setOpen(true);
  };

  const handleDelete = async (data: any) => {
    const payload = {
      id: data.id,
      op: 'deleted',
    };
    // if (data.Details) {
    // const shop_details_payload = data.Details.map((detail: any) => ({
    //   id: detail.id,
    //   op: "deleted",
    // }));
    // await axios
    //   .post("/communities/Save_MysteryShopDetail/", {
    //     data: shop_details_payload,
    //   })
    //   .then(async (details_res) => {
    await axios
      .post('/communities/Save_MysteryShop/', { data: [payload] })
      .then((res) => {
        showMessage('success', 'Deleted successfully!');
        mystry_state.refetch();
      })
      .catch((err) => {
        showMessage('error', 'Something went wrong');
      });
    // });
    // }
    // else {
    //   await axios
    //     .post("/communities/Save_MysteryShop/", { data: [payload] })
    //     .then((res) => {});
    // }
  };

  const handleOnSearch = (value: string) => {
    const results = matchSorter(allData, value, {
      keys: ['apartment_name', 'request_deadline', 'user_name'],
    });
    setTableData(results);
  };

  const handleUpdateStatus = (data: any) => {
    setSelectedData(data);
    setOpenUpdateStatus(true);
  };
  const handleCancel = () => {
    setSelectedData(null);
    setOpen(false);
    setOpenUpdateStatus(false);
  };

  return (
    <div style={{ padding: 10 }}>
      {contextHolder}
      <UpdateStatusModal
        open={openUpdateStatus}
        handleClose={handleCancel}
        selectedId={selectedData?.id}
        status={selectedData?.status}
        onSuccess={() => {
          showMessage('success', 'Saved successfully!');
          mystry_state.refetch();
          setOpenUpdateStatus(false);
        }}
      />
      {open && (
        <AddMystryShopModal
          open={open}
          companies={companies}
          handleClose={handleCancel}
          onSuccess={() => {
            showMessage('success', 'Saved successfully!');
            mystry_state.refetch();
            setSelectedData(null);
            setOpen(false);
          }}
          selectedData={selectedData}
        />
      )}

      <Form onFinish={onFinish} layout="vertical" requiredMark={false} autoComplete="off">
        <Row gutter={[10, 10]}>
          {/* <Col xs={24} md={12}>
              <Form.Item name="checkbox-group" label="Filter by Status">
                <Checkbox value="Communities" style={{ lineHeight: "32px" }}>
                  Requested
                </Checkbox>
                <Checkbox value="Competitors" style={{ lineHeight: "32px" }}>
                  In Progress
                </Checkbox>
                <Checkbox value="Competitors" style={{ lineHeight: "32px" }}>
                  Ready for Review
                </Checkbox>
                <Checkbox value="Competitors" style={{ lineHeight: "32px" }}>
                  Solved
                </Checkbox>
                <Checkbox value="Competitors" style={{ lineHeight: "32px" }}>
                  Satisfied
                </Checkbox>
              </Form.Item>
            </Col> */}
          <Col xs={24}>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ width: '450px' }}>
                <Select
                  placeholder="Select"
                  options={[
                    { label: 'All', value: 0 },
                    { label: 'Requested', value: 1 },
                    { label: 'In Progress', value: 2 },
                    { label: 'Ready for Review', value: 3 },
                    { label: 'Solved', value: 4 },
                    { label: 'Satisfied', value: 5 },
                  ]}
                  style={{ width: '100%' }}
                  onSelect={(value) => handleStatusChange(value)}
                  value={selectedStatus}
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Input
                  prefix={<SearchOutlined />}
                  style={{ marginRight: 20 }}
                  placeholder="Search..."
                  onChange={(e) => handleOnSearch(e.target.value)}
                />
                <Button
                  icon={<PlusOutlined />}
                  type="link"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setOpen(true)}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Form>
      <br />
      <MysteryShopTable
        data={tableData}
        loading={mystry_state.isLoading}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        handleUpdateStatus={handleUpdateStatus}
      />
    </div>
  );
};

export default MysteryShop;
