import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "config/axiosPrivate";
import {
  Typography,
  Select,
  Button,
  Table,
  Row,
  Col,
  SelectProps,
  DatePicker,
  Rate,
  message,
} from "antd";
import SelectInput from "formComponents/SelectInput";
import { useAppSelector } from "store/hook";
import dayjs from "dayjs";
import { format } from "date-fns";
import type { DatePickerProps } from "antd";
import { useQuery } from "react-query";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import CreateBuisnessModal from "modals/CreateBuisnessModal";
// import UpdateBuisnessModal from "modals/SentimentAnalysis/UpdateBuisnessModal";

const SentimentAnalysis = () => {
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const userCompanies = useAppSelector((state) => state.global.companyList);
  const [company, setCompany] = useState<number | null>(null);
  const [buisnessNumbers, setBuisnessNumbers] = useState<string[]>([]);
  const [community, setCommunity] = useState<any | null>(null);
  const [birdEyeData, setBirdEyeData] = useState<any[]>([]);
  const [birdEyeBuisnessChildren, setBirdEyeBuisnessChildren] = useState<any[]>([]);
  const [birdEyeRatingData, setBirdEyeRatingData] = useState<any | null>(null);
  const [birdEyeReviewsData, setBirdEyeReviewsData] = useState<any[]>([]);
  const [companyOptions, setCompanyOptions] = useState<SelectProps['options']>([]);
  const [fromDate, setFromDate] = useState('01-01-2023');
  const [toDate, setToDate] = useState(format(new Date(), 'MM-dd-yyyy').toString());
  const [months, setMonths] = useState('12');
  const [loading, setLoading] = useState(false);
  const [createBuisnessLoading, setCreateBuisnessLoading] = useState(false);
  const [birdEyeDataLoading, setBirdEyeDataLoading] = useState(false);
  const [openCreateBuisnessModal, setOpenCreateBuisnessModal] = useState(false);
  const [openUpdateBuisnessModal, setOpenUpdateBuisnessModal] = useState(false);
  const [companyCommunities, setCompanyCommunities] = useState<any>([]);
  const [loadingBirdEyeBuisnessChildren, setLoadingBirdEyeBuisnessChildren] = useState(false);

  const columns = [
    Table.EXPAND_COLUMN,
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            Sr No
          </Typography.Title>
        </div>
      ),
      dataIndex: 'index',
      key: 'index',
      width: 50,
    },
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: "16px" }}>
            Business Name
          </Typography.Title>
        </div>
      ),
      dataIndex: 'alias',
      key: 'alias',
      width: 200,
    },
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            Overall Avg Score
          </Typography.Title>
        </div>
      ),
      dataIndex: 'avgScore',
      key: 'avgScore',
      width: 100,
    },
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            Overall Grade
          </Typography.Title>
        </div>
      ),
      dataIndex: 'grade',
      key: 'grade',
      width: 100,
    },
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            Total Count
          </Typography.Title>
        </div>
      ),
      dataIndex: 'totalCount',
      key: 'totalCount',
      width: 100,
    },
  ];

  const ratingColumns = [
    // {
    //   title: (
    //     <div>
    //       <Typography.Title level={4} style={{ margin: 0, fontSize: "16px" }}>
    //         Sr No
    //       </Typography.Title>
    //     </div>
    //   ),
    //   dataIndex: "index",
    //   key: "index",
    //   width: 50,
    // },
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: "16px" }}>
            Business Name
          </Typography.Title>
        </div>
      ),
      dataIndex: 'label',
      key: 'label',
      width: 200,
    },
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            Avg Rating
          </Typography.Title>
        </div>
      ),
      dataIndex: 'averageRating',
      key: 'averageRating',
      width: 100,
    },
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            Count
          </Typography.Title>
        </div>
      ),
      dataIndex: 'count',
      key: 'count',
      width: 100,
    },
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            Total Count
          </Typography.Title>
        </div>
      ),
      dataIndex: 'totalCount',
      key: 'totalCount',
      width: 100,
    },
  ];

  const reviewsColumns = [
    Table.EXPAND_COLUMN,
    // {
    //   title: (
    //     <div>
    //       <Typography.Title level={4} style={{ margin: 0, fontSize: "16px" }}>
    //         Sr No
    //       </Typography.Title>
    //     </div>
    //   ),
    //   dataIndex: "index",
    //   key: "index",
    //   width: 50,
    // },
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: "16px" }}>
            Business Name
          </Typography.Title>
        </div>
      ),
      dataIndex: 'label',
      key: 'label',
      width: 900,
    },
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: '16px' }}>
            Count
          </Typography.Title>
        </div>
      ),
      dataIndex: 'count',
      key: 'count',
      width: 100,
      render: (_: any, row: any) => (
        <div style={{ height: '100%', width: '100%' }}>
          <Typography.Paragraph style={{ fontSize: '14px' }}>
            {''}
            {row.reviewsData.length}
          </Typography.Paragraph>
        </div>
      ),
    },
    // {
    //   title: (
    //     <div>
    //       <Typography.Title level={4} style={{ margin: 0, fontSize: "16px" }}>
    //         Count
    //       </Typography.Title>
    //     </div>
    //   ),
    //   dataIndex: "count",
    //   key: "count",
    //   width: 100,
    // },
    // {
    //   title: (
    //     <div>
    //       <Typography.Title level={4} style={{ margin: 0, fontSize: "16px" }}>
    //         Total Count
    //       </Typography.Title>
    //     </div>
    //   ),
    //   dataIndex: "totalCount",
    //   key: "totalCount",
    //   width: 100,
    // },
  ];

  const onFDateChange: DatePickerProps["onChange"] = (date, dateString) => {
    //
    setFromDate(dateString);
  };

  const onTDateChange: DatePickerProps["onChange"] = (date, dateString) => {
    //
    setToDate(dateString);
  };

  const showMessage = (type: 'success' | 'error', mgs: string) => {
    messageApi.open({
      type: type,
      content: mgs,
    });
  };

  // const getCommunityInfo = (id: number) => {
  //   const community = companyCommunities.filter(
  //     (item: any) => item.value == id
  //   );
  //   if (community.length > 0) {
  //
  //     setCommunity(community[0]);
  //   }
  // };

  useQuery({
    queryKey: 'Get_UserCompanies',
    queryFn: () => axios.get(`company/Get_UserCompanies/`).then((res) => res.data?.payload),
    onSuccess: (data) => {
      //
      const companiesList = data.map((item: any, idx: any) => ({
        value: item.birdeye_business_id || -1 * idx,
        label: item.name,
        id: item.id,
        apikey: item.birdeye_apikey,
      }));
      setCompanyOptions(companiesList);
    },
    retry: false,
  });

  const getCompanyCommunities = async (value: any) => {
    try {
      const res = await axios.get(`/communities/Get_CompanyCommunities/${value}/`);
      const data = res.data?.payload;
      if (data) {
        const list = data?.map((el: any) => {
          if (el.type == 'apartment') {
            return {
              ...el,
              label: el?.comunity_name,
              value: el.birdeye_business_id,
              id: el.id,
            };
          }
        });

        setCompanyCommunities(list);
      } else {
        setCompanyCommunities([]);
      }
      // setLoadingCommunities(false);
    } catch (error) {
      // setLoadingCommunities(false);
    }
  };

  const getProcessId = () => {
    setLoading(true);
    // axios
    //   .get(`/birdeye/Get_be_category_report_by_bid_loc/${company}/`)
    //   .then((res) => {
    //     const data = res.data.payload;
    // if (data.Birdeye_category_report?.length > 0) {
    // const { process_id } = data.Birdeye_category_report[0];
    //
    const companySelected = companyOptions?.filter(
      (item) => item.value == company
    )[0];
    let ratingPayload: any = [];
    if (birdEyeRatingData?.dataPoints) {
      birdEyeRatingData.dataPoints.map((point: any, idx: any) => {
        if (idx < 1) {
          return;
        }
        const community = birdEyeBuisnessChildren.filter((child: any) => child.id == point.businessNumber)[0];
        // const apartmentId =
        //   companyCommunities.filter(
        //     (comp: any) => comp.value == community?.id
        //   )[0]?.id || 0;
        //
        // if (apartmentId > 0) {
        ratingPayload.push({
          id: 1,
          // process_id: Number(process_id) + 1,
          company_id: companySelected?.id,
          company_name: companySelected?.label,
          // apartment_id: apartmentId,
          community_name: community?.name,
          birdeye_business_id: point.businessNumber,
          alias: community.alias,
          totalcount: point.totalCount,
          count: point.count,
          average_rating: point.averageRating,
          nonzerocount: point.nonZeroCount,
          review_growth_percent: point.reviewGrowthPercent,
          avgrating_growth_percent: point.avgRatingGrowthPercent,
          prev_count: point.prevCount,
          prev_average_rating: point.prevAverageRating,
          op: 'added',
        });
        // }
      });

      if (ratingPayload.length > 0) {
        saveBirdEyeRatingData(ratingPayload);
      }
    }
    const payload: any = [];
    birdEyeData.map((item) => {
      // axios
      //   .get(`/communities/Get_CommunitybyAlias/${item.alias.split(",")[0]}/`)
      //   .then((response) => {
      // const aliasdata = response.data.payload;
      //
      // let payload: any = [];
      Object.keys(item.metricForLocn).map((key) => {
        payload.push({
          // process_id: Number(process_id) + 1,
          company_id: companySelected?.id,
          // apartment_id: aliasdata[0]?.id,
          // birdeye_business_id: buisnessNumbers[0],
          alias: item.alias,
          overall_avgscore: item.avgScore,
          overall_grade: item.grade || '',
          overall_score: item.score || 0,
          category: item.metricForLocn[key].category,
          category_avgscore: item.metricForLocn[key].avgScore,
          category_count: item.metricForLocn[key].count,
          category_grade: item.metricForLocn[key].grade || '',
          op: 'added',
        });
      });
      // saveBirdEyeData(payload);
      // })
      // .catch((err) => {
      //
      //   setLoading(false);
      //   showMessage("error", "Something went wrong");
      // });
    });
    //
    if (payload.length > 0) {
      saveBirdEyeData(payload);
    }

    let reviewsPayload: any = [];
    birdEyeReviewsData.forEach((item) => {
      item.reviewsData.forEach((review: any) => {
        reviewsPayload.push({
          // apartment_id: 0,
          type: review.businessType,
          birdeye_business_id: review.businessId,
          reviewid: review.reviewId,
          rating: review.rating,
          comments: review.comments,
          title: review.title,
          reviewer: review.reviewer.nickName,
          firstname: review.reviewer.firstName,
          lastname: review.reviewer.lastName,
          nickname: review.reviewer.nickName,
          thumbnailurl: review.reviewer.thumbnailUrl,
          emailid: review.reviewer.emailId,
          phone: review.reviewer.phone,
          facebookid: review.reviewer.facebookId,
          city: review.reviewer.city,
          state: review.reviewer.state,
          customerid: review.reviewer.customerId,
          reviewurl: review.reviewUrl,
          sourcetype: review.sourceType,
          reviewdate: review.reviewDate
            ? format(new Date(review.reviewDate), "yyyy-MM-dd")
            : "",
          response: review.response,
          uniquereviewurl: review.uniqueReviewUrl,
          businessname: review.businessName,
          businesstype: review.businessType,
          tags: review.tags,
          review_status: review.status,
          extraparams: review.extraParams,
          recommended: review.recommended,
          assitedbyuser: "",
          sentimenttype: review.sentimentType,
          enablereply: review.enableReply,
          medias: "",
          customerid_0: review.customerId,
          rdate: review.rdate,
          status: 1,
          apartment: 0,
          op: "added",
        });
      });
    });
    if (reviewsPayload.length > 0) {
      saveBirdEyeReviewsData(reviewsPayload);
    }
    // }
    //
    // })
    // .catch((err) => {
    //
    //   setLoading(false);
    //   showMessage("error", "Something went wrong");
    // });
  };

  const saveBirdEyeData = (payload: any) => {
    axios
      .post(`/birdeye/Save_Category_Report_by_Location/`, { data: payload })
      .then((res) => {
        //
        setLoading(false);
        showMessage('success', 'Saved successfully!');
        setBirdEyeData([]);
      })
      .catch((err) => {
        setLoading(false);
        showMessage('error', 'Something went wrong');
      });
  };

  const saveBirdEyeRatingData = (payload: any) => {
    axios
      .post(`/birdeye/Save_BirdeyeRatingReport/`, { data: payload })
      .then((res) => {
        //
        setLoading(false);
        showMessage('success', 'Saved Rating successfully!');
        setBirdEyeRatingData([]);
      })
      .catch((err) => {
        setLoading(false);
        showMessage("error", "Something went wrong");
      });
  };

  const saveBirdEyeReviewsData = (payload: any) => {
    axios
      .post(`/birdeye/Save_BirdeyeReviewReport/`, { data: payload })
      .then((res) => {
        //
        setLoading(false);
        showMessage("success", "Saved Reviews successfully!");
        setBirdEyeReviewsData([]);
      })
      .catch((err) => {
        setLoading(false);
        showMessage('error', 'Something went wrong');
      });
  };

  const getBirdEyeData = () => {
    if (!company || company < 1) {
      return;
    }
    getBirdEyeRatingData();
    getBirdEyeReviewsData();
    setBirdEyeDataLoading(true);
    let data = JSON.stringify({
      // startDate: format(new Date(fromDate), "MM/dd/yyyy"),
      // endDate: format(new Date(toDate), "MM/dd/yyyy"),
      // sindex: 0,
      // count: 20,
      // startIndex: 5,
      // pageSize: 25,
      // months: 12,
      businessNumbers: buisnessNumbers,
    });
    // const apiKey = companyOptions?.filter((item) => item.value == company)[0]
    //   .apikey;
    // MaNtUNJj4nbZPRIzOIRO6D4OkKFkcwqu
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      // url: `https://api.birdeye.com/resources/v1/business/child/all?api_key=pr80Fc7ICB8F8xWMM98e7QxNYpbASrSD&pid=${company}`,
      url: `https://api.birdeye.com/resources/v1/keywords/opmetric/location?api_key=pr80Fc7ICB8F8xWMM98e7QxNYpbASrSD&businessId=${company}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        const data = response.data.map((item: any, idx: any) => ({
          ...item,
          index: idx + 1,
        }));
        setBirdEyeData(data);
        setBirdEyeDataLoading(false);
      })
      .catch((error) => {
        setBirdEyeDataLoading(false);
        setBirdEyeData([]);
      });
  };

  const getBuisnessChild = (company_id: any) => {
    setLoadingBirdEyeBuisnessChildren(true);
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `https://api.birdeye.com/resources/v1/business/child/all?api_key=pr80Fc7ICB8F8xWMM98e7QxNYpbASrSD&pid=${company_id}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    };

    axios
      .request(config)
      .then((response) => {
        const data = response.data.map((item: any) => ({
          ...item,
          label: item.name,
          value: item.id,
        }));
        setBirdEyeBuisnessChildren(data);
        setLoadingBirdEyeBuisnessChildren(false);
        // let businessNumbers: string[] = [];
        // data.map((child: any) => {
        //   businessNumbers.push(`${child.id}`);
        // });
        // getBirdEyeRatingData(businessNumbers);
        // getBirdEyeData(businessNumbers);
      })
      .catch((error) => {
        setBirdEyeBuisnessChildren([]);
        setLoadingBirdEyeBuisnessChildren(false);
      });
  };

  const getBirdEyeRatingData = () => {
    if (!company) {
      return;
    }
    let finalBusinessNumbers: string[] = [];
    if (buisnessNumbers.length < 1) {
      birdEyeBuisnessChildren.map((child: any) => {
        finalBusinessNumbers.push(`${child.value}`);
      });
    } else {
      finalBusinessNumbers = buisnessNumbers;
    }

    // setBirdEyeDataLoading(true);
    const data = JSON.stringify({
      startDate: '01/01/2023',
      endDate: '12/12/2023',
      sourceAliases: [],
      businessNumbers: finalBusinessNumbers,
      months: 12,
    });

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `https://api.birdeye.com/resources/v1/reports/rating/location?api_key=pr80Fc7ICB8F8xWMM98e7QxNYpbASrSD&businessId=${company}&sortby=rating&sorder=0`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        setBirdEyeRatingData(response.data);
      })
      .catch((error) => {
        setBirdEyeRatingData([]);
      });
  };

  const getBirdEyeReviewsData = () => {
    if (!company) {
      return;
    }
    //
    let finalBusinessNumbers: string[] = [];
    if (buisnessNumbers.length < 1) {
      birdEyeBuisnessChildren.map((child: any) => {
        finalBusinessNumbers.push(`${child.value}`);
      });
    } else {
      finalBusinessNumbers = buisnessNumbers;
    }

    // setBirdEyeDataLoading(true);
    const data = JSON.stringify({
      // fromDate: "04/01/2013",
      // toDate: "17/10/2023",
      // updateFromDate: "04/01/2013",
      // updateToDate: "17/10/2023",
      // sources: ["google", "care"],
      ratings: [1, 2, 3, 4, 5, 0],
      // searchStr: "test string",
      subBusinessIds: finalBusinessNumbers,
      statuses: ['published', 'parked'],
      allChild: 'true',
      // tags: ["positive", "negative"],
      fetchExtraParams: false,
      needCustomerInfo: false,
    });

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `https://api.birdeye.com/resources/v1/review/businessId/${company}?sindex=1&count=30&api_key=${'pr80Fc7ICB8F8xWMM98e7QxNYpbASrSD'}&includeNonAggregatedReviews=false`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        //
        if (buisnessNumbers.length < 1) {
          const reviews = birdEyeBuisnessChildren.map((item: any) => ({
            ...item,
            reviewsData: response.data.filter((review: any) => review.businessId == item.id),
          }));
          //
          setBirdEyeReviewsData(reviews);
        } else {
          const finalNumbers = birdEyeBuisnessChildren.filter((item) => {
            // const number = buisnessNumbers.filter((bnum) => bnum == item.id)
            //
            //
            if (buisnessNumbers.indexOf(item.id) > -1) {
              //
              return item;
            }
          });
          //
          const reviews = finalNumbers.map((item: any) => ({
            ...item,
            reviewsData: response.data.filter((review: any) => review.businessId == item.id),
          }));

          setBirdEyeReviewsData(reviews);
        }
      })
      .catch((error) => {
        // setBirdEyeRatingData([]);
      });
  };

  const createBuisness = (values: any) => {
    if (openUpdateBuisnessModal) {
      if (values.bid) {
        setCreateBuisnessLoading(true);
        updateBuisness(values.bid, values);
        return;
      }
    } else {
      if (values.bid) {
        showMessage("error", "Business already exist");
        return;
      }
    }
    const data = JSON.stringify({
      businessName: values.buisnessName,
      zip: values.zip,
      type: 'Business',
      phone: values.phone,
      aggrOptions: values.aggrOptions,
    });
    // 169090028212657
    // pr80Fc7ICB8F8xWMM98e7QxNYpbASrSD
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `https://api.birdeye.com/resources/v1/signup/reseller/subaccount?api_key=pr80Fc7ICB8F8xWMM98e7QxNYpbASrSD&rid=${
        values.type == 'Business' ? '169090028212657' : values.company
      }&email_id=imtiaz@talkfurther.com`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: data,
    };

    setCreateBuisnessLoading(true);
    axios
      .request(config)
      .then((response) => {
        updateBuisness(response.data.businessId, values);
        axios
          .post(`communities/Save_CommuntiyBirdeyeInfo/`, {
            id: values.buisness,
            birdeye_business_id: response.data.businessId,
            op: 'edited',
          })
          .then((res) => {
            setCreateBuisnessLoading(false);

            showMessage("success", "Saved successfully!");
            setOpenCreateBuisnessModal(false);
          })
          .catch((err) => {
            setLoading(false);
            showMessage('error', 'Something went wrong');
          });
      })
      .catch((error) => {
        setCreateBuisnessLoading(false);
        if (error.response.data.code == 1011) {
          showMessage('error', 'Company not Found...!!');
        } else if (error.response.data.message) {
          showMessage('error', error.response.data.message);
        } else {
          showMessage('error', 'Something went wrong');
        }
      });
  };

  const updateBuisness = (bid: any, values: any) => {
    let data: any = {
      alias: values.buisnessName,
      location: {
        address1: values.address,
        city: values.city,
        state: values.state,
        zip: values.zip,
        countryCode: 'US',
        countryName: 'United States of America',
        lat: values.latitude,
        lng: values.longitude,
      },
    };
    if (values.website) {
      data['websiteUrl'] = values.website;
    }
    // 169090028212657
    // pr80Fc7ICB8F8xWMM98e7QxNYpbASrSD
    const config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `https://api.birdeye.com/resources/v1/business/${bid}?api_key=pr80Fc7ICB8F8xWMM98e7QxNYpbASrSD&email_id=imtiaz@talkfurther.com`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(data),
    };
    axios
      .request(config)
      .then((response) => {
        if (openUpdateBuisnessModal) {
          setCreateBuisnessLoading(false);
          showMessage('success', 'Updated successfully!');
          setOpenCreateBuisnessModal(false);
          setOpenUpdateBuisnessModal(false);
        }
      })
      .catch((error) => {
        setCreateBuisnessLoading(false);
        if (error.response.data.message) {
          showMessage('error', error.response.data.message);
        } else {
          showMessage('error', 'Something went wrong');
        }
      });
  };

  // useEffect(() => {
  //   if (company) {
  //     getBirdEyeData();
  //   }
  // }, [fromDate, toDate]);

  useEffect(() => {
    // updateBuisness("169704868165430", "The Village At Gleannloch Farms");
  }, []);

  return (
    <div
      style={{
        padding: '20px 30px 0px',
      }}
    >
      {contextHolder}
      {openCreateBuisnessModal && (
        <CreateBuisnessModal
          open={openCreateBuisnessModal}
          title="Create/Update Business"
          handleClose={() => {
            setOpenCreateBuisnessModal(false);
            setOpenUpdateBuisnessModal(false);
          }}
          handleAddData={createBuisness}
          companyOptions={companyOptions}
          loading={createBuisnessLoading}
          edit={openUpdateBuisnessModal}
          setEdit={setOpenUpdateBuisnessModal}
        />
      )}
      {/* <UpdateBuisnessModal
        open={openUpdateBuisnessModal}
        title="Update Buisness"
        handleClose={() => setOpenUpdateBuisnessModal(false)}
        handleAddData={createBuisness}
        companyOptions={companyOptions}
        loading={createBuisnessLoading}
      /> */}
      <div>
        <div>
          <span>
            <span style={{ color: '#1f58b0', cursor: 'pointer' }} onClick={() => navigate('/')}>
              HOME
            </span>
            <span
              style={{
                color: '#222',
                margin: '0px 10px',
                textTransform: 'uppercase',
              }}
            >
              Sentiment Analysis
            </span>
          </span>
        </div>
      </div>
      <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
        <Col offset={7} xs={24} lg={4}>
          <Select
            showSearch
            optionFilterProp="children"
            filterOption={(input: any, option: any) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={companyOptions}
            placeholder="Company"
            style={{ width: '100%' }}
            onSelect={(val) => {
              setCompany(val);
              const company = companyOptions?.filter(
                (item) => item.value == val
              )[0];
              //
              getCompanyCommunities(company?.id);
              getBuisnessChild(val);
              // getMatrics(val);
            }}
          />
          {/* <SelectInput
            placeholder="Company"
            options={companyOptions}
            onApply={(value) => {
              setCompanyList(value);
            }}
            // loading={loading}
            initValue={companyList}
          /> */}
        </Col>
        <Col xs={24} lg={4}>
          <Select
            showSearch
            mode="multiple"
            allowClear
            optionFilterProp="children"
            filterOption={(input: any, option: any) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={birdEyeBuisnessChildren}
            loading={loadingBirdEyeBuisnessChildren}
            placeholder="Select Community"
            style={{ width: '100%' }}
            onChange={(values: string[]) => setBuisnessNumbers(values)}
          />
          {!loadingBirdEyeBuisnessChildren && birdEyeBuisnessChildren.length < 1 && company && (
            <p
              style={{
                color: 'red',
                fontSize: '12px',
                marginTop: '5px',
                paddingRight: '2px',
              }}
            >
              *No Community Found on BirdApi
            </p>
          )}
        </Col>
        {/* <Col xs={24} lg={4}>
          <DatePicker
            onChange={onFDateChange}
            style={{ width: "100%" }}
            placeholder="From"
            defaultValue={dayjs(fromDate, "MM-DD-YYYY")}
            format={"MM-DD-YYYY"}
          />
        </Col>
        <Col xs={24} lg={4}>
          <DatePicker
            onChange={onTDateChange}
            style={{ width: "100%" }}
            placeholder="To"
            defaultValue={dayjs(toDate)}
            format={"MM-DD-YYYY"}
          />
        </Col> */}
        <Col lg={2}>
          <Button onClick={getBirdEyeData} disabled={birdEyeDataLoading || loadingBirdEyeBuisnessChildren}>
            Fetch
          </Button>
        </Col>
        {/* <Col xs={24} lg={4}>
          <Select
            options={[
              { value: "1", label: "1 Month" },
              { value: "2", label: "2 Months" },
              { value: "3", label: "3 Months" },
              { value: "4", label: "4 Months" },
              { value: "5", label: "5 Months" },
              { value: "6", label: "6 Months" },
              { value: "7", label: "7 Months" },
              { value: "8", label: "8 Months" },
              { value: "9", label: "9 Months" },
              { value: "10", label: "10 Months" },
              { value: "11", label: "11 Months" },
              { value: "12", label: "12 Months" },
            ]}
            placeholder="No of Months"
            style={{ width: "100%" }}
            onSelect={(val) => {
              setMonths(val);
              getBirdEyeData();
            }}
            defaultValue={"12"}
          />
        </Col> */}
      </Row>
      {/* <div
        style={{
          marginTop: "10px",
          marginBottom: "10px",
          border: "1px solid red",
        }}
      ></div> */}
      <div style={{ marginTop: '20px' }}>
        <Typography.Title level={3}>Sentiment Analysis Data</Typography.Title>
        <div style={{ marginBottom: "10px", float: "right", width: "150px" }}>
          {/* <Select
            options={[
              { value: '1', label: 'Create Buisness' },
              { value: '2', label: 'Update Buisness' },
            ]}
            placeholder="Actions"
            style={{ width: '100%' }}
            onSelect={(val) => {
              setOpenCreateBuisnessModal(true);
              if (val == '2') {
                setOpenUpdateBuisnessModal(true);
              }
            }}
          /> */}
          <Button onClick={() => setOpenCreateBuisnessModal(true)}>
            Create Buisness
          </Button>
          {/* <Button onClick={() => setOpenUpdateBuisnessModal(true)}>
            Update Buisness
          </Button> */}
        </div>

        <Table
          columns={columns}
          dataSource={birdEyeData}
          loading={birdEyeDataLoading}
          rowKey="index"
          expandable={{
            expandedRowRender: (record) => (
              <div style={{ margin: '0 30px 20px' }}>
                <Row gutter={[10, 16]}>
                  <Col offset={1} span={11}>
                    <h4 style={{ marginLeft: '14px' }}>Category</h4>
                  </Col>
                  <Col span={5}>
                    <h4>Category Avg Score</h4>
                  </Col>
                  <Col span={4}>
                    <h4>Category Grade</h4>
                  </Col>
                  <Col span={3}>
                    <h4>Category Count</h4>
                  </Col>
                </Row>
                {Object.keys(record.metricForLocn).map((item: any, index: any) => (
                  <Row key={'id' + index} gutter={[16, 16]}>
                    <Col offset={1} span={11}>
                      <p style={{ marginLeft: '14px' }}>{item}</p>
                    </Col>
                    <Col span={5}>
                      <p>{record.metricForLocn[item].avgScore}</p>
                    </Col>
                    <Col span={4}>
                      <p> {record.metricForLocn[item].grade}</p>
                    </Col>
                    <Col span={3}>
                      <p> {record.metricForLocn[item].count}</p>
                    </Col>
                  </Row>
                ))}
              </div>
            ),
            //   rowExpandable: (record) =>
            //     reviews && reviews[record.id] ? true : false,
            expandIcon: ({ expanded, onExpand, record }) => (
              <div>
                {expanded ? (
                  <CaretUpOutlined onClick={(e) => onExpand(record, e)} />
                ) : (
                  <CaretDownOutlined onClick={(e) => onExpand(record, e)} />
                )}
              </div>
            ),
            columnWidth: 10,
            defaultExpandAllRows: true,
          }}
        />
      </div>
      <div style={{ marginTop: '20px' }}>
        <Typography.Title level={4}>Ratings</Typography.Title>
        <Table
          columns={ratingColumns}
          dataSource={birdEyeRatingData?.dataPoints ? birdEyeRatingData.dataPoints.slice(1) : []}
          loading={birdEyeDataLoading}
          rowKey="businessNumber"
          pagination={false}
        />
      </div>

      <div style={{ marginTop: '20px' }}>
        <Typography.Title level={4}>Reviews</Typography.Title>
        <Table
          columns={reviewsColumns}
          dataSource={birdEyeReviewsData}
          loading={birdEyeDataLoading}
          rowKey={(record: any) => 'reviews' + record.id}
          pagination={false}
          expandable={{
            expandedRowRender: (record) => (
              <div style={{ margin: '0 30px 20px' }}>
                {record.reviewsData.map((item: any, index: any) => (
                  <div style={{ marginBottom: '20px' }} key={'expand' + index}>
                    <h5
                      style={{
                        float: 'right',
                        marginRight: '10px',
                        color: '#1976D2',
                      }}
                    >
                      {item.sourceType}
                    </h5>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ marginRight: '15px' }}>
                        <img src={item.reviewer.thumbnailUrl} height="30" width="auto" />
                      </div>
                      <div>
                        <Rate allowHalf defaultValue={item.rating || 0} style={{ fontSize: '12px' }} disabled />
                        <div style={{ display: 'flex' }}>
                          <h5>{item.reviewer.nickName || 'Anonymous'}</h5> <h3 style={{ margin: '0 5px' }}> . </h3>
                          <p>{item.reviewDate}</p>
                        </div>
                      </div>
                    </div>
                    <div>
                      {/* <p style={{ color: "#000" }}>{item.comments}</p> */}
                      <Typography.Paragraph
                        style={{ whiteSpace: 'break-spaces', color: '#000' }}
                        ellipsis={{ rows: 6, expandable: true, symbol: 'more' }}
                      >
                        {item.comments}
                      </Typography.Paragraph>
                    </div>
                    {item.response && (
                      <div
                        style={{
                          padding: '20px',
                          background: '#d5d5d588',
                          marginTop: '15px',
                        }}
                      >
                        <p style={{}}>{`Business response on ${item.sourceType} ${item.responseDate}`}</p>
                        <Typography.Paragraph
                          style={{ whiteSpace: 'break-spaces', color: '#000' }}
                          ellipsis={{ rows: 3, expandable: true, symbol: 'more' }}
                        >
                          {item.response}
                        </Typography.Paragraph>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ),
            //   rowExpandable: (record) =>
            //     reviews && reviews[record.id] ? true : false,
            expandIcon: ({ expanded, onExpand, record }) => (
              <>
                {record.reviewsData.length > 0 ? (
                  <div>
                    {expanded ? (
                      <CaretUpOutlined onClick={(e) => onExpand(record, e)} />
                    ) : (
                      <CaretDownOutlined onClick={(e) => onExpand(record, e)} />
                    )}
                  </div>
                ) : null}
              </>
            ),
            columnWidth: 1,
          }}
        />
      </div>
      <Row gutter={16} style={{ marginTop: '20px' }}>
        <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button type="primary" loading={loading} disabled={loading} onClick={getProcessId}>
            Save
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default SentimentAnalysis;
