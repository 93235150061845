import React, { useState } from 'react';
import { geocodeByAddress } from 'react-places-autocomplete';
import { useSearchParams } from 'react-router-dom';

import { CaretRightFilled, LoadingOutlined, RedoOutlined } from '@ant-design/icons';
import { Col, message, Row, Skeleton, Space, Spin, Tabs, Tooltip, Typography } from 'antd';

import axios from 'config/axiosPrivate';
import LocationSearchInput from 'formComponents/LocationSearchInput';
import { getDecimals } from 'helpers';
import SearchCommunityModal from 'modals/SearchCommunityModal';
import CommunityDetails from 'pages/PriceHealing/CommunityDetails';
import Occupancies from 'pages/PriceHealing/Occupancies';
import { useAppDispatch } from 'store/hook';
import { handleClearCommunityData } from 'store/slices/subCommunitySlice';

import AccommodationTab from './AccommodationTab';
import AdditionalLinks from './AdditionalLinks';
import CompetitorSearchItem from './CompetitorSearchItem';
import FeesForm from './FeesForm';
import ShopNotes from './ShopNotes';
import classes from './styles.module.scss';
import BasicInfoForm from './sub_competitor/BasicInfoForm';
import ComparisonTab from './sub_competitor/ComparisonTab';
import ImageBrochure from './sub_competitor/ImageBrochure';

interface Comp_Avgs {
  ACC: number;
  Avg_base_rent_care: number;
  LivInfo: {
    ApartmentId: number;
    Avg_Base_Rent: number;
    Avg_Price_Per_Sq: number;
    Avg_Sq_Footage: number;
    ParentId: number;
  };
  MACC: number;
  google_rating: number;
  google_reviews: number;
}

const getCompetitorAvgRating = (data_obj: any) => {
  const values = {
    rating: 0,
    reviews: 0,
  };

  if (!data_obj || Object.keys(data_obj).length === 0) return values;

  Object.keys(data_obj).forEach((key: any) => {
    if (data_obj[key].google_rating || data_obj[key].google_reviews) {
      values.rating += data_obj[key].google_rating;
      values.reviews += data_obj[key].google_reviews;
    }
  });

  values.rating = Math.floor(values.rating / Object.keys(data_obj).length);
  values.reviews = Math.floor((values.reviews / Object.keys(data_obj).length) as number);

  return values;
};

interface Props {
  communityInfo: any;
  community_id: any;
}

const CompetitorsForm = ({ community_id, communityInfo }: Props) => {
  const [params] = useSearchParams();
  const competitor_id = params.get('c_id') as string;
  const [messageApi, contextHolder] = message.useMessage();
  const [allData, setAllData] = React.useState<any>(null);
  const [competitors, setCompetitors] = React.useState<any[]>([]);
  const [deletedCompetitors, setSeletedCompetitors] = React.useState<any[]>([]);
  const [selectedItem, setselectedItem] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [community_market, setcommunity_market] = useState<any>(null);
  const [ratingLoading, setRatingLoading] = React.useState<boolean>(false);
  const [openSearchCommunityModal, setOpenSearchCommunityModal] = useState<boolean>(false);

  const handleSetLocation = async (data: any) => {
    // console.log(data);

    const added_new_one = { ...data, op: 'added' };
    try {
      const payload = [added_new_one];
      setLoading(true);
      const { livingInfo, ...rest } = communityInfo;

      const competitorPayload = payload.map((cmp) => ({
        ...rest,
        comunity_name: cmp.name,
        address: cmp.formatted_address,
        state: cmp.state,
        latitude: cmp.lat,
        longitude: cmp.lng,
        city: cmp.city,
        website: cmp.website,
        zip_code: cmp.zip_code,
        google_map_location: cmp.place_id,
        google_formatted_address: cmp.formatted_address,
        apartment_id: community_id,
        op: cmp.op,
        id: cmp.id || -1,
        type: 'competitor',
      }));
      await axios
        .post('communities/Save_AddComm_CompetitorInfo/', {
          data: competitorPayload,
        })
        .then((res) => res.data);

      setLoading(false);
      getCompetitorList();
      messageApi.open({
        type: 'success',
        content: 'Data Added Successfully',
      });
    } catch (err: any) {
      setLoading(false);
      messageApi.open({
        type: 'error',
        content: err?.response?.data[0],
      });
    }
  };
  const handleRemove = async (item: any) => {
    try {
      item.op = 'deleted';
      const payload = [item];
      setLoading(true);
      const { livingInfo, ...rest } = communityInfo;

      const competitorPayload = payload.map((cmp) => ({
        ...rest,
        comunity_name: cmp.name,
        address: cmp.formatted_address,
        state: cmp.state,
        latitude: cmp.lat,
        longitude: cmp.lng,
        city: cmp.city,
        zip_code: cmp.zip_code,
        google_map_location: cmp.place_id,
        google_formatted_address: cmp.formatted_address,
        apartment_id: community_id,
        op: cmp.op,
        id: cmp.id || -1,
      }));
      axios
        .post('communities/Save_AddComm_CompetitorInfo/', {
          data: competitorPayload,
        })
        .then((res) => {
          setLoading(false);
          getCompetitorList();
          messageApi.open({
            type: 'success',
            content: 'Data deleted successfully',
          });
        });
    } catch (err: any) {
      setLoading(false);
      if (`${err?.response?.data?.Error}`.includes('violates foreign key constraint')) {
        messageApi.open({
          type: 'error',
          content: "Child record exists.You can't delete this record.",
        });
      } else {
        messageApi.open({
          type: 'error',
          content: err?.response?.data[1],
        });
      }
    }
  };

  const getCommunityInfo = () => {
    axios
      .get(`/communities/Get_CommunityInfo/${community_id}/`)
      .then((res) => {
        const data = res.data?.payload;
        // const community_market = data?.CommunityInfo[1]?.Market_Avgs[0];
        const community_market = data.Avg_info;
        community_market.Avg_google_rating = data.Community_Info[0]?.GoogleRating;
        community_market.Sum_google_reviews = data.Community_Info[0]?.GoogleReviews;

        //
        setcommunity_market(community_market);
      })
      .catch((err) => {});
  };

  const showMessage = (type: 'success' | 'error', mgs: string) => {
    messageApi.open({
      type: type,
      content: mgs,
    });
  };

  React.useEffect(() => {
    getCompetitorList();
  }, [community_id]);

  React.useEffect(() => {
    if (competitor_id && competitors.length > 0) {
      const cm = competitors.find((el) => `${el?.id}` === `${competitor_id}`);

      if (cm) {
        setselectedItem(cm);
      } else {
        setselectedItem(competitors[0]);
      }
    }
  }, [competitor_id, competitors]);

  React.useEffect(() => {
    getCommunityInfo();
  }, [community_id]);

  const getlivingAvgDataset = (data: Comp_Avgs) => {
    //
    // const googleRating = allData.Avg_info?.market_Avg_Google_Rating;
    // const googleReview = allData.Avg_info?.market_Avg_Google_Reviews;
    const baseRent = allData.Competitor_Avg_Base_Rent.filter((_c: any) => _c.apartment_id == selectedItem.id)[0];

    const baseRentCare = allData.Competitor_Avg_Care_Fees.filter((_c: any) => _c.apartment_id == selectedItem.id)[0];
    const sqFt = allData.Competitor_Avg_Sq_Footage_Rent.filter((_c: any) => _c.apartment_id == selectedItem.id)[0];
    const ratePerSqFt = allData.Competitor_Avg_Price_Per_Sq_Rent.filter(
      (_c: any) => _c.apartment_id == selectedItem.id
    )[0];
    return {
      avgBaseRent: baseRent?.market_Avg_base_rent || 0,
      avgBaseRentCare: (baseRent?.market_Avg_base_rent || 0) + (baseRentCare?.Avg_Care_Fee || 0),
      avgSqFt: sqFt?.market_Avg_sq_footage || 0,
      avgRatePerSqFt: ratePerSqFt?.market_Avg_price_per_sq || 0,
      google_rating: 0,
      google_reviews: 0,
    };
  };

  const getCompetitorList = () => {
    axios
      .get(`/communities/Get_CompetitorInfo/${community_id}/`)
      .then((res) => {
        const data = res.data?.payload;
        const formatted_data = data.Competitor_Info?.map((cm: any) => ({
          name: cm?.ApartmentName,
          streetNumber: "",
          route: "",
          city: cm?.city,
          state: cm?.state,
          zip_code: cm?.zip_code,
          country: cm?.county,
          formatted_address: cm?.ApartmentAddress,
          place_id: cm?.google_map_location,
          lat: cm?.Latitude,
          lng: cm?.Longitude,
          op: "",
          id: cm.Id,
        }));
        //
        setCompetitors(formatted_data);
        setCompetitorInfo(data.Competitor_Info);
        setAllData(data);
        if (formatted_data.length > 0) {
          setselectedItem(formatted_data[0]);
        } else {
          setSubCommunityInfo(null);
          setCompetitorInfo(null);
          setAvgData(null);
          setAllData(null);
          setShowCompetitorLoading(false);
        }
      });
  };

  // React.useEffect(() => {
  //   if (competitors.length === 0) {
  //     const cmObjs = competitorInfo;
  //     if (cmObjs && Object.keys(cmObjs).length > 0) {
  //       const cm_list: any[] = [];
  //       Object.keys(cmObjs).forEach((key) => {
  //         cm_list.push(cmObjs[key]);
  //       });

  //       const formatted_data = cm_list.map((cm) => ({
  //         name: cm?.comunity_name,
  //         streetNumber: "",
  //         route: "",
  //         city: cm?.city,
  //         state: cm?.state,
  //         zip_code: cm?.zip_code,
  //         country: cm?.county,
  //         formatted_address: cm?.address,
  //         place_id: cm?.google_map_location,
  //         lat: cm?.latitude,
  //         lng: cm?.longitude,
  //         op: "",
  //         id: cm.id,
  //       }));

  //       setCompetitors(formatted_data);
  //     }
  //   }
  // }, [competitorInfo]);

  const handleCreateCommunity = async () => {
    try {
      const addedCom = competitors.filter((cm) => cm.op === 'added');
      const deletedCom = deletedCompetitors.map((cm) => ({
        ...cm,
        op: 'deleted',
      }));
      const payload = [...addedCom, ...deletedCom];
      if (payload.length === 0) return;
      setLoading(true);
      const { livingInfo, ...rest } = communityInfo;

      const competitorPayload = payload.map((cmp) => ({
        ...rest,
        comunity_name: cmp.name,
        address: cmp.formatted_address,
        state: cmp.state,
        latitude: cmp.lat,
        longitude: cmp.lng,
        city: cmp.city,
        zip_code: cmp.zip_code,
        google_map_location: cmp.place_id,
        google_formatted_address: cmp.formatted_address,
        apartment_id: community_id,
        op: cmp.op,
        id: cmp.id || -1,
      }));
      await await axios
        .post('communities/Save_AddComm_CompetitorInfo/', {
          data: competitorPayload,
        })
        .then((res) => res.data);
      setLoading(false);
      messageApi.open({
        type: 'success',
        content: 'Data saved successfully',
      });
    } catch (err: any) {
      setLoading(false);
      messageApi.open({
        type: 'error',
        content: err?.response?.data[0],
      });
    }
  };

  const handleSetGoogleLocation = (data: any) => {
    communityInfo.google_map_location = data.place_id;
    communityInfo.address = data.formatted_address;
    communityInfo.latitude = data.lat;
    communityInfo.longitude = data.lng;
    communityInfo.comunity_name = data.name;
    communityInfo.phone_number = data.phone_number;
    communityInfo.state = data.state;
    communityInfo.website = data.website;
    communityInfo.zip_code = data.zip_code;
    communityInfo.city = data.city;
    updateRating();
    setOpenSearchCommunityModal(false);
  };
  const updateRating = async () => {
    const updates: any = [];
    let place_id: any = null;
    if (communityInfo.google_map_location) {
      place_id = communityInfo.google_map_location;
    } else {
      setOpenSearchCommunityModal(true);
      return;
    }
    const service = new google.maps.places.PlacesService(document.createElement('div'));
    setRatingLoading(true);
    try {
      service.getDetails(
        {
          placeId: place_id,
        },
        async function (place: any, status: any) {
          // console.log(place, status);
          if (status == 'INVALID_REQUEST') {
            setOpenSearchCommunityModal(true);
            setRatingLoading(false);
            return;
          }
          updates.push({
            id: 1,
            apartment_id: communityInfo.id,
            old_google_ratings: communityInfo.google_rating,
            old_google_reviews: communityInfo.google_reviews,
            op: 'added',
            google_ratings: place.rating || 0,
            google_reviews: place.user_ratings_total || 0,
            place_id: communityInfo.google_map_location,
            formatted_address: communityInfo.address,
            lat: communityInfo.latitude,
            lng: communityInfo.longitude,
            name: communityInfo.comunity_name,
            city: communityInfo.city,
            phone_number: communityInfo.phone_number,
            state: communityInfo.state,
            website: communityInfo.website,
            zip_code: communityInfo.zip_code,
          });

          const placeReviews = place.reviews?.map((_r: any) => ({
            id: 1,
            apartment_id: communityInfo.id,
            google_review_id: '12345678',
            google_review_user: _r.author_name,
            google_review_date: new Date(_r.time * 1000),
            google_review_text: _r.text,
            google_review_url: _r.author_url,
            op: 'added',
          }));
          saveGoogleMatrics(updates, placeReviews || []);
        }
      );
    } catch (err) {
      setRatingLoading(false);
    }
  };
  const saveGoogleMatrics = (updates: any, reviews: any) => {
    axios
      .post(`communities/Save_GoogleMetrics/`, { data: updates })
      .then((res) => {
        saveGoogleReviews(reviews);
      })
      .catch((err) => {
        setRatingLoading(false);
        showMessage('error', 'Something went wrong');
      });
  };
  const saveGoogleReviews = (reviews: any) => {
    if (reviews.length < 1) {
      getCommunityInfo();
      showMessage('success', 'Saved successfully!');
      setRatingLoading(false);
      return;
    }
    axios
      .post(`communities/Save_GoogleReviews/`, { data: reviews })
      .then((res) => {
        //
        getCommunityInfo();
        showMessage('success', 'Saved successfully!');
        setRatingLoading(false);
      })
      .catch((err) => {
        setRatingLoading(false);
        showMessage('error', 'Something went wrong');
      });
  };

  // for competitor
  const dispatch = useAppDispatch();
  const [subCommunityInfo, setSubCommunityInfo] = React.useState<any>(null);
  const [competitorInfo, setCompetitorInfo] = React.useState<any>(null);
  const [avgData, setAvgData] = React.useState<any>(null);
  const [competitorLoading, setShowCompetitorLoading] = useState(true);

  const getSubCommunityInfo = (data: any) => {
    //
    const community_data = data.filter(
      (item: any) => item.Id == selectedItem.id
    )[0];
    //
    setSubCommunityInfo(community_data);
    const fields = [`${selectedItem.id}`];
    let object: Comp_Avgs = {
      ACC: 0,
      Avg_base_rent_care: 0,
      LivInfo: {
        ApartmentId: 0,
        Avg_Base_Rent: 0,
        Avg_Price_Per_Sq: 0,
        Avg_Sq_Footage: 0,
        ParentId: 0,
      },
      MACC: 0,
      google_rating: 0,
      google_reviews: 0,
    };
    if (data.Comp_Avgs) {
      ({ [fields[0]]: object } = data.Comp_Avgs);
    }

    //

    const livingAvgDataset = getlivingAvgDataset(object);
    livingAvgDataset.google_rating = community_data.GoogleRating;
    livingAvgDataset.google_reviews = community_data.GoogleReviews;
    //
    setAvgData(livingAvgDataset);

    setShowCompetitorLoading(false);
  };
  // call competitor data
  React.useEffect(() => {
    //
    if (selectedItem?.id) {
      setShowCompetitorLoading(true);
      dispatch(handleClearCommunityData());
      if (competitorInfo == null) {
        //
        axios
          .get(`/communities/Get_CompetitorInfo/${community_id}`)
          .then((res) => {
            const data = res.data;
            setCompetitorInfo(data.Competitor_Info);
            getSubCommunityInfo(data.Competitor_Info);
            setAllData(data);
          })
          .catch((err) => {
            setShowCompetitorLoading(false);
          });
      } else {
        try {
          //
          getSubCommunityInfo(competitorInfo);
        } catch (err) {}
      }
    } else {
      // setSubCommunityInfo(null);
      // setCompetitorInfo(null);
      // setAvgData(null);
      // setShowCompetitorLoading(false);
    }
  }, [selectedItem]);

  return (
    <div>
      {competitorLoading ? (
        <div style={{ padding: '20px' }}>
          <Space>
            <Skeleton.Button active={true} size="large" style={{ width: '200px', height: 100 }} />
            <Skeleton.Button active={true} size="large" style={{ width: '200px', height: 100 }} />
            <Skeleton.Button active={true} size="large" style={{ width: '200px', height: 100 }} />{' '}
            <Skeleton.Button active={true} size="large" style={{ width: '200px', height: 100 }} />
          </Space>
        </div>
      ) : (
        <div
          style={{
            maxWidth: '1300px',
            padding: '0px 20px',
            marginBottom: '30px',
          }}
        >
          <Row className="rowgap-vbox" gutter={[16, 16]}>
            {/* 1 */}
            <Col xs={12} sm={12} md={8} lg={4} xl={4}>
              <div>
                <div>
                  <p
                    className="c_rate_title"
                    style={{
                      fontSize: '14px',
                      color: '#000',
                      marginBottom: -5,
                      marginLeft: 15,
                      display: 'block',
                    }}
                  >
                    AVG. RATE
                  </p>
                </div>
                <div className="c_price__text_box" style={{ color: '#000' }}>
                  <Typography.Paragraph
                    className="c_price_value"
                    style={{
                      color: 'inherit',
                      fontSize: '46px',
                      margin: 0,
                    }}
                  >
                    <sup style={{ fontSize: '22px' }}>$</sup>
                    {parseInt(`${getDecimals(community_market?.Avg_Base_Rent).int}`).toLocaleString()}
                    <span style={{ fontSize: '16px' }}>.{getDecimals(community_market?.Avg_Base_Rent).point}</span>
                  </Typography.Paragraph>
                </div>
                <div style={{ marginTop: -15, marginLeft: 14 }}>
                  <span style={{ color: '#000', fontSize: 16 }}>Market avg. </span>
                  <span
                    style={{
                      color: '#d46b08',
                      fontSize: '14px',
                    }}
                  >
                    ${' '}
                    {parseFloat(community_market?.market_Avg_base_rent || 0).toLocaleString('en-US', {
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={8} lg={4} xl={4}>
              <Tooltip
                title={() => (
                  <span>
                    Rate: ${parseInt(`${getDecimals(community_market?.Avg_Base_Rent).int}`).toLocaleString()}.
                    {getDecimals(community_market?.Avg_Base_Rent).point} <br /> Care: $
                    {parseInt(`${getDecimals(community_market?.Avg_Base_Rent_Care).int}`).toLocaleString()}.
                    {getDecimals(community_market?.Avg_Base_Rent_Care).point}
                  </span>
                )}
                placement="top"
                color="blue"
              >
                <div>
                  <p
                    className="c_rate_title"
                    style={{
                      fontSize: '14px',
                      color: '#000',
                      marginBottom: -5,
                      marginLeft: 15,
                      display: 'block',
                    }}
                  >
                    AVG.(Rate + Care)
                  </p>
                </div>
                <div className="c_price__text_box" style={{ color: '#000' }}>
                  <Typography.Paragraph
                    className="c_price_value"
                    style={{
                      color: 'inherit',
                      fontSize: '46px',
                      margin: 0,
                    }}
                  >
                    <sup style={{ fontSize: '22px' }}>$</sup>
                    {parseInt(
                      `${getDecimals(community_market?.Avg_Base_Rent + community_market?.Avg_Base_Rent_Care).int}`
                    ).toLocaleString()}
                    <span style={{ fontSize: '16px' }}>
                      .{getDecimals(community_market?.Avg_Base_Rent + community_market?.Avg_Base_Rent_Care).point}
                    </span>
                  </Typography.Paragraph>
                </div>
                <div style={{ marginTop: -15, marginLeft: 14 }}>
                  <span style={{ color: '#000', fontSize: 16 }}>Market avg. </span>
                  <span
                    style={{
                      color: '#d46b08',
                      fontSize: '14px',
                    }}
                  >
                    ${' '}
                    {parseFloat(
                      community_market?.market_Avg_base_rent + community_market?.market_Avg_Base_Rent_Care || 0
                    ).toLocaleString('en-US', { maximumFractionDigits: 2 })}
                  </span>
                </div>
              </Tooltip>
            </Col>
            {/* 2 */}
            <Col xs={12} sm={12} md={8} lg={4} xl={4}>
              <div>
                <div>
                  <p
                    className="c_rate_title"
                    style={{
                      fontSize: '14px',
                      color: '#000',
                      marginBottom: -5,
                      marginLeft: 4,
                      display: 'block',
                    }}
                  >
                    AVG. SQ. FT.
                  </p>
                </div>
                <div className="c_price__text_box" style={{ color: '#000' }}>
                  <Typography.Paragraph
                    className="c_price_value"
                    style={{
                      color: 'inherit',
                      fontSize: '46px',
                      margin: 0,
                    }}
                  >
                    {parseInt(`${getDecimals(community_market?.Avg_SQ_Footage).int}`).toLocaleString()}
                    <span style={{ fontSize: '16px' }}>.{getDecimals(community_market?.Avg_SQ_Footage).point}</span>
                  </Typography.Paragraph>
                </div>
                <div style={{ marginTop: -15, marginLeft: 4 }}>
                  <span style={{ color: '#000', fontSize: 16 }}>Market avg. </span>
                  <span
                    style={{
                      color: '#389e0d',
                      fontSize: '14px',
                    }}
                  >
                    {parseFloat(community_market?.market_Avg_sq_footage || 0).toLocaleString('en-US', {
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
              </div>
            </Col>
            {/* 3 */}
            <Col xs={12} sm={12} md={8} lg={4} xl={4}>
              <div>
                <div>
                  <p
                    className="c_rate_title"
                    style={{
                      fontSize: '14px',
                      color: '#000',
                      marginBottom: -5,
                      marginLeft: 15,
                      display: 'block',
                    }}
                  >
                    AVG. RATE / SQ. FT.
                  </p>
                </div>
                <div className="c_price__text_box" style={{ color: '#000' }}>
                  <Typography.Paragraph
                    className="c_price_value"
                    style={{
                      color: 'inherit',
                      fontSize: '46px',
                      margin: 0,
                    }}
                  >
                    <sup style={{ fontSize: '22px' }}>$</sup>

                    {parseInt(`${getDecimals(community_market?.Avg_price_per_sq).int}`).toLocaleString()}
                    <span style={{ fontSize: '16px' }}>.{getDecimals(community_market?.Avg_price_per_sq).point}</span>
                  </Typography.Paragraph>
                </div>
                <div style={{ marginTop: -15, marginLeft: 14 }}>
                  <span style={{ color: '#000', fontSize: 16 }}>Market avg. </span>
                  <span
                    style={{
                      color: '#389e0d',
                      fontSize: '14px',
                    }}
                  >
                    ${' '}
                    {parseFloat(community_market?.market_Avg_price_per_sq || 0).toLocaleString('en-US', {
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={8} lg={4} xl={4}>
              <div>
                <span
                  className="c_rate_title"
                  style={{
                    fontSize: '14px',
                    color: '#000',
                    display: 'block',
                    marginBottom: -5,
                    marginLeft: 4,
                  }}
                >
                  GOOGLE Rating{' '}
                  {ratingLoading ? (
                    <LoadingOutlined />
                  ) : (
                    <Tooltip title={communityInfo?.google_map_location} color="blue">
                      <RedoOutlined onClick={updateRating} style={{ cursor: 'pointer' }} />
                    </Tooltip>
                  )}
                </span>
                <div className="c_price__text_box" style={{ color: '#000' }}>
                  <Typography.Paragraph
                    className="c_price_value"
                    style={{
                      color: 'inherit',
                      fontSize: '46px',
                      margin: 0,
                      position: 'relative',
                      display: 'inline-block',
                    }}
                  >
                    {parseFloat(community_market?.Avg_google_rating || 0).toFixed(1)}
                    <span style={{ fontSize: '17px' }}>({parseFloat(community_market?.Sum_google_reviews || 0)})</span>
                  </Typography.Paragraph>
                </div>
                <div style={{ marginTop: -15 }}>
                  <span style={{ color: '#000', fontSize: '16px' }}>Competitor avg. </span>
                  <span style={{ color: '#389e0d', fontSize: '14px' }}>
                    {community_market?.market_Avg_Google_Rating
                      ? parseFloat(community_market?.market_Avg_Google_Rating || 0).toFixed(1)
                      : 0}
                  </span>{' '}
                  <span style={{ color: 'inherit', fontSize: '14px' }}>
                    (
                    {community_market?.market_Avg_Google_Reviews
                      ? parseFloat(community_market?.market_Avg_Google_Reviews || 0).toLocaleString('en-US', {
                          maximumFractionDigits: 2,
                        })
                      : 0}
                    )
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      )}
      {contextHolder}
      <SearchCommunityModal
        loading={false}
        open={openSearchCommunityModal}
        handleClose={() => setOpenSearchCommunityModal(false)}
        handleSetLocation={handleSetGoogleLocation}
        searchKeyword={communityInfo?.comunity_name}
      />
      {loading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgb(240 240 240 / 71%)',
            zIndex: 99,
          }}
        >
          <Spin size="large" />
        </div>
      )}

      <Row>
        <Col xs={6}>
          <div
            style={{
              minHeight: 'calc(100vh - 430px)',
              position: 'relative',
              padding: '0px 20px',
            }}
          >
            <div>
              <div
                style={{
                  maxWidth: '570px',
                  margin: '0 auto',
                }}
              >
                <LocationSearchInput getLocationBySearch={handleSetLocation} placeholder="Search competitors to add" />
              </div>
              {/* height 400 */}
              <div style={{}}>
                <div style={{ padding: '10px 10px', marginTop: 10 }} className={classes.mgs__scroll}>
                  {competitors &&
                    competitors.map((item, idx) => (
                      <CompetitorSearchItem
                        key={idx}
                        item={item}
                        onSelectItem={() => setselectedItem(item)}
                        handleRemove={() => handleRemove(item)}
                        selectedItem={selectedItem}
                      />
                    ))}
                </div>
              </div>
            </div>
          </div>
          {/* <div
            style={{
              borderTop: "1px solid #eee",
              marginTop: 30,
              padding: "0px 50px",
            }}
          >
            <div
              style={{
                padding: "20px 0px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={loading}
                onClick={handleCreateCommunity}
              >
                Save
              </Button>
            </div>
          </div> */}
        </Col>
        <Col xs={18}>
          {competitorLoading ? (
            <div style={{ padding: '20px' }}>
              <Skeleton.Button active={true} style={{ width: '300px', height: 30 }} />
              <br />
              <br />
              <Space>
                <Skeleton.Button active={true} size="large" style={{ width: '100px', height: 20 }} />
                <Skeleton.Button active={true} size="large" style={{ width: '100px', height: 20 }} />
                <Skeleton.Button active={true} size="large" style={{ width: '100px', height: 20 }} />{' '}
                <Skeleton.Button active={true} size="large" style={{ width: '100px', height: 20 }} />
              </Space>
              <br />
              <br />
              <Space>
                <Skeleton.Button active={true} size="large" style={{ width: '200px', height: 100 }} />
                <Skeleton.Button active={true} size="large" style={{ width: '200px', height: 100 }} />
                <Skeleton.Button active={true} size="large" style={{ width: '200px', height: 100 }} />{' '}
                <Skeleton.Button active={true} size="large" style={{ width: '200px', height: 100 }} />
              </Space>
            </div>
          ) : (
            <>
              {subCommunityInfo ? (
                <div>
                  <span className={classes.community_name} style={{ fontSize: 36 }}>
                    {subCommunityInfo?.ApartmentName}
                    <a
                      target="_blank"
                      href={`https://www.google.com/maps/search/?api=1&query=${subCommunityInfo?.ApartmentAddress}&query_place_id=<placeId>${subCommunityInfo?.GoogleMapLocation}`}
                      rel="noreferrer"
                    >
                      <img
                        src="/images/world-icon.png"
                        height="20"
                        width="auto"
                        style={{ translate: '3px -10px', marginLeft: '5px' }}
                      />
                    </a>
                  </span>
                  <Tabs
                    defaultActiveKey="Comparison"
                    moreIcon={<CaretRightFilled />}
                    tabPosition="top"
                    className={classes.sub_competitor_tab}
                    tabBarGutter={30}
                    destroyInactiveTabPane
                    size="small"
                    items={[
                      {
                        label: 'Comparison',
                        key: 'Comparison',
                        children: (
                          <ComparisonTab
                            communityInfo={subCommunityInfo}
                            avgData={avgData}
                            getCompetitorInfo={getCompetitorList}
                          />
                        ),
                        style: {
                          padding: 0,
                        },
                      },
                      {
                        label: 'Basic Info',
                        key: 'Basic_Info',
                        children: (
                          <BasicInfoForm
                            initialData={subCommunityInfo}
                            community_id={selectedItem?.id || 0}
                            apartment_id={community_id || 0}
                          />
                        ),
                        style: {
                          padding: 0,
                        },
                      },
                      {
                        label: 'Community Details',
                        key: 'Community details',
                        children: <CommunityDetails community_id={selectedItem?.id || 0} />,
                        disabled: communityInfo === null,
                      },
                      {
                        label: 'Accommodation',
                        key: 'Accommodation',
                        children: <AccommodationTab community_id={subCommunityInfo?.Id} />,
                        style: {
                          padding: 0,
                        },
                      },
                      {
                        label: 'Fees & Care',
                        key: 'fees',
                        children: <FeesForm community_id={subCommunityInfo?.Id} />,
                        style: {
                          padding: 0,
                        },
                      },
                      {
                        label: 'Images/Brochures',
                        key: 'brochure',
                        children: <ImageBrochure community_id={subCommunityInfo?.Id} />,
                        style: {
                          padding: 0,
                        },
                      },
                      {
                        label: 'Additional Links',
                        key: 'links',
                        children: <AdditionalLinks community_id={subCommunityInfo?.Id} />,
                        style: {
                          padding: 0,
                        },
                      },
                      {
                        label: 'Occupancies',
                        key: 'occupancies',
                        children: <Occupancies community_id={subCommunityInfo?.Id} type="competitor" />,
                        style: {
                          padding: 0,
                        },

                        disabled: communityInfo === null,
                      },
                      {
                        label: 'Shop Notes',
                        key: 'shop_notes',
                        children: <ShopNotes community_id={parseInt(subCommunityInfo?.Id)} />,
                        style: {
                          padding: 0,
                        },
                        disabled: communityInfo === null,
                      },
                    ]}
                  />
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '100px',
                    fontSize: 22,
                  }}
                >
                  <p>No data found</p>
                </div>
              )}
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CompetitorsForm;
