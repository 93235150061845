const marketTrendsChat: any = {
  series: [
    {
      name: "Brookhave Cook",
      data: [700, 3490, 5900, 4500, 3600],
      color: "#00b96b",
    },
    {
      name: "Competitor #1",
      data: [4500, 400, 6300, 8200, 1000],
      color: "#ff4d4f",
    },
    {
      name: "Competitor #2",
      data: [4500, 2000, 1000, 2200, 5000],
      color: "#e58827",
    },
    {
      name: "Competitor #3",
      data: [8500, 4000, 1000, 7200, 3000],
      color: "#1677ff",
    },
    {
      name: "Competitor #4",
      data: [1500, 5300, 14000, 2300, 4500],
      color: "#52c41a",
    },
    {
      name: "Competitor #5",
      data: [5100, 2500, 4300, 1200, 2340],
      color: "#d9d9d9",
    },
  ],

  options: {
    chart: {
      type: "bar",
      width: "30px",
      height: "auto",

      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 5,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    grid: {
      show: true,
      borderColor: "#ccc",
      strokeDashArray: 2,
    },
    xaxis: {
      categories: ["2018", "2019"],
      labels: {
        show: true,
        align: "center",
        minWidth: 0,
        maxWidth: 160,
        style: {
          fontSize: 11,
          colors: [
            "#333",
            "#333",
            "#333",
            "#333",
            "#333",
            "#333",
            "#333",
            "#333",
            "#333",
            "#333",
          ],
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
        align: "right",
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: [
            "#333",
            "#333",
            "#333",
            "#333",
            "#333",
            "#333",
            "#333",
            "#333",
            "#333",
            "#333",
          ],
        },
      },
    },

    tooltip: {
      y: {
        formatter: function (val: any) {
          return "$ " + val + " thousands";
        },
      },
    },
  },
};

export default marketTrendsChat;
