import React from 'react';

import { CloseCircleFilled, PlusOutlined } from '@ant-design/icons';
import { Button, Input, message, Select, Spin, Switch } from 'antd';

import { useAppDispatch, useAppSelector } from 'store/hook';
import { handleStoreAccessibility } from 'store/slices/communitySlice';
import './styles.css';

interface Props {
  management_options: any[];
  initialValues?: any[];
}

const AccessibilityTable = ({ management_options, initialValues = [] }: Props) => {
  const dispatch = useAppDispatch();
  const [messageApi, contextHolder] = message.useMessage();

  const accessibilities = useAppSelector((state) => state.community.accessibility);
  const [deletedRow, setDeletedRow] = React.useState<any[]>([]);

  React.useEffect(() => {
    if (initialValues && initialValues.length > 0) {
      const init_d = initialValues.map((acb) => ({
        id: acb?.Id,
        apartment_id: acb?.apartment_id,
        company_id: acb?.CompanyId,
        management_company_id: acb?.ManagementCompany,
        comments: acb?.Comments,
        status: acb?.status,
        select: acb?.Select,
        insert: acb?.Insert,
        update: acb?.Update,
        delete: acb?.Delete,
        op: '',
      }));
      dispatch(handleStoreAccessibility(init_d));
    }
  }, [initialValues]);

  const handleAddRow = () => {
    const cp = [...accessibilities];
    cp.push({
      id: -1,
      apartment_id: -1,
      company_id: -1,
      management_company_id: null,
      comments: '',
      status: 0,
      select: 0,
      insert: 0,
      update: 0,
      delete: 0,
      op: 'added',
    });
    dispatch(handleStoreAccessibility(cp));
  };

  const handleUpdateComments = (value: string, row_index: number) => {
    const jsData = JSON.parse(JSON.stringify(accessibilities));
    const cp = [...jsData];
    cp[row_index].comments = value;
    if (cp[row_index].op === '') {
      cp[row_index].op = 'edited';
    }
    dispatch(handleStoreAccessibility(cp));
  };
  const handleUpdateManagementCompany = (opt: any, row_index: number) => {
    const jsData = JSON.parse(JSON.stringify(accessibilities));
    const cp = [...jsData];

    cp[row_index].management_company_id = opt.value;
    if (cp[row_index].op === '') {
      cp[row_index].op = 'edited';
    }

    dispatch(handleStoreAccessibility(cp));
  };

  const handleUpdateRowpermission = (checked: boolean, row_index: number, field: any) => {
    const jsData = JSON.parse(JSON.stringify(accessibilities));
    const cp = [...jsData];

    cp[row_index][field] = checked ? 1 : 0;
    if (cp[row_index].op === '') {
      cp[row_index].op = 'edited';
    }

    dispatch(handleStoreAccessibility(cp));
  };

  const removeRow = (row_index: number) => {
    const jsData = JSON.parse(JSON.stringify(accessibilities));
    const cp = [...jsData];

    const deletedRow = cp.splice(row_index, 1);
    setDeletedRow((prev) => [...prev, ...deletedRow]);
    dispatch(handleStoreAccessibility(cp));
  };

  if (!accessibilities) {
    return (
      <div>
        <Spin />
      </div>
    );
  }

  return (
    <div>
      {contextHolder}
      <div style={{ minHeight: 'calc(100vh - 430px)', paddingBottom: 50 }}>
        <div
          style={{
            margin: 'auto',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              minWidth: '767px',
              margin: '0 auto',
              position: 'relative',
            }}
          >
            <div
              style={{
                position: 'relative',
                display: 'inline-block',
              }}
            >
              <table
                className="acc_table_wrapper"
                style={{
                  borderSpacing: 0,
                }}
              >
                <thead>
                  <tr>
                    <th
                      style={{
                        borderLeft: 0,
                        borderTop: 0,
                        borderRight: 0,
                      }}
                    >
                      Management Company
                    </th>
                    <th
                      style={{
                        borderLeft: 0,
                        borderTop: 0,
                        borderRight: '1px solid #ccc',
                      }}
                    >
                      Comments
                    </th>

                    <th
                      style={{
                        borderLeft: 0,
                        padding: '5px 20px',
                        position: 'relative',
                      }}
                    >
                      Status
                    </th>
                    <th
                      style={{
                        borderLeft: 0,
                        padding: '5px 20px',
                        position: 'relative',
                      }}
                    >
                      Select
                    </th>
                    <th
                      style={{
                        borderLeft: 0,
                        padding: '5px 20px',
                        position: 'relative',
                      }}
                    >
                      Insert
                    </th>

                    <th
                      style={{
                        borderLeft: 0,
                        padding: '5px 20px',
                        position: 'relative',
                      }}
                    >
                      Update
                    </th>

                    <th
                      style={{
                        borderLeft: 0,
                        padding: '5px 20px',
                        position: 'relative',
                      }}
                    >
                      Delete
                    </th>
                    <th style={{ border: 0 }}></th>
                  </tr>
                </thead>
                <tbody>
                  {accessibilities.map((row_data, row_index: number) => (
                    <tr key={row_index} className="acc_row">
                      <td
                        style={{
                          borderLeft: '1px solid #ccc',
                          padding: 0,
                        }}
                      >
                        <Select
                          bordered={false}
                          value={row_data?.management_company_id}
                          options={management_options}
                          style={{ width: '200px', padding: 0 }}
                          onChange={(_, opt) => handleUpdateManagementCompany(opt, row_index)}
                          placeholder="Select"
                        />
                      </td>
                      <td style={{ padding: 0 }}>
                        <Input
                          value={row_data.comments}
                          onChange={(e) => handleUpdateComments(e.target.value, row_index)}
                          style={{
                            border: 0,
                            width: '250px',
                            borderRadius: 0,
                            height: '35px',
                          }}
                          type="text"
                        />
                      </td>

                      <td
                        style={{
                          padding: 0,
                          width: 100,
                          textAlign: 'center',
                        }}
                      >
                        <Switch
                          checked={row_data?.status === 1 ? true : false}
                          onChange={(checked: boolean) => handleUpdateRowpermission(checked, row_index, 'status')}
                        />
                      </td>
                      <td
                        style={{
                          padding: 0,
                          width: 100,
                          textAlign: 'center',
                        }}
                      >
                        <Switch
                          checked={row_data?.select === 1 ? true : false}
                          onChange={(checked: boolean) => handleUpdateRowpermission(checked, row_index, 'select')}
                        />
                      </td>
                      <td
                        style={{
                          padding: 0,
                          width: 100,
                          textAlign: 'center',
                        }}
                      >
                        <Switch
                          checked={row_data?.insert === 1 ? true : false}
                          onChange={(checked: boolean) => handleUpdateRowpermission(checked, row_index, 'insert')}
                        />
                      </td>
                      <td
                        style={{
                          padding: 0,
                          width: 100,
                          textAlign: 'center',
                        }}
                      >
                        <Switch
                          checked={row_data?.update === 1 ? true : false}
                          onChange={(checked: boolean) => handleUpdateRowpermission(checked, row_index, 'update')}
                        />
                      </td>
                      <td
                        style={{
                          padding: 0,
                          width: 100,
                          textAlign: 'center',
                        }}
                      >
                        <Switch
                          checked={row_data?.delete === 1 ? true : false}
                          onChange={(checked: boolean) => handleUpdateRowpermission(checked, row_index, 'delete')}
                        />
                      </td>
                      <td style={{ border: 0 }}>
                        <Button
                          icon={<CloseCircleFilled />}
                          size="small"
                          type="link"
                          danger
                          style={{
                            opacity: row_index === 0 && accessibilities?.length === 1 ? 0 : 1,
                          }}
                          onClick={() => removeRow(row_index)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Button
                icon={<PlusOutlined />}
                type="default"
                shape="round"
                style={{
                  fontSize: 14,
                  height: 'max-content',
                  padding: '6px 10px 4px 10px',
                  marginTop: 10,
                }}
                onClick={handleAddRow}
              >
                Add
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccessibilityTable;
