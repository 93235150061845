import React from 'react';
import { useNavigate } from 'react-router-dom';

import { CloseCircleFilled, PlusOutlined } from '@ant-design/icons';
import { Button, Input, message, Select, Typography } from 'antd';
import { NoticeType } from 'antd/es/message/interface';
import shortUUID from 'short-uuid';

import { useAppDispatch, useAppSelector } from 'store/hook';
import { handleStoreCareInfo } from 'store/slices/communitySlice';

import './styles.css';

type LivingType = {
  id: number;
  value: number | null;
  type: string;
};

type DataType = {
  id: number;
  key: string;
  accommodation: number | null;
  sq_ft: number | null;
  living_type: LivingType[];
  status: 1 | 0;
  op: 'added' | 'edited' | 'deleted' | null;
};
interface Props {
  current: number;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
}

const AccommodationForm = ({ current, setCurrent }: Props) => {
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const apartment_types = useAppSelector((state) => state.global.apartmenttypes);
  const living_types = useAppSelector((state) => state.global.livingtypes);
  const cares_info: DataType[] = useAppSelector((state) => state.community.cares_info);
  const basic_info = useAppSelector((state) => state.community.basic_info);

  const acc_options = apartment_types.map((val) => ({
    label: val.type,
    value: val.id,
  }));

  const handleAddRow = () => {
    const cp = [...cares_info];
    const newData: DataType = {
      id: -1,
      key: shortUUID.generate(),
      accommodation: 1,
      sq_ft: null,
      living_type: living_types.map((item) => ({
        id: item.id,
        value: null,
        type: item.type,
      })),
      // living_type: [
      //   {
      //     id: 1,
      //     value: null,
      //     type: "Independent Living",
      //   },
      //   {
      //     id: 2,
      //     value: null,
      //     type: "Assisted Living",
      //   },
      //   {
      //     id: 3,
      //     value: null,
      //     type: "Memory Care",
      //   },
      // ],
      status: 1,
      op: 'added',
    };

    cp.push(newData);
    dispatch(handleStoreCareInfo(cp));
  };

  const handleUpdateLivingTypeValue = (value: any, row_index: number, col_idx: number) => {
    const jsData = JSON.parse(JSON.stringify(cares_info));
    const cp = [...jsData];
    cp[row_index].living_type[col_idx].value = value;

    dispatch(handleStoreCareInfo(cp));
  };
  const handleUpdateSqFtValue = (value: string, row_index: number) => {
    const jsData = JSON.parse(JSON.stringify(cares_info));
    const cp = [...jsData];
    cp[row_index].sq_ft = value;
    dispatch(handleStoreCareInfo(cp));
  };
  const handleUpdateAccType = (opt: any, row_index: number) => {
    const jsData = JSON.parse(JSON.stringify(cares_info));
    const cp = [...jsData];
    cp[row_index].accommodation = opt.value;
    dispatch(handleStoreCareInfo(cp));
  };

  const removeRow = (row_index: number) => {
    const jsData = JSON.parse(JSON.stringify(cares_info));
    const cp = [...jsData];
    cp.splice(row_index, 1);
    dispatch(handleStoreCareInfo(cp));
  };

  const storeAccommodationAndCare = () => {
    setCurrent((prevState) => prevState + 1);
  };

  const showMessage = (type: NoticeType | undefined, content: string) => {
    messageApi.open({
      type,
      content,
    });
  };

  return (
    <div>
      {contextHolder}
      <div style={{ minHeight: 'calc(100vh - 430px)' }}>
        <div
          style={{
            margin: 'auto',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              minWidth: '767px',
              margin: '0 auto',
              position: 'relative',
            }}
          >
            <div
              style={{
                position: 'relative',
                display: 'inline-block',
              }}
            >
              <table
                className="acc_table_wrapper"
                style={{
                  borderSpacing: 0,
                }}
              >
                <thead>
                  <tr>
                    <th
                      style={{
                        borderLeft: 0,
                        borderTop: 0,
                        borderRight: 0,
                        width: 180,
                      }}
                    >
                      Accommodation
                    </th>
                    <th
                      style={{
                        borderLeft: 0,
                        borderTop: 0,
                        borderRight: '1px solid #ccc',
                        width: 200,
                      }}
                    >
                      SQ. FT
                    </th>
                    {living_types.map((lt, idx: number) => (
                      <th
                        key={idx}
                        style={{
                          borderLeft: 0,
                          padding: 0,
                          position: 'relative',
                          width: 200,
                        }}
                      >
                        {lt.type}
                      </th>
                    ))}
                    {/* <th
                      style={{
                        borderLeft: 0,
                        borderTop: 0,
                        borderRight: "1px solid #ccc",
                        width: 200,
                      }}
                    >
                      Notes
                    </th> */}
                    <th style={{ border: 0 }}></th>
                  </tr>
                </thead>
                <tbody>
                  {cares_info.map((row_data: any, row_index: number) => (
                    <tr key={row_index} className="acc_row">
                      <td
                        style={{
                          borderLeft: '1px solid #ccc',
                          padding: 0,
                        }}
                      >
                        <Select
                          showSearch
                          bordered={true}
                          value={row_data.accommodation}
                          options={acc_options}
                          style={{ width: '200px', padding: 0 }}
                          onChange={(_, opt) => handleUpdateAccType(opt, row_index)}
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                        />
                      </td>
                      <td style={{ padding: 0 }}>
                        <Input
                          value={row_data.sq_ft}
                          onChange={(e) => handleUpdateSqFtValue(e.target.value, row_index)}
                          style={{
                            border: 0,
                            width: '100%',
                            borderRadius: 0,
                            height: '35px',
                          }}
                          type="number"
                        />
                      </td>
                      {row_data.living_type.map((lt: any, idx: number) => (
                        <td style={{ padding: 0 }} key={idx}>
                          <Input
                            prefix="$"
                            value={lt.value}
                            onChange={(e) => handleUpdateLivingTypeValue(e.target.value, row_index, idx)}
                            style={{
                              border: 0,
                              width: '100%',
                              borderRadius: 0,
                              height: '35px',
                            }}
                            type="number"
                          />
                        </td>
                      ))}
                      {/* <td style={{ padding: 0 }}>
                        <Input.TextArea
                          value={row_data.notes || ""}
                          rows={1}
                          onChange={(e) => {
                            handleUpdateSqFtValue(e.target.value, row_index);
                          }}
                          style={{
                            border: 0,
                            width: "100%",
                            borderRadius: 0,
                            height: "35px",
                          }}
                        />
                      </td> */}
                      <td style={{ border: 0 }}>
                        <Button
                          icon={<CloseCircleFilled />}
                          size="small"
                          type="link"
                          danger
                          onClick={() => removeRow(row_index)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Button
                icon={<PlusOutlined />}
                type="default"
                shape="round"
                style={{
                  fontSize: 14,
                  height: 'max-content',
                  padding: '6px 10px 4px 10px',
                  marginTop: 10,
                }}
                onClick={handleAddRow}
              >
                Add
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: '100%',
          borderTop: '2px solid #eee',
          padding: '10px 30px',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          minHeight: '80px',
          marginTop: 30,
        }}
      >
        <Button onClick={() => navigate('/home')}>Cancel</Button>
        <div style={{ textAlign: 'center', padding: '20px 50px', maxWidth: '70%' }}>
          <Typography.Title level={4} style={{ margin: 0 }}>
            {basic_info?.comunity_name}
          </Typography.Title>
          <Typography.Paragraph style={{ color: '#ccc', fontSize: 16 }}>{basic_info?.address}</Typography.Paragraph>
        </div>
        <div>
          {current !== 0 && (
            <Button style={{ marginRight: 10 }} onClick={() => setCurrent((prevState) => prevState - 1)}>
              Previous Step
            </Button>
          )}
          {current === 3 ? (
            <Button type="primary">Finish</Button>
          ) : (
            <Button type="primary" htmlType="button" onClick={storeAccommodationAndCare}>
              Next Step
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccommodationForm;
