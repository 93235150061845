import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

type Communitytabledata = {
  id: number;
  living_type_id: any;
  community_fee: any;
  second_person_fee: any;
  pet_fee: any;
  respite_fee: any;
  additional_fee: any;
  incentive_value: any;
  incentive: any;
  status: number;
  updated_at: string;
  frequency: string;
  op: "added" | "edited" | "deleted" | "";
};

interface GlobalType {
  basic_info: any;
  cares_info: any;
  communityFees: any;
  careFees: any;
  competitors: any[];
  accessibility: any[];
}

const initialCaresInfo = [
  {
    id: 0,
    accommodation: 1,
    sq_ft: "",
    living_type: [
      {
        id: 1,
        value: "",
        type: "Independent Living",
      },
      {
        id: 2,
        value: "",
        type: "Assisted Living",
      },
      {
        id: 3,
        value: "",
        type: "Memory Care",
      },
    ],
    status: 1,
    op: "added",
  },
];

const community_table_data: Communitytabledata[] = [
  {
    id: -1,
    living_type_id: "2",
    community_fee: "",
    second_person_fee: "",
    pet_fee: "",
    respite_fee: "",
    additional_fee: "",
    incentive_value: "",
    incentive: "",
    status: 1,
    updated_at: new Date().toDateString(),
    frequency: "",
    op: "added",
  },
];
/*const care_fees_table: CareTableData[] = [
  {
    id: -1,
    living_type_id: 2,
    fees_type_id: 1,
    fee_note: "",
    level_1: "",
    level_2: "",
    level_3: "",
    level_4: "",
    level_5: "",
    level_6: "",
    level_7: "",
    level_8: "",
    medication_management_fee: "",
    average_care_cost: "",
    status: 1,
    updated_at: new Date().toDateString(),
    op: "added",
  },
];*/

const accessibility = [
  {
    id: -1,
    apartment_id: -1,
    company_id: -1,
    management_company_id: null,
    comments: '',
    status: 0,
    select: 0,
    insert: 0,
    update: 0,
    delete: 0,
    op: 'added',
  },
];

// Define the initial state using that type
const initialState: GlobalType = {
  basic_info: null,
  cares_info: [],
  communityFees: [],
  careFees: [],
  competitors: [],
  accessibility: accessibility,
};

export const communitySlice = createSlice({
  name: 'community',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    handleStoreBasicInfo: (state, action: PayloadAction<any>) => {
      state.basic_info = action.payload;
    },
    handleStoreCareInfo: (state, action: PayloadAction<any>) => {
      state.cares_info = action.payload;
    },
    handleStoreCareFees: (state, action: PayloadAction<any>) => {
      state.careFees = action.payload;
    },
    handleStoreCommunityFees: (state, action: PayloadAction<any>) => {
      state.communityFees = action.payload;
    },
    handleStoreCompetitorsInfo: (state, action: PayloadAction<any>) => {
      state.competitors = action.payload;
    },
    handleStoreAccessibility: (state, action: PayloadAction<any[]>) => {
      state.accessibility = action.payload;
    },
    handleClearCommunityData: (state) => {
      state.basic_info = null;
      state.cares_info = [];
      state.communityFees = [];
      state.careFees = [];
      state.competitors = [];
      state.accessibility = accessibility;
    },
  },
});

export const {
  handleStoreBasicInfo,
  handleStoreCareInfo,
  handleStoreCareFees,
  handleStoreCommunityFees,
  handleStoreCompetitorsInfo,
  handleClearCommunityData,
  handleStoreAccessibility,
} = communitySlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAuth = (state: RootState) => state.auth;

export default communitySlice.reducer;
