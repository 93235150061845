import React from 'react';

import { ArrowRightOutlined, EnvironmentOutlined, InteractionFilled, PhoneFilled } from '@ant-design/icons';
import { Button, Card, Image, theme, Tooltip, Typography } from 'antd';

const SearchItem = () => {
  const { token } = theme.useToken();
  return (
    <Card className="box_shadow" bodyStyle={{ padding: '10px' }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          gap: 10,
        }}
      >
        <Image src="/images/bg-profile.jpg" width={140} height={110} />
        <div>
          <Typography.Title level={4} style={{ color: token.colorPrimary, margin: 0 }}>
            The Club at Briarcliff Manor
          </Typography.Title>
          <Typography.Paragraph>Type: Community | Distance: 31.3 Miles</Typography.Paragraph>

          <Typography.Paragraph>
            <PhoneFilled /> (914) 539-7372
          </Typography.Paragraph>
        </div>
      </div>
      <Typography.Paragraph>
        <EnvironmentOutlined /> 25 Scarborough Rd, Briarcliff Manor, NY 10510, USA
      </Typography.Paragraph>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 10,
          justifyContent: 'space-between',
        }}
      >
        <Button size="small" type="primary">
          Manage Prices
        </Button>
        <Tooltip title="Add to compare">
          <Button size="small" icon={<InteractionFilled />} type="primary" />
        </Tooltip>
        <Button size="small" type="link" icon={<ArrowRightOutlined />}>
          map
        </Button>
      </div>
    </Card>
  );
};

export default SearchItem;
