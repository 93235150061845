import React from 'react';

import { Button, Col, Form, Input, Row, Typography } from 'antd';

import SelectInput from 'formComponents/SelectInput';
import CommunityTitleBar from 'pages/PriceHealing/common/CommunityTitleBar';

import GalleryTable from '../SearchGallery/GalleryTable';

const Gallery = () => {
  const onFinish = (values: any) => {
    // console.log(values);
  };

  return (
    <>
      <div>
        <CommunityTitleBar
          title="Search Gallery (Leave Blank For Full Search / Leave Blank For All Records)"
          textAlign="left"
        />
        <Form onFinish={onFinish} layout="vertical" requiredMark={false} autoComplete="off">
          <Row gutter={[20, 10]}>
            <Col xs={24} md={8}>
              <Form.Item
                name="name"
                label={<p className="form_label">My Community Name</p>}
                rules={[{ required: true, message: 'Enter Community Name' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="apartment_id"
                label={<p className="form_label">Community Name</p>}
                rules={[{ required: true, message: 'All Communities' }]}
              >
                <SelectInput onApply={(value) => console.log(value)} />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="address"
                label={<p className="form_label">Address</p>}
                rules={[{ required: true, message: 'Enter Address' }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[20, 10]}>
            <Col xs={24} md={8}>
              <Form.Item
                name="companyName"
                label={<p className="form_label">Company Name</p>}
                rules={[{ required: true, message: 'Enter Company Name' }]}
              >
                <SelectInput onApply={(value) => console.log(value)} />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="state"
                label={<p className="form_label">State</p>}
                rules={[{ required: true, message: 'Select State' }]}
              >
                <SelectInput onApply={(value) => console.log(value)} />
              </Form.Item>
            </Col>
            <Col xs={24} md={8}>
              <Form.Item
                name="region"
                label={<p className="form_label">Region</p>}
                rules={[{ required: true, message: 'Select Region' }]}
              >
                <SelectInput onApply={(value) => console.log(value)} />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  background: '#eee',
                  borderRadius: 8,
                  padding: 10,
                }}
              >
                <div>
                  <Typography.Text style={{ margin: 0, fontWeight: 'normal' }}>
                    Results: <strong>3</strong>
                  </Typography.Text>
                  <br />
                  <Typography.Text style={{ margin: 0 }}>
                    Searched in: <strong>Company</strong>
                  </Typography.Text>
                </div>
                <Button type="primary" htmlType="submit">
                  Search
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
        <GalleryTable />
      </div>
    </>
  );
};

export default Gallery;
