import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Spin } from 'antd';
import axios from 'axios';

import ConfigurationHeader from './components/ConfigurationHeader';
import GeneratedEditableTable from './components/GeneratedEditableTable';

interface CalculationOptionType {
  id: number;
  description: string;
  process_id: string;
}

interface Props {
  communityInfo: any;
}

function CalculationsTab({ communityInfo }: Props) {
  const [params] = useSearchParams();
  const communityId = params.get('id');
  const [calculationOptions, setCalculationOptions] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [calculationsData, setCalculationsData] = useState<any[]>([]);
  const [currentConfig, setCurrentConfig] = useState<any[]>([]);
  const [currentCalculator, setCurrentCalculator] = useState<any>();

  const handleCalculationChange = (val: number) => {
    setCurrentCalculator(val);
    setIsLoading(true);
    axios
      .get(`/communities/Get_CommunityCalculator/${val}/`)
      .then((res) => {
        setIsLoading(false);
        setCurrentConfig(res.data?.payload?.CalculatorProc);
        const modified = res.data?.payload?.Calculator.map((obj: any) => ({
          ...obj,
          op: '',
        }));
        setCalculationsData(modified);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    axios
      .get(`/communities/Get_CommunityCalculatorList/${communityId}`)
      .then((res) => {
        const objList: Array<CalculationOptionType> = res.data?.payload?.CalculatorProc;

        const modifiedList = objList.map((obj: CalculationOptionType) => ({
          label: obj.process_id,
          value: obj.id,
        }));

        setCalculationOptions(modifiedList);
      })
      .catch((err) => {});
  }, [communityId]);
  return (
    <div>
      <div style={{ margin: '5px 0px 25px 25px' }}>
        <br />
        <ConfigurationHeader
          currentConfig={currentConfig}
          calculationOptions={calculationOptions}
          handleCalculationChange={handleCalculationChange}
          setCurrentConfig={setCurrentConfig}
        />
      </div>

      <br />
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 100,
          }}
        >
          <Spin />
        </div>
      ) : (
        <GeneratedEditableTable
          calculationsData={calculationsData}
          setCalculationsData={setCalculationsData}
          currentConfig={currentConfig}
          currentCalculator={currentCalculator}
          communityInfo={communityInfo}
        />
      )}
    </div>
  );
}

export default CalculationsTab;
