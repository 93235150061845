import React, { useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useReactToPrint } from 'react-to-print';

import { CloseCircleFilled, CompassOutlined } from '@ant-design/icons';
import { Button, Table, Tooltip, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { format } from 'date-fns';
import * as XLSX from 'xlsx';
// @ts-ignore
import * as XlsxPopulate from 'xlsx-populate/browser/xlsx-populate';

import axios from 'config/axiosPrivate';
import { useAppSelector } from 'store/hook';

import classes from './cma.module.scss';
// const ExcelJS = require('exceljs');

const month = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

function groupByApartmentId(data: any[], key: any) {
  const groups: any = {};

  data.forEach(function (val) {
    const category = val[key];
    if (category) {
      if (category in groups) {
        groups[category].push(val);
      } else {
        groups[category] = new Array(val);
      }
    }
  });
  return groups;
}

interface Props {
  community_id: number;
  communityInfo: any;
}

const title_arr = [
  "Fee/Incentives",
  "Accommodation",
  "Care Fees",
  "Incentives",
  "Occupancies",
  "Shop Notes",
];
const sub_title_arr = ["Independent Living", "Assisted Living", "Memory Care"];

const CMASummery = ({ community_id, communityInfo }: Props) => {
  const apartmentTypes = useAppSelector((state) => state.global.apartmenttypes);
  const [tableCollumns, settableCollumns] = useState<any[]>([]);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [dataHeaders, setHeaders] = useState<any[]>([]);
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const componentRef = useRef<any>();
  const file_name_with_date = communityInfo?.comunity_name + '-' + format(new Date(), 'MM-dd-yyyy');

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: file_name_with_date,
  });

  async function exportToExcell(table_data: any[], dataHeaders: any[], fileName: string) {
    const hedersNames = ['Community Name', ...dataHeaders.map((el) => el.comunity_name)];

    const updated_data = table_data.map((el) => {
      let obj = {};
      //
      Object.keys(el)
        // .reverse()
        .forEach((key) => {
          if (key !== 'tableKey') {
            if (key !== 'dataKey') {
              const header_name = el[key][0].comunity_name || el[key][0].CommunityName;
              if (el['dataKey'] == 'Last_Updated_Date' && el[key][0][el['dataKey']]) {
                obj = {
                  ...obj,
                  [header_name]: format(new Date(el[key][0][el['dataKey']]), 'MM-dd-yyyy'),
                };
              } else if (
                (el["dataKey"] == "incentive" ||
                  el["dataKey"] == "occupancies") &&
                el[key][0][el["dataKey"]]
              ) {
                //
                obj = {
                  ...obj,
                  [header_name]: el[key][0][el['dataKey']].join('\n'),
                };
              } else {
                obj = {
                  ...obj,
                  [header_name]: el[key][0][el['dataKey']],
                };
              }
            } else {
              if (el[key].indexOf('_') !== -1) {
                const value = el[key].split('_');
                for (let i = 0; i < value.length; i++) {
                  value[i] = value[i].charAt(0).toUpperCase() + value[i].slice(1);
                }
                obj = {
                  ['Community Name']: value.join(' '),
                  ...obj,
                };
              } else if (el[key] == "incentive" || el[key] == "occupancies") {
              } else {
                obj = {
                  ['Community Name']: el[key].charAt(0).toUpperCase() + el[key].slice(1),
                  ...obj,
                };
              }
            }
          }
        });
      return obj;
    });

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    let header: any[] = [];
    Object.keys(updated_data[0]).forEach((key) => {
      header.push(key);
    });

    const fromIndex = header.indexOf(communityInfo?.comunity_name); // 👉️ 0
    const value = header[1];
    header[1] = communityInfo?.comunity_name;
    header[fromIndex] = value;
    let headerIndexes = 0;
    const mainKeys: number[] = [];
    updated_data.forEach((data, index) => {
      for (const key in data) {
        // @ts-ignore
        if (sub_title_arr.indexOf(data[key]) !== -1) {
          mainKeys.push(index);
        }
      }
      // debugger;
      const keysLength = Object.keys(data).length;
      headerIndexes = keysLength > headerIndexes ? keysLength : headerIndexes;
    });

    // const ws = XLSX.utils.json_to_sheet(updated_data);
    const ws = XLSX.utils.json_to_sheet([]);

    const wb = {
      Sheets: { CMA_Summary: ws },
      SheetNames: ['CMA_Summary'],
      origin: 'A1',
    };
    XLSX.utils.sheet_add_json(
      wb.Sheets.CMA_Summary,
      [
        {
          note: "Report Generated by Further's Pricing Assistant",
        },
      ],
      {
        header: ['note'],
        skipHeader: true,
        origin: 'A1',
      }
    );
    XLSX.utils.sheet_add_json(
      wb.Sheets.CMA_Summary,
      [
        {
          logo: <img src="/pricing_assistant_logo.png" />,
          note: 'CMA Summary',
          space: '',
          at: `Report Created at: ${format(new Date(), 'MM-dd-yyyy:hh:mm:ss')}`,
        },
      ],
      {
        header: ['logo', 'note', 'space', 'at'],
        skipHeader: true,
        origin: 'A2',
      }
    );
    XLSX.utils.sheet_add_json(wb.Sheets.CMA_Summary, updated_data, {
      header: header,
      skipHeader: false,
      origin: 'A3',
    });
    const ws_details_sheet = 'Pricing Assistant Details';

    /* make worksheet */
    const ws_data = [
      ['Company Name', 'Further Technologies'],
      ['Product Name', 'Pricing Assistant'],
      ['File Name', `${fileName}`],
      ['Product Name', 'Pricing Assistant'],
      ['Download By', `${currentUser?.name}`],
      ['Download Date', `${format(new Date(), 'MM-dd-yyyy')}`],
      ['Learn More about the Pricing Assistant', 'https://www.talkfurther.com/pricingassistant'],
    ];
    const ws_details = XLSX.utils.aoa_to_sheet(ws_data);

    /* Add the worksheet to the workbook */
    XLSX.utils.book_append_sheet(wb, ws_details, ws_details_sheet);
    // workbook.Sheets["my-sheet"]["!images"] = [
    //   {
    //     name: "image1.jpg",
    //     data: logo.base64,
    //     opts: { base64: true },
    //     position: {
    //       type: "twoCellAnchor",
    //       attrs: { editAs: "oneCell" },
    //       from: { col: 1, row: 1 },
    //       to: { col: 1, row: 1 },
    //     },
    //   },
    // ];
    const excelBuffer = XLSX.write(
      wb,
      {
        bookType: 'xlsx',
        type: 'array',
        Props: {
          Company: 'Further Technologies',
          Manager: 'Alex Smit',
          Author: `${currentUser?.name}`,
          LastAuthor: `${currentUser?.name}`,
          Title: 'CMA Summary From Pricing Assistant',
          Subject: `CMA Summary File of ${communityInfo?.comunity_name}`,
        },
      }
      //   {
      //   bookType: "xlsx",
      //   type: "array",
      // }
    );

    const data = new Blob([excelBuffer], { type: fileType });
    // FileSaver.saveAs(data, fileName + fileExtension);

    // const totalRecords = data.length;
    const dataInfo = {
      atCell: 'D2:D2',
      titleRange: 'B2:C2',
      tbodyRange: `A4:${String.fromCharCode(65 + headerIndexes)}${updated_data.length + 3}`,
      tlinkRange: `B5:${String.fromCharCode(65 + headerIndexes)}5`,
      theadRange: `A3:${String.fromCharCode(65 + headerIndexes - 1)}3`,
      // theadRange:
      //   headerIndexes?.length >= 1
      //     ? `A${headerIndexes[0] + 1}:G${headerIndexes[0] + 1}`
      //     : null,
      // theadRange1:
      //   headerIndexes?.length >= 2
      //     ? `A${headerIndexes[1] + 1}:H${headerIndexes[1] + 1}`
      //     : null,
      // tFirstColumnRange:
      //   headerIndexes?.length >= 1
      //     ? `A${headerIndexes[0] + 1}:A${totalRecords + headerIndexes[0] + 1}`
      //     : null,
      // tLastColumnRange:
      //   headerIndexes?.length >= 1
      //     ? `G${headerIndexes[0] + 1}:G${totalRecords + headerIndexes[0] + 1}`
      //     : null,

      // tFirstColumnRange1:
      //   headerIndexes?.length >= 1
      //     ? `A${headerIndexes[1] + 1}:A${totalRecords + headerIndexes[1] + 1}`
      //     : null,
      // tLastColumnRange1:
      //   headerIndexes?.length >= 1
      //     ? `H${headerIndexes[0] + 1}:H${totalRecords + headerIndexes[1] + 1}`
      //     : null,
    };
    addStyle(data, dataInfo, headerIndexes, mainKeys).then((url: any) => {
      //
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute("download", fileName + fileExtension);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  }

  const addStyle = (workbookBlob: any, dataInfo: any, headerIndexes: number, mainKeys: number[]) => {
    return XlsxPopulate.fromDataAsync(workbookBlob).then((workbook: any) => {
      workbook.sheets().forEach((sheet: any, index: number) => {
        if (index == 0) {
          sheet.usedRange().style({
            fontFamily: 'AvenirLTPro-Medium',
            verticalAlignment: 'center',
          });
          //
          sheet.column("D").width(30);
          for (let i = 0; i < headerIndexes; i++) {
            const length = sheet.column(String.fromCharCode(65 + i)).cell(3)._value.length;
            sheet.column(String.fromCharCode(65 + i)).width(length * 2.5);
          }
          //

          // sheet.range(dataInfo.atCell).style({
          //   wrapText: true,
          // });
          // sheet.column("B").width(75);
          // sheet.column("C").width(15);
          // sheet.column("E").width(15);
          // sheet.column("G").width(15);

          sheet.range(dataInfo.titleRange).merged(true).style({
            fontSize: 36,
            horizontalAlignment: 'center',
            verticalAlignment: 'center',
            underline: true,
          });
          // sheet.range(dataInfo.titleRange).merged(true).style({
          //   bold: true,
          //   horizontalAlignment: "center",
          //   verticalAlignment: "center",
          // });
          if (dataInfo.tbodyRange) {
            sheet.range(dataInfo.tbodyRange).style({
              horizontalAlignment: 'left',
              bold: true,
              fontSize: 10,
              wrapText: true,
            });
          }
          sheet.row(1).style({
            horizontalAlignment: 'left',
            fontSize: 9,
          });
          sheet.cell(2, 4).style({
            horizontalAlignment: 'center',
            verticalAlignment: 'bottom',
            fontSize: 9,
          });
          sheet.row(3).style({
            bold: true,
            horizontalAlignment: 'left',
            fontSize: 24,
          });

          if (dataInfo.tlinkRange) {
            sheet.range(dataInfo.tlinkRange).style({
              horizontalAlignment: 'left',
              bold: false,
              fontSize: 14,
              fontColor: '0563c1',
              underline: true,
            });
          }
          sheet.range(dataInfo.theadRange).style({
            fill: 'D9D9D9',
            bold: true,
            horizontalAlignment: 'left',
          });
          for (let i = 0; i < mainKeys.length; i++) {
            sheet.cell(4 + mainKeys[i], 1).style({
              underline: true,
              fontSize: 16,
            });
          }

          // if (dataInfo.theadRange1) {
          //   sheet.range(dataInfo.theadRange1).style({
          //     fill: "808080",
          //     bold: true,
          //     horizontalAlignment: "center",
          //     fontColor: "ffffff",
          //   });
          // }

          // if (dataInfo.tFirstColumnRange) {
          //   sheet.range(dataInfo.tFirstColumnRange).style({
          //     bold: true,
          //   });
          // }

          // if (dataInfo.tLastColumnRange) {
          //   sheet.range(dataInfo.tLastColumnRange).style({
          //     bold: true,
          //   });
          // }

          // if (dataInfo.tFirstColumnRange1) {
          //   sheet.range(dataInfo.tFirstColumnRange1).style({
          //     bold: true,
          //   });
          // }

          // if (dataInfo.tLastColumnRange1) {
          //   sheet.range(dataInfo.tLastColumnRange1).style({
          //     bold: true,
          //   });
          // }
        } else if (index == 1) {
          sheet.column('A').width(35);
          sheet.column('B').width(55);
          sheet.cell(7, 2).style({
            horizontalAlignment: 'left',
            fontColor: '0563c1',
            underline: true,
          });
        }
      });

      return workbook.outputAsync().then((workbookBlob: any) => URL.createObjectURL(workbookBlob));
    });
  };

  const renderCollumnName = (name: string) => {
    //
    if (!name) return "";
    const newName = name?.split("_").join(" ");
    return newName;
  };

  const { isLoading } = useQuery({
    queryKey: `Get_CommunityCMASummary_${community_id}`,
    queryFn: () => axios.get(`/communities/Get_CommunityCMASummary/${community_id}`).then((res) => res.data?.payload),
    onSuccess: (data) => {
      const headerData = data[0]?.Header;
      const cmm: any[] = data[1]?.community;
      const comp: any[] = data[2]?.competitors;
      const feeInc: any[] = data[3]?.feesinc;
      const livinginfo: any[] = data[4]?.livinginfo;
      const carefees: any[] = data[5]?.carefees;
      const shopnotes: any[] = data[6]?.shopnotes;
      const occupancies: any = data[7].occupancies;
      const dateWiseFeeInc: any = data[8]?.datewisefeeinc;
      setHeaders(headerData);
      const table_columns: ColumnsType<any> = [
        {
          title: (
            <div
              style={{
                padding: '10px 4px',
              }}
            >
              <Typography.Title style={{ margin: 0 }} level={4}>
                Community Name
              </Typography.Title>
            </div>
          ),
          dataIndex: 'dataKey',
          key: 'dataKey',
          render(value, record, index) {
            const apartmentTypeNames = apartmentTypes.map((el) => el.type);
            if (title_arr.indexOf(value) !== -1) {
              return (
                <Button
                  type="text"
                  className="p_healing_active_tab"
                  shape="round"
                  style={{
                    marginRight: 20,
                    height: 'auto',
                    padding: '5px 20px',
                    fontSize: 20,
                    pointerEvents: 'none',
                  }}
                >
                  {value}
                </Button>
              );
            } else if (sub_title_arr.indexOf(value) !== -1) {
              return (
                <Typography.Title style={{ margin: 0, textDecoration: 'underline' }} level={4}>
                  {value}
                </Typography.Title>
              );
            } else if (apartmentTypeNames.indexOf(value) !== -1) {
              return (
                <Typography.Title
                  style={{
                    margin: 0,
                    fontWeight: 700,
                    fontSize: 18,
                    textTransform: 'capitalize',
                  }}
                  level={4}
                >
                  {value}
                </Typography.Title>
              );
            } else if (value == "incentive" || value == "occupancies") {
              return (
                <Typography.Title
                  style={{
                    margin: 0,
                    fontWeight: 700,
                    fontSize: 18,
                    textTransform: 'capitalize',
                  }}
                  level={4}
                ></Typography.Title>
              );
            } else {
              return (
                <Typography.Title
                  style={{
                    margin: 0,
                    fontSize: 16,
                    textTransform: 'capitalize',
                  }}
                  level={5}
                >
                  {renderCollumnName(value)}
                </Typography.Title>
              );
            }
          },
          fixed: 'left',
          // onCell: (data, index) => {
          //   if (index === 6)
          //     return {
          //       colSpan: 5,
          //     };
          //   return {};
          // },
          width: 220,
        },
        ...headerData.map((collumn: any) => ({
          title: (
            <div
              style={{
                padding: '10px 4px',
              }}
            >
              <Typography.Title style={{ margin: 0, whiteSpace: 'break-spaces' }} level={4}>
                {collumn?.comunity_name}

                {collumn?.type === 'apartment' && (
                  <span
                    style={{
                      background: '#4CD7D2',
                      color: '#fff',
                      fontSize: '12px',
                      padding: '5px 9px',
                      borderRadius: '10px',
                      marginLeft: 6,
                    }}
                  >
                    ME
                  </span>
                )}
              </Typography.Title>
            </div>
          ),
          dataIndex: collumn?.id,
          key: collumn?.id,
          render(value: any, record: any) {
            if (value && value.length > 0) {
              const rowData = value[0];
              if (record.dataKey === 'website' || record.dataKey === 'file_url') {
                return (
                  <Typography.Link href={rowData[record.dataKey]} style={{ margin: 0, marginBottom: -10 }}>
                    {rowData[record.dataKey]}
                  </Typography.Link>
                );
              } else if (
                record.dataKey === "incentive" ||
                record.dataKey === "occupancies"
              ) {
                return rowData[record.dataKey]?.map((inc: any) => (
                  <>
                    <Typography.Title level={5} style={{ margin: 0, marginBottom: -10 }}>
                      {inc}
                    </Typography.Title>
                    <br />
                  </>
                ));
              } else if (record.dataKey === 'Last_Updated_Date' || record.dataKey === 'request_date') {
                if (rowData[record.dataKey]) {
                  return (
                    <Typography.Title level={5} style={{ margin: 0, marginBottom: -10 }}>
                      {format(new Date(rowData[record.dataKey]), 'MM-dd-yyyy')}
                    </Typography.Title>
                  );
                } else {
                  return (
                    <Typography.Title level={5} style={{ margin: 0, marginBottom: -10 }}>
                      {rowData[record.dataKey]}
                    </Typography.Title>
                  );
                }
              } else if (record.dataKey === 'attachment') {
                if (rowData[record.dataKey]) {
                  return (
                    <Typography.Link
                      href={`${process.env.REACT_APP_API}/media/${rowData[record.dataKey]}`}
                      style={{ margin: 0, marginBottom: -10 }}
                    >
                      {`${process.env.REACT_APP_API}/media/${rowData[record.dataKey]}`}
                    </Typography.Link>
                  );
                } else {
                  return (
                    <Typography.Link
                      href={`${process.env.REACT_APP_API}/media/${rowData[record.dataKey]}`}
                      style={{ margin: 0, marginBottom: -10 }}
                    >
                      {`${process.env.REACT_APP_API}/media/${rowData[record.dataKey]}`}
                    </Typography.Link>
                  );
                }
              } else {
                return (
                  <Typography.Title level={5} style={{ margin: 0, marginBottom: -10 }}>
                    {rowData[record.dataKey]}
                  </Typography.Title>
                );
              }
            }
            return <span></span>;
          },
          width: 300,
        })),
      ];
      settableCollumns(table_columns);

      //======

      const comm_comp_list = [];
      if (cmm && cmm.length > 0) {
        comm_comp_list.push(...cmm);
      }
      if (comp && comp.length > 0) {
        comm_comp_list.push(...comp);
      }

      const grup__apartment = groupByApartmentId(comm_comp_list, 'id');

      const table_apartment_data: any[] = [];
      ['address', 'website', 'google_rating', 'google_reviews', 'Last_Updated_Date'].forEach((name, index) => {
        const newRow = {
          dataKey: name,
          tableKey: 'rd' + index + Math.random(),
          ...grup__apartment,
        };
        //
        table_apartment_data.push(newRow);
      });

      // for fee/inc
      // const grup__feeInc = groupByApartmentId(feeInc, "CommunityId");
      table_apartment_data.push({
        dataKey: 'Fees/Incentives',
        tableKey: 'acvc1' + Math.random(),
      });
      //
      // [
      //   "community_fee",
      //   "pet_fee",
      //   "second_person_fee",
      //   "additional_fee",
      // ].forEach((name) => {
      //   const newRow = {
      //     dataKey: name,
      //     ...grup__feeInc,
      //   };
      //   table_apartment_data.push(newRow);
      // });

      const feeInc_by_living_type = groupByApartmentId(feeInc, 'LivingTypeName');

      if (Object.keys(feeInc_by_living_type).length > 0) {
        Object.keys(feeInc_by_living_type).forEach((living_type) => {
          table_apartment_data.push({
            dataKey: living_type,
            tableKey: 'af2' + Math.random(),
          });

          const type_data_arr = feeInc_by_living_type[living_type];
          const updated_arr = type_data_arr.map((el: any) => ({
            ...el,
            community_fee: el?.community_fee > 0 ? `$ ${el?.community_fee}` : '',
            pet_fee: el?.pet_fee > 0 ? `$ ${el?.pet_fee}` : '',
            second_person_fee: el?.second_person_fee > 0 ? `$ ${el?.second_person_fee}` : '',
            additional_fee: el?.additional_fee > 0 ? `$ ${el?.additional_fee}` : '',
          }));

          const IL_grp_by_id = groupByApartmentId(updated_arr, 'CommunityId');

          ['community_fee', 'pet_fee', 'second_person_fee', 'additional_fee'].forEach((name, index) => {
            const newRow = {
              dataKey: name,
              tableKey: 'bcd' + index + Math.random(),
              ...IL_grp_by_id,
            };
            table_apartment_data.push(newRow);
          });
        });
      }

      // for living types
      table_apartment_data.push({
        dataKey: 'Accommodation',
        tableKey: 'axs3' + Math.random(),
      });
      const grp_by_living_type = groupByApartmentId(livinginfo, 'LivingTypeName');

      if (Object.keys(grp_by_living_type).length > 0) {
        Object.keys(grp_by_living_type).forEach((living_type) => {
          table_apartment_data.push({
            dataKey: living_type,
            tableKey: 'avxse4' + Math.random(),
          });

          const type_data_arr = grp_by_living_type[living_type];
          const IL_grp_by_aprtmenttype = groupByApartmentId(type_data_arr, 'ApartmentTypeName');
          if (Object.keys(IL_grp_by_aprtmenttype).length > 0) {
            Object.keys(IL_grp_by_aprtmenttype).forEach((aprtment_type) => {
              const apartment_list = IL_grp_by_aprtmenttype[aprtment_type];
              if (apartment_list && apartment_list?.length > 0) {
                const updated_list = apartment_list.map((el: any) => ({
                  ...el,
                  [`${aprtment_type}`]: el.base_rent > 0 ? `$ ${el.base_rent}` : '',
                  price_per_sq: el?.price_per_sq > 0 ? `$ ${el?.price_per_sq}` : '',
                }));
                const data_grp = groupByApartmentId(updated_list, 'CommunityId');
                table_apartment_data.push({
                  dataKey: aprtment_type,
                  tableKey: 'dzaw1' + Math.random(),
                  ...data_grp,
                });
                ['sq_footage', 'price_per_sq'].forEach((name, idx) => {
                  const newRow = {
                    dataKey: name,
                    tableKey: 'ess' + idx + Math.random(),
                    ...data_grp,
                  };
                  table_apartment_data.push(newRow);
                });
              }
            });
          }
        });
      }

      // for care fees

      table_apartment_data.push({
        dataKey: 'Care Fees',
        tableKey: 'a10' + Math.random(),
      });
      const carefees_by_living_type = groupByApartmentId(carefees, 'LivingTypeName');

      if (Object.keys(carefees_by_living_type).length > 0) {
        Object.keys(carefees_by_living_type).forEach((living_type) => {
          table_apartment_data.push({
            dataKey: living_type,
            tableKey: 'rfdtet' + Math.random(),
          });

          const type_data_arr = carefees_by_living_type[living_type];
          const updated_arr = type_data_arr.map((el: any) => ({
            ...el,
            pet_fee: el?.pet_fee > 0 ? `$ ${el?.pet_fee}` : '',
            level_1: el?.level_1 > 0 ? `$ ${el?.level_1}` : '',
            level_2: el?.level_2 > 0 ? `$ ${el?.level_2}` : '',
            level_3: el?.level_3 > 0 ? `$ ${el?.level_3}` : '',
            level_4: el?.level_4 > 0 ? `$ ${el?.level_4}` : '',
            level_5: el?.level_5 > 0 ? `$ ${el?.level_5}` : '',
            level_6: el?.level_6 > 0 ? `$ ${el?.level_6}` : '',
            level_7: el?.level_7 > 0 ? `$ ${el?.level_7}` : '',
            level_8: el?.level_8 > 0 ? `$ ${el?.level_8}` : '',
            medication_management_fee: el?.medication_management_fee > 0 ? `$ ${el?.medication_management_fee}` : '',
          }));
          const IL_grp_by_id = groupByApartmentId(updated_arr, 'CommunityId');

          [
            'FeeTypeName',
            'pet_fee',
            'level_1',
            'level_2',
            'level_3',
            'level_4',
            'level_5',
            'level_6',
            'level_7',
            'level_8',
            'medication_management_fee',
            'fee_note',
          ].forEach((name, idx) => {
            const newRow = {
              dataKey: name,
              tableKey: 'a1qq' + idx + Math.random(),
              ...IL_grp_by_id,
            };
            table_apartment_data.push(newRow);
          });
        });
      }

      // for shop notes

      table_apartment_data.push({
        dataKey: 'Shop Notes',
        tableKey: 'a10shp' + Math.random(),
      });
      const array: any = [];
      const SN_grp_by_id = groupByApartmentId(shopnotes, 'CommunityId');
      let max_length = 0;
      Object.keys(SN_grp_by_id).forEach((key: any) => {
        if (max_length < SN_grp_by_id[key].length) {
          max_length = SN_grp_by_id[key].length;
        }
      });
      for (let i = 0; i < max_length; i++) {
        const obj: any = {};
        Object.keys(SN_grp_by_id).forEach((key) => {
          if (SN_grp_by_id[key][i]) {
            obj[key] = [SN_grp_by_id[key][i]];
          }
        });
        array.push(obj);
      }
      //
      for (let i = 0; i < max_length; i++) {
        [`${i + 1}`, 'request_date', 'file_url', 'attachment', 'request_notes'].forEach((name, index) => {
          const newRow = {
            dataKey: name,
            tableKey: 'a1qqshp' + index + Math.random(),
            ...array[i],
            // ...SN_grp_by_id,
          };
          //
          table_apartment_data.push(newRow);
        });
      }

      //for incentives

      table_apartment_data.push({
        dataKey: 'Incentives',
        tableKey: 'acvc1bydate' + Math.random(),
      });
      const combDateWiseFeeincentives = [dateWiseFeeInc.Community, ...dateWiseFeeInc.Compitetor];
      const date_wise_feeinc_by_living_type = groupByApartmentId(combDateWiseFeeincentives, 'Year');

      if (Object.keys(date_wise_feeinc_by_living_type).length > 0) {
        Object.keys(date_wise_feeinc_by_living_type).forEach((living_type) => {
          // table_apartment_data.push({
          //   dataKey: living_type,
          //   tableKey: "af2" + Math.random(),
          // });

          const type_data_arr = date_wise_feeinc_by_living_type[living_type];
          const updated_arr = type_data_arr.map((el: any) => ({
            ...el,
            community_fee: el?.community_fee > 0 ? `$ ${el?.community_fee}` : '',
            pet_fee: el?.pet_fee > 0 ? `$ ${el?.pet_fee}` : '',
            second_person_fee: el?.second_person_fee > 0 ? `$ ${el?.second_person_fee}` : '',
            additional_fee: el?.additional_fee > 0 ? `$ ${el?.additional_fee}` : '',
          }));

          //

          const IL_grp_by_id = groupByApartmentId(updated_arr, 'CommunityId');

          // const months: any[] = [];
          // let keyarray: string[] = [];
          Object.keys(IL_grp_by_id).forEach((key) => {
            IL_grp_by_id[key].forEach((item: any) => {
              item['incentive'] = item.Incentives;
              // keyarray.push(item.Year.toString());
            });
          });
          //
          // keyarray = keyarray.filter(
          //   (value, index) => keyarray.indexOf(value) === index
          // );
          //
          ["incentive"].forEach((name, index) => {
            const newRow = {
              dataKey: name,
              tableKey: 'bcddate' + index + Math.random(),
              ...IL_grp_by_id,
            };
            table_apartment_data.push(newRow);
          });
        });
      }

      //for occupancies

      table_apartment_data.push({
        dataKey: "Occupancies",
        tableKey: "occup" + Math.random(),
      });
      const combOccupancies = [
        occupancies.Community,
        ...occupancies.Compitetor,
      ];
      const occupancies_by_living_type = groupByApartmentId(
        combOccupancies,
        "Year"
      );

      if (Object.keys(occupancies_by_living_type).length > 0) {
        Object.keys(occupancies_by_living_type).forEach((living_type) => {
          // table_apartment_data.push({
          //   dataKey: living_type,
          //   tableKey: "af2" + Math.random(),
          // });

          const type_data_arr = occupancies_by_living_type[living_type];
          const updated_arr = type_data_arr.map((el: any) => ({
            ...el,
          }));

          //

          const IL_grp_by_id = groupByApartmentId(updated_arr, "CommunityId");

          // const months: any[] = [];
          // let keyarray: string[] = [];
          Object.keys(IL_grp_by_id).forEach((key) => {
            IL_grp_by_id[key].forEach((item: any) => {
              item["occupancies"] = item.occupancies;
              // keyarray.push(item.Year.toString());
            });
          });
          //
          // keyarray = keyarray.filter(
          //   (value, index) => keyarray.indexOf(value) === index
          // );
          //
          ["occupancies"].forEach((name, index) => {
            const newRow = {
              dataKey: name,
              tableKey: "bcoddate" + index + Math.random(),
              ...IL_grp_by_id,
            };
            table_apartment_data.push(newRow);
          });
        });
      }

      setDataSource(table_apartment_data);
    },
  });

  // pdf modal
  const [openPdfModal, setOpenPdfModal] = useState(false);

  return (
    <div className={classes.table_wrapper}>
      {/* <ExcelExportHelper
        data={dataSource}
        dataHeaders={dataHeaders}
        file_name_with_date={file_name_with_date}
      /> */}
      <Tooltip title="Export to Excel Sheet" placement="topRight">
        <Button
          onClick={() => exportToExcell(dataSource, dataHeaders, file_name_with_date)}
          type="text"
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 96 96"
              fill="#FFF"
              strokeMiterlimit="10"
              strokeWidth="2"
              style={{ width: '100%', height: 25 }}
            >
              <path
                stroke="#979593"
                d="M67.1716,7H27c-1.1046,0-2,0.8954-2,2v78 c0,1.1046,0.8954,2,2,2h58c1.1046,0,2-0.8954,2-2V26.8284c0-0.5304-0.2107-1.0391-0.5858-1.4142L68.5858,7.5858 C68.2107,7.2107,67.702,7,67.1716,7z"
              />
              <path fill="none" stroke="#979593" d="M67,7v18c0,1.1046,0.8954,2,2,2h18" />
              <path
                fill="#C8C6C4"
                d="M51 61H41v-2h10c.5523 0 1 .4477 1 1l0 0C52 60.5523 51.5523 61 51 61zM51 55H41v-2h10c.5523 0 1 .4477 1 1l0 0C52 54.5523 51.5523 55 51 55zM51 49H41v-2h10c.5523 0 1 .4477 1 1l0 0C52 48.5523 51.5523 49 51 49zM51 43H41v-2h10c.5523 0 1 .4477 1 1l0 0C52 42.5523 51.5523 43 51 43zM51 67H41v-2h10c.5523 0 1 .4477 1 1l0 0C52 66.5523 51.5523 67 51 67zM79 61H69c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C80 60.5523 79.5523 61 79 61zM79 67H69c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C80 66.5523 79.5523 67 79 67zM79 55H69c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C80 54.5523 79.5523 55 79 55zM79 49H69c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C80 48.5523 79.5523 49 79 49zM79 43H69c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C80 42.5523 79.5523 43 79 43zM65 61H55c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C66 60.5523 65.5523 61 65 61zM65 67H55c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C66 66.5523 65.5523 67 65 67zM65 55H55c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C66 54.5523 65.5523 55 65 55zM65 49H55c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C66 48.5523 65.5523 49 65 49zM65 43H55c-.5523 0-1-.4477-1-1l0 0c0-.5523.4477-1 1-1h10c.5523 0 1 .4477 1 1l0 0C66 42.5523 65.5523 43 65 43z"
              />
              <path
                fill="#107C41"
                d="M12,74h32c2.2091,0,4-1.7909,4-4V38c0-2.2091-1.7909-4-4-4H12c-2.2091,0-4,1.7909-4,4v32 C8,72.2091,9.7909,74,12,74z"
              />
              <path d="M16.9492,66l7.8848-12.0337L17.6123,42h5.8115l3.9424,7.6486c0.3623,0.7252,0.6113,1.2668,0.7471,1.6236 h0.0508c0.2617-0.58,0.5332-1.1436,0.8164-1.69L33.1943,42h5.335l-7.4082,11.9L38.7168,66H33.041l-4.5537-8.4017 c-0.1924-0.3116-0.374-0.6858-0.5439-1.1215H27.876c-0.0791,0.2684-0.2549,0.631-0.5264,1.0878L22.6592,66H16.9492z" />
            </svg>
          }
          style={{ marginBottom: 10 }}
        />
      </Tooltip>
      <Tooltip title="Export to PDF" placement="topRight">
        <Button
          type="text"
          icon={
            <svg
              enableBackground="new 0 0 334.371 380.563"
              version="1.1"
              viewBox="0 0 14 16"
              xmlSpace="preserve"
              xmlns="http://www.w3.org/2000/svg"
              style={{ width: '100%', height: 25 }}
            >
              <g transform="matrix(.04589 0 0 .04589 -.66877 -.73379)">
                <polygon
                  points="51.791 356.65 51.791 23.99 204.5 23.99 282.65 102.07 282.65 356.65"
                  fill="#fff"
                  strokeWidth="212.65"
                />
                <path
                  d="m201.19 31.99 73.46 73.393v243.26h-214.86v-316.66h141.4m6.623-16h-164.02v348.66h246.85v-265.9z"
                  strokeWidth="21.791"
                />
              </g>
              <g transform="matrix(.04589 0 0 .04589 -.66877 -.73379)">
                <polygon
                  points="282.65 356.65 51.791 356.65 51.791 23.99 204.5 23.99 206.31 25.8 206.31 100.33 280.9 100.33 282.65 102.07"
                  fill="#fff"
                  strokeWidth="212.65"
                />
                <path
                  d="m198.31 31.99v76.337h76.337v240.32h-214.86v-316.66h138.52m9.5-16h-164.02v348.66h246.85v-265.9l-6.43-6.424h-69.907v-69.842z"
                  strokeWidth="21.791"
                />
              </g>
              <g transform="matrix(.04589 0 0 .04589 -.66877 -.73379)" strokeWidth="21.791">
                <polygon points="258.31 87.75 219.64 87.75 219.64 48.667 258.31 86.38" />
                <path d="m227.64 67.646 12.41 12.104h-12.41v-12.104m-5.002-27.229h-10.998v55.333h54.666v-12.742z" />
              </g>
              <g transform="matrix(.04589 0 0 .04589 -.66877 -.73379)" fill="#ed1c24" strokeWidth="212.65">
                <polygon points="311.89 284.49 22.544 284.49 22.544 167.68 37.291 152.94 37.291 171.49 297.15 171.49 297.15 152.94 311.89 167.68" />
                <path d="m303.65 168.63 1.747 1.747v107.62h-276.35v-107.62l1.747-1.747v9.362h272.85v-9.362m-12.999-31.385v27.747h-246.86v-27.747l-27.747 27.747v126h302.35v-126z" />
              </g>
              <rect x="1.7219" y="7.9544" width="10.684" height="4.0307" fill="none" />
              <g transform="matrix(.04589 0 0 .04589 1.7219 11.733)" fill="#fff" strokeWidth="21.791">
                <path d="m9.216 0v-83.2h30.464q6.784 0 12.928 1.408 6.144 1.28 10.752 4.608 4.608 3.2 7.296 8.576 2.816 5.248 2.816 13.056 0 7.68-2.816 13.184-2.688 5.504-7.296 9.088-4.608 3.456-10.624 5.248-6.016 1.664-12.544 1.664h-8.96v26.368zm22.016-43.776h7.936q6.528 0 9.6-3.072 3.2-3.072 3.2-8.704t-3.456-7.936-9.856-2.304h-7.424z" />
                <path d="m87.04 0v-83.2h24.576q9.472 0 17.28 2.304 7.936 2.304 13.568 7.296t8.704 12.8q3.2 7.808 3.2 18.816t-3.072 18.944-8.704 13.056q-5.504 5.12-13.184 7.552-7.552 2.432-16.512 2.432zm22.016-17.664h1.28q4.48 0 8.448-1.024 3.968-1.152 6.784-3.84 2.944-2.688 4.608-7.424t1.664-12.032-1.664-11.904-4.608-7.168q-2.816-2.56-6.784-3.456-3.968-1.024-8.448-1.024h-1.28z" />
                <path d="m169.22 0v-83.2h54.272v18.432h-32.256v15.872h27.648v18.432h-27.648v30.464z" />
              </g>
            </svg>
          }
          style={{ marginBottom: 10, marginLeft: 5 }}
          // onClick={handlePrint}
          onClick={() => setOpenPdfModal(true)}
        />
      </Tooltip>
      {openPdfModal && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 9999,
            background: '#fff',
            padding: '10px 20px',
            boxSizing: 'border-box',
            overflow: 'scroll',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: 10,
            }}
          >
            <Button onClick={handlePrint} type="primary">
              Download
            </Button>
            <Button icon={<CloseCircleFilled />} onClick={() => setOpenPdfModal(false)} type="primary" />
          </div>
          <div ref={componentRef}>
            <Table
              ref={componentRef}
              pagination={false}
              columns={tableCollumns}
              dataSource={dataSource}
              bordered={true}
              loading={isLoading}
              showHeader={true}
              className={classes.tableWrapper}
              rowKey="tableKey"
              // scroll={{ x: "1300px", y: "57vh" }}
            />
          </div>
        </div>
      )}
      {/* <div ref={componentRef}> */}
      <Table
        pagination={false}
        columns={tableCollumns}
        dataSource={dataSource}
        bordered={true}
        loading={isLoading}
        showHeader={true}
        scroll={{ x: '1300px', y: '57vh' }}
        rowKey="tableKey"
      />
      {/* </div> */}
    </div>
  );
};

export default CMASummery;
