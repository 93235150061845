import { Modal, Table, Typography, Tooltip, Rate } from "antd";
import axios from "config/axiosPrivate";
import { useEffect, useState } from "react";
import type { ColumnsType } from "antd/es/table";
import GaugeGraph from "../pageComponents/Graphs/Guage";
import Competitors from "pages/PriceHealing/Competitors";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import { format } from "date-fns";

interface Props {
  open: boolean;
  handleClose: () => void;
  title?: string;
  loading?: boolean;
  communityInfo?: any;
}

const BirdEyeModal: React.FC<Props> = ({ open, handleClose, title, loading, communityInfo }) => {
  const [birdEyeData, setBirdEyeData] = useState([]);
  const [birdEyeReviewsData, setBirdEyeReviewsData] = useState<any>([]);
  const [birdEyeRatingData, setBirdEyeRatingData] = useState<any>(null);
  const [columns, setColumns] = useState<any[]>([]);

  function groupByApartmentId(data: any[], key: any) {
    const groups: any = {};

    data.forEach(function (val) {
      const category = val[key];
      if (category) {
        if (category in groups) {
          groups[category].push(val);
        } else {
          groups[category] = new Array(val);
        }
      }
    });
    return groups;
  }

  const reviewsColumns = [
    Table.EXPAND_COLUMN,
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: "16px" }}>
            Community/Competitor Name
          </Typography.Title>
        </div>
      ),
      dataIndex: "label",
      key: "label",
      width: 900,
      render: (value: any, record: any) => {
        return (
          <Typography.Paragraph style={{ margin: 0 }}>
            {record.label}
            {record?.type === "apartment" && (
              <span
                style={{
                  background: "#4CD7D2",
                  color: "#fff",
                  fontSize: "12px",
                  padding: "5px 9px",
                  borderRadius: "10px",
                  marginLeft: 6,
                  whiteSpace: "normal",
                }}
              >
                ME
              </span>
            )}
          </Typography.Paragraph>
        );
      },
    },
    {
      title: (
        <div>
          <Typography.Title level={4} style={{ margin: 0, fontSize: "16px" }}>
            Count
          </Typography.Title>
        </div>
      ),
      dataIndex: "count",
      key: "count",
      width: 100,
    },
  ];

  const BirdEye = () => {
    // https://pa.excalibursol.com:8447/birdeye/Get_CategoryReportbyLocation/2002/
    axios
      .get(`/birdeye/Get_CategoryReportbyLocation/${communityInfo?.id}/`)
      .then((res) => {
        const data = res.data.payload;

        // if (data?.Birdeye_category_report) {
        //   const community = data?.Birdeye_category_report.community[0];
        //   setBirdEyeData(community ? community : []);
        // }

        // const header = [
        //   {
        //     community_name: "Novellus Cypresswood",
        //     type: "apartment",
        //     id: "2002",
        //   },
        //   {
        //     community_name: "The Village At Gleannloch Farms",
        //     type: "competitor",
        //     id: "2007",
        //   },
        // ];

        const apartmentColumns = data?.Headers.map((column: any) => ({
          title: (
            <div>
              <Tooltip title={column.community_name} color="blue">
                <Typography.Title
                  level={4}
                  style={{ margin: 0, fontSize: "16px" }}
                >
                  {column.community_name.slice(0, 15)}...
                  {column?.type === "apartment" && (
                    <span
                      style={{
                        background: "#4CD7D2",
                        color: "#fff",
                        fontSize: "12px",
                        padding: "5px 9px",
                        borderRadius: "10px",
                        marginLeft: 6,
                        whiteSpace: "normal",
                      }}
                    >
                      ME
                    </span>
                  )}
                </Typography.Title>
              </Tooltip>
            </div>
          ),
          dataIndex: column.apartment_id,
          key: column.apartment_id,
          width: 100,
        }));

        const finalColumns = [
          {
            title: (
              <div>
                <Typography.Title level={4} style={{ margin: 0, fontSize: '16px' }}>
                  Category
                </Typography.Title>
              </div>
            ),
            dataIndex: "category",
            key: "category",
            width: 150,
          },
          ...apartmentColumns,
        ];

        setColumns(finalColumns);

        const group = groupByApartmentId(
          data?.Birdeye_category_report,
          "category"
        );

        const finalData: any = [];
        Object.keys(group).map((key) => {
          const obj: any = {
            category: key,
          };
          group[key].map((community: any) => {
            obj[community.apartment_id] = `${community.category_grade} ${community.category_avgscore}`;
          });
          finalData.push(obj);
        });

        setBirdEyeData(finalData);
      })
      .catch((err) => {
        setBirdEyeData([]);
      });
  };

  const BirdRatingEye = () => {
    axios
      .get(`/birdeye/Get_BirdeyeRatingReport/${communityInfo?.id}/`)
      .then((res) => {
        const data = res.data.payload;

        setBirdEyeRatingData(data.Birdeye_category_report);
      })
      .catch((err) => {});
  };

  const BirdEyeReviews = () => {
    axios
      .get(`/birdeye/Get_BirdeyeReviewReport/${communityInfo?.id}/`)
      .then((res) => {
        const data = res.data.payload;

        // const array = [
        //   {
        //     label: title,
        //     count: data.Birdeye_category_report.length,
        //     reviewsData: data.Birdeye_category_report,
        //   },
        // ];
        //
        const group = groupByApartmentId(
          data?.Birdeye_category_report,
          "apartment_id"
        );

        const { Headers } = data;
        const finalData = Headers.map((item: any) => {
          return {
            id: item.apartment_id,
            type: item.type,
            label: item.community_name,
            count: group[item.apartment_id]?.length || 0,
            reviewsData: group[item.apartment_id]
              ? group[item.apartment_id]
              : [],
          };
        });
        setBirdEyeReviewsData(finalData);
      })
      .catch((err) => {});
  };

  // https://pa.excalibursol.com:8447/birdeye/Get_BirdeyeRatingReportslist/169239002421935/
  useEffect(() => {
    if (communityInfo?.birdeye_business_id) {
      BirdEye();
      BirdRatingEye();
      BirdEyeReviews();
    }
  }, [communityInfo]);

  return (
    <Modal
      title={""}
      centered
      open={open}
      onCancel={handleClose}
      width={1200}
      footer={false}
    >
      <div style={{ height: "700px", overflow: "auto", marginTop: "40px" }}>
        <div>
          <img src="/pricing_assistant_logo.png" height="40" width="110" />{" "}
          <h2 style={{ textAlign: "center", marginTop: -50, marginBottom: 20 }}>
            {title}
          </h2>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "10px",
          }}
        >
          <div
            id="guage-container"
            className=""
            style={{
              display: "flex",
              // maxWidth: "37vw",
              overflow: "auto",
              // border: "1px solid red",
            }}
          >
            {birdEyeRatingData?.map((item: any, idx: any) => (
              <div
                style={{ textAlign: "center", width: "150px" }}
                key={idx + "rating"}
              >
                <Tooltip title={item.community_name} color="blue">
                  <p
                    className="c_rate_title"
                    style={{
                      fontSize: "14px",
                      color: "#000",
                      marginTop: 0,
                      display: "block",
                    }}
                  >
                    {" "}
                    {item.community_name.slice(0, 15)}...
                  </p>
                </Tooltip>
                <GaugeGraph
                  percentage={(item.average_rating || 0) / 5}
                  community={item.type == "apartment"}
                />
                <p
                  style={{
                    marginTop: "-9px",
                    // color: item.highScore && "#389e0d",
                    fontSize: "18px",
                  }}
                >
                  {item.average_rating || 0}
                </p>
                <Rate
                  allowHalf
                  defaultValue={item.average_rating || 0}
                  style={{ fontSize: "12px" }}
                  disabled
                />
              </div>
            ))}
            {/* {birdEyeRatingData?.competitors[0]?.map((item: any, idx: any) => (
            <div
              style={{ textAlign: "center", width: "150px" }}
              key={idx + "rating"}
            >
              <Tooltip title={item.community_name} color="blue">
                <p
                  className="c_rate_title"
                  style={{
                    fontSize: "14px",
                    color: "#000",
                    marginTop: 0,
                    display: "block",
                  }}
                ></p>
              </Tooltip>
              <GaugeGraph percentage={item.average_rating || 0 / 5} />
              <p
                style={{
                  marginTop: "-9px",
                  // color: item.highScore && "#389e0d",
                  fontSize: "18px",
                }}
              >
                {item.average_rating || 0}
              </p>
              <Rate
                allowHalf
                defaultValue={item.average_rating || 0}
                style={{ fontSize: "12px" }}
                disabled
              />
            </div>
          ))} */}
          </div>
        </div>
        <div>
          <Table
            columns={columns}
            dataSource={birdEyeData}
            rowKey="category"
            pagination={false}
            scroll={{ x: "1200px" }}
          />
        </div>
        <div style={{ marginTop: "20px" }}>
          <Typography.Title level={4}>Reviews</Typography.Title>
          <Table
            columns={reviewsColumns}
            dataSource={birdEyeReviewsData}
            // loading={birdEyeDataLoading}
            rowKey={(record: any) => "reviews" + record.id}
            pagination={false}
            expandable={{
              expandedRowRender: (record) => (
                <div style={{ margin: "0 30px 20px" }} key={record.id}>
                  {record.reviewsData.map((item: any, index: any) => (
                    <div style={{ marginBottom: "20px" }} key={item.reviewid}>
                      <h5
                        style={{
                          float: "right",
                          marginRight: "10px",
                          color: "#1976D2",
                        }}
                      >
                        {item.sourcetype}
                      </h5>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div style={{ marginRight: "15px" }}>
                          <img
                            src={item.reviewer.thumbnailurl}
                            height="30"
                            width="auto"
                          />
                        </div>
                        <div>
                          <Rate
                            allowHalf
                            defaultValue={item.rating || 0}
                            style={{ fontSize: "12px" }}
                            disabled
                          />
                          <div style={{ display: "flex" }}>
                            <h5>{item.reviewer.nickName || "Anonymous"}</h5>{" "}
                            <h3 style={{ margin: "0 5px" }}> . </h3>
                            <p>
                              {format(new Date(item.reviewdate), "MM-dd-yyyy")}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        {/* <p style={{ color: "#000" }}>{item.comments}</p> */}
                        <Typography.Paragraph
                          style={{ whiteSpace: "break-spaces", color: "#000" }}
                          ellipsis={
                            true
                              ? { rows: 6, expandable: true, symbol: "more" }
                              : false
                          }
                        >
                          {item.comments}
                        </Typography.Paragraph>
                      </div>
                      {item.response && (
                        <div
                          style={{
                            padding: "20px",
                            background: "#d5d5d588",
                            marginTop: "15px",
                          }}
                        >
                          <p style={{}}>
                            {`Business response on ${item.sourcetype}`}
                          </p>
                          <Typography.Paragraph
                            style={{
                              whiteSpace: "break-spaces",
                              color: "#000",
                            }}
                            ellipsis={
                              true
                                ? { rows: 3, expandable: true, symbol: "more" }
                                : false
                            }
                          >
                            {item.response}
                          </Typography.Paragraph>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ),
              //   rowExpandable: (record) =>
              //     reviews && reviews[record.id] ? true : false,
              expandIcon: ({ expanded, onExpand, record }) => (
                <>
                  {record.reviewsData.length > 0 ? (
                    <div>
                      {expanded ? (
                        <CaretUpOutlined onClick={(e) => onExpand(record, e)} />
                      ) : (
                        <CaretDownOutlined
                          onClick={(e) => onExpand(record, e)}
                        />
                      )}
                    </div>
                  ) : null}
                </>
              ),
              columnWidth: 1,
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default BirdEyeModal;
