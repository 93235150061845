import React from 'react';
import ReactRouterPrompt from 'react-router-prompt';

import { Modal, Typography } from 'antd';

interface Props {
  unsaved: boolean;
}

const { confirm } = Modal;

const UnsavedChangesPrompt = ({ unsaved }: Props) => {
  const delayPromise = (ms = 0) => {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  };
  return (
    <ReactRouterPrompt
      when={unsaved}
      beforeConfirm={async () => {
        await delayPromise();
        // await fetch("https://api.zippopotam.us/in/400072")
        //   .then((response) => response.text())
        //   .then((result) => alert("called beforeConfirm " + result))
        //   .catch((error) =>
      }}
      beforeCancel={() => delayPromise()}
    >
      {({ isActive, onConfirm, onCancel }) =>
        isActive && (
          <Modal
            title="Confirm Navigation"
            open={isActive}
            onOk={onConfirm}
            onCancel={onCancel}
            okButtonProps={{ disabled: false, children: 'Leave Page' }}
            okText="Leave Page"
          >
            <Typography.Text
              style={{
                fontSize: '16px',
                paddingLeft: '25px',
              }}
            >
              Your changes have not been saved. Are you sure you
            </Typography.Text>
            <br />
            <Typography.Text
              style={{
                fontSize: '16px',
                paddingLeft: '25px',
              }}
            >
              want to leave this page?
            </Typography.Text>
          </Modal>
        )
      }
    </ReactRouterPrompt>
  );
};

export default UnsavedChangesPrompt;
