import React from 'react';

import { Image } from 'antd';
import { motion } from 'framer-motion';

const Marker = ({ data, ...rest }: any) => {
  return (
    <motion.div
      whileHover={{
        scaleY: 1.2,
        originX: 0,
        originY: '100%',
        originZ: 0,
        transition: {
          type: 'spring',
          stiffness: 400,
        },
        cursor: 'pointer',
      }}
    >
      <Image src="/marker/red.png" width={50} preview={false} />
    </motion.div>
  );
};

export default Marker;
