import React from 'react';
import Dropzone from 'react-dropzone';

import { CloseCircleFilled, UploadOutlined } from '@ant-design/icons';
import { Button, Col, message, Modal, Row, Upload } from 'antd';

interface Props {
  open: boolean;
  handleClose: () => void;
  title?: string;
  handleAddData: (values: any) => void;
  loading?: boolean;
}

const { Dragger } = Upload;
const UploadBrochureFileModal: React.FC<Props> = ({ open, handleClose, title = '', handleAddData, loading }) => {
  const [file, setFile] = React.useState<any>(null);
  const onUpload = (files: any) => {
    if (files.length > 0) {
      setFile(files[0]);
    }
  };

  const handleSubmit = () => {
    const payload = {
      data: file,
      type: 'upload',
    };
    handleAddData(payload);
  };

  const onCloseModal = () => {
    setFile(null);
    handleClose();
  };

  const onChange = (info: any) => {
    const { status } = info.file;
    if (status !== 'uploading') {
      // Do nothing
    }
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      // Do nothing
    }
  };
  const dummyRequest = (options: any) => {
    //
    setTimeout(() => {
      if (options.file.size / (1024 * 1024) > 25) {
        options.onError('Size exeed 25 Mb');
        message.error(`${options.file.name} file upload failed.Size exeed 25 Mb`);
      } else {
        setFile(options.file);
        options.onSuccess('ok');
      }
    }, 1000);
  };

  return (
    <>
      <Modal title={title} centered open={open} onCancel={onCloseModal} width={500} footer={false}>
        <div>
          <Row gutter={[20, 10]}>
            <Col xs={24}>
              <Dragger
                multiple={false}
                maxCount={1}
                onChange={(info: any) => onChange(info)}
                onDrop={(acceptedFiles) => onUpload(acceptedFiles.dataTransfer.files)}
                customRequest={dummyRequest}
                onRemove={() => setFile(null)}
              >
                {/* {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "4px dotted #eee",
                          padding: 20,
                          borderRadius: 8,
                        }}
                      > */}
                <p className="ant-upload-drag-icon">
                  <UploadOutlined style={{ fontSize: '45px' }} />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-text">{'[Max 25MB]'}</p>
                {/* </div>
                    </div>
                  </section>
                )} */}
              </Dragger>
            </Col>
            {/* <Col xs={24}>
              {file && (
                <div
                  key={file?.name}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    background: "#eee",
                    padding: "4px",
                    marginBottom: 5,
                    borderRadius: "5px",
                  }}
                >
                  <p>{file?.name}</p>
                  <Button
                    icon={<CloseCircleFilled />}
                    size="middle"
                    danger
                    className="p-button-link"
                    style={{
                      padding: 0,
                      width: "auto",
                      height: "auto",
                      border: 0,
                      display: "block",
                      boxShadow: "none",
                      background: "none",
                    }}
                    onClick={() => setFile(null)}
                  />
                </div>
              )}
            </Col> */}
          </Row>

          <br />
          <Row>
            <Col xs={24}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  borderRadius: 8,
                  padding: 10,
                }}
              >
                <Button type="primary" htmlType="button" loading={loading} disabled={loading} onClick={handleSubmit}>
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default UploadBrochureFileModal;
