import React from 'react';

import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import GalleryPopup from './GalleryPopup';

interface DataType {
  id: string;
  community: any;
  exteriorfront: any;
  commonarea: string;
  apartmentimage: string;
  diningroom: string;
  otherimage: string;
  uncategorized: string;
  updatedDate: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: 'No',
    dataIndex: 'id',
    key: 'id',
    render: (text) => <span>{text}</span>,
  },
  {
    title: 'Community/Competitor',
    dataIndex: 'community',
    key: 'community',
  },
  {
    title: 'Exterior Front',
    dataIndex: 'exteriorfront',
    key: 'exteriorfront',
  },
  {
    title: 'Common Area',
    dataIndex: 'commonarea',
    key: 'commonarea',
  },
  {
    title: 'Apartment Image',
    dataIndex: 'apartmentimage',
    key: 'apartmentimage',
  },
  {
    title: 'Dining Room',
    dataIndex: 'diningroom',
    key: 'diningroom',
  },
  {
    title: 'Other Image',
    dataIndex: 'otherimage',
    key: 'otherimage',
  },
  {
    title: 'Uncategorized',
    dataIndex: 'uncategorized',
    key: 'uncategorized',
    render: (_, record) => (
      <div>
        <GalleryPopup url={record?.uncategorized} title={record?.community} />
      </div>
    ),
  },
  // {
  //   title: "Action",
  //   key: "action",
  //   render: (_, record) => (
  //     <Space size="middle" direction="vertical">
  //       edit | delete
  //     </Space>
  //   ),
  // },
];

const data: DataType[] = [
  {
    id: '1',
    community: 'Test title A',
    exteriorfront: 'Demo Text',
    commonarea: '-',
    apartmentimage: '-',
    diningroom: '-',
    otherimage: '-',
    uncategorized: 'https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg',
    updatedDate: new Date().toDateString(),
  },
  {
    id: '2',
    community: 'Test title B',
    exteriorfront: 'Demo Text',
    commonarea: '-',
    apartmentimage: '-',
    diningroom: '-',
    otherimage: '-',
    uncategorized:
      'https://render.fineartamerica.com/images/images-profile-flow/400/images-medium-large-5/awesome-solitude-bess-hamiti.jpg',
    updatedDate: new Date().toDateString(),
  },
  {
    id: '3',
    community: 'Test title C',
    exteriorfront: 'Demo Text',
    commonarea: '-',
    apartmentimage: '-',
    diningroom: '-',
    otherimage: '-',
    uncategorized:
      'https://render.fineartamerica.com/images/images-profile-flow/400/images-medium-large-5/awesome-solitude-bess-hamiti.jpg',
    updatedDate: new Date().toDateString(),
  },
];

const GalleryTable: React.FC = () => {
  return (
    <>
      <Table columns={columns} dataSource={data} scroll={{ x: 991 }} bordered />
    </>
  );
};

export default GalleryTable;
