import React, { useState } from 'react';

import { Button, Tabs } from 'antd';

import { useAppSelector } from 'store/hook';

import IndependentTab from './IndependentTab';

// type TabType = "Independent_living" | "Assisted_living" | "Memory_care";

interface Props {
  community_id: any;
}

const AccommodationTab = ({ community_id }: Props) => {
  const livingTypes = useAppSelector((state) => state.global.livingtypes);
  const [selectedTab, setSelectedTab] = useState(livingTypes[0]?.type || "");

  const items = livingTypes.map((item: any) => ({
    label: (
      <Button
        type="text"
        className={selectedTab == item.type ? 'p_healing_active_tab' : 'btnStyle'}
        shape="round"
        style={{
          marginRight: 10,
          height: 'auto',
          padding: '5px 10px',
          fontSize: 14,
        }}
        onClick={() => setSelectedTab(item.type)}
      >
        {item.type}
      </Button>
    ),
    key: item.type,
    children: <IndependentTab community_id={community_id} livingType={item.livingtype_order} />,
    style: {
      padding: 0,
    },
  }));
  return (
    <div style={{ padding: '0px 15px' }}>
      <Tabs activeKey={selectedTab} tabPosition="top" tabBarGutter={10} size="small" items={items} />
    </div>
  );
};

export default AccommodationTab;

// items={[
//   {
//     label: (
//       <Button
//         type="text"
//         className={
//           selectedTab === "Independent_living"
//             ? "p_healing_active_tab"
//             : "btnStyle"
//         }
//         shape="round"
//         style={{
//           marginRight: 10,
//           height: "auto",
//           padding: "5px 10px",
//           fontSize: 14,
//         }}
//         onClick={() => setSelectedTab("Independent_living")}
//       >
//         Independent Living
//       </Button>
//     ),
//     key: "Independent_living",
//     children: (
//       <IndependentTab community_id={community_id} livingType={1} />
//     ),
//     style: {
//       padding: 0,
//     },
//   },
//   {
//     label: (
//       <Button
//         type="text"
//         className={
//           selectedTab === "Assisted_living"
//             ? "p_healing_active_tab"
//             : "btnStyle"
//         }
//         shape="round"
//         style={{
//           marginRight: 10,
//           height: "auto",
//           padding: "5px 10px",
//           fontSize: 14,
//         }}
//         onClick={() => setSelectedTab("Assisted_living")}
//       >
//         Assisted Living
//       </Button>
//     ),
//     key: "Assisted_living",
//     children: (
//       <IndependentTab community_id={community_id} livingType={2} />
//     ),
//     style: {
//       padding: 0,
//     },
//   },
//   {
//     label: (
//       <Button
//         type="text"
//         className={
//           selectedTab === "Memory_care"
//             ? "p_healing_active_tab"
//             : "btnStyle"
//         }
//         shape="round"
//         style={{
//           marginRight: 10,
//           height: "auto",
//           padding: "5px 10px",
//           fontSize: 14,
//         }}
//         onClick={() => setSelectedTab("Memory_care")}
//       >
//         Memory Care
//       </Button>
//     ),
//     key: "Memory_care",
//     children: (
//       <IndependentTab community_id={community_id} livingType={3} />
//     ),
//     style: {
//       padding: 0,
//     },
//   },
// ]}
